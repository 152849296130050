import React, { useState } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  RadioGroup,
  FormControlLabel,
  Radio,
  TextField,
  FormHelperText,
} from "@material-ui/core";

interface DisableSensorModalProps {
  open: boolean;
  onClose: () => void;
  onSubmit: (reason: string) => void;
}

const DisableSensorModal: React.FC<DisableSensorModalProps> = ({
  open,
  onClose,
  onSubmit,
}) => {
  const [selectedReason, setSelectedReason] = useState("");
  const [missingFeatures, setMissingFeatures] = useState("");
  const [otherReason, setOtherReason] = useState("");
  const [error, setError] = useState("");

  const reasons = [
    "Client cancelled/End user cancelled",
    "Duplicate camera",
    "To be re-enabled later",
    "Camera moved to another site",
    "Costing (too expensive)",
    "System not performing adequately",
    "Unreliable - unstable product",
    "Analytics failed - missed detection",
    "Missing features",
    "Other",
  ];

  const handleSubmit = () => {
    if (!selectedReason) {
      setError("Please select a reason");
      return;
    }

    if (selectedReason === "Missing features" && !missingFeatures.trim()) {
      setError("Please provide details about missing features");
      return;
    }

    if (selectedReason === "Other" && !otherReason.trim()) {
      setError("Please provide details");
      return;
    }

    const finalReason =
      selectedReason === "Missing features"
        ? `Missing features: ${missingFeatures}`
        : selectedReason === "Other"
        ? `Other: ${otherReason}`
        : selectedReason;

    onSubmit(finalReason);
    handleClose();
  };

  const handleClose = () => {
    setSelectedReason("");
    setMissingFeatures("");
    setOtherReason("");
    setError("");
    onClose();
  };

  return (
    <Dialog open={open} onClose={handleClose} maxWidth="sm" fullWidth>
      <DialogTitle>Disable Sensor</DialogTitle>
      <DialogContent>
        <RadioGroup
          value={selectedReason}
          onChange={(e) => {
            setSelectedReason(e.target.value);
            setError("");
          }}
        >
          {reasons.map((reason) => (
            <FormControlLabel
              key={reason}
              value={reason}
              control={<Radio />}
              label={reason}
            />
          ))}
        </RadioGroup>
        {selectedReason === "Missing features" && (
          <TextField
            fullWidth
            multiline
            rows={3}
            variant="outlined"
            margin="normal"
            label="Please specify missing features"
            value={missingFeatures}
            onChange={(e) => {
              setMissingFeatures(e.target.value);
              setError("");
            }}
            error={selectedReason === "Missing features" && !!error}
          />
        )}
        {selectedReason === "Other" && (
          <TextField
            fullWidth
            multiline
            rows={3}
            variant="outlined"
            margin="normal"
            label="Please specify reason"
            value={otherReason}
            onChange={(e) => {
              setOtherReason(e.target.value);
              setError("");
            }}
            error={selectedReason === "Other" && !!error}
          />
        )}
        {error && <FormHelperText error>{error}</FormHelperText>}
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} color="primary">
          Cancel
        </Button>
        <Button onClick={handleSubmit} color="primary" variant="contained">
          Submit
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default DisableSensorModal;
