import React, {
  useState,
  useEffect,
  useRef,
  useCallback,
  useContext,
} from "react";
// import { Select, Tooltip } from "antd";
import { AppStore } from "../../../stores/AppStore";
import { useStyles } from "../../styling/global/global";
import { Site } from "../../../services/FunctionService";
import Alert from "@material-ui/lab/Alert";
import IconButton from "@material-ui/core/IconButton";
import Collapse from "@material-ui/core/Collapse";
import CloseIcon from "@material-ui/icons/Close";
import CustomModal from "../../UI/CustomModal";
import { MainContext } from "../../containers/HomePage/HomePage";
import OrgtreeAutocomplete from "../../UI/OrgtreeAutocomplete";
import MultiSiteSelect from "../../UI/MultiSiteSelect";
import CameraList from "../../UI/CameraList";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import CustomizedRadios from "../../UI/RadioBtn";
import { getBaseUrl } from "../../HelperFunctions";
import Spinner from "../../../spinner/Spinner";
import ListAlert from "../../UI/ListAlert";
import AlertViewerMulti from "../../UI/AlertViewerMulti";
import Autocomplete from "@material-ui/lab/Autocomplete";
import TextField from "@material-ui/core/TextField";
import CustomButton from "../../UI/CustomButton";
import TimeRange from "../../UI/DashboardUI/TimeRange";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import { ArrayToStringifiedArray } from "../../../hooks/ArrayToStringifiedArray";
import { Tooltip } from "@material-ui/core";
import Button from "@material-ui/core/Button";
import { Space, Table, Tag, Select as AntSelect } from "antd";
import Input from "@material-ui/core/Input";
import { ListItemText } from "@mui/material";

// import { AnyAaaaRecord } from "dns";
// import { transform } from "typescript";

interface Props {
  appStore: AppStore;
  setSelectedItem: (item: any, editing: boolean) => void;
}

interface review_logs {
  created: number;
  log_username: string;
  review_status: string;
  content: string;
}

interface EventType {
  doc_id: string;
  event_doc_id?: string;
  track_id: string;
  elastic_index: string;
  event_type: string;
  data_origin: string;
  site_id: number;
  site_name: string;
  sensor_id_str: string;
  sensor_human_name: string;
  media_url: string;
  image_height: number;
  image_width: number;
  created: number;
  classifier: string;
  probabilities: number[] | string[];
  class_labels: string[];
  message: string;
  improve_accuracy_flag: boolean;
  improve_accuracy_username: string | null;
  improve_accuracy_timestamp: string | null;
  suppress_flag: boolean;
  record_type: string;
  review_logs?: review_logs[] | [];
  reason_for_flag: string;
  rule_types?: string[];
  rules_processed?: string[];
  alert_config_ids?: number[];
}

interface objProp {
  inference_class: string;
  inference_probability: number;
  x0: number;
  y0: number;
  x1: number;
  y1: number;
}

const time_date = [
  "none",
  "Last 1 hour",
  "Last 3 hours",
  "Last 6 hours",
  "Last 12 hours",
  "Last 24 hours",
  "Last 2 days",
  "Last 7 days",
  "Last 2 weeks",
  "Last 30 days",
];

// const flag_predefined_comments = [
//   "Nuisance object detected",
//   "Real object incorrectly detected in the image",
//   "One or more real objects incorrectly detected in the image with large bounding box",
//   "Incorrect object detection label",
//   "No detection of objects at all",
//   "Other",
// ];

const flag_predefined_comments = [
  "Nuisance object detected",
  "Real object incorrectly detected i.e. Vehicle as a person",
  "Missed a Person",
  "Missed a Vehicle ",
  "Large bounding box incorrect detection",
  "Incorrect ‘count’ logic",
  "Detection outside the zone of interest",
  "Other",
];
const silence_predefined_comments = [
  "Nuisance object detected",
  "Unnecessary object detection alert",
  "Alerting on stationary vehicles",
  "Other",
];

const initialState = {
  doc_id: "no data",
  track_id: "no data",
  elastic_index: "no data",
  event_type: "no data",
  data_origin: "no data",
  site_id: 0,
  site_name: "no data",
  sensor_id_str: "no data",
  sensor_human_name: "no data",
  media_url: "no data",
  image_height: 0,
  image_width: 0,
  created: 0,
  classifier: "no data",
  probabilities: [],
  class_labels: [],
  message: "no data",
  improve_accuracy_flag: false,
  improve_accuracy_username: "no data",
  improve_accuracy_timestamp: "no data",
  suppress_flag: false,
  record_type: "no data",
  reason_for_flag: "",
};

const initialState1 = {
  doc_id: "no data",
  track_id: "no data",
  elastic_index: "no data",
  event_type: "no data",
  data_origin: "no data",
  site_id: 0,
  site_name: "no data",
  sensor_id_str: "no data",
  sensor_human_name: "no data",
  media_url: "no data",
  image_height: 0,
  image_width: 0,
  created: 1,
  classifier: "no data",
  probabilities: [],
  class_labels: [],
  message: "no data",
  improve_accuracy_flag: false,
  improve_accuracy_username: "no data",
  improve_accuracy_timestamp: "no data",
  suppress_flag: false,
  record_type: "no data",
  reason_for_flag: "",
};

const ReviewPageV3: React.FC<Props> = ({ appStore, setSelectedItem }) => {
  const [open, setOpen] = useState(false);

  const [flaggedImageCounts, setFlaggedImageCounts] = useState<any | null>(
    null
  );
  const [suppressedAlertCounts, setSuppressedAlertsCounts] = useState<
    any | null
  >(null);

  const [flaggedEventCount, setFlaggedEventCount] = useState<any>(-1);
  const [suppressedEventCount, setSuppressedEventCount] = useState<any>(-1);
  const [ruleType, setRuleType] = useState<string>("all");

  const { setShowGraph, showGraph } = useContext(MainContext);
  const [autoplay, setAutoplay] = React.useState(false);
  const [selectedAlert, setSelectedAlert] = useState<EventType>(initialState);

  const [selectedAlertData, setSelectedAlertData] = useState<
    { event_data: EventType; inference_data: EventType } | any
  >(null);

  const [alertList, setAlertList] = useState<EventType[]>([]);
  const [eventCount, setEventCount] = useState(0);
  const [alertEventsList, setAlertEventsList] = useState<
    { event_data: EventType; inference_data: EventType }[] | []
  >([]);
  const [groupid, setGroupId] = useState(-1);
  const classes = useStyles();
  const [siteid, setSiteId] = useState<number[]>([]);
  const [sensorId, setSensorId] = useState(-1);
  const [loading, setLoading] = React.useState<boolean>(false);
  const [loadingViewer, setLoadingViewer] = React.useState<boolean>(false);
  const [silenceAlert, setSilenceAlert] = React.useState<boolean>(false);
  const [loadingSite, setLoadingSite] = React.useState<boolean>(false);
  const [errMessage, setErrMessage] = React.useState<string>("");
  const [flagged_ids, setFlaggedIds] = React.useState<string[]>([]);
  const [silenced_ids, setSilencedIds] = React.useState<string[]>([]);
  const [stored_ids, setStoredIds] = React.useState<string[]>([]);
  const [alertType, setAlertType] = React.useState<string>("all");
  const [message, setMessage] = React.useState<string>("");
  const [comment, setComment] = React.useState<string>("");
  const [siteList, setSiteList] = React.useState<Site[]>([]);
  const [openToast, setOpenToast] = React.useState(false);
  const [err, setErr] = React.useState(false);
  const [page, setPage] = useState(1);
  const [hasMore, setHasMore] = useState(true);
  const [reloadImgs, setReloadImgs] = useState(false);
  const [loadImgs, setLoadImgs] = useState(false);
  const [event_timeout, setEventTimeout] = useState(false);
  const [openFlagModal, setOpenFlagModal] = useState(false);
  const [openSilenceModal, setOpenSilenceModal] = useState(false);
  const [showOtherComment, setShowOtherComment] = React.useState<string>("");

  const [showHideBtn, setShowHideBtn] = useState<boolean>(true);

  const [AutoPlay, setAutoPlay] = useState<any>(true);

  const [selectAllSites, setSelectAllSites] = React.useState(false);
  const [showOther, setShowOther] = React.useState(false);
  const [dateTitle, setDateTitle] = React.useState<string>("Last 24 hours");
  const [class_filter, setClassFilter] = React.useState<string>("all");
  const [object_detections, setObjectDetections] =
    React.useState<any>(undefined);
  const [newStartTime, setNewStartTime] = useState<number>(
    new Date(new Date().getTime() - 1000 * 60 * 60 * 24).getTime() / 1000
  );
  const [newStartTimeFilter, setNewStartTimeFilter] = useState<number>(
    // new Date(new Date().getTime() - 1000 * 60 * 60 * 24).getTime() / 1000
    0
  );

  const [savedTime, setSavedTime] = useState({ startT: 0, endT: 0 });

  const [inferenceIdsMap, setInferenceIdsMap] = useState<any[]>([]);

  const [newEndTimeFilterAl, setNewEndTimeFilterAl] = useState<number>(0);
  const [newEndTimeFilterInf, setNewEndTimeFilterInf] = useState<number>(0);

  const [newEndTime, setNewEndTime] = useState<number | null>(
    new Date().getTime() / 1000
  );
  const [openTimeRange, setOpenTimeRange] = React.useState(false);
  const [openInsights, setOpenInsights] = React.useState(false);
  const [openAlertReason, setOpenAlertReason] = React.useState(false);

  const observer = useRef<HTMLDivElement | any>();
  // ALL INFORMATION REGARDING THE NEXT PREVIOUS BUTTON COMES HERE ///////////////////////////////////////////////////////////////////
  const [number, setNumber] = useState(0);
  const [disablePrev, setDisablePrev] = useState("disabled");
  const [disableNext, setDisableNext] = useState("able");
  const [finalNextorPrev, setFinalNextorPrev] = useState<any>({});
  const [sendPrevORNext, setSendPrevORNext] = useState<any>("");
  // const [getActiveItem, setActiveItem] = useState<any>(selectedAlert?.doc_id);
  const [getActiveItem, setActiveItem] = useState<any>(alertList[0]?.doc_id);
  //GET NEXT ITEM AND PREVIOUS!
  const [getNextOFActiveItem, setNextOfActiveItem] = useState<any>({});
  const [getPreviousOFActiveItem, setPreviousOfActiveItem] = useState<any>({});

  const [flagButton, setFlagButton] = useState<any>("");

  const [getFilterString, setFilterString] = useState<any>("");
  const [ruleEvaluation, setRuleEvaluation] = useState<any>("");
  const [ruleEvaluationObject, setRuleEvaluationObject] = useState<any>(null);
  const [loadingRuleEvaluation, setLoadingRuleEvaluation] = useState(false);

  const columns = [
    {
      title: "Rule Type",
      dataIndex: "type",
      width: 250,
      key: "type",
    },
    {
      title: "Rule Name",
      dataIndex: "name",
      width: 250,
      key: "name",
    },
    {
      title: "Action",
      dataIndex: "id",
      width: 250,
      key: "id",
      render: (id: any, obj: any, i: any) => (
        <a
          onClick={() => {
            setOpenAlertReason(true);
            fetchRuleEvaluation(i);
          }}
        >
          View Details
        </a>
      ),
    },
  ];

  useEffect(() => {
    setActiveItem(alertList[0]?.doc_id);
    setDisableNext("able");
    setDisablePrev("disabled");
  }, [alertList, alertEventsList]);

  const getNextItemHandler = () => {
    setAutoPlay(true);
    setNumber(Math.floor(Math.random() * 10) + 1);
    const indexOFActive = alertList.findIndex(
      (alert) => alert.doc_id === getActiveItem
    );
    if (indexOFActive === alertList.length - 1) {
      setNextOfActiveItem(alertList[indexOFActive]);
      setDisableNext("disabled");
    } else {
      setDisablePrev("able");
      setNextOfActiveItem((prev: any) => {
        return alertList[indexOFActive + 1];
      });
      setActiveItem(alertList[indexOFActive + 1].doc_id);
      setSelectedAlert(alertList[indexOFActive + 1]);
      setSendPrevORNext((prev: any | string) => {
        if (prev === "") {
          return "Send_Next";
        } else if (prev === "Send_Prev") {
          return "Send_Next";
        } else if (prev === "Send_Next") {
          return "Send_Next";
        }
      });
    }

    if (selectedAlertData.inference_data?.length > 1) {
      setAutoPlay(true);
    } else if (selectedAlertData.inference_data?.length <= 1) {
      setAutoPlay(false);
    }
  };

  const getPreviousItemHandler = () => {
    setAutoPlay(true);
    const indexOFActive = alertList.findIndex(
      (alert) => alert.doc_id === getActiveItem
    );
    if (indexOFActive === 0) {
      setPreviousOfActiveItem(alertList[indexOFActive]);
      setDisablePrev("disabled");
    } else {
      setDisableNext("able");
      setPreviousOfActiveItem(alertList[indexOFActive - 1]);
      setActiveItem(alertList[indexOFActive - 1].doc_id);
      setSelectedAlert(alertList[indexOFActive - 1]);

      setSendPrevORNext((prev: any | string) => {
        if (prev === "") {
          return "Send_Prev";
        } else if (prev === "Send_Prev") {
          return "Send_Prev";
        } else if (prev === "Send_Next") {
          return "Send_Prev";
        }
      });
    }
    if (selectedAlertData.inference_data?.length > 1) {
      setAutoPlay(true);
    } else if (selectedAlertData.inference_data?.length <= 1) {
      setAutoPlay(false);
    }
  };

  const [prevNextData, setPrevNextData] = useState<any>({});

  useEffect(() => {
    const indexOFActive = alertList.findIndex(
      (alert) => alert.doc_id === getActiveItem
    );
    if (indexOFActive === alertList.length - 1 && alertList.length !== 0) {
      setDisableNext("disabled");
    }
    if (indexOFActive === 0) {
      setDisablePrev("disabled");
    }
    if (sendPrevORNext === "Send_Prev") {
      const index = alertEventsList.findIndex(
        (event: any) =>
          event.event_data.doc_id === getPreviousOFActiveItem.doc_id
      );
      setLoadingViewer(true);
      setTimeout(() => {
        setLoadingViewer(false);
      }, 400);
      setSelectedAlertData({
        event_data: {
          object_detections: getPreviousOFActiveItem?.object_detections,
          advanced_rule_name: getPreviousOFActiveItem?.rule_name,
          advanced_rule_type: getPreviousOFActiveItem?.rule_type,
          class_labels: getPreviousOFActiveItem?.class_labels,
          classifier: getPreviousOFActiveItem?.classifier,
          created: getPreviousOFActiveItem?.created,
          data_origin: getPreviousOFActiveItem?.data_origin,
          doc_id: getPreviousOFActiveItem?.doc_id,
          elastic_index: getPreviousOFActiveItem?.elastic_index,
          event_type: getPreviousOFActiveItem?.event_type,
          image_height: getPreviousOFActiveItem?.image_height,
          image_width: getPreviousOFActiveItem?.image_width,
          improve_accuracy_flag: getPreviousOFActiveItem?.improve_accuracy_flag,
          improve_accuracy_timestamp:
            getPreviousOFActiveItem?.improve_accuracy_timestamp,
          improve_accuracy_username:
            getPreviousOFActiveItem?.improve_accuracy_username,
          media_url: getPreviousOFActiveItem?.media_url,
          message: getPreviousOFActiveItem?.message,
          probabilities: getPreviousOFActiveItem?.probabilities,
          reason_for_flag: getPreviousOFActiveItem?.reason_for_flag,
          record_type: getPreviousOFActiveItem?.record_type,
          review_logs: getPreviousOFActiveItem?.review_logs,
          sensor_human_name: getPreviousOFActiveItem?.sensor_human_name,
          sensor_id_str: getPreviousOFActiveItem?.sensor_id_str,
          site_id: getPreviousOFActiveItem?.site_id,
          site_name: getPreviousOFActiveItem?.site_name,
          suppress_flag: getPreviousOFActiveItem?.suppress_flag,
          track_id: getPreviousOFActiveItem?.track_id,
          native_width: getPreviousOFActiveItem?.native_width,
          native_height: getPreviousOFActiveItem?.native_height,
          native_depth: getPreviousOFActiveItem?.native_depth,
          isAlpr: getPreviousOFActiveItem?.isAlpr ? true : false,
        },
        inference_data: alertEventsList[index]?.inference_data,
      });
    } else if (sendPrevORNext === "Send_Next") {
      const index = alertEventsList.findIndex(
        (event: any) => event.event_data.doc_id === getNextOFActiveItem.doc_id
      );

      setLoadingViewer(true);
      setTimeout(() => {
        setLoadingViewer(false);
      }, 400);

      setSelectedAlertData({
        event_data: {
          object_detections: getNextOFActiveItem?.object_detections,
          advanced_rule_name: getNextOFActiveItem?.rule_name,
          advanced_rule_type: getNextOFActiveItem?.rule_type,
          class_labels: getNextOFActiveItem?.class_labels,
          classifier: getNextOFActiveItem?.classifier,
          created: getNextOFActiveItem?.created,
          data_origin: getNextOFActiveItem?.data_origin,
          doc_id: getNextOFActiveItem?.doc_id,
          elastic_index: getNextOFActiveItem?.elastic_index,
          event_type: getNextOFActiveItem?.event_type,
          image_height: getNextOFActiveItem?.image_height,
          image_width: getNextOFActiveItem?.image_width,
          improve_accuracy_flag: getNextOFActiveItem?.improve_accuracy_flag,
          improve_accuracy_timestamp:
            getNextOFActiveItem?.improve_accuracy_timestamp,
          improve_accuracy_username:
            getNextOFActiveItem?.improve_accuracy_username,
          media_url: getNextOFActiveItem?.media_url,
          message: getNextOFActiveItem?.message,
          probabilities: getNextOFActiveItem?.probabilities,
          reason_for_flag: getNextOFActiveItem?.reason_for_flag,
          record_type: getNextOFActiveItem?.record_type,
          review_logs: getNextOFActiveItem?.review_logs,
          sensor_human_name: getNextOFActiveItem?.sensor_human_name,
          sensor_id_str: getNextOFActiveItem?.sensor_id_str,
          site_id: getNextOFActiveItem?.site_id,
          site_name: getNextOFActiveItem?.site_name,
          suppress_flag: getNextOFActiveItem?.suppress_flag,
          track_id: getNextOFActiveItem.track_id,
          native_width: getNextOFActiveItem?.native_width,
          native_height: getNextOFActiveItem?.native_height,
          native_depth: getNextOFActiveItem?.native_depth,
          isAlpr: getNextOFActiveItem?.isAlpr ? true : false,
        },
        inference_data: alertEventsList[index]?.inference_data,
      });
    } else if (sendPrevORNext === "") {
      setPrevNextData({});
    }
  }, [sendPrevORNext, getNextOFActiveItem, getPreviousOFActiveItem, number]);

  const comp =
    sendPrevORNext === ""
      ? selectedAlertData?.event_data?.doc_id
      : prevNextData?.event_data?.doc_id;

  const comp2 =
    sendPrevORNext === ""
      ? selectedAlertData?.event_data?.doc_id
      : prevNextData?.event_data?.doc_id;

  const comp3 =
    sendPrevORNext === ""
      ? selectedAlertData?.event_data?.site_id
      : prevNextData?.event_data?.site_id;

  const tempSiteName =
    sendPrevORNext === ""
      ? selectedAlertData?.event_data?.site_name
      : prevNextData?.event_data?.site_name;
  const tempSiteName2 =
    sendPrevORNext === ""
      ? selectedAlertData?.event_data?.site_name
      : prevNextData?.event_data?.site_name;
  // ALL STATES FOR THE BUTTONS END HERE!

  // ALL INFORMATION REGARDING NEXT AND PREVIOUS BUTTONS ENDS HERE //////////////////////////////////////////////////////////////////////

  const lastImage = useCallback(
    async (node: any) => {
      if (loading) return;
      if (observer.current) observer!.current!.disconnect();
      observer!.current! = new IntersectionObserver(async (entries) => {
        if ((entries[0].isIntersecting && hasMore) || reloadImgs) {
          // setLoading(true);

          if (alertType === "alerted") {
            fetchAlertEvents(false, newStartTimeFilter);
          } else if (alertType === "filtered") {
            fetchAInfEvents(false, newStartTimeFilter);
          } else if (alertType === "flagged") {
            fetchFlaggedInf(false, newStartTimeFilter);
          } else if (alertType === "silenced" || alertType === "unsilenced") {
            fetchData(false, newStartTimeFilter);
          } else {
            await fetchAllEvents(false, newStartTimeFilter);
          }
        }
      });
      if (node) observer!.current!.observe(node);
    },
    [
      loading,
      hasMore,
      reloadImgs,
      newStartTimeFilter,
      alertType,
      newEndTimeFilterInf,
      newEndTimeFilterAl,
    ]
  );

  const handleToastClose = () => {
    setMessage("");
    setErr(false);
    setOpenToast(false);
  };

  const applyTime = (startTime: number, endTime: number) => {
    setNewStartTime(startTime);
    setNewEndTime(endTime);
    setOpenTimeRange(false);
  };

  const handleItemSelection = async (value: any) => {
    let type = "site";
    if (typeof value === "number") {
      type = "org";
    }

    if (type === "site") {
      value = siteList.find((item) => item.site_id === value.site_id);
    }
    if (value) {
      setLoading(true);
      const token = await appStore?.authService.getAuthorisedToken();
      if (token) {
        var rest_org = await appStore?.functionService.getOrgOneLevel(
          token!,
          value.org_id
        );

        if (rest_org) {
          if (rest_org.orgs == undefined) {
            rest_org.orgs = [];
          }
          await appStore?.authService.setBreadcrumbOrgs(rest_org);
          appStore?.helpers.setCurrentOrgId(value.org_id);
          let temp_site: Site = value;
          temp_site.type = "site";
          temp_site.name = value.site_name;
          setSelectedItem(temp_site, false);
        }
      }
      setLoading(false);
      // closeModal();
    }
  };

  function getLastTwoWeeksDate() {
    const now = new Date();
    return new Date(now.getTime() - 14 * 24 * 60 * 60 * 1000).getTime() / 1000;
  }

  function getLastHourDate() {
    const now = new Date();
    return new Date(now.getTime() - 24 * 60 * 60 * 1000).getTime() / 1000;
  }

  // New Silenced or unSilenced Alerts
  const fetchData = async (reset: boolean = true, startTime: number = 0) => {
    if (startTime) {
      setLoadImgs(true);
    } else {
      setLoading(true);
    }
    var token = await appStore?.authService.getAuthorisedToken();
    var baseUrl = getBaseUrl();
    if (token) {
      try {
        const request = JSON.stringify({
          org_id: groupid,
          site_ids: !selectAllSites ? siteid.join() : null,
          sensor_ids: sensorId > 0 ? `${sensorId}` : null,
          suppress_flag: alertType === "silenced" ? true : false,
          created_start: newStartTime || getLastTwoWeeksDate(),
          created_end: newEndTime,
        });

        const res = await fetch(baseUrl + "/review_suppressed_alerts", {
          method: "post",
          headers: {
            Accept: "application/json",
            Authorization: "Bearer " + token,
            "Content-Type": "application/json",
          },
          body: request,
        });

        const result = await (await res).json();

        let flagged_alertIds: string[] = [];
        let fmt_alerts: any[] = [];
        let alert_l: EventType[] = [];
        if (result && result.success) {
          // let ordered = result.msg
          let ordered = result.msg.sort(
            (a: any, b: any) => b.created - a.created
          );

          if (result.msg.length > 0 && alertType !== "all") {
            setNewStartTimeFilter(ordered[ordered.length - 1].created - 0.001);
          }
          ordered.forEach((i: any, indx: number) => {
            let obj_data = {
              doc_id: i.alert_id,
              track_id: i.alert_id,
              elastic_index: i.alert_id,
              event_type:
                alertType === "silenced"
                  ? "silenced_image"
                  : "unsilenced_image",
              data_origin: "",
              site_id: i.site_id,
              site_name: i.site_name,
              sensor_id_str: i.sensor_id_str,
              sensor_human_name: i.sensor_human_name,
              media_url: i.media_url,
              image_height: i.alert_image_height,
              image_width: i.alert_image_width,
              created: i.created,
              classifier: i.classifier,
              probabilities: i.probabilities,
              class_labels: i.class_labels,
              message: i.message,
              improve_accuracy_flag: i.improve_accuracy_flag,
              improve_accuracy_username: null,
              improve_accuracy_timestamp: null,
              suppress_flag: alertType === "silenced" ? true : false,
              record_type: "alert",
              review_logs: i.review_logs,
              reason_for_flag: i.reason_for_flag,
              advanced_rule_name: i.rule_name || "",
              advanced_rule_type: i.rule_type || "",
            };
            fmt_alerts.push({
              event_data: obj_data,
              inference_data: [obj_data],
            });
            alert_l.push(obj_data);

            if (i.improve_accuracy_flag) {
              flagged_alertIds.push(obj_data.doc_id);
            }
          });

          if (startTime !== 0 || alertType === "all") {
            setFlaggedIds((prev) => [...prev, ...flagged_alertIds]);
            setAlertList((prev) => [...prev, ...alert_l].slice(0, eventCount));
            setAlertEventsList((prev) =>
              [...prev, ...fmt_alerts].slice(0, eventCount)
            );
          } else {
            setFlaggedIds(flagged_alertIds);
            setAlertList(alert_l);
            setAlertEventsList(fmt_alerts);
          }

          let list_len = alert_l.length;
          let list_size = alertList.length + list_len;
          setHasMore(result.msg.length > 0 && list_size <= eventCount);

          if (startTime === 0) {
            if (fmt_alerts[0] || alert_l[0]) {
              if (reset) {
                setSelectedAlert(alert_l[0]);
                setSelectedAlertData(fmt_alerts[0]);
                setSilenceAlert(
                  fmt_alerts[0].event_data.suppress_flag ? true : false
                );
              } else {
                let b = alert_l!.find(
                  (i: EventType) => i.doc_id === selectedAlert.doc_id
                );
                let c = fmt_alerts!.find(
                  (i: { event_data: EventType; inference_data: EventType }) =>
                    i.event_data.doc_id === selectedAlert.doc_id
                );
                if (b) {
                  setSelectedAlert(b!);
                } else {
                  setSelectedAlert(alert_l[0]);
                }
                if (c) {
                  setSelectedAlertData(c!);
                  setSilenceAlert(c!.suppress_flag ? true : false);
                } else {
                  setSelectedAlertData(fmt_alerts[0]);
                  setSilenceAlert(
                    fmt_alerts[0].event_data.suppress_flag ? true : false
                  );
                }
              }
            } else {
              setSelectedAlert(initialState);
            }
          }
        }

        setLoading(false);
        setLoadImgs(false);
      } catch (err) {
        setLoading(false);
        return err;
      }
    }
  };

  // fetch EventCounts
  const fetchEventCounts = async () => {
    setLoading(true);
    var token = await appStore?.authService.getAuthorisedToken();
    var baseUrl = getBaseUrl();

    if (token) {
      try {
        const request = JSON.stringify({
          top_org_id: siteid.join() && !selectAllSites ? null : groupid,
          site_ids:
            !selectAllSites && siteid.length > 0 && sensorId === -1
              ? `${siteid.join()},`
              : null,
          sensor_ids: sensorId > 0 ? `${sensorId},` : null,
          start_time_filter: newStartTime || getLastTwoWeeksDate(),
          end_time_filter: newEndTime,
          event_type:
            alertType === "alerted"
              ? "alerted_events"
              : alertType === "all"
              ? "all_events"
              : "filtered_out_events",
          rule_types: ruleType !== "all" ? `["${ruleType}"]` : undefined,
        });

        const res = await fetch(baseUrl + "/event_counts_v3", {
          method: "post",
          headers: {
            Accept: "application/json",
            Authorization: "Bearer " + token,
            "Content-Type": "application/json",
          },
          body: request,
        });
        const result = await (await res).json();
        if (result && result.success) {
          setEventCount(result.msg.event_count);
          setEventTimeout(result.msg.timeout);
        }
        // setLoading(false);
        return result.msg.event_count;
      } catch (err) {
        // setLoading(false);
        return err;
      } finally {
        // setLoading(false);
      }
    }
  };

  // fetch FLAGGED_COUNT
  const fetchFlaggedImageCounts = async () => {
    setLoading(true);
    var token = await appStore?.authService.getAuthorisedToken();
    var baseUrl = getBaseUrl();
    // const orgs = !groupid ?

    if (token) {
      try {
        const request = JSON.stringify({
          created_start: newStartTime,
          // start_time_filter: newStartTime || getLastTwoWeeksDate(),
          created_end: newEndTime,
          sensor_ids: sensorId > 0 ? `${JSON.stringify([sensorId])}` : null,
          site_ids:
            !selectAllSites && siteid.length > 0
              ? `${JSON.stringify(siteid)}`
              : null,
          // top_org_id: siteid.join() && !selectAllSites ? groupid : null,
          org_id: groupid,
          improve_accuracy_flag: alertType === "flagged" ? true : false,
        });

        const res = await fetch(baseUrl + "/flagged_image_counts", {
          method: "post",
          headers: {
            Accept: "application/json",
            Authorization: "Bearer " + token,
            "Content-Type": "application/json",
          },
          body: request,
        });
        const result = await (await res).json();
        if (result && result.success) {
          setFlaggedImageCounts(result.msg);
          setEventCount(result.msg.event_count);
          setFlaggedEventCount(result.msg.event_count);
          // setEventTimeout(result.msg.timeout);
        }
        // setLoading(false);
        return result.msg.event_count;
      } catch (err) {
        // setLoading(false);
        return err;
      } finally {
        // setLoading(false);
      }
    }
  };

  useEffect(() => {
    if (alertType === "flagged" && groupid) {
      fetchFlaggedImageCounts();
    } else {
      return;
    }
  }, [sensorId, siteid, groupid, alertType, newStartTime, newEndTime, page]);

  // fetch FLAGGED_COUNT
  const fetchSuppressedAlertsCount = async () => {
    setLoading(true);
    var token = await appStore?.authService.getAuthorisedToken();
    var baseUrl = getBaseUrl();

    if (token) {
      try {
        const request = JSON.stringify({
          // start_time_filter: newStartTime || getLastTwoWeeksDate(),
          // end_time_filter: newEndTime,
          created_start: newStartTime,
          // start_time_filter: newStartTime || getLastTwoWeeksDate(),
          created_end: newEndTime,
          sensor_ids: sensorId > 0 ? `${JSON.stringify([sensorId])}` : null,
          site_ids:
            !selectAllSites && siteid.length > 0
              ? `${JSON.stringify(siteid)}`
              : null,
          // top_org_id: siteid.join() && !selectAllSites ? groupid : null,
          org_id: groupid,
          improve_accuracy_flag: alertType === "silenced" ? true : false,
        });

        const res = await fetch(baseUrl + "/suppressed_alert_counts", {
          method: "post",
          headers: {
            Accept: "application/json",
            Authorization: "Bearer " + token,
            "Content-Type": "application/json",
          },
          body: request,
        });
        const result = await (await res).json();
        if (result && result.success) {
          setSuppressedAlertsCounts(result.msg);
          setEventCount(result.msg.event_count);
          setSuppressedEventCount(result.msg.event_count);
          // setEventTimeout(result.msg.timeout);
        }
        // setLoading(false);
        return result.msg.event_count;
      } catch (err) {
        // setLoading(false);
        return err;
      } finally {
        // setLoading(false);
      }
    }
  };

  useEffect(() => {
    if (alertType === "silenced" && groupid) {
      fetchSuppressedAlertsCount();
    } else {
      return;
    }
  }, [sensorId, siteid, groupid, alertType, newStartTime, newEndTime, page]);

  // Calculate startime filter for both alerts and inferences
  const getNewStartTime = (endTime: number, count: number, step = 1) => {
    // let end_time_filter = newEndTime!;
    let end_time_filter = endTime !== 0 ? endTime : newEndTime!;
    let start_time_filter = newStartTime || getLastHourDate();
    let event_count = count > 0 ? count : eventCount;
    let range = newEndTime! - start_time_filter;
    // let range = end_time_filter - start_time_filter;

    if (event_count < 1500 || range < 0) {
      return start_time_filter;
    }

    let average_time_per_event = range / event_count;

    let avg_time_per_five_hundred_events = 100 * average_time_per_event;

    let result = end_time_filter - avg_time_per_five_hundred_events * step;

    if (result > start_time_filter) {
      start_time_filter = result;
    }
    return start_time_filter;
  };

  // Re-order events
  const reOrderEvents = (selAlert: any) => {
    let finalEvent = { ...selAlert };
    let event_data = selAlert.event_data;
    let inf_data = selAlert.inference_data;
    if (event_data.media_url !== inf_data[0].media_url) {
      let filteredInferences = inf_data.filter(
        (item: any) => item.media_url !== event_data.media_url
      );
      filteredInferences.unshift(event_data);
      finalEvent = { ...finalEvent, inference_data: filteredInferences };
    }
    return finalEvent;
  };

  function reFormatEvents(items: any, type: string) {
    let result: any = [];

    items.map((data: any) => {
      let event_data;
      let inference_data: any = [];
      let alert_data: any = [];

      if ((type === "alert" || type === "all") && data.alerts[0]) {
        event_data = {
          doc_id: data.alerts[0].doc_id,
          event_doc_id: data.event_data?.doc_id,
          rule_types: data.event_data?.rule_types,
          rules_processed: data.event_data?.rules_processed,
          alert_config_ids: data.event_data?.rules_processed?.map(
            (item: any) => item?.alert_config_id || null
          ),
          track_id: data.event_data.track_id,
          elastic_index: data.alerts[0].elastic_index,
          event_type: data.alerts[0].alert_type,
          data_origin: data.event_data.data_origin,
          site_id: data.event_data.site_id,
          site_name: data.event_data.site_name,
          sensor_id_str: data.event_data.sensor_id_str,
          sensor_human_name: data.event_data.sensor_human_name,
          native_width: data.event_data.native_width,
          native_height: data.event_data.native_height,
          native_depth: data.event_data.native_depth,
          isAlpr:
            data.event_data?.rule_types &&
            data.event_data?.rule_types.join().includes("known_vehicle")
              ? true
              : false,
          vehicle_recognitions: data.alerts[0]?.vehicle_recognitions,
          media_url: data.alerts[0].media_url || "",
          image_height: data.alerts[0].image_height || 480,
          image_width: data.alerts[0].image_width || 640,
          created: data.event_data.created,
          classifier: data.alerts[0].classifier,
          probabilities: data.alerts[0].probabilities,
          class_labels: data.alerts[0].class_labels,
          message: data.alerts[0].message,
          improve_accuracy_flag: data.alerts[0].improve_accuracy_flag,
          improve_accuracy_username:
            data.alerts[0].improve_accuracy_username || null,
          improve_accuracy_timestamp:
            data.alerts[0].improve_accuracy_timestamp || null,
          suppress_flag: data.alerts[0].suppress_flag,
          record_type: data.alerts[0].record_type,
          review_logs: data.alerts[0].review_logs || null,
          reason_for_flag: data.alerts[0].reason_for_flag || "",
          advanced_rule_name: data.alerts[0].rule_name || "",
          advanced_rule_type: data.alerts[0].rule_type || "",
        };

        data.alerts.map((alert: any, index: number) => {
          if (index !== 0) {
            alert_data.push({
              doc_id: alert.doc_id,
              rule_types: data.event_data?.rule_types,
              event_doc_id: data.event_data?.doc_id,
              rules_processed: data.event_data?.rules_processed,
              alert_config_ids: data.event_data?.rules_processed?.map(
                (item: any) => item?.alert_config_id || null
              ),
              track_id: data.event_data.track_id,
              elastic_index: alert.elastic_index,
              event_type: type,
              data_origin: data.event_data.data_origin,
              site_id: data.event_data.site_id,
              site_name: data.event_data.site_name,
              sensor_id_str: data.event_data.sensor_id_str,
              sensor_human_name: data.event_data.sensor_human_name,
              media_url: alert.media_url || "",
              image_height: alert.image_height || 480,
              image_width: alert.image_width || 640,
              native_width: data.event_data.native_width,
              native_height: data.event_data.native_height,
              native_depth: data.event_data.native_depth,
              vehicle_recognitions: alert.vehicle_recognitions || [],
              created: alert.created,
              classifier: alert.classifier,
              probabilities: alert.probabilities,
              class_labels: alert.class_labels,
              message: alert.message,
              improve_accuracy_flag: alert.improve_accuracy_flag,
              improve_accuracy_username:
                alert.improve_accuracy_username || null,
              improve_accuracy_timestamp:
                alert.improve_accuracy_timestamp || null,
              suppress_flag: alert.suppress_flag,
              record_type: alert.record_type,
              review_logs: alert.review_logs || null,
              reason_for_flag: data.alerts[0].reason_for_flag || "",
              advanced_rule_name: alert?.rule_name || "",
              advanced_rule_type: alert?.rule_type || "",
            });
          }
        });

        data.inferences.map((inference: any) => {
          let arr: any = [];
          inference.object_detections &&
            inference.object_detections.map((item: any) => {
              if (arr.indexOf(item.inference_class) === -1) {
                arr.push(item.inference_class);
              }
            });

          inference_data.push({
            doc_id: inference.doc_id,
            rule_types: data.event_data?.rule_types,
            event_doc_id: data.event_data?.doc_id,
            rules_processed: data.event_data?.rules_processed,
            alert_config_ids: data.event_data?.rules_processed?.map(
              (item: any) => item?.alert_config_id || null
            ),
            track_id: data.event_data.track_id,
            elastic_index: inference.elastic_index,
            event_type: type,
            data_origin: inference.data_origin || data.event_data.data_origin,
            site_id: data.event_data.site_id,
            site_name: data.event_data.site_name,
            sensor_id_str: data.event_data.sensor_id_str,
            sensor_human_name: data.event_data.sensor_human_name,
            media_url: inference.media_url || "",
            image_height: inference.image_height || 480,
            image_width: inference.image_width || 640,
            native_width: data.event_data.native_width,
            native_height: data.event_data.native_height,
            native_depth: data.event_data.native_depth,
            vehicle_recognitions: inference?.vehicle_recognitions || [],
            created: inference.created,
            classifier: inference.classifier,
            probabilities: inference.probabilities,
            class_labels: inference.class_labels,
            message: inference.message,
            improve_accuracy_flag: inference.improve_accuracy_flag,
            improve_accuracy_username:
              inference.improve_accuracy_username || null,
            improve_accuracy_timestamp:
              inference.improve_accuracy_timestamp || null,
            suppress_flag: inference.suppress_flag,
            record_type: inference.record_type,
            review_logs: inference.review_logs || null,
            object_detections: inference.object_detections,
            reason_for_flag: inference.reason_for_flag || "",
            // object_detections,
            classes: arr,
          });
        });
        result.push({
          event_data: event_data,
          inference_data: [...alert_data, ...inference_data],
        });
      } else if (
        (type === "inference" || type === "all") &&
        data.inferences[0]
      ) {
        setInferenceIdsMap((prev) => [
          ...prev,
          { [`${data.event_data.doc_id}`]: data.inferences[0].doc_id },
        ]);
        event_data = {
          doc_id: data.event_data.doc_id,
          rule_types: data.event_data?.rule_types,
          event_doc_id: data.event_data?.doc_id,
          rules_processed: data.event_data?.rules_processed,
          alert_config_ids: data.event_data?.rules_processed?.map(
            (item: any) => item?.alert_config_id || null
          ),
          // doc_id: data.inferences[0].doc_id,
          track_id: data.event_data.track_id,
          elastic_index: data.inferences[0].elastic_index,
          event_type: data.inferences[0].alert_type,
          data_origin: data.event_data.data_origin,
          site_id: data.event_data.site_id,
          site_name: data.event_data.site_name,
          sensor_id_str: data.event_data.sensor_id_str,
          sensor_human_name: data.event_data.sensor_human_name,
          native_width: data.event_data.native_width,
          native_height: data.event_data.native_height,
          native_depth: data.event_data.native_depth,
          isAlpr:
            data.event_data?.rule_types &&
            data.event_data?.rule_types.join().includes("known_vehicle")
              ? true
              : false,
          vehicle_recognitions: data.inferences[0]?.vehicle_recognitions,
          media_url: data.inferences[0].media_url || "",
          image_height: data.inferences[0].image_height || 480,
          image_width: data.inferences[0].image_width || 640,
          created: data.event_data.created,
          classifier: data.inferences[0].classifier,
          probabilities: data.inferences[0].probabilities,
          class_labels: data.inferences[0].class_labels,
          message: data.inferences[0].message,
          improve_accuracy_flag: data.inferences[0].improve_accuracy_flag,
          improve_accuracy_username:
            data.inferences[0].improve_accuracy_username || null,
          improve_accuracy_timestamp:
            data.inferences[0].improve_accuracy_timestamp || null,
          suppress_flag: data.inferences[0].suppress_flag,
          record_type: data.inferences[0].record_type,
          review_logs: data.inferences[0].review_logs || null,
          reason_for_flag: data.inferences[0].reason_for_flag || "",
          object_detections: data.inferences[0].object_detections,
        };

        data.alerts.map((alert: any, index: number) => {
          if (index !== 0) {
            alert_data.push({
              doc_id: alert.doc_id,
              rule_types: data.event_data?.rule_types,
              event_doc_id: data.event_data?.doc_id,
              rules_processed: data.event_data?.rules_processed,
              alert_config_ids: data.event_data?.rules_processed?.map(
                (item: any) => item?.alert_config_id || null
              ),
              track_id: data.event_data.track_id,
              elastic_index: alert.elastic_index,
              event_type: type,
              data_origin: data.event_data.data_origin,
              site_id: data.event_data.site_id,
              site_name: data.event_data.site_name,
              sensor_id_str: data.event_data.sensor_id_str,
              sensor_human_name: data.event_data.sensor_human_name,
              media_url: alert.media_url || "",
              image_height: alert.image_height || 480,
              image_width: alert.image_width || 640,
              native_width: data.event_data.native_width,
              native_height: data.event_data.native_height,
              native_depth: data.event_data.native_depth,
              created: alert.created,
              classifier: alert.classifier,
              probabilities: alert.probabilities,
              class_labels: alert.class_labels,
              message: alert.message,
              vehicle_recognitions: alert?.vehicle_recognitions || [],
              improve_accuracy_flag: alert.improve_accuracy_flag,
              improve_accuracy_username:
                alert.improve_accuracy_username || null,
              improve_accuracy_timestamp:
                alert.improve_accuracy_timestamp || null,
              suppress_flag: alert.suppress_flag,
              record_type: alert.record_type,
              review_logs: alert.review_logs || null,
              reason_for_flag: alert.reason_for_flag || "",
            });
          }
        });
        data.inferences.map((inference: any) => {
          let arr: any = [];
          inference.object_detections &&
            inference.object_detections.map((item: any) => {
              if (arr.indexOf(item.inference_class) === -1) {
                arr.push(item.inference_class);
              }
            });
          inference_data.push({
            doc_id: inference.doc_id,
            rule_types: data.event_data?.rule_types,
            event_doc_id: data.event_data?.doc_id,
            rules_processed: data.event_data?.rules_processed,
            alert_config_ids: data.event_data?.rules_processed?.map(
              (item: any) => item?.alert_config_id || null
            ),
            track_id: data.event_data.track_id,
            elastic_index: inference.elastic_index,
            event_type: type,
            data_origin: inference.data_origin || data.event_data.data_origin,
            site_id: data.event_data.site_id,
            site_name: data.event_data.site_name,
            sensor_id_str: data.event_data.sensor_id_str,
            sensor_human_name: data.event_data.sensor_human_name,
            media_url: inference.media_url || "",
            image_height: inference.image_height || 480,
            image_width: inference.image_width || 640,
            native_width: data.event_data.native_width,
            native_height: data.event_data.native_height,
            native_depth: data.event_data.native_depth,
            vehicle_recognitions: inference?.vehicle_recognitions || [],
            created: inference.created,
            classifier: inference.classifier,
            probabilities: inference.probabilities,
            class_labels: inference.class_labels,
            message: inference.message,
            improve_accuracy_flag: inference.improve_accuracy_flag,
            improve_accuracy_username:
              inference.improve_accuracy_username || null,
            improve_accuracy_timestamp:
              inference.improve_accuracy_timestamp || null,
            suppress_flag: inference.suppress_flag,
            record_type: inference.record_type,
            review_logs: inference.review_logs || null,
            reason_for_flag: inference.reason_for_flag || "",
            object_detections: inference.object_detections,
            // object_detections,
            classes: arr,
          });
        });
        result.push({
          event_data: event_data,
          inference_data: [...alert_data, ...inference_data],
        });
      } else {
        console.debug("result.data no match");
      }
    });

    return result;
  }

  // fetch Alert Events.
  const fetchAlertEvents = async (
    reset: boolean = true,
    startTime: number = 0,
    count = -1,
    stepper = 1
  ) => {
    if (startTime) {
      setLoadImgs(true);
    } else {
      setLoading(true);
    }

    if (selectedAlert === initialState && count > 0) {
      setSelectedAlert(initialState1);
    }

    var token = await appStore?.authService.getAuthorisedToken();
    var baseUrl = getBaseUrl();
    var calc_start_time = getNewStartTime(startTime, count, stepper);
    // fetchAlertEvents(false, newStartTimeFilter, 0, 2);
    if (token) {
      try {
        // setLoading(true);
        const request = JSON.stringify({
          top_org_id: siteid.join() && !selectAllSites ? null : groupid,
          site_ids:
            !selectAllSites && siteid.length > 0 && sensorId === -1
              ? `${siteid.join()},`
              : null,
          sensor_ids: sensorId > 0 ? `${sensorId},` : null,
          start_time_filter: calc_start_time,
          // start_time_filter: newStartTime || getLastTwoWeeksDate(),
          end_time_filter: startTime !== 0 ? startTime : newEndTime,
          // max_event_count: 20,
          max_event_count: selectedAlert === initialState ? 2 : 20,
          event_type: "alerted_events",
          rule_types: ruleType !== "all" ? `["${ruleType}"]` : undefined,
        });

        const res = await fetch(baseUrl + "/events_v3", {
          method: "post",
          headers: {
            Accept: "application/json",
            Authorization: "Bearer " + token,
            "Content-Type": "application/json",
          },
          body: request,
        });
        const result = await (await res).json();
        let flagged_alertIds: string[] = [];
        let silenced_alertIds: string[] = [];
        if (result && result.success) {
          let fmt_alerts: any[] = [];
          let alert_l: EventType[] = [];

          result.data = reFormatEvents(result.data, "alert");

          let ordered = result.data.sort(
            (a: any, b: any) => b.event_data.created - a.event_data.created
          );

          if (result.data.length > 0) {
            setNewStartTimeFilter(
              ordered[result.data.length - 1].event_data.created - 0.001
            );
          }

          let step = stepper + 1;
          if (
            result.data.length === 0 &&
            calc_start_time > newStartTime &&
            step < 10
          ) {
            fetchAlertEvents(
              false,
              newStartTimeFilter || getLastHourDate(),
              count,
              step
            );
            return;
          }

          result.data.forEach((i: any, indx: number) => {
            let item = i;

            item.inference_data.unshift(i.event_data);
            fmt_alerts.push(item);

            alert_l.push(i.event_data);

            if (i.event_data.improve_accuracy_flag) {
              flagged_alertIds.push(i.event_data.doc_id);
            }

            i.inference_data.forEach((item: any, index: number) => {
              if (item.improve_accuracy_flag) {
                flagged_alertIds.push(item.doc_id);
              }

              if (item.suppress_flag) {
                silenced_alertIds.push(item.doc_id);
              }
            });

            // if (i.inference_data.improve_accuracy_flag) {
            //   flagged_alertIds.push(i.inference_data.doc_id);
            // }

            // silenced events
            if (i.event_data.suppress_flag) {
              silenced_alertIds.push(i.event_data.doc_id);
            }
            if (i.inference_data.suppress_flag) {
              silenced_alertIds.push(i.inference_data.doc_id);
            }
          });

          if (startTime !== 0 || alertType === "all") {
            setFlaggedIds((prev) => [...prev, ...flagged_alertIds]);
            setSilencedIds((prev) => [...prev, ...silenced_alertIds]);
            setAlertList((prev) => [...prev, ...alert_l].slice(0, eventCount));
            setAlertEventsList((prev) =>
              [...prev, ...fmt_alerts].slice(0, eventCount)
            );
          } else {
            setFlaggedIds(flagged_alertIds);
            setSilencedIds((prev) => [...prev, ...silenced_alertIds]);
            setAlertList(alert_l);
            setAlertEventsList(fmt_alerts);
          }

          let list_len = alert_l.length;
          let list_size = alertList.length + list_len;
          setHasMore(result.data.length > 0);

          if (startTime === 0) {
            if (fmt_alerts[0] || alert_l[0]) {
              if (reset) {
                setSelectedAlert(alert_l[0]);
                setSelectedAlertData(fmt_alerts[0]);
                setSilenceAlert(
                  fmt_alerts[0].event_data.suppress_flag ? true : false
                );
              } else {
                let b = alert_l!.find(
                  (i: EventType) => i.doc_id === selectedAlert.doc_id
                );
                let c = fmt_alerts!.find(
                  (i: { event_data: EventType; inference_data: EventType }) =>
                    i.event_data.doc_id === selectedAlert.doc_id
                );
                if (b) {
                  setSelectedAlert(b!);
                } else {
                  setSelectedAlert(alert_l[0]);
                }
                if (c) {
                  setSelectedAlertData(c!);
                  setSilenceAlert(c!.suppress_flag ? true : false);
                } else {
                  setSelectedAlertData(fmt_alerts[0]);
                  setSilenceAlert(
                    fmt_alerts[0].event_data.suppress_flag ? true : false
                  );
                }
              }
            } else {
              setSelectedAlert(initialState);
            }
          }
        }

        setLoading(false);
        setLoadImgs(false);
      } catch (err) {
        setLoading(false);
        return err;
      } finally {
        setReloadImgs(false);
      }
      setLoading(false);
      setLoadImgs(false);
    }
  };

  // Set object detections.
  useEffect(() => {
    if (selectedAlertData?.event_data?.object_detections) {
      if (class_filter !== "all" && class_filter !== "none") {
        setObjectDetections(
          selectedAlertData.event_data?.object_detections.filter(
            (item: any) => item.inference_class === class_filter
          )
        );
      } else if (class_filter === "none") {
        setObjectDetections(undefined);
      } else {
        setObjectDetections(selectedAlertData.event_data?.object_detections);
      }
    } else {
      setObjectDetections(undefined);
    }
  }, [selectedAlertData, class_filter, selectedAlert]);

  // fetch All Events.
  const fetchAllEvents = async (
    reset: boolean = true,
    startTime: number = 0,
    count = -1,
    stepper = 1
  ) => {
    if (startTime) {
      setLoadImgs(true);
    } else {
      setLoading(true);
    }

    if (selectedAlert === initialState && count > 0) {
      setSelectedAlert(initialState1);
    }

    var token = await appStore?.authService.getAuthorisedToken();
    var baseUrl = getBaseUrl();
    var calc_start_time = getNewStartTime(startTime, count, stepper);
    if (token) {
      try {
        // setLoading(true);
        const request = JSON.stringify({
          top_org_id: siteid.join() && !selectAllSites ? null : groupid,
          site_ids:
            !selectAllSites && siteid.length > 0 && sensorId === -1
              ? `${siteid.join()},`
              : null,
          sensor_ids: sensorId > 0 ? `${sensorId},` : null,
          start_time_filter: calc_start_time,
          end_time_filter: startTime !== 0 ? startTime : newEndTime,
          max_event_count: 20,
          event_type: "all_events",
          rule_types: ruleType !== "all" ? `["${ruleType}"]` : undefined,
        });

        const res_al = await fetch(baseUrl + "/events_v3", {
          method: "post",
          headers: {
            Accept: "application/json",
            Authorization: "Bearer " + token,
            "Content-Type": "application/json",
          },
          body: request,
        });
        const result1 = await (await res_al).json();

        const total_result = reFormatEvents(result1.data, "all")
          .sort((a: any, b: any) => b.event_data.created - a.event_data.created)
          .slice(0, 10);

        let flagged_alertIds: string[] = [];
        let silenced_alertIds: string[] = [];
        if (result1 && result1.success) {
          let fmt_alerts: any[] = [];
          let alert_l: EventType[] = [];

          if (total_result.length > 0) {
            setNewStartTimeFilter(
              total_result[total_result.length - 1].event_data.created - 0.001
            );
          }

          let step = stepper + 1;
          if (
            total_result.length === 0 &&
            calc_start_time > newStartTime &&
            step < 10
          ) {
            fetchAllEvents(
              false,
              newStartTimeFilter || getLastHourDate(),
              count,
              step
            );
            return;
          }

          total_result.forEach((i: any, indx: number) => {
            let item = { ...i };
            if (item.event_data.record_type === "alert") {
              item.inference_data.unshift(i.event_data);
            }

            alert_l.push(i.event_data);

            if (i.event_data.improve_accuracy_flag) {
              flagged_alertIds.push(i.event_data.doc_id);
            }

            i.inference_data.forEach((item: any, index: number) => {
              if (item.improve_accuracy_flag) {
                flagged_alertIds.push(item.doc_id);
              }

              if (item.suppress_flag) {
                silenced_alertIds.push(item.doc_id);
              }
            });

            // if (i.inference_data.improve_accuracy_flag) {
            //   flagged_alertIds.push(i.inference_data.doc_id);
            // }

            // silenced events
            if (i.event_data.suppress_flag) {
              silenced_alertIds.push(i.event_data.doc_id);
            }
            if (i.inference_data.suppress_flag) {
              silenced_alertIds.push(i.inference_data.doc_id);
            }

            if (i.inference_data.length === 0) {
              item.inference_data = [i.event_data];
            }
            fmt_alerts.push(reOrderEvents(item));
          });

          if (startTime !== 0) {
            setFlaggedIds((prev) => [...prev, ...flagged_alertIds]);
            setSilencedIds((prev) => [...prev, ...silenced_alertIds]);
            setAlertList((prev) => [...prev, ...alert_l].slice(0, eventCount));
            setAlertEventsList((prev) =>
              [...prev, ...fmt_alerts].slice(0, eventCount)
            );
          } else {
            setFlaggedIds(flagged_alertIds);
            setSilencedIds((prev) => [...prev, ...silenced_alertIds]);
            setAlertList(alert_l);
            setAlertEventsList(fmt_alerts);
          }

          setHasMore(total_result.length > 0);

          if (startTime === 0) {
            if (fmt_alerts[0] || alert_l[0]) {
              if (reset) {
                setSelectedAlert(alert_l[0]);
                setSelectedAlertData(fmt_alerts[0]);
                setSilenceAlert(
                  fmt_alerts[0].event_data.suppress_flag ? true : false
                );
              } else {
                let b = alert_l!.find(
                  (i: EventType) => i.doc_id === selectedAlert.doc_id
                );
                let c = fmt_alerts!.find(
                  (i: { event_data: EventType; inference_data: EventType }) =>
                    i.event_data.doc_id === selectedAlert.doc_id
                );
                if (b) {
                  setSelectedAlert(b!);
                } else {
                  setSelectedAlert(alert_l[0]);
                }
                if (c) {
                  setSelectedAlertData(c!);
                  setSilenceAlert(c!.suppress_flag ? true : false);
                } else {
                  setSelectedAlertData(fmt_alerts[0]);
                  setSilenceAlert(
                    fmt_alerts[0].event_data.suppress_flag ? true : false
                  );
                }
              }
            } else {
              setSelectedAlert(initialState);
            }
          }
        } else {
          console.debug("something is wrong", result1, result1.success);
        }

        setLoading(false);
        setLoadImgs(false);
      } catch (err) {
        setLoading(false);
        return err;
      } finally {
        setReloadImgs(false);
      }
    }
  };

  // fetch Filtered Out Events.
  const fetchAInfEvents = async (
    reset: boolean = true,
    startTime: number = 0,
    count = -1,
    stepper = 1
  ) => {
    if (startTime) {
      setLoadImgs(true);
    } else {
      setLoading(true);
    }

    if (selectedAlert === initialState && count > 0) {
      setSelectedAlert(initialState1);
    }

    var token = await appStore?.authService.getAuthorisedToken();
    var baseUrl = getBaseUrl();
    var calc_start_time = getNewStartTime(startTime, count, stepper);
    if (token) {
      try {
        // setLoading(true);
        const request = JSON.stringify({
          top_org_id: siteid.join() && !selectAllSites ? null : groupid,
          site_ids:
            !selectAllSites && siteid.length > 0 && sensorId === -1
              ? `${siteid.join()},`
              : null,
          sensor_ids: sensorId > 0 ? `${sensorId},` : null,
          start_time_filter: calc_start_time,
          // start_time_filter: newStartTime || getLastTwoWeeksDate(),
          end_time_filter: startTime !== 0 ? startTime : newEndTime,
          max_event_count: selectedAlert === initialState ? 2 : 20,
          event_type: "filtered_out_events",
          rule_types: ruleType !== "all" ? `["${ruleType}"]` : undefined,
        });

        const res = await fetch(baseUrl + "/events_v3", {
          method: "post",
          headers: {
            Accept: "application/json",
            Authorization: "Bearer " + token,
            "Content-Type": "application/json",
          },
          body: request,
        });
        const result = await (await res).json();

        let flagged_alertIds: string[] = [];
        let fmt_alerts: any[] = [];
        let alert_l: EventType[] = [];
        if (result && result.success) {
          result.data = reFormatEvents(result.data, "inference");

          let ordered = result.data.sort(
            (a: any, b: any) => b.event_data.created - a.event_data.created
          );

          if (result.data.length > 0 && alertType !== "all") {
            setNewStartTimeFilter(
              ordered[result.data.length - 1].event_data.created - 0.001
            );
          }

          let step = stepper + 1;
          if (
            result.data.length < 1 &&
            calc_start_time > newStartTime &&
            step < 10
          ) {
            fetchAInfEvents(
              false,
              newStartTimeFilter || getLastHourDate(),
              count,
              step
            );
            return;
          }

          ordered.forEach((i: any, indx: number) => {
            let item = { ...i };

            alert_l.push(i.event_data);

            if (i.event_data.improve_accuracy_flag) {
              flagged_alertIds.push(i.event_data.doc_id);
            }

            i.inference_data.forEach((item: any, index: number) => {
              if (item.improve_accuracy_flag) {
                flagged_alertIds.push(item.doc_id);
              }
            });

            // if (i.inference_data.improve_accuracy_flag) {
            //   flagged_alertIds.push(i.inference_data.doc_id);
            // }

            if (i.inference_data.length === 0) {
              item.inference_data = [i.event_data];
            }

            fmt_alerts.push(reOrderEvents(item));
          });

          if (startTime !== 0 || alertType === "all") {
            setFlaggedIds((prev) => [...prev, ...flagged_alertIds]);
            setAlertList((prev) => [...prev, ...alert_l].slice(0, eventCount));
            setAlertEventsList((prev) =>
              [...prev, ...fmt_alerts].slice(0, eventCount)
            );
          } else {
            setFlaggedIds(flagged_alertIds);
            setAlertList(alert_l);
            setAlertEventsList(fmt_alerts);
          }

          let list_len = alert_l.length;
          let list_size = alertList.length + list_len;
          setHasMore(
            result.data.length > 0 &&
              list_size <= (count === -1 ? eventCount : count)
          );

          if (startTime === 0) {
            if (fmt_alerts[0] || alert_l[0]) {
              if (reset) {
                setSelectedAlert(alert_l[0]);
                setSelectedAlertData(fmt_alerts[0]);
                if (fmt_alerts[0].object_detections) {
                  setObjectDetections(fmt_alerts[0].object_detections);
                }
                setSilenceAlert(
                  fmt_alerts[0].event_data.suppress_flag ? true : false
                );
              } else {
                let b = alert_l!.find(
                  (i: EventType) => i.doc_id === selectedAlert.doc_id
                );
                let c = fmt_alerts!.find(
                  (i: { event_data: EventType; inference_data: EventType }) =>
                    i.event_data.doc_id === selectedAlert.doc_id
                );
                if (b) {
                  setSelectedAlert(b!);
                } else {
                  setSelectedAlert(alert_l[0]);
                }
                if (c) {
                  setSelectedAlertData(c!);
                  if (c.object_detections) {
                    setObjectDetections(c.object_detections);
                  }
                  setSilenceAlert(c!.suppress_flag ? true : false);
                } else {
                  setSelectedAlertData(fmt_alerts[0]);
                  if (fmt_alerts[0].object_detections) {
                    setObjectDetections(fmt_alerts[0].object_detections);
                  }
                  setSilenceAlert(
                    fmt_alerts[0].event_data.suppress_flag ? true : false
                  );
                }
              }
            } else {
              setSelectedAlert(initialState);
            }
          }
        }

        setLoading(false);
        setLoadImgs(false);
      } catch (err) {
        setLoading(false);
        return err;
      } finally {
        setReloadImgs(false);
      }
      setLoading(false);
      setLoadImgs(false);
    }
  };

  // Fetch Images Flagged for training.
  const fetchFlaggedInf = async (
    reset: boolean = true,
    startTime: number = 0,
    count = -1,
    stepper = 1
  ) => {
    if (startTime) {
      setLoadImgs(true);
    } else {
      setLoading(true);
    }

    if (selectedAlert === initialState) {
      setSelectedAlert(initialState1);
    }

    var token = await appStore?.authService.getAuthorisedToken();
    var baseUrl = getBaseUrl();
    var calc_start_time = getNewStartTime(startTime, count, stepper);
    if (token) {
      try {
        // setLoading(true);

        const alertRequest = JSON.stringify({
          org_id: siteid.join() && !selectAllSites ? null : groupid,
          site_ids:
            !selectAllSites && siteid.length > 0 && sensorId === -1
              ? `${siteid.join()}`
              : null,
          sensor_ids: sensorId > 0 ? `${sensorId}` : null,
          // created_start: calc_start_time,
          created_start: newStartTime || getLastTwoWeeksDate(),
          created_end: startTime !== 0 ? startTime : newEndTime,
          max_count: 10,
          record_type: "alert",
        });

        const infRequest = JSON.stringify({
          org_id: siteid.join() && !selectAllSites ? null : groupid,
          site_ids:
            !selectAllSites && siteid.length > 0 && sensorId === -1
              ? `${siteid.join()}`
              : null,
          sensor_ids: sensorId > 0 ? `${sensorId}` : null,
          created_start: newStartTime || getLastTwoWeeksDate(),
          created_end: startTime !== 0 ? startTime : newEndTime,
          max_count: 10,
          record_type: "inference",
        });

        const res = await fetch(baseUrl + "/flagged_images_v3", {
          method: "post",
          headers: {
            Accept: "application/json",
            Authorization: "Bearer " + token,
            "Content-Type": "application/json",
          },
          body: alertRequest,
        });
        const result = await (await res).json();

        const res1 = await fetch(baseUrl + "/flagged_images_v3", {
          method: "post",
          headers: {
            Accept: "application/json",
            Authorization: "Bearer " + token,
            "Content-Type": "application/json",
          },
          body: infRequest,
        });

        const result1 = await (await res1).json();

        let flagged_alertIds: string[] = [];

        const total_result = [...result1.data, ...result.data];

        if (result && result1 && result.success && result1.success) {
          let fmt_alerts: any[] = [];
          let alert_l: EventType[] = [];

          // let ordered = result.data
          let ordered = total_result.sort(
            (a: any, b: any) => b.created - a.created
          );

          if (total_result.length > 0 && alertType !== "all") {
            setNewStartTimeFilter(ordered[ordered.length - 1].created - 0.001);
          }

          ordered.forEach((i: any, indx: number) => {
            fmt_alerts.push({ event_data: i, inference_data: [i] });
            alert_l.push(i);

            if (i.improve_accuracy_flag) {
              flagged_alertIds.push(i.doc_id);
            }
          });

          if (startTime !== 0 || alertType === "all") {
            setFlaggedIds((prev) => [...prev, ...flagged_alertIds]);
            setAlertList((prev) => [...prev, ...alert_l]);
            setAlertEventsList((prev) => [...prev, ...fmt_alerts]);
          } else {
            setFlaggedIds(flagged_alertIds);
            setAlertList(alert_l);
            setAlertEventsList(fmt_alerts);
          }

          let list_len = alert_l.length;
          let list_size = alertList.length + list_len;
          setHasMore(
            result.data.length > 0
            // &&
            //   list_size <= (count === -1 ? eventCount : count)
          );

          if (startTime === 0) {
            if (fmt_alerts[0] || alert_l[0]) {
              if (reset) {
                setSelectedAlert(alert_l[0]);
                setSelectedAlertData(fmt_alerts[0]);
                setSilenceAlert(fmt_alerts[0].suppress_flag ? true : false);
              } else {
                let b = alert_l!.find(
                  (i: EventType) => i.doc_id === selectedAlert.doc_id
                );
                let c = fmt_alerts!.find(
                  (i: { event_data: EventType; inference_data: EventType }) =>
                    i.event_data.doc_id === selectedAlert.doc_id
                );
                if (b) {
                  setSelectedAlert(b!);
                } else {
                  setSelectedAlert(alert_l[0]);
                }
                if (c) {
                  setSelectedAlertData(c!);
                  setSilenceAlert(c!.suppress_flag ? true : false);
                } else {
                  setSelectedAlertData(fmt_alerts[0]);
                  setSilenceAlert(fmt_alerts[0].suppress_flag ? true : false);
                }
              }
            } else {
              setSelectedAlert(initialState);
            }
          }
        }

        setLoading(false);
        setLoadImgs(false);
      } catch (err) {
        setLoading(false);
        return err;
      } finally {
        setReloadImgs(false);
      }
      setLoading(false);
      setLoadImgs(false);
    }
  };

  const handleChangeAuto = (value: any) => {
    if (!value) {
      return;
    }
    setShowOther(false);

    if (value === "Other") {
      setShowOther(true);
    }

    setComment(value);
  };

  // Fix what to reload
  const suppressAlert = async () => {
    setLoadingViewer(true);
    let site = siteList.find((item) => item.site_id == selectedAlert.site_id);
    if (site && site!.data_origin.includes("motioncam")) {
      setLoadingViewer(false);
      setOpenToast(true);
      setErr(true);
      await setTimeout(async () => {
        setOpenToast(false);
      }, 3000);
      return setMessage("Image could not be silenced");
    }
    var token = await appStore?.authService.getAuthorisedToken();
    var baseUrl = getBaseUrl();
    if (token) {
      try {
        const request = JSON.stringify({
          doc_ids: `${
            alertType === "silenced" || alertType === "unsilenced"
              ? selectedAlert!.doc_id
              : selectedAlertData!.event_data.doc_id
          }`,
          suppress_flag: silenceAlert ? false : true,
          reason_for_flag:
            comment === "Other" ? showOtherComment : comment || "",
        });

        const res = fetch(baseUrl + "/suppress_alert", {
          method: "put",
          headers: {
            Accept: "application/json",
            Authorization: "Bearer " + token,
            "Content-Type": "application/json",
          },
          body: request,
        });
        const result = await (await res).json();
        setOpenToast(true);
        if (result && result.success) {
          setErr(false);
          setShowOtherComment("");
          setMessage(
            silenceAlert
              ? "successfully unsilenced alert"
              : "successfully silenced alert"
          );
          if (!silenceAlert) {
            setFlaggedIds((prev) => [
              ...prev,
              selectedAlertData!.event_data.doc_id,
            ]);
            setSilencedIds((prev) => [
              ...prev,
              selectedAlertData!.event_data.doc_id,
            ]);
          } else {
            let temp_ids = [...silenced_ids];
            let indx = temp_ids.indexOf(selectedAlertData!.event_data.doc_id);
            if (indx >= 0) {
              temp_ids.splice(indx, 1);
              setSilencedIds(temp_ids);
            }
          }
        } else {
          setErr(true);
          setMessage("something went wrong");
        }
        setLoadingViewer(false);
      } catch (err) {
        setLoadingViewer(false);
        return err;
      } finally {
        await setTimeout(async () => {
          if (
            alertType === "silenced" ||
            alertType === "unsilenced" ||
            alertType === "flagged"
          ) {
            // setOpenToast(false);
            await fetchData();
          } else if (alertType === "alerted") {
            // fetchAlertEvents(false)
          } else {
            // fetchAlertEvents(false)
          }
        }, 1000);
        setLoadingViewer(false);
        await setTimeout(async () => {
          setOpenToast(false);
        }, 3000);
      }
    }
  };

  // Fetch Rule Evaluation
  const fetchRuleEvaluation = async (i: number) => {
    if (alertType === "silenced" || alertType === "unsilenced") {
      setOpenAlertReason(false);
      return;
    }
    setLoadingRuleEvaluation(true);

    var token = await appStore?.authService.getAuthorisedToken();
    var baseUrl = getBaseUrl();
    if (token) {
      try {
        const request = JSON.stringify({
          event_doc_id: `${selectedAlert?.event_doc_id}`,
          alert_config_id:
            selectedAlertData?.event_data.alert_config_ids?.length > 0
              ? selectedAlertData?.event_data.alert_config_ids[i]
              : null,
        });

        const res = fetch(baseUrl + "/rule_eval_explanation", {
          method: "post",
          headers: {
            Accept: "application/json",
            Authorization: "Bearer " + token,
            "Content-Type": "application/json",
          },
          body: request,
        });
        const result = await (await res).json();

        if (result && result.success) {
          setErr(false);
          setRuleEvaluation(result.msg);
          setRuleEvaluationObject(JSON.parse(result.msg));
        } else {
          setRuleEvaluation(result.msg);
        }
        setLoadingRuleEvaluation(false);
      } catch (err) {
        setRuleEvaluation(err);
        setLoadingRuleEvaluation(false);
        return err;
      } finally {
        setLoadingRuleEvaluation(false);
      }
    }
  };

  // Flag Images for Training
  const flagImages = async () => {
    setLoadingViewer(true);
    var baseUrl = getBaseUrl();
    var token = await appStore?.authService.getAuthorisedToken();

    let event_id = selectedAlertData!.event_data.doc_id;

    const foundObject = inferenceIdsMap.find(
      (item) => Object.keys(item)[0] === selectedAlertData!.event_data.doc_id
    );

    if (foundObject) {
      event_id = foundObject[`${selectedAlertData!.event_data.doc_id}`];
    }

    setMessage("");
    if (token) {
      try {
        let request = JSON.stringify({
          doc_ids:
            alertType === "silenced" || alertType === "unsilenced"
              ? selectedAlert!.doc_id
              : event_id,
          doc_type:
            alertType === "silenced" || alertType === "unsilenced"
              ? "alert"
              : selectedAlertData!.event_data.record_type,
          improve_accuracy_flag:
            alertType === "flagged" ||
            flagged_ids.findIndex(
              (id: string) => id === selectedAlertData?.event_data.doc_id
            ) > -1
              ? false
              : true,
          reason_for_flag:
            comment === "Other" ? showOtherComment : comment || "",
        });

        const res = await fetch(baseUrl + "/flag_for_training", {
          method: "PUT",
          headers: {
            Accept: "application/json",
            Authorization: "Bearer " + token,
            "Content-Type": "application/json",
          },
          body: request,
        });

        setOpenToast(true);
        await setTimeout(async () => {
          setOpenToast(false);
        }, 3000);

        const result = (await (await res).json()) as any;

        if (result.success) {
          if (
            flagged_ids.findIndex(
              (id: string) => id === selectedAlertData?.event_data.doc_id
            ) === -1
          ) {
            setFlaggedIds((prev) => [
              ...prev,
              selectedAlertData!.event_data.doc_id,
            ]);
          } else {
            let indx = flagged_ids.indexOf(
              selectedAlertData!.event_data.doc_id
            );
            if (indx > -1) {
              flagged_ids.splice(indx, 1);
            }
          }

          setErr(false);
          setShowOtherComment("");
          alertType === "flagged"
            ? setMessage("successfully unflagged image")
            : setMessage("successfully flagged image");
        }

        if (!result.success) {
          setErr(true);
          setMessage(
            result.msg || "Something went wrong, please try again later"
          );
        }
        setLoadingViewer(false);
      } catch (err) {
        setLoadingViewer(false);
      } finally {
        await setTimeout(async () => {
          if (alertType === "flagged") {
            await fetchFlaggedInf();
          } else if (alertType === "alerted") {
          } else {
          }
        }, 1000);
        setLoadingViewer(false);
        await setTimeout(async () => {
          setOpenToast(false);
        }, 3000);
      }
    }
  };

  // Fetch Alerts with grouped images
  useEffect(() => {
    const fetchImages = async () => {
      if (groupid >= 0) {
        setHasMore(true);
        setFlaggedIds([]);
        setSilencedIds([]);
        setAlertEventsList([]);
        setAlertList([]);
        setStoredIds([]);
        setEventTimeout(false);

        setSelectedAlert(initialState);
        if (alertType === "alerted") {
          let count = await fetchEventCounts();
          await fetchAlertEvents(true, 0, count);
        }
        if (alertType === "filtered") {
          let count = await fetchEventCounts();
          await fetchAInfEvents(true, 0, count);
        }

        if (alertType === "flagged") {
          await fetchFlaggedInf(true, 0);
        }

        if (alertType === "all") {
          let count = await fetchEventCounts();
          await fetchAllEvents(true, 0, 0, count);
        }
        if (alertType === "silenced" || alertType === "unsilenced") {
          fetchData();
        }
      } else {
        console.debug("org id is invalid");
      }
    };
    fetchImages();
  }, [
    sensorId,
    siteid,
    groupid,
    alertType,
    newStartTime,
    newEndTime,
    page,
    ruleType,
  ]);

  const options = [
    { value: "all", label: "All" },
    {
      value: "alert_zones",
      label: "Alert Zones",
    },
    {
      value: "alert_zones_and_counts",
      label: "Alert Zones and Counts",
    },
    { value: "snapshot", label: "Snapshot" },
    {
      value: "scene_change",
      label: "Scene Change",
    },
    {
      value: "known_vehicle",
      label: "Known Vehicle Filter",
    },
    {
      value: "suspicious_activity_self_storage",
      label: "Suspicious Activity",
    },
    {
      value: "thermal",
      label: "Thermal",
    },
  ];

  // const handleChange = (value: string) => {
  //   console.debug(`selected ${value}`);
  //   setRuleType(value);
  // };
  const handleChange = (event: any) => {
    console.debug(`selected ${event?.target?.value}`);
    console.debug(`changing`);
    setRuleType(event?.target?.value);
  };

  let toast: any = [
    <Collapse in={openToast}>
      {message && (
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            padding: 20,
            justifyContent: "center",
            zIndex: 100,
            // transform: "translate(0px, -100px)",
          }}
        >
          <Alert
            severity={err ? "error" : "success"}
            action={
              <IconButton
                aria-label="close"
                color="inherit"
                size="small"
                onClick={() => {
                  handleToastClose();
                  setOpenToast(false);
                }}
              >
                <CloseIcon fontSize="inherit" />
              </IconButton>
            }
          >
            {message}
          </Alert>
        </div>
      )}
    </Collapse>,
  ];

  const tableStyle: any = {
    borderCollapse: "collapse",
    width: "100%",
  };

  const thStyle: any = {
    backgroundColor: "rgb(31, 49, 97)",
    color: "white",
    fontWeight: "bold",
    textAlign: "left",
    padding: "5px",
  };

  const tdStyle: any = {
    backgroundColor: "rgb(240, 240, 240)",
    color: "black",
    textAlign: "left",
    padding: "5px",
  };

  return (
    <div
      className={classes.root_dashboard}
      style={{
        width: !showGraph ? 1380 : "100%",
        overflow: "hidden",
      }}
    >
      <Grid container spacing={1}>
        <CustomModal
          closeHandler={() => {
            setOpenFlagModal(false);

            setOpenSilenceModal(false);
            setShowOther(false);
            setComment("");
          }}
          open={openFlagModal || openSilenceModal}
          onContinueHandler={() => {
            if (openFlagModal) {
              setAutoPlay(false);
              flagImages();
            } else {
              suppressAlert();
            }
            setOpenFlagModal(false);
            setAutoPlay(false);

            setOpenSilenceModal(false);
            setShowOther(false);
            setComment("");
          }}
          continueAction={true}
          continue
          title={openFlagModal ? "Flag" : "Silence"}
        >
          <Autocomplete
            id="combo-box-demo"
            onChange={(event: React.ChangeEvent<any>, value: any) => {
              handleChangeAuto(value);
            }}
            options={
              openFlagModal
                ? flag_predefined_comments
                : silence_predefined_comments
            }
            getOptionLabel={(option) => option}
            style={{ width: 400, color: "#6D809D" }}
            renderInput={(params) => (
              <TextField
                {...params}
                label={
                  <span
                    className={classes.bold_text}
                    style={{ color: "#15325F" }}
                  >
                    {comment ? comment : "Please Select Reason / Skip"}
                  </span>
                }
                variant="outlined"
                value={groupid}
                className={classes.bold_text}
              />
            )}
          />
          {showOther && (
            <div>
              <TextField
                onChange={(e) => {
                  setShowOtherComment(e.target.value);
                }}
                style={{ color: "#15325F", marginTop: 20 }}
                label="Enter Your Comment"
                value={showOtherComment}
                margin="normal"
                fullWidth
                InputProps={{
                  className: classes.bold_text,
                }}
              />
            </div>
          )}
        </CustomModal>
        <CustomModal
          closeHandler={() => {
            setOpenTimeRange(false);
          }}
          open={openTimeRange}
        >
          <TimeRange
            applyChangesHandler={applyTime}
            backDateHandler={(e: number) => {
              setFlaggedIds([]);
              setSilencedIds([]);
              setAlertEventsList([]);
              setAlertList([]);
              setEventTimeout(false);
              setNewStartTime(e);
              setNewStartTimeFilter(e);
              setNewEndTime(null);
              setOpenTimeRange(false);
            }}
            savedTime={savedTime}
            title={dateTitle}
            setSavedTime={(e) => {
              setSavedTime(e);
            }}
            dateTitle={(e) => setDateTitle(e)}
            closeModal={() => setOpenTimeRange(false)}
          />
        </CustomModal>
        <CustomModal
          closeHandler={() => {
            setOpenInsights(false);
          }}
          open={openInsights}
        >
          <h3 style={{ fontWeight: "bold", textAlign: "center" }}>
            ALERT RULES
          </h3>
          {selectedAlertData &&
          selectedAlert?.rules_processed &&
          selectedAlert?.rules_processed.length > 0 ? (
            <Table
              pagination={false}
              style={{ margin: 20, width: 380 }}
              columns={columns}
              dataSource={selectedAlert?.rules_processed.map(
                (item: any, index: number) => {
                  return {
                    type: item.rule_type,
                    id: selectedAlert.doc_id,
                    name: item.rule_name,
                  };
                }
              )}
              size="middle"
            />
          ) : (
            <p
              style={{
                margin: 20,
                width: 380,
                textAlign: "center",
                marginTop: 40,
              }}
            >
              Rule insights are not available for this product and site
            </p>
          )}
          <CustomModal
            closeHandler={() => {
              setOpenAlertReason(false);
            }}
            open={openAlertReason}
          >
            <h3 style={{ fontWeight: "bold", textAlign: "center" }}>
              ALERT REASON
            </h3>
            {!loadingRuleEvaluation ? (
              <p
                style={{
                  margin: 20,
                  width: 600,
                  textAlign: "center",
                  marginTop: 40,
                }}
              >
                {/* {ruleEvaluation} */}
                {ruleEvaluationObject && (
                  <table style={tableStyle} border={0}>
                    <tbody>
                      <tr>
                        <th style={thStyle}>ZONE</th>
                        <td style={tdStyle}>
                          {ruleEvaluationObject.analytics.zone}
                        </td>
                      </tr>
                      <tr>
                        <th style={thStyle}>ALERT RAISED</th>
                        <td style={tdStyle}>
                          {ruleEvaluationObject.analytics.alert_raised}
                        </td>
                      </tr>
                      <tr>
                        <th style={thStyle}>RULE</th>
                        <td style={tdStyle}>
                          {ruleEvaluationObject.analytics.rule}
                        </td>
                      </tr>
                      {/* <tr>
          <th style={thStyle}>RULE DESCRIPTION</th>
          <td style={tdStyle}>{ruleEvaluationObject.analytics.rule_description}</td>
        </tr> */}
                      <tr>
                        <th style={thStyle}>EXPLANATION</th>
                        <td style={tdStyle}>
                          {ruleEvaluationObject.analytics.explanation}
                        </td>
                      </tr>
                    </tbody>
                  </table>
                )}
              </p>
            ) : (
              <div
                className={classes.bold_text}
                style={{
                  display: "flex",
                  paddingTop: 30,
                  justifyContent: "center",
                  alignItems: "center",
                  width: 300,
                }}
              >
                <Spinner />
              </div>
            )}
          </CustomModal>
        </CustomModal>
        <Grid item xs={12} sm={12} md={12} spacing={0}>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              minWidth: 900,
              paddingRight: 10,
              marginTop: 0,
            }}
          >
            <OrgtreeAutocomplete
              groupid={groupid}
              enabled={true}
              appStore={appStore}
              setGroupId={(id) => {
                setGroupId(id);
                setSiteId([]);
                setSensorId(-1);
                setFlaggedIds([]);
                setSilencedIds([]);
                setEventTimeout(false);
                setAlertEventsList([]);
                setAlertList([]);
                setSelectedAlert(initialState);
              }}
              setLoading={(e: boolean) => setLoading(e)}
            />
            <MultiSiteSelect
              enabled
              selectAllSites={setSelectAllSites}
              groupid={groupid}
              appStore={appStore}
              setSiteIds={(ids, motioncamids) => {
                setSiteId(ids);
                // setMotionCamIds(motioncamids);
                setSensorId(-1);
                setFlaggedIds([]);
                setSilencedIds([]);
                setEventTimeout(false);
                setAlertEventsList([]);
                setAlertList([]);
                setSelectedAlert(initialState);
              }}
              setSiteLists={(sites: Site[]) => setSiteList(sites)}
              setLoading={(e: boolean) => setLoadingSite(e)}
              top_org_only={false}
              loading={loading}
            />
            {/* <AntMultiSelect /> */}
            <CameraList
              selectAllSites={selectAllSites}
              siteids={siteid}
              sensorId={sensorId}
              appStore={appStore}
              setSiteId={(id) => setSiteId([id])}
              setLoading={(e: boolean) => setLoadingSite(e)}
              setSensorId={(e) => {
                setSensorId(e);
                setFlaggedIds([]);
                setSilencedIds([]);
                setEventTimeout(false);
                setAlertEventsList([]);
                setAlertList([]);
                setSelectedAlert(initialState);
              }}
              loading={loadingSite}
            />
          </div>
        </Grid>
        <Grid item xs={12} md={12}>
          {/* <br /> */}
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              padding: 10,
              marginBottom: 3,
              width: "100%",
            }}
          >
            <CustomizedRadios
              getFilterString={setFilterString}
              flaggedButton={setFlagButton}
              autoPlay={setAutoPlay}
              disablePrevBtn={setDisablePrev}
              showFilteredEvents={
                appStore!.authService!.getRoles()!.indexOf("sys_admin") >= 0
              }
              loading={loading || loadImgs}
              groupid={groupid}
              optionHander={(e) => {
                if (e === "silenced") {
                  setSilenceAlert(true);
                }
                if (e === "unsilenced") {
                  setSilenceAlert(false);
                }
                setFlaggedIds([]);
                setSilencedIds([]);
                setAlertEventsList([]);
                setEventTimeout(false);
                setAlertList([]);
                setSelectedAlert(initialState);
                setAlertType(e);
              }}
            />
            {/* } */}
          </div>
        </Grid>
        <Grid item xs={12} md={12} direction="row" container>
          <div
            style={{
              width: "100%",
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            <div
              style={{
                width: "60%",
              }}
            >
              {!!alertList.length && (
                <div
                  className={classes.bold_text}
                  style={{
                    fontSize: 13,
                    marginLeft: 10,
                    width: "100%",
                    marginRight: 20,
                    transform: "translate(130px,-5px)",
                    position: "absolute",
                    zIndex: 1,
                  }}
                >
                  <span
                    style={{
                      fontSize: 14,
                      color: "#F32424",
                      fontWeight: "bold",
                    }}
                  >
                    {alertType === "flagged" &&
                    siteid.join() &&
                    groupid &&
                    sensorId &&
                    newStartTime &&
                    newEndTime
                      ? flaggedImageCounts.event_count
                      : alertType === "silenced" &&
                        siteid.join() &&
                        groupid &&
                        sensorId &&
                        newStartTime &&
                        newEndTime
                      ? suppressedAlertCounts.event_count
                      : alertList.length}
                    {""}
                  </span>{" "}
                  {alertType === "silenced"
                    ? "SILENCED ALERTS OF "
                    : alertType === "unsilenced"
                    ? "TOTAL UNSILENCED ALERTS "
                    : alertType === "alerted"
                    ? ""
                    : alertType === "filtered"
                    ? ""
                    : alertType === "flagged"
                    ? "FLAGGED FOR TRAINING OF "
                    : ""}
                  {alertType === "flagged" && (
                    <span
                      style={{
                        fontSize: 14,
                        color: "#F32424",
                        fontWeight: "bold",
                        marginRight: 20,
                      }}
                    >
                      {flaggedEventCount}
                    </span>
                  )}
                  {alertType === "silenced" && (
                    <span
                      style={{
                        fontSize: 14,
                        color: "#F32424",
                        fontWeight: "bold",
                        marginRight: 20,
                      }}
                    >
                      {suppressedEventCount}
                    </span>
                  )}
                  {(alertType === "alerted" ||
                    alertType === "filtered" ||
                    alertType === "all") && <span> LISTED OF </span>}
                  {(alertType === "alerted" ||
                    alertType === "filtered" ||
                    alertType === "all") && (
                    <span
                      style={{
                        fontSize: 14,
                        color: "#F32424",
                        fontWeight: "bold",
                        marginRight: 20,
                      }}
                    >
                      {eventCount}
                      {event_timeout && "+"}
                    </span>
                  )}
                </div>
              )}
            </div>
            <div
              style={{
                width: "100%",
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",

                minWidth: 1020,
              }}
            >
              <div
                style={{
                  marginRight: 10,
                  marginLeft: 50,
                  transform: "translate(520px,-63px)",
                  position: selectedAlertData ? "absolute" : "static",
                }}
              >
                <Button
                  style={{
                    fontWeight: 300,
                    fontSize: 10,
                    borderRadius: 4,
                    minWidth: 120,
                    height: 40,
                  }}
                  variant="outlined"
                  color="primary"
                  component="span"
                  onClick={() => setOpenTimeRange(true)}
                >
                  {dateTitle || ""}
                </Button>
                {/* Hide the button for now, until Jasper fixes the rules_processed_summary function */}
                {false && (
                  <Button
                    style={{
                      fontWeight: 300,
                      fontSize: 10,
                      borderRadius: 4,
                      minWidth: 120,
                      height: 40,
                      marginLeft: 10,
                    }}
                    variant="outlined"
                    color="primary"
                    component="span"
                    onClick={() => setOpenInsights(true)}
                  >
                    INSIGHTS
                  </Button>
                )}
              </div>

              {selectedAlertData && selectedAlertData.event_data && (
                <div>
                  <FormControl
                    disabled={
                      AutoPlay === true
                        ? true
                        : false ||
                          (selectedAlertData?.event_data?.classes?.length > 0
                            ? false
                            : true)
                    }
                    variant="outlined"
                    style={{
                      borderColor: "red",
                      marginLeft: 0,
                      fontWeight: 300,
                      transform: "translate(550px,29px)",
                      zIndex: 1,
                    }}
                    className={classes?.formControl}
                  >
                    <Select
                      labelId="demo-simple-select-outlined-label"
                      id="demo-simple-select-outlined"
                      value={class_filter}
                      onChange={(
                        event: React.ChangeEvent<{ value: unknown }>
                      ) => setClassFilter(event.target.value as string)}
                      label="CLASSES"
                      className={classes?.bold_text}
                      style={{
                        height: 39,
                        borderColor: "red",
                        minWidth: 120,
                        marginLeft: 0,
                        marginRight: 25,
                        fontWeight: 300,
                      }}
                    >
                      <MenuItem
                        className={classes?.bold_text}
                        value="all"
                        style={{ fontWeight: 300 }}
                      >
                        ALL
                      </MenuItem>
                      <MenuItem
                        className={classes?.bold_text}
                        value="none"
                        style={{ fontWeight: 300 }}
                      >
                        NONE
                      </MenuItem>
                      {selectedAlertData?.event_data?.classes?.length > 0 &&
                        selectedAlertData?.event_data?.classes.map(
                          (item: string) => (
                            <MenuItem
                              style={{ fontWeight: 300 }}
                              className={classes?.bold_text}
                              value={item}
                            >
                              {item.toUpperCase()}
                            </MenuItem>
                          )
                        )}
                    </Select>
                  </FormControl>
                </div>
              )}

              {/* temp end */}
              {(alertType === "silenced" ||
                alertType === "unsilenced" ||
                alertType === "flagged") && (
                <div
                  style={
                    flagButton === "flagged"
                      ? { transform: "translate(249px,29px)", zIndex: 1 }
                      : { transform: "translate(250px,29px)", zIndex: 1 }
                  }
                >
                  {alertType !== "flagged" && (
                    <CustomButton
                      title={
                        alertType === "silenced"
                          ? "Un-Silence Alert"
                          : "Silence Alert"
                      }
                      onClickHandler={(id) => {
                        if (
                          selectedAlert.site_id > -1 &&
                          alertType === "silenced"
                        ) {
                          suppressAlert();
                        } else if (
                          alertType !== "silenced" &&
                          selectedAlert.site_id > -1
                        ) {
                          setOpenSilenceModal(true);
                          suppressAlert();
                        }
                      }}
                      id={"refresh"}
                      red={true}
                      disabled={
                        alertList.length < 1 || AutoPlay
                          ? true
                          : false ||
                            // ? true
                            !(
                              alertType === "silenced" ||
                              alertType === "unsilenced" ||
                              alertType === "flagged"
                            ) ||
                            !selectedAlert ||
                            selectedAlert?.record_type !== "alert"
                      }
                    />
                  )}
                </div>
              )}

              {alertType !== "flagged" &&
                alertType !== "silenced" &&
                alertType !== "unsilenced" && (
                  <div
                    style={
                      flagButton === "flagged"
                        ? {
                            transform: "translate(-55px,29px)",
                            zIndex: 1,
                          }
                        : {
                            transform: "translate(260px,29px)",
                            zIndex: 1,
                          }
                    }
                  >
                    {selectedAlertData && (
                      <CustomButton
                        title={
                          silenceAlert ? "Un-Silence Alert" : "Silence Alert"
                        }
                        disabled={
                          AutoPlay
                            ? true
                            : // ? true
                            selectedAlertData?.event_data?.data_origin
                            ? !(
                                (!selectedAlertData?.event_data?.data_origin.includes(
                                  "motioncam"
                                ) &&
                                  selectedAlertData?.event_data &&
                                  selectedAlertData!.event_data.record_type !==
                                    "inference" &&
                                  alertType !== "flagged" &&
                                  alertType !== "silenced" &&
                                  alertType !== "unsilenced") ||
                                false
                              ) ||
                              alertType === "silenced" ||
                              (silenced_ids.findIndex(
                                (id: string) =>
                                  id === selectedAlertData!.event_data.doc_id
                              ) > -1 &&
                                !selectedAlertData!.event_data.supress_flag &&
                                !silenceAlert)
                            : false
                        }
                        onClickHandler={(id) => {
                          if (
                            selectedAlert.site_id > -1 &&
                            selectedAlertData?.event_data &&
                            selectedAlertData!.event_data.record_type !==
                              "inference" &&
                            alertType !== "flagged" &&
                            alertType !== "silenced" &&
                            alertType !== "unsilenced"
                          ) {
                            if (!silenceAlert) {
                              setOpenSilenceModal(true);
                            }
                            suppressAlert();
                          }
                        }}
                        id={"refresh"}
                        red={true}
                        groupid={groupid}
                      />
                    )}
                  </div>
                )}

              {alertType !== "flagged" &&
                flagged_ids.findIndex(
                  (id: string) => id === selectedAlertData?.event_data.doc_id
                ) === -1 && (
                  <div
                    style={{
                      transform: "translate(-15px,29px)",
                      zIndex: 1,
                      // position: "relative",
                    }}
                  >
                    {selectedAlertData && (
                      <div>
                        <CustomButton
                          disabled={
                            selectedAlertData.inference_data.length === 1
                              ? false
                              : showHideBtn ||
                                alertType === "flagged" ||
                                alertType === "silenced" ||
                                alertType === "unsilenced" ||
                                AutoPlay ||
                                alertList.length < 1 ||
                                !selectedAlert ||
                                !selectedAlertData
                          }
                          title="Training"
                          groupid={groupid}
                          onClickHandler={() => {
                            setAutoPlay(false);
                            if (
                              selectedAlert.site_id > -1 &&
                              alertType !== "flagged" &&
                              selectedAlertData
                            ) {
                              setOpenFlagModal(true);
                              // flagImages();
                            } else {
                              console.debug(
                                "not flagging",
                                selectedAlertData,
                                "site_id",
                                selectedAlert.site_id
                              );
                            }
                          }}
                          id="refresh"
                          red={true}
                        />
                      </div>
                    )}
                  </div>
                )}
              {alertType === "flagged" && (
                <div
                  style={
                    alertType === "flagged"
                      ? { transform: "translate(-70px,27px)", zIndex: 1 }
                      : { transform: "translate(-15px,27px)", zIndex: 1 }
                  }
                >
                  <CustomButton
                    disabled={!!!selectedAlertData || AutoPlay}
                    title={"Unflag Image"}
                    onClickHandler={(id) => {
                      if (
                        selectedAlert.site_id > -1 &&
                        alertType === "flagged" &&
                        selectedAlertData
                      ) {
                        console.debug("flagging...", selectedAlert);
                        // flagImages();
                      } else {
                        console.debug(
                          "not flagging",
                          selectedAlertData,
                          "site_id",
                          selectedAlert.site_id
                        );
                      }
                      flagImages();
                    }}
                    id={"refresh"}
                    red={true}
                  />
                </div>
              )}
              {alertType === "flagged" ||
                (flagged_ids.findIndex(
                  (id: string) => id === selectedAlertData?.event_data.doc_id
                ) > -1 && (
                  <div
                    style={{ transform: "translate(-15px,27px)", zIndex: 1 }}
                  >
                    <CustomButton
                      disabled={!!!selectedAlertData || AutoPlay}
                      title={"Unflag Image"}
                      onClickHandler={(id) => {
                        if (
                          selectedAlert.site_id > -1 &&
                          alertType === "flagged" &&
                          selectedAlertData
                        ) {
                          console.debug("flagging...", selectedAlert);
                          // flagImages();
                        } else {
                          console.debug(
                            "not flagging",
                            selectedAlertData,
                            "site_id",
                            selectedAlert.site_id
                          );
                        }
                        flagImages();
                      }}
                      id={"refresh"}
                      red={true}
                    />
                  </div>
                ))}
            </div>
          </div>
        </Grid>

        <div
          style={{
            textAlign: "center",
          }}
        ></div>
        <Grid item xs={4}>
          <Paper
            className={classes.paper}
            style={{
              borderRadius: 20,
              border: "1px solid rgb(0, 60, 128)",
              transform: "translate(0px,-50px)",
              zIndex: -100,
            }}
          >
            <div
              style={{
                height: 912,
              }}
            >
              {/* start filter */}
              <div style={{ margin: "0 auto", marginTop: 28 }}>
                {/* <AntSelect
                  defaultValue={ruleType}
                  style={{ width: 250 }}
                  onChange={handleChange}
                  options={[
                    {
                      value: "all",
                      label: "All",
                    },
                    {
                      value: "alert_zones",
                      label: "Alert Zones",
                    },
                    {
                      value: "alert_zones_and_counts",
                      label: "Alert Zones and Counts",
                    },
                    {
                      value: "snapshot",
                      label: "Snapshot",
                    },
                    {
                      value: "scene_change",
                      label: "Scene Change",
                    },
                    {
                      value: "known_vehicle",
                      label: "Known Vehicle Filter",
                    },
                    {
                      value: "suspicious_activity_self_storage",
                      label: "Suspicious Activity",
                    },
                  ]}
                /> */}
                <FormControl
                  fullWidth
                  variant="standard"
                  style={{
                    width: "280px",
                  }}
                >
                  <Select
                    labelId="demo-simple-type-label"
                    id="select-multiple-chip"
                    value={ruleType}
                    label="Rule Type"
                    onChange={handleChange}
                    input={<Input />}
                    style={{
                      width: 280,
                      height: 35,
                      color: "#15325F",
                    }}
                    MenuProps={{
                      anchorOrigin: {
                        vertical: "bottom",
                        horizontal: "left",
                      },
                      transformOrigin: {
                        vertical: "top",
                        horizontal: "left",
                      },
                      getContentAnchorEl: null, // This is important to prevent the menu from being positioned relative to the select item
                    }}
                  >
                    {options?.map((option) => (
                      <MenuItem
                        value={option?.value}
                        key={option?.value}
                        style={{ fontSize: "10px" }}
                      >
                        <Tooltip title={"Camera includes rule"}>
                          <ListItemText primary={option?.label} />
                        </Tooltip>
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </div>
              {/* end filter */}
              {!loading &&
                selectedAlert.created === 0 &&
                alertList.length === 0 && (
                  <div
                    className={classes.bold_text}
                    style={{
                      display: "flex",
                      paddingTop: 100,
                      justifyContent: "center",
                      fontSize: 16,
                    }}
                  >
                    No Events Found!
                  </div>
                )}
              {!loading &&
                selectedAlert.created === 0 &&
                alertList.length > 0 && (
                  <p onClick={() => fetchAllEvents()}>
                    An error occured! click here to reload events
                  </p>
                )}
              {!loading && selectedAlert.created !== 0 && (
                <>
                  <div
                    className={classes.bold_text}
                    style={{
                      margin: 0,
                      width: "100%",
                      height: 800,
                      transform: "translate(0, 10px)",
                    }}
                  >
                    <ListAlert
                      selectedDataAlert={selectedAlertData}
                      selectedAlert={selectedAlert}
                      activeItem={getActiveItem}
                      filterString={getFilterString}
                      auto_play_Func={setAutoPlay}
                      activatePrevButton={setDisablePrev}
                      PrevORNext={setSendPrevORNext}
                      getActiveItem={setActiveItem}
                      lastImage={lastImage}
                      loading={loadImgs}
                      type={alertType}
                      selectedId={selectedAlertData?.event_data?.doc_id}
                      alertList={alertList}
                      openModal={(id: number | string) => {
                        console.debug("insights ID -> ", id);
                        setLoadingViewer(true);
                        setTimeout(() => {
                          setLoadingViewer(false);
                        }, 400);
                        setObjectDetections(undefined);
                        setClassFilter("all");
                        if (
                          alertType === "alerted" ||
                          alertType === "filtered" ||
                          alertType === "all" ||
                          alertType === "silenced" ||
                          alertType === "unsilenced" ||
                          alertType === "flagged"
                        ) {
                          let a = alertEventsList.filter(
                            (alert: any) => alert.event_data.doc_id === id
                          )[0];
                          setSelectedAlertData(a);
                          setSilenceAlert(a.event_data.suppress_flag);
                        }
                        setSelectedAlert(
                          alertList.filter((alert) => alert.doc_id === id)[0]
                        );
                        setSilenceAlert(
                          alertList.filter((alert) => alert.doc_id === id)[0]
                            .suppress_flag
                        );
                      }}
                    />
                  </div>
                  <br />

                  <CustomButton
                    title={"Refresh"}
                    disabled={time_date.indexOf(dateTitle) < 0}
                    onClickHandler={(id) => {
                      setHasMore(true);
                      setNewEndTime(new Date().getTime() / 1000);
                      if (
                        alertType === "silenced" ||
                        alertType === "unsilenced" ||
                        alertType === "flagged"
                      ) {
                        fetchData();
                      } else if (groupid >= 0 && alertType === "alerted") {
                        fetchAlertEvents(true);
                      } else if (groupid >= 0 && alertType === "filtered") {
                        fetchAInfEvents(true);
                      } else if (groupid >= 0 && alertType === "all") {
                        fetchAlertEvents(true);
                        fetchAInfEvents(true);
                      } else {
                        fetchAlertEvents(true);
                      }
                      setFlaggedIds([]);
                      setSilencedIds([]);
                      setEventTimeout(false);
                      setAlertEventsList([]);
                      setAlertList([]);
                    }}
                    id={"refresh"}
                  />
                  <br />
                </>
              )}

              {loading && (
                <div
                  className={classes.bold_text}
                  style={{
                    display: "flex",
                    paddingTop: 130,
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <Spinner />
                </div>
              )}
              {errMessage && (
                <p
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  {errMessage}
                </p>
              )}
            </div>
          </Paper>
        </Grid>
        <Grid item xs={8}>
          <Paper
            className={classes.paper}
            style={{
              minWidth: 750,
              borderRadius: 20,
              border: "1px solid rgb(0, 60, 128)",
              transform: "translate(0px,-50px)",
            }}
          >
            {!loading &&
              !loadingViewer &&
              selectedAlertData &&
              !!alertEventsList.length && (
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-between",
                    padding: "10px 30px",
                    transform: "translate(-37px,0)",
                  }}
                >
                  <Button
                    disabled={disablePrev === "disabled"}
                    variant="contained"
                    color="secondary"
                    onClick={getPreviousItemHandler}
                  >
                    Newer Event
                  </Button>
                  <Button
                    disabled={disableNext === "disabled"}
                    variant="contained"
                    color="secondary"
                    style={{
                      transform: "translate(-560px,0)",
                    }}
                    onClick={getNextItemHandler}
                  >
                    Older Event
                  </Button>
                </div>
              )}
            <div
              style={{
                paddingBottom: "50px",
              }}
            ></div>
            <div
              style={{
                height: 812,
              }}
            >
              {!loading &&
                !loadingViewer &&
                selectedAlertData &&
                !!alertEventsList.length && (
                  <div style={{ transform: "translate(0px,-55px)" }}>
                    <AlertViewerMulti
                      selectedDataAlert={selectedAlertData}
                      AutoPlay={AutoPlay}
                      autoplayHook={setAutoPlay}
                      flagBtn={setShowHideBtn}
                      appStore={appStore}
                      alert={alertEventsList[4]}
                      object_detections={object_detections}
                      alertList={alertList}
                      mode={alertType}
                      review_logs={
                        selectedAlertData!.event_data?.review_logs || []
                      }
                      selectedAlert={selectedAlertData}
                      autoplay={autoplay}
                      setSelectedAlert={(e: any) => {
                        setSelectedAlertData((prev: any) => e);
                      }}
                      siteId={selectedAlertData!.event_data.site_id}
                      sensorName={
                        selectedAlertData!.event_data.sensor_human_name
                      }
                      sensorId={selectedAlertData!.event_data.sensor_id_str}
                      advanced_rule_name={
                        selectedAlertData!.event_data.rule_name
                      }
                      advanced_rule_type={
                        selectedAlertData!.event_data.rule_type
                      }
                      classifier={selectedAlertData!.event_data.class_labels}
                      imgUrl={
                        selectedAlertData!.event_data.media_url ||
                        "/assets/img/nodata3.png"
                      }
                      created={selectedAlertData!.event_data.created}
                      message={selectedAlertData?.event_data?.message || ""}
                      siteName={selectedAlertData!.event_data.site_name}
                      probability={selectedAlertData!.event_data.probabilities}
                      flagged={
                        flagged_ids.findIndex(
                          (id: string) =>
                            id === selectedAlertData!.event_data.doc_id
                        ) > -1
                      }
                      silenced={
                        silenced_ids.findIndex(
                          (id: string) =>
                            id === selectedAlertData!.event_data.doc_id
                        ) > -1
                      }
                      onClickHandler={() => {
                        let temp_site = siteList.filter(
                          (item) =>
                            item.site_id ==
                            selectedAlertData!.event_data.site_id
                        )[0];
                        temp_site.type = "site";
                        temp_site.name =
                          selectedAlertData!.event_data.site_name;
                        temp_site.site_name =
                          selectedAlertData!.event_data.site_name;
                        handleItemSelection(temp_site);
                      }}
                    />
                  </div>
                )}
              {!loading &&
                alertList.length === 0 &&
                alertEventsList.length === 0 && (
                  <div
                    style={{
                      width: "100%",
                      display: "block",
                      borderRadius: 8,
                      overflow: "hidden",
                      transition: "width 2s, height 4s",
                    }}
                  >
                    <img
                      src={require("./noimage.jpeg")}
                      width={640}
                      style={{
                        display: "block",
                        marginLeft: "auto",
                        marginRight: "auto",
                        objectFit: "contain",
                        borderRadius: 10,
                        cursor: "pointer",
                        width: 640,
                        height: 350,
                      }}
                    />
                  </div>
                )}
              {(loading || loadingViewer) && (
                <div
                  className={classes.bold_text}
                  style={{
                    display: "flex",
                    paddingTop: 140,
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <Spinner />
                </div>
              )}
            </div>
          </Paper>
        </Grid>
        <Grid item xs={6} md={6}></Grid>
      </Grid>
    </div>
  );
};

export default ReviewPageV3;
