import React, { useState, useEffect } from "react";
import { Typography as AntTypography } from "antd";
import { Card, IconButton, Typography } from "@material-ui/core";
// import Button from '@material-ui/core/Button';
import Dialog, { DialogProps } from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import { Brightness1 } from "@material-ui/icons";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { Tooltip } from "@material-ui/core";
import { AppStore } from "../../../stores/AppStore";
import { Divider } from "@material-ui/core";
import moment from "moment-timezone";
import Alert from "@material-ui/lab/Alert";
import CloseIcon from "@material-ui/icons/Close";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import Drawer from "@material-ui/core/Drawer";
import RightDrawer from "../../containers/RightDrawer/RightDrawer";
import useRightDrawer from "../useRightDrawer";
import MailIcon from "@material-ui/icons/Mail";
import { ArrayToStringifiedArray } from "../../../hooks/ArrayToStringifiedArray";
import {
  AlertChannel,
  Site,
  Org,
  MainMenu,
  Hub,
  Sensor,
  SiteReport,
  ReportGroup,
  SiteHealth,
  NetworkConfig,
  AjaxDeviceStatus,
  AjaxHubStatus,
} from "../../../services/FunctionService";
import LoadingSpinner from "../../../spinner/LoadingSpinner";
import Container from "@material-ui/core/Container";
import MessageModal from "../../modals/MessageModal/MessageModal";
import EditHubModal from "../../modals/AddHubModal/EditHubModal";
import EditSiteReportModal from "../../modals/AddSiteReportModal/EditSiteReportModal";
import StatusHubModal from "../../modals/AddHubModal/StatusHubModal";
import ConfirmDialog from "../../components/ConfirmDialog/ConfirmDialog";
import ExpansionPanelMenu from "../../components/ExpansionPanelMenu/ExpansionPanelMenu";
import HubExpansionPanelMenu from "../../components/ExpansionPanelMenu/HubExpansionPanelMenu";
import SiteExpansionPanelMenu from "../../components/ExpansionPanelMenu/SiteExpansionPanelMenu";
import { useStyles } from "../../styling/global/global";
import AlertChannelInSite from "../AlertChannelInSite/AlertChannelInSite";
import CameraMenu from "../../components/CameraMenu/CameraMenu";
import { FormControlLabel, Checkbox } from "@material-ui/core";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import SentimentSatisfiedAltIcon from "@material-ui/icons/SentimentSatisfiedAlt";
import SentimentVeryDissatisfiedIcon from "@material-ui/icons/SentimentVeryDissatisfied";
import SentimentDissatisfiedIcon from "@material-ui/icons/SentimentDissatisfied";
import RadioButtonUncheckedIcon from "@material-ui/icons/RadioButtonUnchecked";
import SiteHealthDetail from "../../modals/SiteHealthDetail/SiteHealthDetail";
import CameraWizard from "../../components/CameraWizard/CameraWizard";
import ExpandLessIcon from "@material-ui/icons/ExpandLess";
import { SmtpEmails } from "./SmtpEmails";
import DisableSensorModal from "../../modals/DisableSensorModal/DisableSensorModal";
import { MainContext } from "../../containers/HomePage/HomePage";

const { Text } = AntTypography;

interface SmtpEmail {
  hub_id_str: string;
  site_id: number | undefined;
  smtp_email_type: string;
  created: string;
  content: string;
  image_count: number;
}

interface SiteMainDrawerProps {
  showFullModalHandler: (params: (number | string)[]) => void;
  interval: number;
  selectedItem: { [key: string]: any };
  mainMenu: MainMenu;
  siteHealthModal: (params: (number | string)[]) => void;
  handleIntervalChange: (x: number) => void;
  currentOrg: Org;
  appStore?: AppStore;
  orgsite: Site;
  reportgroups: ReportGroup[];
  setSelectedItem: (x: any) => void;
  openAddHubModal: () => void;
  openAddCameraSimpleModal: () => void;
  openAddAlertChannelModal: () => void;
  openAddSiteReportModal: () => void;
  health: SiteHealth[] | undefined;
  saveNewCamera: (
    hub_id: number,
    site_id: number,
    name: string,
    config: string,
    current_env: string
  ) => void;
}

interface deleteType {
  id: number;
  report: string;
}

interface CameraMuteModalProps {
  appStore?: AppStore;
  channelIds: number[] | undefined;
  sensorId: number | undefined;
  siteId: number | undefined;
  open: boolean;
  onClose: () => void;
}

let url = "/assets/ptz.png";

export const CameraMuteModal: React.FC<CameraMuteModalProps> = ({
  channelIds,
  sensorId,
  siteId,
  open,
  onClose,
  appStore,
}: // elements,
CameraMuteModalProps) => {
  const [muteMinutes, setMinutes] = useState<number | undefined>(5);
  const [muteHours, setHours] = useState<number | undefined>(0);
  const [message, setMessage] = useState<string>("");
  const [err, setErr] = useState(false);
  const [muted, setMuted] = React.useState(false);
  const classes = useStyles();

  if (message) {
    setTimeout(() => {
      setMessage("");
    }, 20000); //10 Seconds delay
  }

  const handleMute = async (
    mute_mins: number,
    site_id: number,
    sensor_id: number,
    alert_channel_id: number[]
  ) => {
    let action: string = "mute";
    if (mute_mins === -1) {
      action = "unmute";
    }
    let until = new Date();
    if (sensor_id != 0) {
      let token = await appStore?.authService.getAuthorisedToken();
      if (token) {
        var muted = await setMute(
          token,
          [site_id],
          sensor_id,
          action,
          mute_mins
        );
        if (muted && muted.success) {
          setErr(false);
          if (mute_mins >= 1) {
            setMuted(true);
            setMessage(
              "You have successfully muted camera for All Rulesets, give a few seconds to propagate"
            );
          } else {
            setMuted(false);
            setMessage(
              "You have successfully unmuted camera, give a few seconds to propagate"
            );
            // setMuted(false);
          }
          // setTimeout(() => {
          //   setMuted(false);
          // }, mute_mins*60*1000);
        } else {
          setMuted(false);
          setErr(true);
          setMessage("Something went wrong!");
        }
      }
    } else {
      let token = await appStore?.authService.getAuthorisedToken();
      if (token) {
        var muted = await setMute(token, [site_id], 0, action, mute_mins);
        if (muted && muted.success) {
          setMessage(
            "Successfully muted camera, give a few minutes to propagate"
          );
        }
      }
    }
  };

  const getBaseUrl = (): string => {
    let base = "";
    if (window.location.href.includes("local")) {
      base = "https://manage.staging.deepalert.ai";
    }
    base += "/api/v1.3";
    return base;
  };

  const setMute = async (
    access_token: string,
    site_ids: number[],
    sensor_id: number,
    action: string,
    mute_mins: number
  ): Promise<any> => {
    //action is either "mute" or "unmute"
    try {
      // let ch_id = channel_id_filter[0]
      let site_id_filter = "[";
      site_ids.forEach((alert_channel_id) => {
        site_id_filter = site_id_filter + alert_channel_id + ",";
      });
      site_id_filter =
        site_id_filter.substr(0, site_id_filter.length - 1) + "]";
      let request = "";
      if (sensor_id !== 0) {
        request = JSON.stringify({
          site_ids: site_id_filter,
          sensor_id: sensor_id,
          mute_mins: mute_mins,
          action: action,
        });
      } else {
        request = JSON.stringify({
          site_ids: site_id_filter,
          mute_mins: mute_mins,
          action: action,
        });
      }
      const res = fetch(getBaseUrl() + "/site_sensor_arm_control", {
        method: "put",
        headers: {
          Accept: "application/json",
          Authorization: "Bearer " + access_token,
          "Content-Type": "application/json",
        },
        body: request,
      });
      let muteresult = (await (await res).json()) as string;
      return muteresult;
    } catch (err) {
      return err;
    }
  };

  // Fetch Alert Channels Functions

  const handleClose = () => {
    setMessage("");
    setMuted(false);
    onClose();
  };
  const customMute = () => {
    setMessage("");
    const muteTime = muteMinutes! + muteHours! * 60;
    handleMute(muteTime!, siteId!, sensorId!, channelIds!);
  };
  return (
    <Dialog
      onClose={handleClose}
      aria-labelledby="simple-dialog-title"
      open={open}
      PaperProps={{
        style: {
          borderRadius: 20,
        },
      }}
    >
      <DialogTitle>
        <h2 style={{ fontStyle: "italic" }} className={classes.bold_text}>
          CAMERA MUTE SETUP
        </h2>
        {message && (
          <>
            <br />
            <div>
              <Alert
                severity={err ? "error" : "success"}
                action={
                  <IconButton
                    aria-label="close"
                    color="inherit"
                    size="small"
                    onClick={() => {
                      handleClose();
                    }}
                  >
                    <CloseIcon fontSize="inherit" />
                  </IconButton>
                }
              >
                {message}
              </Alert>
            </div>
          </>
        )}
      </DialogTitle>
      <div className={classes.contollers}>
        {/* <Divider variant='middle' /> */}

        <div className={classes.menu_container}>
          {/* {!muted && ( */}
          {!muted && (
            <>
              <div style={{ color: "#15325F" }} className={classes.bold_text}>
                SET MUTE TIME
              </div>
              <div className={classes.muteInputs}>
                <input
                  className={classes.muteInput}
                  type="number"
                  value={muteHours}
                  onChange={(e) => {
                    setHours(Math.floor(Math.max(0, +e.target.value)));
                  }}
                />
                <span className={classes.muteInput}>HOURS &nbsp;</span>
                <input
                  className={classes.muteInput}
                  type="number"
                  value={muteMinutes}
                  onChange={(e) => {
                    setMinutes(Math.floor(Math.max(0, +e.target.value)));
                  }}
                />
                <span className={classes.muteInput}>MINUTES</span>
              </div>
            </>
          )}
          {/* )} */}
          <div className={classes.button_container_small2}>
            <Button
              variant="contained"
              className={classes.cancel_button3}
              onClick={handleClose}
            >
              {!muted ? "CANCEL" : "CLOSE"}
            </Button>

            {!muted && (
              <Button
                variant="contained"
                className={classes.mute_button3}
                onClick={() => {
                  customMute();
                  // setMuted(false);
                }}
              >
                MUTE
              </Button>
            )}
            {/* {muted && (
              <Button
                variant='contained'
                className={classes.mute_button3}
                onClick={() => {
                  setMessage('');
                  handleMute(-1, siteId!, sensorId!, channelIds!);
                  setMuted(true);
                }}
              >
                UNMUTE
              </Button>
            )} */}
            {/* ) : undefined} */}
          </div>
        </div>
      </div>
    </Dialog>
  );
};

const SiteMainDrawer: React.FC<SiteMainDrawerProps> = ({
  siteHealthModal,
  mainMenu,
  showFullModalHandler,
  selectedItem,
  interval,
  currentOrg,
  handleIntervalChange,
  health,
  appStore,
  orgsite,
  reportgroups,
  saveNewCamera,
  setSelectedItem,
  openAddHubModal,
  openAddSiteReportModal,
  openAddCameraSimpleModal,
  openAddAlertChannelModal,
}: SiteMainDrawerProps) => {
  // COLLECT THE DEFAULT RULES KEYS
  const classes = useStyles();
  const [showSpinner, setShowSpinner] = useState<boolean>(false);
  const [reload, setReload] = useState<number>(0);
  useEffect(() => {
    setShowSpinner(true);
    setTimeout(() => {
      setShowSpinner(false);
    }, 2000);
  }, [reload]);

  const [hubs, setHubs] = useState<Hub[] | undefined>(undefined);
  const [hub_id_strs, setHubIdStrs] = useState<string[]>([]);
  const [editinghub, setEditingHub] = useState<Hub | undefined>(undefined);
  const [edithub, setEditHub] = useState<boolean>(false);
  const [sitehealthdetail, setSiteHealthDetail] = useState<boolean>(false);
  const [editingsitereport, setEditingSiteReport] = useState<
    SiteReport | undefined
  >(undefined);
  const [editsitereport, setEditSiteReport] = useState<boolean>(false);
  const [statusinghub, setStatusingHub] = useState<Hub | undefined>(undefined);
  const [statushub, setStatusHub] = useState<boolean>(false);
  const [camerawizardopen, setCameraWizardOpen] = useState<boolean>(false);
  const [smtp_emails_open, setSMTPEmailOpen] = useState<boolean>(false);
  const [site, setSite] = useState<Site | undefined>(undefined);

  const [ajaxHubId, setAjaxHubId] = useState<string>("");
  const [alertchannels, setAlertChannels] = useState<
    AlertChannel[] | undefined
  >(undefined);
  const [sensors, setSensors] = useState<Sensor[] | undefined>(undefined);

  const [deletehubid, setDeleteHubId] = React.useState(0);
  const [confirmHubOpen, setConfirmHubOpen] = React.useState(false);
  const [advancedsettings, setAdvancedSettings] = React.useState(false);
  const [deletesensorid, setDeleteSensorId] = React.useState(0);
  const [deletesensorhubid, setDeleteSensorHubId] = React.useState(0);
  const [confirmSensorOpen, setConfirmSensorOpen] = React.useState(false);
  const [maxWidth] = React.useState<DialogProps["maxWidth"]>("md");
  const [test_email, setTestEmailList] = React.useState<any[] | undefined>([]);
  // ["Test Mail", "This is a Test Email", "Camera configured"]
  const [unknown_email_list, setUnknownEmailList] = React.useState<
    any[] | undefined
  >([]);
  //   [
  //   "DVR Event E-Mail !!",
  //   "Time : 08/02/2022 17:36:37",
  //   "Time_GMT : 2022-2-8 17:36:37",
  //   "System ID : SYSTEMID",
  // ]

  const [confirmsitereportopen, setConfirmSiteReportOpen] =
    React.useState(false);
  const [deletesitereportid, setDeleteSiteReportId] = React.useState(0);

  const [confirmmessage, setConfirmMessage] = React.useState("");
  const [messagemodalopen, setMessageModalOpen] = React.useState(false);
  const [popmessage, setPopMessage] = React.useState("");
  const [refresh, setRefresh] = React.useState(0);
  const [localloading, setLocalLoading] = React.useState(false);
  const [sitereports, setSiteReports] = useState<SiteReport[] | undefined>(
    undefined
  );
  const [training, setTraining] = React.useState<boolean>(false);
  const [training_triggers_only, setTrainingTriggersOnly] =
    React.useState<boolean>(false);
  const [training_fraction, setTrainingFraction] = React.useState<number>(0);
  const [name, setName] = React.useState<string>("");
  const [message, setMessage] = React.useState<string>("");
  const [muteMessage, setMuteMessage] = React.useState<string>("");
  const [clone, setClone] = React.useState<Sensor | undefined>(undefined);
  const [, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const [channelIDs, setChannelDs] = React.useState<number[] | undefined>();
  const [selectedSensorId, setSelectedSensorId] = React.useState<
    number | undefined
  >();
  const [mutedSensorIds, setMutedSensorIds] = React.useState<number[]>([]);
  const [siteId, setSiteId] = useState<number>();

  const [disableSensorModalOpen, setDisableSensorModalOpen] = useState(false);
  const [selectedSensor, setSelectedSensor] = useState<Sensor | null>(null);

  //Hook
  const {
    activity,
    sensorhealthtimeline,
    healthtimeline,
    healthsites,
    sensorhealth,
  } = useRightDrawer(appStore, currentOrg, interval, selectedItem);

  const { showGraph } = React.useContext(MainContext);

  //

  // boolean that indicates if disabled cameras are visible
  const [seedisabledcameras, setSeeDisabledCameras] = useState(false);
  // boolean that indicates if sensors are expanded
  const [sensorsexpanded, setSensorsExpanded] = useState(true);
  // boolean that indicates if hubs are expanded
  const [hubsexpanded, setHubsExpanded] = useState(false);
  // boolean that indicates if reports are expanded
  const [reportsexpanded, setReportsExpanded] = useState(false);
  // boolean that indicates if alertchannels are expanded
  const [alertchannelsexpanded, setAlertChannelsExpanded] = useState(true);
  // boolean that indicates if frontel buttons are expanded
  const [frontelExpanded, setFrontelExpanded] = useState(true);
  const [frontelResponse, setFrontelResponse] = useState("");
  const [ajaxExpanded, setAjaxExpanded] = useState(true);
  const [ajaxResponse, setAjaxResponse] = useState("");
  const [ajaxDeviceStatus, setAjaxDevicesStatus] = useState<AjaxDeviceStatus[]>(
    []
  );
  const [ajaxHubStatus, setAjaxHubStatus] = useState<AjaxHubStatus | null>(
    null
  );

  const [mutedsensors, setMutedSensors] = React.useState<
    [number, number, string][]
  >([]);

  // Open Camera Modal
  const [openCameraMuting, setCameraMuting] = useState(false);
  const [open, setOpen] = React.useState(false);

  const isCameraMate =
    site?.data_origin?.includes("client_hub_cameramate") || false;

  const handleCloseMessage = () => {
    setOpen(false);
  };

  const openSMTPEmails = () => {
    setSMTPEmailOpen(true);
  };

  var loading = true;
  var isvirtual = false;
  if (site && hubs) {
    loading = false;
    if (
      site.data_origin === "alert_rest_client" ||
      site.data_origin === "alert_smtp_client" ||
      site.data_origin === "alert_ftp_client" ||
      site.data_origin === "inference_api_client" ||
      site.data_origin.includes("frontel") ||
      site.data_origin.includes("ajax")
    ) {
      isvirtual = true;
    }
  }

  const getBaseUrl = (): string => {
    let base = "";
    if (window.location.href.includes("local")) {
      base = "https://manage.staging.deepalert.ai";
    }
    base += "/api/v1.3";
    return base;
  };

  // useEffect(() => {
  //   const fetchData = async () => {};
  //   fetchData();
  //   const intervalId = setInterval(() => {
  //     fetchData();
  //   }, 5000);
  //   return () => clearInterval(intervalId);
  // }, []);

  const fetchTestEmails = async () => {
    let token = await appStore?.authService.getAuthorisedToken();
    if (token && hubs) {
      let request = JSON.stringify({
        // hub_id_strs: "DL1TLDS",
        hub_id_strs: hubs!.map((hub) => `${hub.hub_id_str}`).toString(),
        smtp_email_types: "smtp_test",
      });

      const res: any = fetch(getBaseUrl() + "/list_smtp_emails", {
        method: "post",
        headers: {
          Accept: "application/json",
          Authorization: "Bearer " + token,
          "Content-Type": "application/json",
        },
        body: request,
      });

      let test_emails = (await (await res).json()) as any;

      if (typeof test_emails.msg !== "string") {
        let temp: any[] = [];
        test_emails.msg.map((email: SmtpEmail) => {
          if (
            email.smtp_email_type === "unknown_email_format" ||
            email.smtp_email_type === "known_email_format"
          ) {
            return;
          }

          let c_time = email.created.replace(".000Z", "");
          const d = new Date(`${c_time}`);
          let time = d.getTime();
          temp.push({
            hub_id: email.hub_id_str,
            title: email.smtp_email_type,
            created_time: c_time,
            content: email.content.split("\n"),
            image_count: email.image_count,
            time: time,
          });
        });
        // return test_emails.msg[0].content
        return temp.sort(function (a: any, b: any) {
          return b.time - a.time;
        });
      } else {
        return [];
      }
    }
  };

  const fetchUnknownEmails = async () => {
    let token = await appStore?.authService.getAuthorisedToken();

    if (token && hubs) {
      let request = JSON.stringify({
        // hub_id_strs: "['DL1TLDS']",
        hub_id_strs: hubs!.map((hub) => `${hub.hub_id_str}`).toString(),
        smtp_email_types: "unknown_email_format,known_email_format",
      });

      const res: any = fetch(getBaseUrl() + "/list_smtp_emails", {
        method: "post",
        headers: {
          Accept: "application/json",
          Authorization: "Bearer " + token,
          "Content-Type": "application/json",
        },
        body: request,
      });

      let unknown_emails = (await (await res).json()) as any;
      if (typeof unknown_emails.msg !== "string") {
        let temp: any[] = [];
        unknown_emails.msg.map((email: SmtpEmail) => {
          if (email.smtp_email_type === "smtp_test") {
            return;
          }
          let c_time = email.created.replace(".000Z", "");
          const d = new Date(`${c_time}`);
          let time = d.getTime();
          temp.push({
            hub_id: email.hub_id_str,
            title: email.smtp_email_type,
            created_time: c_time,
            content: email.content.split("\n"),
            image_count: email.image_count,
            time: time,
          });
        });
        return temp.sort(function (a: any, b: any) {
          return b.time - a.time;
        });
      } else {
        return [];
      }
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      let unkwn_emails = await fetchUnknownEmails();
      let tst_email = await fetchTestEmails();
      if (!!unkwn_emails?.length) {
        setUnknownEmailList(unkwn_emails);
      }
      if (!!tst_email?.length) {
        setTestEmailList(tst_email);
      }
    };
    if (site?.data_origin === "alert_smtp_client") {
      fetchData();
    }
  }, [hubs]);

  //SETMUTE
  const setMute = async (
    access_token: string,
    site_ids: number[],
    sensor_id: number,
    action: string,
    mute_mins: number
  ): Promise<any> => {
    //action is either "mute" or "unmute"
    try {
      let site_id_filter = "[";
      site_ids.forEach((alert_channel_id) => {
        site_id_filter = site_id_filter + alert_channel_id + ",";
      });
      site_id_filter =
        site_id_filter.substr(0, site_id_filter.length - 1) + "]";
      let request = "";
      if (sensor_id !== 0) {
        request = JSON.stringify({
          site_ids: site_id_filter,
          sensor_id: sensor_id,
          mute_mins: mute_mins,
          action: action,
        });
      } else {
        request = JSON.stringify({
          site_ids: site_id_filter,
          mute_mins: mute_mins,
          action: action,
        });
      }
      const res = fetch(getBaseUrl() + "/site_sensor_arm_control", {
        method: "put",
        headers: {
          Accept: "application/json",
          Authorization: "Bearer " + access_token,
          "Content-Type": "application/json",
        },
        body: request,
      });
      let muteresult = (await (await res).json()) as string;
      return muteresult;
    } catch (err) {
      return err;
    }
  };

  //START
  const handleMute = async (
    mute_mins: number,
    site_id: number,
    sensor_id: number,
    alert_channel_id: number[]
  ) => {
    let action: string = "mute";
    if (mute_mins === -1) {
      action = "unmute";
    }
    let until = new Date();
    let struntil = moment(until).add(mute_mins, "minutes").toString();
    if (sensor_id !== 0) {
      let token = await appStore?.authService.getAuthorisedToken();
      if (token) {
        var muted = await setMute(
          token,
          [site_id],
          sensor_id,
          action,
          mute_mins
        );
        if (muted && muted.success) {
          // setErr(false);
          if (mute_mins >= 1) {
            // setMuted(true);
            setMuteMessage(
              "You have successfully muted camera, give a few seconds to propagate"
            );
          } else {
            // setMuted(false);
            setOpen(true);
            setMuteMessage(
              "You have successfully unmuted camera, give a few seconds to propagate"
            );
            // setMuted(false);
          }
        } else {
          setMuteMessage("Something went wrong!");
        }
      }
    } else {
      let token = await appStore?.authService.getAuthorisedToken();
      if (token) {
        var muted = await setMute(token, [site_id], 0, action, mute_mins);
        if (muted && muted.success) {
          setMessage(
            "Successfully muted camera, give a few minutes to propagate"
          );
        }
      }
    }
  };

  //END

  const getMuteStatus = async (
    access_token: string,
    site_ids: number[]
  ): Promise<any> => {
    try {
      let site_id_filter = "[";
      site_ids.forEach((alert_channel_id) => {
        site_id_filter = site_id_filter + alert_channel_id + ",";
      });
      site_id_filter =
        site_id_filter.substr(0, site_id_filter.length - 1) + "]";
      let request = "";
      request = JSON.stringify({
        site_ids: site_id_filter,
      });
      const res = fetch(getBaseUrl() + "/site_sensor_mute_and_arm_status", {
        method: "post",
        headers: {
          Accept: "application/json",
          Authorization: "Bearer " + access_token,
          "Content-Type": "application/json",
        },
        body: request,
      });
      let muteresult = (await (await res).json()) as string;
      return muteresult;
    } catch (err) {
      return err;
    }
  };

  // make sure visible mute statuses are in sync with what the backend have, this happens every 30 seconds on the stats timer call
  const [armData, setArmData] = React.useState<any>(undefined);
  const updateMuteStatuses = async (
    siteIds: number[] | undefined,
    sensors: Sensor[]
  ) => {
    var token = await appStore?.authService.getAuthorisedToken();
    if (token) {
      var mutestatuses = await getMuteStatus(token!, siteIds!);
      if (mutestatuses.success) {
        const data =
          mutestatuses?.arm_data?.length > 0
            ? Object.values(mutestatuses?.arm_data[`${siteIds![0]}`]).find(
                (item) => !!item
              )
            : undefined;
        if (data) {
          setArmData(data);
        }
        let mutedata = mutestatuses.mute_data;
        let newmutedsensors: [number, number, string][] = [];
        for (let key in mutedata) {
          // check if all sensors muted before setting channel as muted.
          let mutedchannel = mutedata[key];

          for (let sensor in mutedchannel) {
            let muteduntil = mutedchannel[sensor] * 1000;
            let untildate = new Date(muteduntil);

            let thissensor: [number, number, string] = [
              Number(key),
              Number(sensor),
              untildate.toString(),
            ];
            newmutedsensors.push(thissensor);
          }
        }
        // setMutedChannels(newmutedchannels);
        let sensorIds = sensors!.map((sensor) => {
          return sensor.sensor_id;
        });
        const filteredData = newmutedsensors.filter(
          (item) => sensorIds!.indexOf(item[1]) >= 0
        );
        setMutedSensors(filteredData);
        let snsId: number[] = [];
        newmutedsensors.forEach((sensor) => {
          snsId.push(sensor[1]);
        });
        setMutedSensorIds(snsId);
      }
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      if (orgsite) {
        setLocalLoading(true);
        var token = await appStore?.authService.getAuthorisedToken();
        if (token) {
          let site = await appStore?.functionService.getSite(
            token,
            orgsite.site_id
          );
          if (site) {
            if (site.data_origin.includes("ajax")) {
              let ajax_hub_id = await appStore?.functionService.getAjaxHub(
                token,
                site.site_id
              );

              if (ajax_hub_id !== "") {
                setAjaxHubId(ajax_hub_id);

                let ajax_status =
                  await appStore?.functionService.fetchAjaxStatus(
                    token,
                    site.site_id,
                    ajax_hub_id
                  );

                if (ajax_status) {
                  if (typeof ajax_status != "string") {
                    setAjaxHubStatus(ajax_status.hub_status);
                    setAjaxDevicesStatus(ajax_status.device_statuses);
                  } else {
                    setPopMessage(ajax_status);
                    openMessageModal();
                  }
                } else {
                  setPopMessage("error encountered in fetching Ajax status");
                  openMessageModal();
                }
              }
            }
            setSite(site);
            if (site.site_name) {
              setName(site.site_name);
              setSiteId(site!.site_id);
            }
            if (site.meta && site.meta.training) {
              if (site.meta.training.fraction) {
                if (site.meta.training.fraction === 0) {
                  setTrainingFraction(0);
                  setTraining(false);
                } else {
                  setTraining(true);
                  setTrainingFraction(Number(site.meta.training.fraction));
                }
              }
              if (site.meta.training.triggers_only) {
                setTrainingTriggersOnly(site.meta.training.triggers_only);
              }
            }
          } else {
            loading = false;
            setPopMessage("Site unavailable at present!");
            openMessageModal();
            setRefresh(new Date().getTime());
            setSelectedItem(currentOrg);
            setLocalLoading(false);
          }
        }
        setLocalLoading(false);
      }
    };
    fetchData();
  }, [refresh, orgsite]);
  // Changed to site_id if that's the main identifier for changes

  let handle: NodeJS.Timeout;
  const fetchSensorsData = async (channelIds?: number[]) => {
    if (site) {
      var token = await appStore?.authService.getAuthorisedToken();
      if (token) {
        var hubs = await appStore?.functionService.getHubsForSite(
          token,
          site.site_id
        );
        let sharedhub_id_strs: string[] = [];
        if (hubs) {
          var sensors: Sensor[] =
            await appStore?.functionService.getSensorsForSite(
              token,
              site?.site_id,
              "thumbnail"
            );
          if (sensors) {
            updateMuteStatuses([site?.site_id], sensors);
            setSensors(sensors);
            // setInterval(() => {
            //   updateMuteStatuses(channelIds, sensors!);
            //   // clearInterval(handle);
            // }, 10000);
            let sharedhub_ids: number[] = [];
            if (sensors.length > 0) {
              sensors.forEach((sensor) => {
                let sensorhub = hubs?.find(
                  (x: any) => x.hub_id === sensor.hub_id
                );
                if (!sensorhub) {
                  sharedhub_ids.push(sensor.hub_id);
                }
              });
            }
            if (sharedhub_ids.length > 0) {
              var sharedhubs: Hub[] = await appStore?.functionService.getHubs(
                token,
                sharedhub_ids
              );
              if (sharedhubs && sharedhubs.length > 0) {
                sharedhubs.forEach((sharedhub) => {
                  sharedhub.hub_id_str = sharedhub.hub_id_str;
                  sharedhub.shared = " (Shared)";
                  hubs?.push(sharedhub);
                  sharedhub_id_strs.push(sharedhub.hub_id_str);
                  hub_id_strs.push(sharedhub.hub_id_str);
                });
                setHubIdStrs(hub_id_strs);
              }
            }
          }
          setHubs(hubs);
        }
      }
    }
  };
  useEffect(() => {
    if (!channelIDs) {
      return;
    }
    var handler = setInterval(() => {
      fetchSensorsData(channelIDs);
    }, 10000);

    return () => {
      clearInterval(handler);
    };
  }, [channelIDs]);

  useEffect(() => {
    const fetchData = async () => {
      if (site && site.site_id) {
        setLocalLoading(true);
        var token = await appStore?.authService.getAuthorisedToken();
        if (token) {
          var alertchannelz: AlertChannel[] =
            await appStore?.functionService.getAlertChannelsForSite(
              token,
              site?.site_id
            );
          if (alertchannelz) {
            setAlertChannels(alertchannelz);

            let ids = alertchannelz?.map(
              (channel) => channel?.alert_channel_id
            );
            setChannelDs(ids);
            // updateMuteStatuses(ids);
            fetchSensorsData(ids);
          }
        }
        setLocalLoading(false);
      }
    };
    fetchData();
    return () => {
      // clearInterval(handle);
    };
  }, [site, reload]);

  useEffect(() => {
    const fetchData = async () => {
      if (site) {
        setLocalLoading(true);
        var token = await appStore?.authService.getAuthorisedToken();
        if (token) {
          var sitereports = await appStore?.functionService.getSiteReports(
            token,
            site.site_id
          );
          setSiteReports(sitereports);
        }
        setLocalLoading(false);
      }
    };
    fetchData();
  }, [site]);

  const handleUpdate = () => {
    if (isNaN(+training_fraction)) {
      setMessage("Training fraction is not a valid number!");
    } else if (Number(training_fraction) < 0 || Number(training_fraction) > 1) {
      setMessage("Training fraction must be a decimal between 0 and 1!");
    } else if (name === "") {
      setMessage("Site name cannot be empty!");
    } else {
      if (!training) {
        setTrainingFraction(0);
        updateSite(name, Number(training_fraction), training_triggers_only);
      } else {
        if (training_fraction === 0) {
          setMessage(
            "Training fraction must be greater than 0 if training set to be provided!"
          );
        } else {
          updateSite(name, Number(training_fraction), training_triggers_only);
        }
      }
    }
  };

  //function to toggle hubsexpanded when hubs open/close icon clicked
  const handleHubsExpanded = () => {
    setHubsExpanded(!hubsexpanded);
  };
  //function to toggle reportsexpanded when reports open/close icon clicked
  const handleReportsExpanded = () => {
    setReportsExpanded(!reportsexpanded);
  };
  //function to toggle sensorsexpanded when sensor open/close icon clicked
  const handleSensorsExpanded = () => {
    setSensorsExpanded(!sensorsexpanded);
  };
  //function to toggle alertchannelsexpanded whenalertchannels open/close icon clicked
  const handleAlertChannelsExpanded = () => {
    setAlertChannelsExpanded(!alertchannelsexpanded);
  };
  //function to toggle frontelExpanded when Frontel Controls open/close icon clicked
  const handleFrontelExpanded = () => {
    setFrontelExpanded(!frontelExpanded);
  };
  //function to toggle ajaxExpanded when Ajax Controls open/close icon clicked
  const handleAjaxExpanded = () => {
    setAjaxExpanded(!ajaxExpanded);
  };

  // TODO: frontel disable time selection
  const handleFrontelDisarmTimeSelection = async () => {
    let isValidInput = false;
    let parsedTime = 0;

    while (!isValidInput) {
      let selectedTime = window.prompt(
        "WARNING: after the elapsed time below the panel will not be contactable from DeepAlert " +
          "and would require re-arming on panel itself. Time selection allows a window to re-arm the panel\n\n" +
          "Enter a re-activation window duration in minutes from 1 - 30, default time is 5 minutes:",
        "5"
      );
      let validTimes = [];
      for (let i = 1; i <= 30; i++) {
        validTimes.push(i);
      }
      if (selectedTime === null) {
        selectedTime = "5";
      }
      parsedTime = parseInt(selectedTime, 10);
      if (!isNaN(parsedTime) && validTimes.includes(parsedTime)) {
        isValidInput = true;
      } else {
        alert(
          "Invalid input. Please enter a valid duration between 1 and 30 minutes."
        );
      }
    }
    await handleFrontelDisarm(parsedTime);
  };

  const handleFrontelDisarm = async (time: number) => {
    let response = "Failed to disarm site";
    let frontelAccount = "";
    let frontelPanel = "";
    let status = 0;

    const token = await appStore?.authService.getAuthorisedToken();

    if (site?.meta?.frontel_account && site?.meta?.frontel_panel) {
      frontelAccount = site.meta.frontel_account;
      frontelPanel = site.meta.frontel_panel;
    }
    if (token && frontelAccount !== "" && frontelPanel !== "") {
      response = await appStore?.functionService.handleFrontelArming(
        token,
        frontelAccount,
        frontelPanel,
        status,
        time
      );
    }
    setFrontelResponse(response);
  };

  const handleFrontelArm = async () => {
    let response = "Failed to arm site";
    let frontelAccount = "";
    let frontelPanel = "";
    let status = 1;
    const token = await appStore?.authService.getAuthorisedToken();

    if (site?.meta?.frontel_account && site?.meta?.frontel_panel) {
      frontelAccount = site.meta.frontel_account;
      frontelPanel = site.meta.frontel_panel;
    }
    if (token && frontelAccount !== "" && frontelPanel !== "") {
      response = await appStore?.functionService.handleFrontelArming(
        token,
        frontelAccount,
        frontelPanel,
        status
      );
    }
    setFrontelResponse(response);
  };

  const handleFrontelDisconnect = async () => {
    let response = "Failed to disconnect site";
    let frontelAccount = "";
    let frontelPanel = "";
    const token = await appStore?.authService.getAuthorisedToken();

    if (site?.meta?.frontel_account && site?.meta?.frontel_panel) {
      frontelAccount = site.meta.frontel_account;
      frontelPanel = site.meta.frontel_panel;
    }
    if (token && frontelAccount !== "" && frontelPanel !== "") {
      response = await appStore?.functionService.handleFrontelDisconnect(
        token,
        frontelAccount,
        frontelPanel
      );
    }
    setFrontelResponse(response);
  };

  const handleAjaxArm = async (status: string) => {
    let response = "Failed to " + status.toLowerCase() + " hub";
    const token = await appStore?.authService.getAuthorisedToken();

    if (token && ajaxHubId !== "" && siteId !== undefined) {
      response = await appStore?.functionService.handleAjaxArming(
        token,
        siteId,
        ajaxHubId,
        status
      );
    }
    setAjaxResponse(response);
    setPopMessage(response);
    openMessageModal();
  };

  // function to Open Camera Mute Modal
  const handleCameraMute = (sensor_id: number) => {
    setSelectedSensorId(sensor_id);
    setCameraMuting(true);
  };

  const handleCameraUnMute = (sensor_id: number) => {
    setSelectedSensorId(sensor_id);
    handleMute(-1, site!.site_id, sensor_id, channelIDs!);
    setCameraMuting(false);
  };

  const openCameraWizard = (sensor: Sensor | undefined) => {
    if (sensor) {
      setClone(sensor);
      setCameraWizardOpen(true);
    } else {
      if (isvirtual) {
        openAddCameraSimpleModal();
      } else {
        setClone(undefined);
        setCameraWizardOpen(true);
      }
    }
  };
  const handleCheckTraining = () => {
    if (training) {
      setTrainingFraction(0);
      setTrainingTriggersOnly(false);
    }
    setTraining(!training);
    setMessage("");
  };
  const handleCheckTrainingTriggerOnly = () => {
    setTrainingTriggersOnly(!training_triggers_only);
    setMessage("");
  };
  const handleTrainingPercentageChange = (event: React.ChangeEvent<any>) => {
    setTrainingFraction(event.target.value);
    setMessage("");
  };
  const handleNameChange = (event: React.ChangeEvent<any>) => {
    setName(event.target.value);
    setMessage("");
  };

  const openMessageModal = () => {
    setMessageModalOpen(true);
  };
  const closeMessageModal = () => {
    setMessageModalOpen(false);
  };
  const openConfirmDeleteHub = (hub_id: number, hub_id_str: string) => {
    setDeleteHubId(hub_id);
    setConfirmMessage("Are you sure you want to delete " + hub_id_str + "?");
    setConfirmHubOpen(true);
  };

  const updateSharedHub = async (
    location: string,
    networkconfig: NetworkConfig
  ) => {
    setEditHub(false);
    setLocalLoading(true);
    const token = await appStore?.authService.getAuthorisedToken();
    if (token) {
      let update_hub = await appStore?.functionService.modifyHub(
        token,
        editinghub!.hub_id,
        location,
        0,
        true
      );
      if (update_hub) {
        if (update_hub.success) {
          let update_network = await appStore?.functionService.modifyHubNetwork(
            token,
            editinghub?.hub_id!,
            networkconfig
          );
          if (update_network) {
            if (update_network.success) {
              setLocalLoading(false);
              setPopMessage("Updated hub");
              openMessageModal();

              setHubs(undefined);
              setSite(undefined);
              setRefresh(new Date().getTime());
            } else {
              setLocalLoading(false);
              setPopMessage(update_network.msg);
              openMessageModal();
              setRefresh(new Date().getTime());
            }
          }
        } else {
          setLocalLoading(false);
          setPopMessage(update_hub.msg);
          openMessageModal();
          setRefresh(new Date().getTime());
        }
      }
    }
  };

  const updateHub = async (location: string, networkconfig: NetworkConfig) => {
    setEditHub(false);
    setLocalLoading(true);
    const token = await appStore?.authService.getAuthorisedToken();
    if (token) {
      let update_hub = await appStore?.functionService.modifyHub(
        token,
        editinghub?.hub_id!,
        location,
        site?.site_id!,
        false
      );
      if (update_hub) {
        if (update_hub.success) {
          let update_network = await appStore?.functionService.modifyHubNetwork(
            token,
            editinghub?.hub_id!,
            networkconfig
          );
          if (update_network) {
            if (update_network.success) {
              setLocalLoading(false);
              setPopMessage("Updated hub");
              openMessageModal();

              setHubs(undefined);
              setSite(undefined);
              setRefresh(new Date().getTime());
            } else {
              setLocalLoading(false);
              setPopMessage(update_network.msg);
              openMessageModal();
              setRefresh(new Date().getTime());
            }
          }
        } else {
          setLocalLoading(false);
          setPopMessage(update_hub.msg);
          openMessageModal();
          setRefresh(new Date().getTime());
        }
      }
    }
  };
  const deleteHub = async () => {
    setConfirmHubOpen(false);
    setLocalLoading(true);
    const token = await appStore?.authService.getAuthorisedToken();
    if (token) {
      let delete_hub = await appStore?.functionService.deleteHub(
        token,
        deletehubid
      );
      if (delete_hub) {
        setLocalLoading(false);
        if (delete_hub.success) {
          setPopMessage("Deleted hub");
          openMessageModal();
          setHubs(undefined);
          setSite(undefined);
          setRefresh(new Date().getTime());
        } else {
          setPopMessage(delete_hub.msg);
          openMessageModal();
          setRefresh(new Date().getTime());
        }
      }
    }
  };
  const updateSite = async (
    name: string,
    training_fraction: number,
    training_triggers_only: boolean
  ) => {
    setLocalLoading(true);

    const token = await appStore?.authService.getAuthorisedToken();
    if (token) {
      let update_site = await appStore?.functionService.modifySite(
        token,
        site!.site_id,
        name,
        training_fraction,
        training_triggers_only
      );
      if (update_site) {
        setLocalLoading(false);
        if (update_site.success) {
          setPopMessage("Updated site");
          openMessageModal();

          setRefresh(new Date().getTime());
        } else {
          setPopMessage(update_site.msg);
          openMessageModal();
          setRefresh(new Date().getTime());
        }
      }
    }
  };
  const openConfirmDeleteSensor = (
    sensor_id: number,
    sensor_id_str: string,
    hub_id: number
  ) => {
    setDeleteSensorId(sensor_id);
    setDeleteSensorHubId(hub_id);
    setConfirmMessage("Are you sure you want to delete " + sensor_id_str + "?");
    setConfirmSensorOpen(true);
  };
  const deleteSensor = async (isCameraMate?: boolean) => {
    setConfirmSensorOpen(false);
    setLocalLoading(true);
    setSensors(undefined);
    const token = await appStore?.authService.getAuthorisedToken();
    if (token) {
      let delete_sensor = await appStore?.functionService.deleteSensor(
        token,
        deletesensorid
      );
      if (delete_sensor) {
        if (delete_sensor.success) {
          let newsensors = sensors;
          let idx = sensors?.findIndex((x) => x.sensor_id === deletesensorid);
          if (idx !== undefined && idx >= 0) {
            newsensors?.splice(idx, 1);
            setSensors(newsensors);
          }
          let hub_force = await appStore?.functionService.forceHubUpdate(
            token,
            deletesensorhubid
          );
          var hubforce = " but could not send refresh message to hub!";
          if (hub_force && hub_force.success) {
            hubforce = " and sent refresh message to hub.";
          }
          setLocalLoading(false);
          setPopMessage("Deleted camera stream " + hubforce);
          openMessageModal();
          setRefresh(new Date().getTime());
        } else {
          setLocalLoading(false);
          setPopMessage(delete_sensor.msg);
          openMessageModal();
          setRefresh(new Date().getTime());
        }
      }
    }
  };
  const handleEnableDisableSensor = async (sensor: Sensor) => {
    if (sensor.enabled) {
      setSelectedSensor(sensor);
      setDisableSensorModalOpen(true);
      return;
    }
    await updateSensorStatus(sensor);
  };

  const updateSensorStatus = async (sensor: Sensor, reason?: string) => {
    const token = await appStore?.authService.getAuthorisedToken();
    if (token) {
      setLocalLoading(true);
      let updateSensor = await appStore?.functionService.modifySensorEnabled(
        token,
        sensor.sensor_id,
        !sensor.enabled,
        reason
      );
      if (updateSensor && updateSensor.success) {
        sensor.enabled = !sensor.enabled;
        let idx = sensors?.findIndex((x) => x.sensor_id === sensor.sensor_id);
        if (idx && idx >= 0) {
          sensors![idx] = sensor;
          setSensors(sensors);
        }
        if (sensor.enabled) {
          setPopMessage("Camera enabled.");
        } else {
          setPopMessage("Camera disabled.");
        }

        openMessageModal();
        setLocalLoading(false);
        setRefresh(new Date().getTime());
      } else {
        if (updateSensor) {
          setPopMessage(updateSensor.msg);
        } else {
          setPopMessage("Could not update sensor.");
        }
        openMessageModal();
      }
    }
  };

  const openSingleOpenRulesetMessage = () => {
    setPopMessage("");
    setPopMessage("This site can only accept a single ruleset");
    setMessageModalOpen(true);
  };
  const unAuthorisedRulesetMessage = () => {
    setPopMessage("");
    setPopMessage("This site does not accept any alert channel ruleset");
    setMessageModalOpen(true);
  };

  const dummy = () => {};
  const statusHub = (hub: Hub) => {
    setStatusingHub(hub);
    setStatusHub(true);
  };
  const closeStatusHub = () => {
    setStatusHub(false);
  };

  const closeCameraWizard = () => {
    setCameraWizardOpen(false);
  };
  const editHub = (hub: Hub) => {
    if (!isvirtual) {
      setEditingHub(hub);
      setEditHub(true);
    }
  };
  const closeEditHub = () => {
    setEditHub(false);
  };
  const siteHealthDetail = () => {
    setSiteHealthDetail(true);
  };
  const closeSiteHealthDetail = () => {
    setSiteHealthDetail(false);
  };
  const editSiteReport = (sitereport: SiteReport) => {
    setEditingSiteReport(sitereport);
    setEditSiteReport(true);
  };
  const closeEditSiteReport = () => {
    setEditSiteReport(false);
  };
  const handleSeeDisabledCameras = () => {
    setSeeDisabledCameras(!seedisabledcameras);
  };
  const openConfirmDeleteSiteReport = (
    deletesitereportid: number,
    sitereport_type: string
  ) => {
    setDeleteSiteReportId(deletesitereportid);
    setConfirmMessage(
      "Are you sure you want to delete " + sitereport_type + "?"
    );
    setConfirmSiteReportOpen(true);
  };

  const deleteSiteReport = async () => {
    setConfirmSiteReportOpen(false);
    setLocalLoading(true);
    const token = await appStore?.authService.getAuthorisedToken();
    if (token) {
      let delete_report = await appStore?.functionService.deleteSiteReport(
        token,
        deletesitereportid
      );
      if (delete_report) {
        setLocalLoading(false);
        if (delete_report.success) {
          setPopMessage("Deleted report");
          openMessageModal();
          setHubs(undefined);
          setSite(undefined);
          setSiteReports(undefined);
          setRefresh(new Date().getTime());
        } else {
          setPopMessage(delete_report.msg);
          openMessageModal();
          setRefresh(new Date().getTime());
        }
      }
    }
  };
  const updateSiteReport = async (
    site_reporting_id: number,
    daily_utc_schedule: string
  ) => {
    setEditSiteReport(false);
    setLocalLoading(true);
    const token = await appStore?.authService.getAuthorisedToken();
    if (token) {
      let update_report = await appStore?.functionService.modifySiteReport(
        token,
        site_reporting_id,
        daily_utc_schedule
      );
      if (update_report) {
        setLocalLoading(false);
        if (update_report.success) {
          setPopMessage("Updated report");
          openMessageModal();
          setHubs(undefined);
          setSite(undefined);
          setSiteReports(undefined);
          setRefresh(new Date().getTime());
        } else {
          setPopMessage(update_report.msg);
          openMessageModal();
          setRefresh(new Date().getTime());
        }
      }
    }
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  // menuitems.push(<MenuItem onClick={Edit}><span className={classes.bold_text}>Edit Web Alert  </span></MenuItem>)

  // menuitems.push(<MenuItem onClick={Delete}><span className={classes.bold_text}>Delete</span></MenuItem>)

  function getSiteReports() {
    var index = 0;
    var sitereport_drawer: React.ReactNode[] = [];
    if (sitereports && sitereports.length > 0) {
      sitereports?.map((sitereport) => {
        if (reportsexpanded) {
          sitereport_drawer.push(
            <Card raised className={classes.expansion_shelf}>
              <div
                className={classes.shelf_name}
                onClick={() => setSelectedItem(sitereport)}
              >
                <div className={classes.shelf_icon}>
                  <svg
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M17 12h-5v5h5v-5zM16 1v2H8V1H6v2H5c-1.11 0-1.99.9-1.99 2L3 19c0 1.1.89 2 2 2h14c1.1 0 2-.9 2-2V5c0-1.1-.9-2-2-2h-1V1h-2zm3 18H5V8h14v11z"
                      fill="#15325F"
                      fill-opacity="0.87"
                    />
                  </svg>
                </div>
                <div className={classes.bold_text}>
                  {appStore?.functionService.translate(
                    "en_ZA",
                    "report_types",
                    sitereport.report_type
                  )}{" "}
                  - ( {sitereport.report_group_name} )
                </div>
              </div>
              <div
                className={classes.shelf_container}
                onClick={() => setSelectedItem(sitereport)}
              >
                <div className={classes.shelf_item}>
                  <div className="circle"></div>
                </div>
              </div>
              <div className={classes.shelf_menu}>
                <ExpansionPanelMenu
                  site={site}
                  handleGenerateSentinelToken={() => {}}
                  handleShowSentinelToken={() => {}}
                  sentinel={false}
                  handleAddGroupToSentinel={() => {}}
                  training={false}
                  handleTraining={dummy}
                  rename_site={false}
                  handleRenameSite={dummy}
                  enter={false}
                  nvr={false}
                  siteDynDNS={false}
                  requestDynDNS={true}
                  canSetDynDNS={false}
                  canViewDynDNS={false}
                  canViewFrontel={false}
                  billingratesedit={false}
                  disconnectOption={false}
                  disconnectStatus={false}
                  handleDisconnect={dummy}
                  handleBillingRatesEdit={dummy}
                  move={false}
                  handleMove={dummy}
                  handleNvr={dummy}
                  handleActivateDynDns={dummy}
                  handleSetDynDns={dummy}
                  handleViewDynDns={dummy}
                  del={true}
                  edit={false}
                  enable={false}
                  enabled={true}
                  handleEdit={dummy}
                  handleEnable={dummy}
                  handleDelete={() =>
                    openConfirmDeleteSiteReport(
                      sitereport.id,
                      sitereport.report_type
                    )
                  }
                  alert_smtp_client={false}
                  handleSmtpPasswordChange={dummy}
                  report={true}
                  rules={false}
                  view_users={false}
                  handleAddTele={dummy}
                  handleAddGroupToFrontel={() => {}}
                  handleAddSiteToFrontel={() => {}}
                ></ExpansionPanelMenu>
              </div>
            </Card>
          );
        }
        index++;
      });
    }
    if (index === 0) {
      sitereport_drawer.push(
        <Card raised className={classes.expansion_shelf}>
          <div className={classes.bold_text}>
            no site reports added to the site
          </div>
        </Card>
      );
    }
    return [sitereport_drawer, index];
  }

  function getHubs() {
    var index = 0;
    var hub_drawer: React.ReactNode[] = [];
    hubs?.forEach((hub) => {
      var activated = [];
      if (!isvirtual) {
        if (hub.activated.hub_activated === "no") {
          activated.push("Not Activated");
        } else {
          if (isCameraMate) {
            activated.push(
              <div style={{ display: "flex", alignItems: "center" }}>
                <div style={{ marginRight: 10 }}>Activated</div>{" "}
                <Brightness1 style={{ color: "green", fontSize: 20 }} />
              </div>
            );
          } else {
            activated.push("Activated");
          }
        }
      }
      var healthicons = [];
      var fill = "";
      var opacity = "0.95";
      if (health && health.length > 0 && !isvirtual && !isCameraMate) {
        let sitehealth = health.find((x) => x.site_id == site?.site_id);
        let hubshealth = sitehealth?.hubs;
        let hubhealth = hubshealth?.find(
          (x: any) => x.hub_id_str === hub.hub_id_str
        );
        if (hubhealth) {
          healthicons.push("Message:");
          hubhealth.msg_healthy
            ? healthicons.push(
                <SentimentSatisfiedAltIcon
                  className={classes.green}
                ></SentimentSatisfiedAltIcon>
              )
            : healthicons.push(
                <SentimentVeryDissatisfiedIcon
                  className={classes.red}
                ></SentimentVeryDissatisfiedIcon>
              );
          healthicons.push("VPN:");
          hubhealth.vpn_healthy
            ? healthicons.push(
                <SentimentSatisfiedAltIcon
                  className={classes.green}
                ></SentimentSatisfiedAltIcon>
              )
            : healthicons.push(
                <SentimentVeryDissatisfiedIcon
                  className={classes.red}
                ></SentimentVeryDissatisfiedIcon>
              );
          healthicons.push("Site:");
          if (hubhealth.site_health_indicator === "error") {
            healthicons.push(
              <SentimentVeryDissatisfiedIcon
                className={classes.red}
              ></SentimentVeryDissatisfiedIcon>
            );
            fill = "#ee4623";
          } else if (hubhealth.site_health_indicator === "warning") {
            healthicons.push(
              <SentimentDissatisfiedIcon
                className={classes.amber}
              ></SentimentDissatisfiedIcon>
            );
            fill = "#b4d5fa";
          } else if (hubhealth.site_health_indicator === "ok") {
            healthicons.push(
              <SentimentSatisfiedAltIcon
                className={classes.green}
              ></SentimentSatisfiedAltIcon>
            );
            fill = "#3f54a5";
            opacity = "0.75";
          } else {
            healthicons.push(
              <RadioButtonUncheckedIcon></RadioButtonUncheckedIcon>
            );
          }
        } else {
          healthicons.push("Message:");
          healthicons.push(
            <RadioButtonUncheckedIcon></RadioButtonUncheckedIcon>
          );
          healthicons.push("VPN:");
          healthicons.push(
            <RadioButtonUncheckedIcon></RadioButtonUncheckedIcon>
          );
          healthicons.push("Hub:");
          healthicons.push(
            <RadioButtonUncheckedIcon></RadioButtonUncheckedIcon>
          );
        }
      }
      hub.type = "hub";
      var hubmenu: React.ReactNode[] = [];
      if (hubsexpanded) {
        if (!isvirtual) {
          hubmenu.push(
            <div className={classes.shelf_menu}>
              <HubExpansionPanelMenu
                move={false}
                handleMove={dummy}
                status={true}
                del={true}
                edit={
                  appStore!.authService.getRoles().indexOf("sys_admin") >= 0
                }
                enable={false}
                enabled={true}
                handleEdit={() => editHub(hub)}
                handleStatus={() => statusHub(hub)}
                handleEnable={dummy}
                handleDelete={() =>
                  openConfirmDeleteHub(hub.hub_id, hub.hub_id_str)
                }
              ></HubExpansionPanelMenu>
            </div>
          );
        }
        hub_drawer.push(
          <Card raised className={classes.expansion_shelf}>
            <div className={classes.shelf_name} onClick={() => editHub(hub)}>
              <div className={classes.shelf_icon}>
                <svg
                  width="35"
                  height="35"
                  viewBox="-10 -10 35 35"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <g opacity="0.87">
                    <circle
                      cx="12"
                      cy="12"
                      r="13"
                      fill={fill}
                      fill-opacity={opacity}
                    ></circle>
                    <path
                      d="M17 16L13 12V8.82C14.16 8.4 15 7.3 15 6C15 4.34 13.66 3 12 3C10.34 3 9 4.34 9 6C9 7.3 9.84 8.4 11 8.82V12L7 16H3V21H8V17.95L12 13.75L16 17.95V21H21V16H17Z"
                      fill="#15325F"
                      fill-opacity="0.87"
                    />
                  </g>
                </svg>
              </div>
              <div className={classes.bold_text}>
                {hub.hub_id_str}
                {hub.shared ? hub.shared : ""}
              </div>
            </div>
            <div
              className={classes.shelf_container}
              onClick={() => editHub(hub)}
            >
              <div className={classes.shelf_item}>
                <div className={classes.bold_text}>
                  {hub.type_str === "logical" ? "logical hub" : hub.location}
                </div>
              </div>
              <div className={classes.shelf_item}>
                <div className={classes.bold_text}>{activated}</div>
              </div>
              <div className={classes.shelf_item}>
                <div className={classes.bold_text}>{healthicons}</div>
              </div>
            </div>
            {hubmenu}
          </Card>
        );
      }
      index++;
    });
    if (index === 0) {
      hub_drawer.push(
        <Card raised className={classes.expansion_shelf}>
          <div className={classes.bold_text}>no hubs added to the site</div>
        </Card>
      );
    }
    return [hub_drawer, index];
  }

  function getSensors() {
    var sensor_drawer: React.ReactNode[] = [];
    var index = 0;
    var enabledSensors = 0;
    var disabledSensors = 0;
    var hasdisabled = false;

    if (sensors && sensors.length > 0) {
      const parentSensors = sensors.filter(
        (item: any) => !item.parent_sensor_id
      );
      const subSensors = sensors.filter((item: any) => item.parent_sensor_id);

      parentSensors?.forEach((pSensor: any) => {
        const foundSubSensors = subSensors.filter(
          (sSensor: any) => sSensor?.parent_sensor_id === pSensor?.sensor_id
        );
        let allSensors: any = [pSensor];
        if (foundSubSensors.length > 0) {
          allSensors = [pSensor, ...foundSubSensors];
        }

        allSensors?.forEach((sensor: any) => {
          sensor.type = "sensor";
          var thumbnail: React.ReactNode[] = [];
          if (sensor.thumbnail && sensor.thumbnail.stringb64) {
            thumbnail.push(
              <img
                src={"data:image/png;base64," + sensor.thumbnail.stringb64}
                height={35}
              />
            );
          }
          var fill = "";
          var opacity = "0.95";
          if (health && health.length > 0) {
            let sitehealth = health.find((x) => x.site_id === site?.site_id);
            let sensors = sitehealth?.sensors;
            let sensorhealth = sensors?.find(
              (x: any) => x.sensor_id === sensor.sensor_id
            );
            if (sensorhealth?.sensor_overall_indicator === "ok") {
              fill = "#3f54a5";
              opacity = "0.75";
            }
            if (sensorhealth?.sensor_overall_indicator === "warning") {
              fill = "#b4d5fa";
            }
            if (sensorhealth?.sensor_overall_indicator === "error") {
              fill = "#ee4623";
            }
          }
          var show = false;
          var sensor_icon: React.ReactNode[] = [];
          var default_icon: React.ReactNode[] = [
            <svg
              width="35"
              height="35"
              viewBox="-10 -10 35 35"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <g opacity="0.87">
                <circle
                  cx="11"
                  cy="11"
                  r="13"
                  fill={fill}
                  fill-opacity={opacity}
                ></circle>
                <path
                  d="M17 10.5V7c0-.55-.45-1-1-1H4c-.55 0-1 .45-1 1v10c0 .55.45 1 1 1h12c.55 0 1-.45 1-1v-3.5l4 4v-11l-4 4z"
                  fill="#15325F"
                  fill-opacity="0.87"
                />
              </g>
            </svg>,
          ];
          let bln_sensor_muted = false;
          let muted_until = "";
          let bool = false;
          if (sensor.enabled) {
            show = true;

            // Camera Mute Icon logic has been added here
            // Debugging Variables
            // let muteduntil = 1627904261.992137 * 1000;
            // let untildate = new Date(muteduntil);
            // let testArray: [number, number, string][] = [
            //   [61, 108, untildate.toString()],
            // ];

            // let bl = mutedSensorIds!.indexOf(sensor.sensor_id) >= 0;

            bool = mutedSensorIds.indexOf(sensor.sensor_id) >= 0;
            let timeArr: React.ReactNode[] = [];
            if (mutedsensors.length > 0) {
              mutedsensors.forEach((muted) => {
                if (muted.length === 3 && muted[1] === sensor.sensor_id) {
                  const channelName = alertchannels!.filter(
                    (channel) => channel.site_id === muted[0]
                  )[0].name;
                  bln_sensor_muted = true;
                  let now = new Date();
                  let until_date = Date.parse(muted[2]);
                  var diff = (until_date - now.getTime()) / 1000;
                  diff /= 60;
                  let min = Math.abs(Math.round(diff));
                  if (min === 1) {
                    muted_until = min + ` minute left - ${channelName}`;
                    timeArr.push(muted_until);
                  } else {
                    muted_until = min + ` minutes left - ${channelName}`;
                    timeArr.push(muted_until);
                  }
                  if (timeArr.length === 0) {
                    sensor_icon.push(
                      <Tooltip placement="top" title={muted_until}>
                        <svg
                          width="38"
                          height="38"
                          viewBox="-10 -10 38 38"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <g opacity="0.87">
                            <path
                              d="M6 2v6h.01L6 8.01 10 12l-4 4 .01.01H6V22h12v-5.99h-.01L18 16l-4-4 4-3.99-.01-.01H18V2H6zm10 14.5V20H8v-3.5l4-4 4 4zm-4-5l-4-4V4h8v3.5l-4 4z"
                              fill="#ee4623"
                            />
                          </g>
                        </svg>
                      </Tooltip>
                    );
                  } else {
                    let test_time: React.ReactNode[] = [];
                    if (timeArr !== undefined) {
                      timeArr.forEach((time) => {
                        test_time.push(` ${time}`);
                      });
                    } else {
                      test_time.push(" one channel is unmuted");
                    }
                    sensor_icon = [
                      <Tooltip
                        placement="top"
                        title={`Camera Mute Info : ${test_time}`}
                      >
                        <svg
                          width="38"
                          height="38"
                          viewBox="-10 -10 38 38"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <g opacity="0.87">
                            <path
                              d="M6 2v6h.01L6 8.01 10 12l-4 4 .01.01H6V22h12v-5.99h-.01L18 16l-4-4 4-3.99-.01-.01H18V2H6zm10 14.5V20H8v-3.5l4-4 4 4zm-4-5l-4-4V4h8v3.5l-4 4z"
                              fill="#ee4623"
                            />
                          </g>
                        </svg>
                      </Tooltip>,
                    ];
                  }
                }
                //  else {
                //   sensor_icon.push(
                //     <svg
                //       width='35'
                //       height='35'
                //       viewBox='-10 -10 35 35'
                //       fill='none'
                //       xmlns='http://www.w3.org/2000/svg'
                //     >
                //       <g opacity='0.87'>
                //         <circle
                //           cx='11'
                //           cy='11'
                //           r='13'
                //           fill={fill}
                //           fill-opacity={opacity}
                //         ></circle>
                //         <path
                //           d='M17 10.5V7c0-.55-.45-1-1-1H4c-.55 0-1 .45-1 1v10c0 .55.45 1 1 1h12c.55 0 1-.45 1-1v-3.5l4 4v-11l-4 4z'
                //           fill='#15325F'
                //           fill-opacity='0.87'
                //         />
                //       </g>
                //     </svg>
                //   );
                // }
              });
            } else {
              sensor_icon.push(
                <svg
                  width="35"
                  height="35"
                  viewBox="-10 -10 35 35"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <g opacity="0.87">
                    <circle
                      cx="11"
                      cy="11"
                      r="13"
                      fill={fill}
                      fill-opacity={opacity}
                    ></circle>
                    <path
                      d="M17 10.5V7c0-.55-.45-1-1-1H4c-.55 0-1 .45-1 1v10c0 .55.45 1 1 1h12c.55 0 1-.45 1-1v-3.5l4 4v-11l-4 4z"
                      fill="#15325F"
                      fill-opacity="0.87"
                    />
                  </g>
                </svg>
              );
            }
            //END

            // sensor_icon.push(
            //   <svg
            //     width='35'
            //     height='35'
            //     viewBox='-10 -10 35 35'
            //     fill='none'
            //     xmlns='http://www.w3.org/2000/svg'
            //   >
            //     <g opacity='0.87'>
            //       <circle
            //         cx='11'
            //         cy='11'
            //         r='13'
            //         fill={fill}
            //         fill-opacity={opacity}
            //       ></circle>
            //       <path
            //         d='M17 10.5V7c0-.55-.45-1-1-1H4c-.55 0-1 .45-1 1v10c0 .55.45 1 1 1h12c.55 0 1-.45 1-1v-3.5l4 4v-11l-4 4z'
            //         fill='#15325F'
            //         fill-opacity='0.87'
            //       />
            //     </g>
            //   </svg>
            // );
          } else {
            hasdisabled = true;
            if (seedisabledcameras) {
              show = true;
            }
            sensor_icon.push(
              <svg
                width="35"
                height="35"
                viewBox="-10 -10 35 35"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <g opacity="0.87">
                  <circle
                    cx="11"
                    cy="11"
                    r="12"
                    stroke="#ee4623"
                    stroke-width="2"
                  ></circle>
                  <line
                    x1="3"
                    y1="3"
                    x2="20"
                    y2="20"
                    stroke="#ee4623"
                    stroke-width="2"
                  />
                  <path
                    d="M17 10.5V7c0-.55-.45-1-1-1H4c-.55 0-1 .45-1 1v10c0 .55.45 1 1 1h12c.55 0 1-.45 1-1v-3.5l4 4v-11l-4 4z"
                    fill="#15325F"
                    fill-opacity="0.87"
                  />
                </g>
              </svg>
            );
          }
          if (sensorsexpanded && show) {
            sensor_drawer.push(
              <Card
                raised
                className={classes.expansion_shelf}
                style={{
                  textAlign: "left",
                  width: sensor?.parent_sensor_id ? "95%" : "100%",
                  cursor: "default",
                }}
              >
                <div
                  className={classes.shelf_name}
                  onClick={() => setSelectedItem(sensor)}
                  style={{ cursor: "pointer" }}
                >
                  <div className={classes.shelf_icon}>
                    {!!sensor_icon.length
                      ? sensor_icon.length > 0
                        ? sensor_icon
                        : sensor_icon
                      : default_icon}
                    {/* {sensor_icon} */}
                  </div>
                  <div className={classes.bold_text}>
                    {sensor?.sensor_id_str}
                  </div>
                </div>
                <div
                  className={classes.shelf_icon}
                  style={{
                    fontWeight: "bold",
                    display: "flex",
                    alignContent: "center",
                    marginTop: "-15px",
                    justifyContent: "center",
                    padding: "0 5px",
                    color: "rgb(21,50,95)",
                    fontSize: "7px",
                  }}
                >
                  {sensor?.default_rule === true && (
                    <div
                      className={classes.shelf_item}
                      style={{
                        // marginRight: 30,
                        // marginLeft: 45,
                        // width: 70,
                        position: "absolute",
                        left: "180px",
                        marginLeft: 20,
                        paddingTop: 0,
                        marginRight: 150,
                      }}
                    >
                      <div
                        className={classes.bold_text}
                        style={{
                          color: "#ccce",
                          fontSize: 12,
                          marginBottom: 0,
                          // marginLeft: 45,
                          width: 60,
                        }}
                      >
                        <i>Using Default Rules</i>
                      </div>
                    </div>
                  )}
                  {sensor?.default_rule === false && (
                    <div
                      className={classes.shelf_item}
                      style={{
                        // marginRight: 30,
                        // marginLeft: 45,
                        // width: 70,
                        position: "absolute",
                        left: "180px",
                        marginLeft: 20,
                        paddingTop: 0,
                        marginRight: 150,
                      }}
                    >
                      <div
                        className={classes.bold_text}
                        style={{
                          color: "rgb(21,50,95)",
                          fontSize: 12,
                          marginBottom: 0,
                          marginLeft: 5,
                        }}
                      >
                        <i>Using Defined Rule</i>
                      </div>
                    </div>
                  )}
                </div>
                <div
                  className={classes.shelf_container}
                  onClick={() => {
                    setSelectedItem(sensor);
                  }}
                >
                  {/* {sensor?.advanced_rules_enabled ? (
                      <div
                        className={classes.shelf_item}
                        style={{ marginRight: 30, width: 70 }}
                      >
                        <div
                          className={classes.bold_text}
                          style={{ color: "#ccce", fontSize: 12 }}
                        >
                          <i>Advanced</i>
                        </div>
                      </div>
                    ) : (
                      <div
                        className={classes.shelf_item}
                        style={{ marginRight: 30, width: 80 }}
                      >
                        <div
                          className={classes.bold_text}
                          style={{ color: "#ccce", fontSize: 12 }}
                        >
                          <i></i>
                        </div>
                      </div>
                    )} */}
                  {/* ptz */}
                  {/* {sensor?.ptz && (
                      <div
                        className={classes.shelf_item}
                        style={{ marginRight: 30 }}
                      >
                        <div
                          className={classes.bold_text}
                          style={{ color: "#ccce", fontSize: 12 }}
                        >
                          <Tooltip title="PTZ Camera">
                            <img src={url} height={25} />
                          </Tooltip>
                        </div>
                      </div>
                    )} */}
                  <div
                    className={classes.shelf_item}
                    style={{
                      width: 250,
                      textAlign: "left",
                      alignContent: "left",
                      position: "absolute",
                      left: "350px",
                      marginLeft: 20,
                    }}
                  >
                    <Text
                      className={classes.bold_text}
                      style={{
                        width: 400,
                        textAlign: "left",
                        marginBottom: 0,
                        marginRight: 20,
                        cursor: "pointer",
                      }}
                      ellipsis={{ tooltip: sensor.human_name }}
                    >
                      {sensor.human_name}
                    </Text>
                  </div>
                  <div
                    className={classes.shelf_item}
                    style={{
                      position: "absolute",
                      left: "550px",
                      marginBottom: 0,
                      marginLeft: 70,
                      width: 100,
                      cursor: "pointer",
                    }}
                  >
                    <div className={classes.bold_text}>
                      {sensor.config?.camera_rtsp_ip?.slice(0, 20)}
                    </div>
                  </div>
                  <div
                    className={classes.shelf_item}
                    style={{
                      textAlign: "end",
                      position: "absolute",
                      left: "720px",
                      marginBottom: 0,
                      cursor: "pointer",
                      marginLeft: 20,
                    }}
                  >
                    <div className={classes.medium_text_bold}>
                      {sensor.config?.camera_rtsp_url?.slice(0, 20)}
                    </div>
                  </div>
                  <div
                    className={classes.shelf_image}
                    style={{
                      width: 200,
                      position: "absolute",
                      right: "5px",
                      cursor: "pointer",
                    }}
                  >
                    {thumbnail}
                  </div>
                </div>
                {sensor?.ptz ? (
                  <div
                    className={`${classes.shelf_menu} ${classes.shelf_name}`}
                    style={{ marginRight: 30, width: 50, marginBottom: 0 }}
                  >
                    <div
                      className={classes.bold_text}
                      style={{
                        color: "#ccce",
                        fontSize: 12,
                        cursor: "pointer",
                      }}
                    >
                      <Tooltip title="PTZ Camera">
                        <img src={url} height={25} />
                      </Tooltip>
                    </div>
                  </div>
                ) : (
                  <div
                    className={`${classes.shelf_menu} ${classes.shelf_name}`}
                    style={{ marginRight: 30, width: 50, marginBottom: 0 }}
                  >
                    <div
                      className={classes.bold_text}
                      style={{ color: "#ccce", fontSize: 12 }}
                    >
                      {/* <Tooltip title="PTZ Camera">
                            <img src={url} height={25} />
                          </Tooltip> */}
                    </div>
                  </div>
                )}
                <div className={classes.shelf_menu}>
                  <CameraMenu
                    del={
                      appStore!.authService!.getRoles()!.indexOf("sys_admin") >=
                        0 ||
                      appStore!.authService.getRoles().indexOf("support") >= 0
                    }
                    edit={!isvirtual}
                    enable={
                      appStore!
                        .authService!.getRoles()!
                        .indexOf("site_admin") >= 0
                    }
                    // appStore={appStore}
                    wizard={false}
                    snapshot={false}
                    clone={!isvirtual}
                    enabled={sensor.enabled}
                    handleWizard={() => openCameraWizard(sensor)}
                    handleEdit={() => setSelectedItem(sensor)}
                    handleEnable={() => handleEnableDisableSensor(sensor)}
                    handleSnapshot={dummy}
                    handleClone={() => {
                      openCameraWizard(sensor);
                    }}
                    handleDelete={() =>
                      openConfirmDeleteSensor(
                        sensor.sensor_id,
                        sensor.sensor_id_str,
                        sensor.hub_id
                      )
                    }
                    handleCameraMute={() => {
                      handleCameraMute(sensor.sensor_id);
                    }}
                    handleCameraUnMute={() => {
                      handleCameraUnMute(sensor.sensor_id);
                    }}
                    muted={mutedSensorIds.indexOf(sensor.sensor_id) >= 0}
                    mutedLength={mutedSensorIds.length}
                  ></CameraMenu>
                </div>
              </Card>
            );
          }
          if (sensor?.enabled) {
            enabledSensors++;
          } else if (!sensor?.enabled) {
            disabledSensors++;
          }
          index++;
        });
      });
    }

    if (index === 0) {
      sensor_drawer.push(
        <Card raised className={classes.expansion_shelf}>
          <div className={classes.bold_text}>
            no camera streams added to the site
          </div>
        </Card>
      );
    }
    return [sensor_drawer, index, hasdisabled, enabledSensors, disabledSensors];
  }

  const max_number_of_alert_channels_allowed_by_default = 1;

  function getAlertChannels() {
    var alert_channels_drawer: React.ReactNode[] = [];

    var alert_channel_drawer: React.ReactNode[] = [];
    if (alertchannelsexpanded) {
      alertchannels?.forEach((alertchannel) => {
        alert_channel_drawer.push(
          <AlertChannelInSite
            reload={setReload}
            alertchannel={alertchannel}
            currentOrg={currentOrg}
            appStore={appStore}
            site={site}
            setRefreshParent={() => setRefresh((val) => val + 1)}
          ></AlertChannelInSite>
        );
      });
    }
    let expandalertchannels: React.ReactNode[] = [];

    if (alertchannelsexpanded) {
      expandalertchannels.push(
        <ExpandLessIcon
          className={classes.icon_white}
          onClick={() => handleAlertChannelsExpanded()}
        />
      );
    } else {
      expandalertchannels.push(
        <ExpandMoreIcon
          className={classes.icon_white}
          onClick={() => handleAlertChannelsExpanded()}
        />
      );
    }
    if (alertchannels?.length === 0) {
      alert_channel_drawer.push(
        <Card raised className={classes.expansion_shelf}>
          <div className={classes.bold_text}>
            no alert rulesets configured for the site
          </div>
        </Card>
      );
    }
    alert_channels_drawer.push(
      <>
        <Card
          className={classes.expansionbar_responsive}
          onClick={() => {
            handleAlertChannelsExpanded();
          }}
        >
          <Tooltip
            placement="bottom"
            title="A set of rules for alerting on the site."
          >
            <Typography>
              <div className={classes.header}>
                <div className={classes.header_icon}>
                  <svg
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M7.58 4.08L6.15 2.65C3.75 4.48 2.17 7.3 2.03 10.5h2c.15-2.65 1.51-4.97 3.55-6.42zm12.39 6.42h2c-.15-3.2-1.73-6.02-4.12-7.85l-1.42 1.43c2.02 1.45 3.39 3.77 3.54 6.42zM18 11c0-3.07-1.64-5.64-4.5-6.32V4c0-.83-.67-1.5-1.5-1.5s-1.5.67-1.5 1.5v.68C7.63 5.36 6 7.92 6 11v5l-2 2v1h16v-1l-2-2v-5zm-6 11c.14 0 .27-.01.4-.04.65-.14 1.18-.58 1.44-1.18.1-.24.15-.5.15-.78h-4c.01 1.1.9 2 2.01 2z"
                      fill="#FFFFFF"
                      fill-opacity="0.87"
                    />
                  </svg>
                </div>
                <div className={classes.header_text_white}>
                  Alert Rulesets ({alertchannels?.length}) -{" "}
                  <span
                    style={{
                      paddingTop: 5,
                      textAlign: "center",
                      color: armData === "disarmed" ? "red" : "white",
                    }}
                  >
                    ({armData ? armData : "scheduled"})
                  </span>
                </div>
                <IconButton
                  edge="end"
                  aria-label="add"
                  onClick={(event) => {
                    event.stopPropagation();
                    if (
                      site?.data_origin === "client_hub_motioncam_security" ||
                      site?.data_origin === "system_hub_motioncam_security"
                    ) {
                      openAddAlertChannelModal();
                    }
                    // else if (
                    //   site?.data_origin === 'client_hub_objectcam_weapons' ||
                    //   site?.data_origin === 'client_hub_objectcam_safety' ||
                    //   site?.data_origin === 'system_hub_objectcam_safety' ||
                    //   site?.data_origin === 'alert_rest_client' ||
                    //   site?.data_origin === 'alert_smtp_client' ||
                    //   site?.data_origin === 'alert_ftp_client'
                    // )
                    else {
                      if (alertchannels) {
                        if (
                          alertchannels.length <
                          max_number_of_alert_channels_allowed_by_default
                        ) {
                          openAddAlertChannelModal();
                        } else {
                          openSingleOpenRulesetMessage();
                        }
                      } else {
                        openAddAlertChannelModal();
                      }
                    }
                    //  openAddAlertChannelModal()
                  }}
                >
                  <svg
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm5 11h-4v4h-2v-4H7v-2h4V7h2v4h4v2z"
                      fill="#FFFFFF"
                      fill-opacity="0.87"
                    />
                  </svg>
                </IconButton>
              </div>
            </Typography>
          </Tooltip>
          {expandalertchannels}
        </Card>

        {!showSpinner ? (
          <div className={classes.expansioncontent}>{alert_channel_drawer}</div>
        ) : (
          <div
            style={{
              margin: "0 auto",
            }}
          >
            <LoadingSpinner />
          </div>
        )}
      </>
    );
    return alert_channels_drawer;
  }
  var show_hubs = true;
  var show_sensors = true;
  var show_reports = true;
  var product: React.ReactNode[] = [];

  if (site && site.product && site!.public_product_name?.includes("Weapons")) {
    product.push(
      <img
        className={classes.site_product_icon}
        src="/assets/img/weapons.png"
        alt=""
      />
    );
  } else if (
    site &&
    site.product &&
    site!.public_product_name?.includes("Safety")
  ) {
    product.push(
      <img
        className={classes.site_product_icon}
        src="/assets/img/safety.png"
        alt=""
      />
    );
  } else if (site && site.product && site!.tier_name?.includes("premium")) {
    product.push(
      <img
        className={classes.site_product_icon}
        src="/assets/img/premium.png"
        alt=""
      />
    );
  } else {
    product.push(
      <img
        className={classes.site_product_icon}
        src="/assets/img/connect.png"
        alt=""
      />
    );
  }

  //get all the drawers
  const [hub_drawer, hubcount] = getHubs();
  const [
    sensor_drawer,
    sensorcount,
    hasdisabled,
    enabledSensors,
    disabledSensors,
  ] = getSensors();
  var alertchannel_drawer = getAlertChannels();
  const [sitereport_drawer, sitereportcount] = getSiteReports();
  var frontel_drawer: React.ReactNode[] = [];
  var ajax_drawer: React.ReactNode[] = [];
  let colour = "#e0e0e0";
  let colourRed = "#F32424";
  let colourBlue = "#15325F";
  let colorGreen = "green";
  let batteryColour = colorGreen;
  let signal_poll_msg = "no poll on record";
  let signal_poll_date = "";
  let signal_poll_time = "";
  let low_battery_msg = "";
  let low_panel_battery_msg = "";
  let low_panel_date = "";
  let low_panel_time = "";
  let low_device_battery_msg = "";
  let low_device_date = "";
  let low_device_time = "";
  let low_battery_list = "";
  const dateCheck = (recorded_date: string) => {
    const currentDate = new Date();
    const recordedDate = new Date(recorded_date);
    const timeDifference = currentDate.getTime() - recordedDate.getTime();
    // 24 hour time span in milliseconds
    const oneDay = 24 * 60 * 60 * 1000;
    return timeDifference > oneDay;
  };
  if (site?.meta && site?.meta?.signal_poll) {
    const wholeDateTime = site.meta["signal_poll"].split("T");
    signal_poll_date = wholeDateTime[0];
    signal_poll_time = wholeDateTime[1].split(".");
    signal_poll_time = signal_poll_time[0];
    signal_poll_msg =
      "last poll received " +
      signal_poll_date +
      " " +
      signal_poll_time +
      " UTC";
    if (dateCheck(site.meta["signal_poll"])) {
      colour = colourRed;
    } else {
      colour = colourBlue;
    }
  }
  if (
    site?.meta?.hasOwnProperty("frontel_panel_battery") &&
    site?.meta["frontel_panel_battery"] !== ""
  ) {
    if (low_battery_list !== "") {
      low_battery_list += ", ";
    }
    low_battery_list += "panel";
    const wholeDateTime = site.meta["frontel_panel_battery"].split("T");
    low_panel_date = wholeDateTime[0];
    low_panel_time = wholeDateTime[1].split(".");
    low_panel_time = low_panel_time[0];
    low_panel_battery_msg =
      "Panel warning at: " + low_panel_date + " " + low_panel_time + " UTC";
  }
  if (
    site?.meta?.hasOwnProperty("frontel_sensor_battery") &&
    site?.meta["frontel_sensor_battery"] !== ""
  ) {
    if (low_battery_list !== "") {
      low_battery_list += ", ";
    }
    low_battery_list += "attached device(s)";
    const wholeDateTime = site.meta["frontel_sensor_battery"].split("T");
    low_device_date = wholeDateTime[0];
    low_device_time = wholeDateTime[1].split(".");
    low_device_time = low_device_time[0];
    low_device_battery_msg =
      "Attached device(s) warning at: " +
      low_device_date +
      " " +
      low_device_time +
      " UTC";
  }
  if (
    (site?.meta?.hasOwnProperty("frontel_panel_battery") &&
      site?.meta["frontel_panel_battery"] !== "") ||
    (site?.meta?.hasOwnProperty("frontel_sensor_battery") &&
      site?.meta["frontel_sensor_battery"] !== "")
  ) {
    low_battery_msg = " - low warning received for: " + low_battery_list;
    batteryColour = colourRed;
  }
  frontel_drawer.push(
    <div style={{ width: "80%" }}>
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "center",
          padding: 10,
        }}
      >
        <div
          style={{
            paddingRight: "5%",
            float: "left",
            display: "flex",
            alignItems: "center",
          }}
        >
          <div
            style={{
              backgroundColor: colour,
              borderRadius: "50%",
              width: "30px",
              height: "30px",
              marginRight: "10px",
            }}
          ></div>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
            }}
          >
            <span className={classes.bold_text}>SIGNAL POLL</span>
            <span className={classes.bold_text}>{signal_poll_msg}</span>
          </div>
        </div>
        <div
          style={{
            float: "right",
            display: "flex",
            alignItems: "center",
          }}
        >
          <div
            style={{
              backgroundColor: batteryColour,
              borderRadius: "50%",
              width: "30px",
              height: "30px",
              marginRight: "10px",
            }}
          ></div>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
            }}
          >
            <span className={classes.bold_text}>
              BATTERY LEVEL{low_battery_msg}
            </span>
            {low_panel_battery_msg !== "" && (
              <span className={classes.bold_text}>{low_panel_battery_msg}</span>
            )}
            {low_device_battery_msg !== "" && (
              <span className={classes.bold_text}>
                {low_device_battery_msg}
              </span>
            )}
          </div>
        </div>
      </div>
      {/* { TODO: frontel buttons removed from GUI pending re-implementation with solution approach
        <div className={classes.button_container_four}>
          <Button
            variant="contained"
            className={classes.cancel_button}
            onClick={handleFrontelArm}
          >
            <span className={classes.bold_text}>ARM PANEL</span>
          </Button>
          <Button
            variant="contained"
            className={classes.save_button}
            onClick={handleFrontelDisarmTimeSelection}
          >
            <span className={classes.bold_text}>DISARM PANEL</span>
          </Button>
          <Button
            variant="contained"
            className={classes.save_button}
            onClick={handleFrontelDisconnect}
          >
            <span className={classes.bold_text}>DISCONNECT PANEL</span>
          </Button>
        </div>
      } */}
    </div>
  );
  let space: string = " ";
  ajax_drawer.push(
    <div style={{ width: "80%" }}>
      {ajaxHubStatus && (
        <div>
          <div
            title="Ajax hub status details"
            style={{
              border: "solid black 2px",
              borderRadius: "2%",
              width: "48%",
              display: "grid",
              float: "left",
              height: "600px",
            }}
          >
            <div style={{ justifySelf: "center", height: "40px" }}>
              <h2>Hub Status</h2>
            </div>
            <div style={{ overflowY: "scroll" }}>
              {Object.entries(ajaxHubStatus).map(([key, value]) => {
                let hub = [];
                let colour = "";
                if (
                  (key === "state" && value === "DISARMED") ||
                  (key === "tampered" && value === true)
                ) {
                  colour = "red";
                }
                if (key === "state" && value === "ARMED") {
                  colour = "green";
                }
                if (typeof value === "object") {
                  if (Array.isArray(value)) {
                    if (value.length === 0) {
                      hub.push(<p style={{ marginLeft: "30px" }}>N/A</p>);
                    } else {
                      if (key === "malfunctions") {
                        colour = "red";
                      }
                      for (let i of value) {
                        hub.push(<p style={{ marginLeft: "30px" }}>{i}</p>);
                      }
                    }
                  } else {
                    for (let nestedKey in value) {
                      if (
                        (nestedKey === "newVersionAvailable" &&
                          value[nestedKey] === true) ||
                        (nestedKey === "autoupdateEnabled" &&
                          value[nestedKey] === false) ||
                        (nestedKey === "chargeLevelPercentage" &&
                          value[nestedKey] <= 25)
                      ) {
                        colour = "red";
                      }
                      if (
                        nestedKey === "chargeLevelPercentage" &&
                        value[nestedKey] > 25 &&
                        value[nestedKey] < 75
                      ) {
                        colour = "orange";
                      }
                      hub.push(
                        <p
                          style={{
                            marginLeft: "30px",
                            backgroundColor: colour,
                          }}
                        >
                          <strong>{nestedKey.replaceAll("_", " ")}:</strong>{" "}
                          {space}{" "}
                          {value[nestedKey] === null ||
                          value[nestedKey] === undefined ||
                          value[nestedKey] === "" ||
                          (Array.isArray(value[nestedKey]) &&
                            value[nestedKey].length === 0)
                            ? "N/A"
                            : typeof value[nestedKey] === "boolean"
                            ? value[nestedKey].toString()
                            : value[nestedKey]}
                        </p>
                      );
                      colour = "";
                    }
                  }
                }
                return (
                  <Typography
                    key={key}
                    variant="body1"
                    style={{ margin: "5px", backgroundColor: colour }}
                  >
                    <strong>{key.replaceAll("_", " ")}: </strong>
                    {space}{" "}
                    {typeof value === "object"
                      ? hub
                      : value === null || value === undefined
                      ? "N/A"
                      : typeof value === "boolean"
                      ? value.toString()
                      : value}
                  </Typography>
                );
              })}
            </div>
          </div>

          <div
            title="Ajax devices status details"
            style={{
              border: "solid black 2px",
              borderRadius: "2%",
              width: "48%",
              display: "grid",
              float: "right",
              height: "600px",
            }}
          >
            <div style={{ justifySelf: "center", height: "40px" }}>
              <h2>Device Statuses</h2>
            </div>
            <div style={{ overflowY: "scroll" }}>
              {Array.isArray(ajaxDeviceStatus) ? (
                ajaxDeviceStatus.map((device, index) => (
                  <div key={index} style={{ marginBottom: "10px" }}>
                    {Object.entries(device).map(([key, value]) => {
                      let devices = [];
                      let colour = "";
                      if (
                        (key === "state" && value === "DISARMED") ||
                        (key === "tampered" && value === true) ||
                        (key === "battery" && value <= 25) ||
                        (key === "online" && value === false) ||
                        (key === "message" && value !== "N/A")
                      ) {
                        colour = "red";
                      }
                      if (key === "battery" && value > 25 && value < 75) {
                        colour = "orange";
                      }
                      if (key === "online" && value === true) {
                        colour = "green";
                      }
                      if (typeof value === "object") {
                        if (Array.isArray(value)) {
                          if (value.length === 0) {
                            devices.push(
                              <p style={{ marginLeft: "30px" }}>N/A</p>
                            );
                          } else {
                            if (key === "malfunctions") {
                              colour = "red";
                            }
                            for (let i of value) {
                              devices.push(
                                <p style={{ marginLeft: "30px" }}>{i}</p>
                              );
                            }
                          }
                        } else {
                          for (let nestedKey in value) {
                            devices.push(
                              <p
                                style={{
                                  marginLeft: "30px",
                                  backgroundColor: colour,
                                }}
                              >
                                {nestedKey.replaceAll("_", " ")}: {space}{" "}
                                {value[nestedKey] === null ||
                                value[nestedKey] === undefined ||
                                value[nestedKey] === "" ||
                                (Array.isArray(value[nestedKey]) &&
                                  value[nestedKey].length === 0)
                                  ? "N/A"
                                  : typeof value[nestedKey] === "boolean"
                                  ? value[nestedKey].toString()
                                  : value[nestedKey]}
                              </p>
                            );
                            colour = "";
                          }
                        }
                      }
                      return (
                        <Typography
                          key={key}
                          variant="body1"
                          style={{ margin: "5px", backgroundColor: colour }}
                        >
                          <strong>{key.replaceAll("_", " ")}: </strong>
                          {space}{" "}
                          {typeof value === "object"
                            ? devices
                            : value === null || value === undefined
                            ? "N/A"
                            : typeof value === "boolean"
                            ? value.toString()
                            : value}
                        </Typography>
                      );
                    })}
                    <hr></hr>
                  </div>
                ))
              ) : (
                <p>No devices available</p>
              )}
            </div>
          </div>
        </div>
      )}
      <div
        className={classes.button_container_four}
        style={{ paddingTop: "2.5%" }}
      >
        <Button
          variant="contained"
          className={classes.cancel_button}
          onClick={() => {
            handleAjaxArm("ARM");
          }}
        >
          <span className={classes.bold_text}>ARM HUB</span>
        </Button>

        {/* TODO - Jason: insert button once elevated company permissions allowing for disarm capabilities established with Ajax */}
        {/* <Button
          variant="contained"
          className={classes.cancel_button}
          onClick={() => {
            handleAjaxArm("DISARM");
          }}
        >
          <span className={classes.bold_text}>DISARM HUB</span>
        </Button> */}
      </div>
    </div>
  );

  let expandsensors: React.ReactNode[] = [];
  if (sensorsexpanded) {
    expandsensors.push(
      <ExpandLessIcon
        className={classes.icon_white}
        onClick={() => handleSensorsExpanded()}
      />
    );
  } else {
    expandsensors.push(
      <ExpandMoreIcon
        className={classes.icon_white}
        onClick={() => handleSensorsExpanded()}
      />
    );
  }

  let expandFrontelCommands: React.ReactNode[] = [];
  if (frontelExpanded) {
    expandFrontelCommands.push(
      <ExpandLessIcon
        className={classes.icon_white}
        onClick={() => handleFrontelExpanded()}
      />
    );
  } else {
    expandFrontelCommands.push(
      <ExpandMoreIcon
        className={classes.icon_white}
        onClick={() => handleFrontelExpanded()}
      />
    );
  }

  var frontel_display: React.ReactNode[] = [];
  // const armStatus = site?.meta?.arm_status || "Unknown"
  const armStatus = site?.meta?.arm_status
    ? ` - (Frontel arm status: ${site?.meta?.arm_status})`
    : `- (Frontel arm status: unknown)`;
  if (site?.data_origin?.includes("frontel")) {
    frontel_display.push(
      <>
        <Card
          className={classes.expansionbar_responsive}
          onClick={() => {
            handleFrontelExpanded();
          }}
        >
          <Tooltip placement="bottom" title="">
            <Typography>
              <div className={classes.header}>
                <div className={classes.header_icon}>
                  <svg
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M17 10.5V7c0-.55-.45-1-1-1H4c-.55 0-1 .45-1 1v10c0 .55.45 1 1 1h12c.55 0 1-.45 1-1v-3.5l4 4v-11l-4 4z"
                      fill="#FFFFFF"
                      fill-opacity="0.87"
                    />
                  </svg>
                </div>
                <div className={classes.header_text_white}>
                  Frontel Controls {armStatus}
                </div>
              </div>
            </Typography>
          </Tooltip>
          {expandFrontelCommands}
        </Card>
        <div className={classes.expansioncontent}>{frontel_drawer}</div>
      </>
    );
  }

  let expandAjaxCommands: React.ReactNode[] = [];
  if (ajaxExpanded) {
    expandAjaxCommands.push(
      <ExpandLessIcon
        className={classes.icon_white}
        onClick={() => handleAjaxExpanded()}
      />
    );
  } else {
    expandAjaxCommands.push(
      <ExpandMoreIcon
        className={classes.icon_white}
        onClick={() => handleAjaxExpanded()}
      />
    );
  }

  var ajax_display: React.ReactNode[] = [];
  if (site?.data_origin?.includes("ajax")) {
    ajax_display.push(
      <>
        <Card
          className={classes.expansionbar_responsive}
          onClick={() => {
            handleAjaxExpanded();
          }}
        >
          <Tooltip placement="bottom" title="">
            <Typography>
              <div className={classes.header}>
                <div className={classes.header_icon}>
                  <svg
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M17 10.5V7c0-.55-.45-1-1-1H4c-.55 0-1 .45-1 1v10c0 .55.45 1 1 1h12c.55 0 1-.45 1-1v-3.5l4 4v-11l-4 4z"
                      fill="#FFFFFF"
                      fill-opacity="0.87"
                    />
                  </svg>
                </div>
                <div className={classes.header_text_white}>Ajax Controls</div>
              </div>
            </Typography>
          </Tooltip>
          {expandAjaxCommands}
        </Card>
        <div className={classes.expansioncontent}>{ajax_drawer}</div>
      </>
    );
  }

  //Set icon to red when able to see disabled sites, else white
  let disablecolor = "#FFFFFF";
  if (seedisabledcameras) {
    disablecolor = "#ee4623";
  }
  //only show see diabled icon if there are disabled sensors
  var disabledicon: React.ReactNode[] = [];
  if (hasdisabled) {
    disabledicon.push(
      <IconButton
        edge="end"
        aria-label="add"
        onClick={(event) => {
          event.stopPropagation();
          handleSeeDisabledCameras();
        }}
      >
        <svg
          width="20"
          height="20"
          viewBox="0 0 20 20"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <g opacity="0.87">
            <circle
              cx="10"
              cy="10"
              r="7"
              stroke={disablecolor}
              stroke-width="2"
            ></circle>
            <line
              x1="3"
              y1="3"
              x2="17"
              y2="17"
              stroke={disablecolor}
              stroke-width="2"
            />
          </g>
        </svg>
      </IconButton>
    );
  }
  var sensors_display: React.ReactNode[] = [];
  if (show_sensors) {
    sensors_display.push(
      <>
        <Card
          className={classes.expansionbar_responsive}
          onClick={() => {
            handleSensorsExpanded();
          }}
        >
          <Tooltip
            placement="bottom"
            title="Depending on the type of site, a camera is either an RTSP video stream from a physical camera or a logical source of images or video for other types of connection."
          >
            <Typography>
              <div className={classes.header}>
                <div className={classes.header_icon}>
                  <svg
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M17 10.5V7c0-.55-.45-1-1-1H4c-.55 0-1 .45-1 1v10c0 .55.45 1 1 1h12c.55 0 1-.45 1-1v-3.5l4 4v-11l-4 4z"
                      fill="#FFFFFF"
                      fill-opacity="0.87"
                    />
                  </svg>
                </div>
                {/* <div className={classes.header_text_white}>
                  Cameras{"  (" + sensorcount + ")"}
                </div> */}
                <div className={classes.header_text_white}>
                  Cameras -
                  {` (enabled=${enabledSensors}) (disabled=${disabledSensors})`}
                </div>
                {/* {site?.data_origin &&
                (site?.data_origin === "alert_smtp_client" ||
                  site?.data_origin.includes("frontel")) ? null : (
                  <IconButton
                    edge="end"
                    aria-label="add"
                    onClick={(event) => {
                      event.stopPropagation();
                      openCameraWizard(undefined);
                    }}
                  >
                    <svg
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm5 11h-4v4h-2v-4H7v-2h4V7h2v4h4v2z"
                        fill="#FFFFFF"
                        fill-opacity="0.87"
                      />
                    </svg>
                  </IconButton>
                )} */}
                {site?.data_origin && site?.data_origin.includes("client_hub") && (
                  <IconButton
                    edge="end"
                    aria-label="add"
                    onClick={(event) => {
                      event.stopPropagation();
                      openCameraWizard(undefined);
                    }}
                  >
                    <svg
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm5 11h-4v4h-2v-4H7v-2h4V7h2v4h4v2z"
                        fill="#FFFFFF"
                        fill-opacity="0.87"
                      />
                    </svg>
                  </IconButton>
                )}

                {disabledicon}
                {site?.data_origin === "alert_smtp_client" && (
                  <span
                    style={{ color: "white", marginLeft: 20, marginTop: 5 }}
                    onClick={(event) => {
                      event.stopPropagation();
                      openSMTPEmails();
                    }}
                  >
                    <MailIcon style={{ fontSize: 25 }} />
                  </span>
                )}
              </div>
            </Typography>
          </Tooltip>
          {expandsensors}
        </Card>
        <div className={classes.expansioncontent}>{sensor_drawer}</div>
      </>
    );
  }
  var hub_display: React.ReactNode[] = [];
  var addhub: React.ReactNode[] = [];
  let expandhubs: React.ReactNode[] = [];
  if (hubsexpanded) {
    expandhubs.push(
      <ExpandLessIcon
        className={classes.icon_white}
        onClick={() => handleHubsExpanded()}
      />
    );
  } else {
    expandhubs.push(
      <ExpandMoreIcon
        className={classes.icon_white}
        onClick={() => handleHubsExpanded()}
      />
    );
  }
  if (!isvirtual) {
    addhub.push(
      <IconButton
        edge="end"
        aria-label="add"
        onClick={(event) => {
          event.stopPropagation();
          openAddHubModal();
        }}
      >
        <svg
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm5 11h-4v4h-2v-4H7v-2h4V7h2v4h4v2z"
            fill="#FFFFFF"
            fill-opacity="0.87"
          />
        </svg>
      </IconButton>
    );
  }
  if (show_hubs) {
    hub_display.push(
      <>
        <Card
          className={classes.expansionbar_responsive}
          onClick={() => {
            handleHubsExpanded();
          }}
        >
          <Tooltip
            placement="bottom"
            title="A hub is a physical computing device to which cameras are attached. While each camera is site specific, a hub can be site specific or shared between sites."
          >
            <Typography>
              <div className={classes.header}>
                <div className={classes.header_icon}>
                  <svg
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M17 16L13 12V8.82C14.16 8.4 15 7.3 15 6C15 4.34 13.66 3 12 3C10.34 3 9 4.34 9 6C9 7.3 9.84 8.4 11 8.82V12L7 16H3V21H8V17.95L12 13.75L16 17.95V21H21V16H17Z"
                      fill="#FFFFFF"
                      fill-opacity="0.87"
                    />
                  </svg>
                </div>
                <div className={classes.header_text_white}>
                  Hubs{"  (" + hubcount + ")"}
                </div>
                {addhub}
              </div>
            </Typography>
          </Tooltip>
          {expandhubs}
        </Card>
        <div className={classes.expansioncontent}>{hub_drawer}</div>
      </>
    );
  }
  let expandreports: React.ReactNode[] = [];
  if (reportsexpanded) {
    expandreports.push(
      <ExpandLessIcon
        className={classes.icon_white}
        onClick={() => handleReportsExpanded()}
      />
    );
  } else {
    expandreports.push(
      <ExpandMoreIcon
        className={classes.icon_white}
        onClick={() => handleReportsExpanded()}
      />
    );
  }
  var report_display: React.ReactNode[] = [];
  if (show_reports) {
    report_display.push(
      <>
        <Card
          className={classes.expansionbar_responsive}
          onClick={() => {
            handleReportsExpanded();
          }}
        >
          <Tooltip
            placement="bottom"
            title="A site report is report for the site configured to show health or activity."
          >
            <Typography>
              <div className={classes.header}>
                <div className={classes.header_icon}>
                  <svg
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M17 12h-5v5h5v-5zM16 1v2H8V1H6v2H5c-1.11 0-1.99.9-1.99 2L3 19c0 1.1.89 2 2 2h14c1.1 0 2-.9 2-2V5c0-1.1-.9-2-2-2h-1V1h-2zm3 18H5V8h14v11z"
                      fill="#FFFFFF"
                      fill-opacity="0.87"
                    />
                  </svg>
                </div>
                <div className={classes.header_text_white}>
                  Site Reports{"  (" + sitereportcount + ")"}
                </div>
                {/* <IconButton
                  edge='end'
                  aria-label='add'
                  onClick={(event) => {
                    event.stopPropagation();
                    openAddSiteReportModal();
                  }}
                >
                  <svg
                    width='24'
                    height='24'
                    viewBox='0 0 24 24'
                    fill='none'
                    xmlns='http://www.w3.org/2000/svg'
                  >
                    <path
                      d='M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm5 11h-4v4h-2v-4H7v-2h4V7h2v4h4v2z'
                      fill='#FFFFFF'
                      fill-opacity='0.87'
                    />
                  </svg>
                </IconButton> */}
              </div>
            </Typography>
          </Tooltip>
          {expandreports}
        </Card>
        <div className={classes.expansioncontent}>{sitereport_drawer}</div>
      </>
    );
  }
  var updateButton: React.ReactNode[] = [];
  updateButton.push(
    <div className={classes.button_container}>
      <Button
        variant="contained"
        className={classes.bold_text}
        onClick={handleUpdate}
      >
        Update
      </Button>
    </div>
  );
  var siteadmininfo: React.ReactNode[] = [];
  siteadmininfo.push(
    <>
      <span className={classes.bold_text}>Group ID: {currentOrg.org_id}</span>
      <br></br>
      <span className={classes.bold_text}>Site ID: {site?.site_id}</span>
      <br></br>

      <span className={classes.bold_text}>Created: {site?.created}</span>
      <br></br>
      <FormControlLabel
        className={classes.form_component_checkbox}
        control={
          <Checkbox
            color="primary"
            checked={training}
            onChange={handleCheckTraining}
          />
        }
        label="Provide Training Images"
      />

      <br></br>
      <FormControlLabel
        className={classes.form_component_checkbox}
        control={
          <Checkbox
            disabled={!training}
            color="primary"
            checked={training_triggers_only}
            onChange={handleCheckTrainingTriggerOnly}
          />
        }
        label="Provide Training Images only on Trigger"
      />
      <TextField
        onChange={handleTrainingPercentageChange}
        label="Training fraction"
        variant="outlined"
        margin="normal"
        value={training_fraction}
        fullWidth
        disabled={!training}
        InputProps={{
          className: classes.bold_text,
        }}
        InputLabelProps={{
          classes: {
            root: classes.bold_text,
          },
        }}
      />
    </>
  );

  var fill = "";
  var opacity = "0.95";
  if (site && health && health.length > 0) {
    let sitehealth = health.find((x) => x.site_id === site?.site_id);
    if (sitehealth?.health_indicator === "ok") {
      fill = "#3f54a5";
      opacity = "0.75";
    }
    if (sitehealth?.health_indicator === "warning") {
      fill = "#b4d5fa";
    }
    if (sitehealth?.health_indicator === "error") {
      fill = "#ee4623";
    }
  }
  var siteinfo: React.ReactNode[] = [];
  if (advancedsettings) {
    siteinfo.push(
      <div className={classes.medium_panel}>
        <Typography className={classes.bold_error_text}>{message}</Typography>
        {siteadmininfo}
        <TextField
          fullWidth
          label="Site Name"
          onChange={handleNameChange}
          value={name}
          variant="outlined"
          margin="normal"
          InputProps={{
            className: classes.bold_text,
          }}
          InputLabelProps={{
            classes: {
              root: classes.bold_text,
            },
          }}
        />
        {updateButton}
      </div>
    );
  }
  if (loading || localloading) {
    return (
      <Container maxWidth="md">
        <div className="wait-container">
          <svg width="48px" height="48px" viewBox="0 0 128 128">
            <g>
              <linearGradient id="linear-gradient">
                <stop offset="0%" stop-color="#ffffff" />
                <stop offset="100%" stop-color="#22305f" />
              </linearGradient>
              <path
                d="M63.85 0A63.85 63.85 0 1 1 0 63.85 63.85 63.85 0 0 1 63.85 0zm.65 19.5a44 44 0 1 1-44 44 44 44 0 0 1 44-44z"
                fill="url(#linear-gradient)"
                fill-rule="evenodd"
              />
              <animateTransform
                attributeName="transform"
                type="rotate"
                from="0 64 64"
                to="360 64 64"
                dur="1080ms"
                repeatCount="indefinite"
              ></animateTransform>
            </g>
          </svg>
        </div>
      </Container>
    );
  } else {
    return (
      <div
        className="main-drawer-container"
        style={{ width: showGraph ? "inherit" : "100%" }}
      >
        <Grid container spacing={1}>
          <Grid item xs={6}>
            <div className={classes.site_header}>
              <Grid container spacing={2} direction={"column"}>
                <Grid item xs={12}>
                  <Tooltip
                    placement="bottom"
                    title="Click to view latest site health info."
                  >
                    <div
                      style={{ cursor: "pointer" }}
                      className={classes.site_header_icon}
                      onClick={siteHealthDetail}
                    >
                      <svg
                        width="35"
                        height="35"
                        viewBox="-10 -10 35 35"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <g opacity="0.87">
                          <circle
                            cx="10"
                            cy="10"
                            r="13"
                            fill={fill}
                            fill-opacity={opacity}
                          ></circle>
                          <path
                            d="M17 6.3V1H14V3.6L10 0L0 9H3V17H9V11H11V17H17V9H20L17 6.3ZM15 15H13V9H7V15H5V7.19L10 2.69L15 7.19V15Z"
                            fill="#15325F"
                            fill-opacity="0.87"
                          />
                          <path
                            d="M8 7H12C12 5.9 11.1 5 10 5C8.9 5 8 5.9 8 7Z"
                            fill="#15325F"
                            fill-opacity="0.87"
                          />
                        </g>
                      </svg>
                    </div>
                  </Tooltip>
                </Grid>
                <Grid item xs={12}>
                  <div className={classes.header_center_text_site}>
                    {site
                      ? "(site id =  " + " " + site.site_id + ")"
                      : "Site ID :" + " "}
                  </div>

                  <div className={classes.header_center_text_site}>
                    {orgsite.name
                      ? orgsite.name
                      : name
                      ? name
                      : "" + "  (site) "}
                  </div>
                </Grid>
              </Grid>
            </div>
          </Grid>

          <Grid item xs={6}>
            <div className={classes.site_header}>
              <Grid container spacing={2}>
                <Grid item xs={11}>
                  <div className={classes.site_product_icon}>{product}</div>
                </Grid>
                <Grid item xs={1}></Grid>
                <Grid item xs={11}>
                  <div className={classes.header_center_text_site}>
                    {site!.public_product_name
                      ? site!.public_product_name
                      : site!.product}
                  </div>
                </Grid>
                <Grid item xs={1}></Grid>
              </Grid>
            </div>
          </Grid>
        </Grid>
        {siteinfo}
        {alertchannel_drawer}
        {frontel_display}
        {ajax_display}
        {sensors_display}
        {hub_display}
        {report_display}
        {/****************************************  Modal POPUS  **************************************/}
        <StatusHubModal
          appStore={appStore}
          open={statushub}
          hub={statusinghub!}
          updateHub={dummy}
          onClose={closeStatusHub}
        ></StatusHubModal>
        <EditHubModal
          site={site}
          appStore={appStore}
          open={edithub}
          hub={editinghub!}
          updateHub={(location: string, networkconfig: NetworkConfig) =>
            updateHub(location, networkconfig)
          }
          updateSharedHub={(location: string, networkconfig: NetworkConfig) =>
            updateSharedHub(location, networkconfig)
          }
          onClose={closeEditHub}
        ></EditHubModal>
        <EditSiteReportModal
          message={""}
          appStore={appStore}
          open={editsitereport}
          reportgroups={reportgroups}
          sitereport={editingsitereport!}
          updateSiteReport={(
            site_reporting_id: number,
            daily_utc_schedule: string
          ) => updateSiteReport(site_reporting_id, daily_utc_schedule)}
          onClose={closeEditSiteReport}
        ></EditSiteReportModal>
        <SiteHealthDetail
          site_id={site?.site_id}
          appStore={appStore}
          open={sitehealthdetail}
          onClose={closeSiteHealthDetail}
        ></SiteHealthDetail>
        <ConfirmDialog
          title=""
          open={confirmHubOpen}
          setOpen={setConfirmHubOpen}
          onConfirm={deleteHub}
        >
          {confirmmessage}
        </ConfirmDialog>
        <ConfirmDialog
          title=""
          open={confirmSensorOpen}
          setOpen={setConfirmSensorOpen}
          onConfirm={deleteSensor}
        >
          {confirmmessage}
        </ConfirmDialog>
        <ConfirmDialog
          title=""
          open={confirmsitereportopen}
          setOpen={setConfirmSiteReportOpen}
          onConfirm={deleteSiteReport}
        >
          {confirmmessage}
        </ConfirmDialog>
        <CameraWizard
          clone={clone}
          appStore={appStore}
          site={site}
          open={camerawizardopen}
          onClose={closeCameraWizard}
          saveNewCamera={saveNewCamera}
        ></CameraWizard>
        {/* {(unknown_email_list!.length !== 0 && test_email!.length !== 0) && */}
        <SmtpEmails
          test_mail={test_email}
          unknown_email={unknown_email_list}
          open_email={smtp_emails_open}
          close_email={() => setSMTPEmailOpen(false)}
        />
        <MessageModal
          open={messagemodalopen}
          onClose={closeMessageModal}
          message={popmessage}
          elements={undefined}
        />
        <CameraMuteModal
          appStore={appStore}
          sensorId={selectedSensorId}
          channelIds={channelIDs}
          siteId={site!.site_id}
          open={openCameraMuting}
          onClose={() => {
            setCameraMuting((prev) => !prev);
          }}
        />
        <DisableSensorModal
          open={disableSensorModalOpen}
          onClose={() => {
            setDisableSensorModalOpen(false);
            setSelectedSensor(null);
            setPopMessage("Cancelled disabling of sensor");
            openMessageModal();
          }}
          onSubmit={(reason) => {
            if (selectedSensor) {
              updateSensorStatus(selectedSensor, reason);
              setDisableSensorModalOpen(false);
              setSelectedSensor(null);
            }
          }}
        />
        {/**********************************************************************************************/}
        <input value={refresh} type="hidden" />
        <Dialog
          fullWidth={false}
          maxWidth={maxWidth}
          open={open}
          onClose={handleClose}
          aria-labelledby="max-width-dialog-title"
        >
          <div
            style={{
              alignItems: "center",
              justifyContent: "center",
              display: "flex",
              flexDirection: "column",
            }}
          >
            <DialogContent>
              <p className={classes.bold_text} style={{ margin: 25 }}>
                {muteMessage}
              </p>
            </DialogContent>

            <DialogActions>
              <Button onClick={handleCloseMessage} color="primary">
                Close
              </Button>
            </DialogActions>
          </div>
        </Dialog>
        {showGraph && (
          <Drawer
            className={classes.drawer}
            variant="permanent"
            classes={{
              paper: classes.drawerPaper,
            }}
            anchor="right"
          >
            <Divider />
            <RightDrawer
              mid={siteId} // Site Id
              type={"site_id"}
              site_id={siteId}
              interval={interval}
              activity={activity}
              sensorhealthtimeline={sensorhealthtimeline}
              healthtimeline={healthtimeline}
              health={healthsites}
              sensorhealth={sensorhealth}
              setIntervalvalue={handleIntervalChange}
              mainMenu={mainMenu}
              showFullModalHandler={showFullModalHandler}
              siteHealthModal={siteHealthModal}
            ></RightDrawer>
          </Drawer>
        )}
      </div>
    );
  }
};

// elements: React.ReactNode[] | undefined;
//     open: boolean;
//     message: string;
//     onClose: () => void;

export default SiteMainDrawer;
