import React from "react";
import Autocomplete from "@material-ui/lab/Autocomplete";
import Grid from "@material-ui/core/Grid";
import TextField from "@material-ui/core/TextField";
import CustomModal from "../../UI/CustomModal";
import { useStyles } from "../../styling/global/global";
import { MainContext } from "../../containers/HomePage/HomePage";
import { useRecoilState } from "recoil";
import { hasSnapshotRuleState } from "./Zone";

interface Props {
  onContinue: (id?: any) => void;
  openModal: boolean;
  onClose: () => void;
  title: string;
  onChange: (event: React.ChangeEvent<any>, value: any) => void;
  value: string;
  options: any[];
}

export const SelectRulesModal: React.FC<Props> = ({
  openModal,
  title,
  onContinue,
  onClose,
  onChange,
  value,
  options,
}) => {
  const classes = useStyles();

  const ctx = React.useContext(MainContext);
  const [hasSnapshotRule] = useRecoilState(hasSnapshotRuleState);

  let filteredOptions = options;

  if (Array.isArray(options)) {
    if (ctx.alertType === "Analytics") {
      filteredOptions = options.filter(
        (item) => item?.fields?.alert_type === "analytics"
      );
    } else if (ctx.alertType === "Scheduled") {
      filteredOptions = options.filter(
        (item) => item?.fields?.alert_type === "scheduled"
      );
    } else if (ctx.alertType === "Detector") {
      filteredOptions = options.filter(
        (item) => item?.fields?.alert_type === "detector"
      );
    } else if (ctx.alertType === "Health") {
      filteredOptions = options.filter(
        (item) => item?.fields?.alert_type === "health"
      );
    } else {
      filteredOptions = options.filter(
        (item) => item?.fields?.alert_type === "snapshot"
      );
    }
  }

  const handleChangeAuto = (event: React.ChangeEvent<any>, e: any) => {
    console.debug("Alert Type: ", e);
    if (!e) {
      return;
    }

    if (e === "Scheduled" && value === "alert_zones") {
      ctx.setRuleType("");
    }

    ctx.setAlertType(e);
  };

  const analyticsOptions = [
    "Analytics",
    "Scheduled",
    "Snapshot",
    "Detector",
  ];

  let renderValue = value;
  if (ctx.alertType) {
    if (ctx.alertType === "Scheduled" && value === "alert_zones") {
      renderValue = "Snapshot";
    } else if (ctx.alertType === "Analytics" && value === "alert_zones") {
      renderValue = "Alert_Zones";
    }
  }

  return (
    <div>
      <Grid container spacing={1}>
        <CustomModal
          closeHandler={onClose}
          open={openModal}
          onContinueHandler={onContinue}
          continueAction={true}
          continue
          title={"continue"}
          disabled={!!(ctx.rule_type === "")}
        >
          <h2 className={classes.bold_text}>Alert Type:</h2>
          <Autocomplete
            id="combo-box-demo"
            onChange={handleChangeAuto}
            options={analyticsOptions}
            getOptionLabel={(option: any) => {
              return option.replace(/_/g, " ");
            }}
            style={{ width: 400, color: "#6D809D" }}
            renderInput={(params: any) => (
              <TextField
                {...params}
                label={
                  <span className={classes.bold_text} style={{ fontSize: 13 }}>
                    {ctx.alertType
                      ? ctx.alertType.replace(/_/g, " ")
                      : "Select Alert Type"}
                  </span>
                }
                variant="outlined"
                value={ctx.alertType}
                className={classes.bold_text}
              />
            )}
          />
          <br />
          <br />
          <h2 className={classes.bold_text}>Alert Config Type:</h2>
          <Autocomplete
            id="combo-box-demo"
            onChange={onChange}
            options={filteredOptions}
            disabled={filteredOptions.length === 0 && !!ctx.alertType}
            getOptionDisabled={(option: any) => {
              // return filteredUpcomingOptions.includes(option);
              return (
                !option?.meta?.create ||
                (option?.fields?.alert_type === "snapshot" && hasSnapshotRule)
              );
            }}
            getOptionLabel={(option: any) => {
              if (option.meta.create_reason) {
                return (
                  option?.fields?.label

                    .replace(/_/g, " ")
                    .replace(/\b\w/g, (char: string) => char.toUpperCase()) +
                  ` (${option.meta.create_reason})`
                );
              }
              return option?.fields?.label
                .replace(/_/g, " ")
                .replace(/\b\w/g, (char: string) => char.toUpperCase());
            }}
            style={{ width: 400, color: "#6D809D" }}
            renderInput={(params: any) => (
              <TextField
                {...params}
                label={
                  <span className={classes.bold_text} style={{ fontSize: 13 }}>
                    {renderValue
                      ? renderValue.replace(/_/g, " ")
                      : filteredOptions.length === 0 && ctx.alertType
                      ? "Not Applicable"
                      : title.replace(/_/g, " ")}
                  </span>
                }
                variant="outlined"
                value={value}
                className={classes.bold_text}
              />
            )}
          />
        </CustomModal>
      </Grid>
    </div>
  );
};

interface PlainProps {
  title: string;
  onChange: (event: React.ChangeEvent<any>, value: any) => void;
  value: string;
  options: string[];
}

export const SelectOptionPlain: React.FC<PlainProps> = ({
  title,
  onChange,
  value,
  options,
}) => {
  const classes = useStyles();
  return (
    <div>
      <Grid container spacing={1}>
        <div>
          <Autocomplete
            id="combo-box-demo"
            onChange={onChange}
            options={options}
            getOptionLabel={(option: any) => option}
            style={{ width: 180, color: "#6D809D" }}
            renderInput={(params: any) => (
              <TextField
                {...params}
                label={
                  <span className={classes.bold_text} style={{ fontSize: 13 }}>
                    {title}
                  </span>
                }
                className={classes.bold_text}
              />
            )}
          />
        </div>
      </Grid>
    </div>
  );
};
