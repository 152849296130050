import React, { useState, useEffect, ReactElement } from "react";
import { useStyles } from "../../styling/global/global";
import { AppStore } from "../../../stores/AppStore";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import MessageModal from "../../modals/MessageModal/MessageModal";
import Switch from "@material-ui/core/Switch";
import Grid from "@material-ui/core/Grid";
import { Tooltip } from "@material-ui/core";
import { atom, useRecoilState } from "recoil";
import {
  withStyles,
  useTheme,
  Theme,
  createStyles,
  fade,
} from "@material-ui/core/styles";
import {
  Button as AntButton,
  Modal,
  message,
  Steps,
  theme as antTheme,
  Typography as AntTypography,
  Space,
  Form,
  Input,
} from "antd";
import { QRCodeSVG } from "qrcode.react";

const steps = [
  {
    title: "Guide",
    content: "First-content",
  },
  {
    title: "Set Up",
    content: "Second-content",
  },
  {
    title: "Verification",
    content: "Last-content",
  },
];

export const twoFAState = atom({
  key: "twoFAEnabled", // unique ID (with respect to other atoms/selectors)
  default: false, // default value (aka initial value)
});

interface WizardProps {
  children: ReactElement;
  closeModal: () => void;
  totpURI: string;
  appStore?: AppStore;
  authhandler: any;
}

interface AntTheme {
  useToken: () => any; // Adjust the return type accordingly
  // Other properties of the theme object
}

const InstructionsPage: React.FC<{ totpURI: string }> = ({ totpURI }) => {
  const infoContent = (count: string, text: string) => (
    <div
      style={{
        display: "flex",
        alignItems: "center",
        width: "100%",
        justifyContent: "space-between",
        margin: 10,
        marginBottom: 5,
        textAlign: "left",
        lineHeight: 1.5,
        fontSize: 15,
      }}
    >
      <p>
        {count}. {text}
      </p>
    </div>
  );

  return (
    <div style={{ lineHeight: 2, margin: 20 }}>
      <AntTypography.Title level={3} style={{ margin: 0, textAlign: "left" }}>
        Multi-Factor Authentication
      </AntTypography.Title>
      <AntTypography.Paragraph
        strong
        style={{ margin: 0, textAlign: "left", fontSize: 14 }}
      >
        Set up an authenticator app
      </AntTypography.Paragraph>
      <br />
      {infoContent(
        "1",
        "Install any authenticator app of your choice on your mobile device e.g Google Authenticator."
      )}
      {infoContent(
        "2",
        "Add your account to the authenticator app through the add new account flow."
      )}
      {infoContent(
        "3",
        'Use "DeepAlert" as the account name and scan the QR code below:'
      )}
      <QRCodeSVG value={totpURI} />
    </div>
  );
};

const InformationPage = () => {
  const infoContent = (url: string, text: string) => (
    <div
      style={{
        display: "flex",
        alignItems: "center",
        width: "100%",
        justifyContent: "space-between",
        color: "black",
        margin: 10,
        marginBottom: 5,
        textAlign: "left",
        lineHeight: 1.5,
        fontSize: 15,
      }}
    >
      <div>
        <img style={{ margin: 20 }} src={url}></img>
      </div>
      <p>{text}</p>
    </div>
  );
  return (
    <div style={{ lineHeight: 2, margin: 20 }}>
      <AntTypography.Title level={3} style={{ margin: 0, textAlign: "left" }}>
        Multi-Factor Authentication
      </AntTypography.Title>
      <AntTypography.Paragraph
        strong
        style={{ margin: 0, textAlign: "left", fontSize: 14 }}
      >
        Secure your account with Multi-Factor Authentication
      </AntTypography.Paragraph>
      <br />
      {infoContent(
        "https://static.licdn.com/sc/h/egg1ezsv65pc86jriqjtd7pge",
        "Multi-Factor authentication gives you additional security by requiring a verification code whenever you sign in on new device."
      )}
      {infoContent(
        "https://static.licdn.com/sc/h/a4otjm5rw3fqp223rzcuq7l6o",
        "Your Authenticator App helps us keep your account secure by adding an additional layer of verification."
      )}
    </div>
  );
};

interface VerificationPageProps {
  appStore?: AppStore;
  authhandler: any;
  onEnableMFA: () => void;
}

const CodeVerificationPage: React.FC<VerificationPageProps> = ({
  appStore,
  authhandler,
  onEnableMFA,
}) => {
  const [verified, setVeried] = useState(false);
  const [loading, setLoading] = useState(false);
  const [, setTwoFAEnabled] = useRecoilState(twoFAState);

  const onFinish = async (values: any) => {
    console.debug("Success:", values);
    setLoading(true);
    const token = await appStore?.authService.getAuthorisedToken();
    if (token) {
      let data = await appStore?.functionService.enableOTP(token, values.code);
      console.debug("Verifcation status", data);
      if (data.success) {
        onEnableMFA();
        setTwoFAEnabled(true);
        setVeried(true);
      }
      if (!data.success) {
        message.error("Failed to verify code, please try again.");
      }
    }
    setLoading(false);
  };

  const onFinishFailed = (errorInfo: any) => {
    console.debug("Failed:", errorInfo);
    setVeried(false);
    setTwoFAEnabled(false);
  };

  type FieldType = {
    username?: string;
    password?: string;
    remember?: string;
  };

  const infoContent = (url: string, text: string) => (
    <div
      style={{
        display: "flex",
        alignItems: "center",
        width: "100%",
        justifyContent: "space-between",
        margin: 10,
        marginBottom: 5,
        textAlign: "left",
        lineHeight: 1.5,
        fontSize: 16,
        color: "limegreen",
      }}
    >
      <div>
        <img style={{ marginRight: 5 }} src={url}></img>
      </div>
      <p style={{ marginRight: 30, fontWeight: 600 }}>{text}</p>
    </div>
  );

  return (
    <div style={{ lineHeight: 2, margin: 20 }}>
      <AntTypography.Title level={3} style={{ margin: 0, textAlign: "left" }}>
        Multi-Factor Authentication
      </AntTypography.Title>
      {!verified && (
        <>
          <AntTypography.Paragraph
            strong
            style={{ margin: 0, textAlign: "left", fontSize: 14 }}
          >
            Enter the 6-digit verification code generated by your Authenticator
            app.
          </AntTypography.Paragraph>
        </>
      )}
      <br />
      {!verified && (
        <div
          style={{
            display: "flex",
            alignItems: "center",
            width: "100%",
            justifyContent: "space-between",
            marginBottom: 5,
            textAlign: "left",
            lineHeight: 1.5,
            fontSize: 15,
          }}
        >
          <p>Note: You can also copy the code by tapping on it and paste it.</p>
        </div>
      )}
      {!verified && <br />}
      {!verified && (
        <Form
          name="basic"
          style={{ maxWidth: 600 }}
          initialValues={{ remember: true }}
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
          autoComplete="off"
          layout="inline"
        >
          <Form.Item<FieldType>
            label="Verification Code"
            name="code"
            rules={[
              { required: true, message: "Please enter the 6 digit Code!" },
            ]}
          >
            <Input placeholder="Enter 6 digit Code" />
          </Form.Item>
          <Form.Item wrapperCol={{ offset: 8, span: 16 }}>
            <AntButton type="primary" htmlType="submit" loading={loading}>
              Verify
            </AntButton>
          </Form.Item>
        </Form>
      )}
      {verified &&
        infoContent(
          "https://static.licdn.com/sc/h/a4otjm5rw3fqp223rzcuq7l6o",
          "Verification successful! Your Multi-Factor Authentication is now active."
        )}
    </div>
  );
};

export const Wizard: React.FC<WizardProps> = (props) => {
  const { token } =
    (antTheme !== null ? (antTheme as AntTheme) : undefined)?.useToken() || {};
  const [current, setCurrent] = useState(0);
  const [enabledMFA, setEnabledMFA] = useState(false);
  const [twoFAEnabled, setTwoFAEnabled] = useRecoilState(twoFAState);
  const next = () => {
    setCurrent(current + 1);
  };

  const prev = () => {
    setCurrent(current - 1);
  };

  useEffect(() => {
    return () => setCurrent(0);
  }, []);

  const items = steps.map((item) => ({ key: item.title, title: item.title }));

  const contentStyle: React.CSSProperties = {
    lineHeight: "260px",
    textAlign: "center",
    color: "rgba(0, 0, 0, 0.45)",
    backgroundColor: `rgba(0, 0, 0, 0.02)`,
    borderRadius: 8,
    border: `1px dashed #d9d9d9`,
    marginTop: 16,
  };

  return (
    <>
      <Steps current={current} items={items} />
      <div style={contentStyle}>
        {/* {props.children} */}
        {current === 0 && <InformationPage />}
        {current === 1 && <InstructionsPage totpURI={props.totpURI} />}
        {current === 2 && (
          <CodeVerificationPage
            onEnableMFA={() => setEnabledMFA(false)}
            appStore={props.appStore}
            authhandler={props.authhandler}
          />
        )}
      </div>
      <div style={{ marginTop: 24 }}>
        {current < steps.length - 1 && (
          <AntButton type="primary" onClick={() => next()}>
            Next
          </AntButton>
        )}
        {current === steps.length - 1 && twoFAEnabled && (
          <AntButton
            type="primary"
            onClick={() => {
              if (twoFAEnabled) {
                setTimeout(() => {
                  props.appStore?.authService.signOut();
                  var ev: Event;
                  ev = new Event("authupdate");
                  props.authhandler(ev);
                }, 2000);
              }
              props.closeModal();
            }}
          >
            Done
          </AntButton>
        )}
        {current > 0 && !twoFAEnabled && (
          <AntButton style={{ margin: "0 8px" }} onClick={() => prev()}>
            Previous
          </AntButton>
        )}
        {!twoFAEnabled && (
          <AntButton
            style={{ margin: "0 8px" }}
            onClick={() => props.closeModal()}
          >
            Close
          </AntButton>
        )}
      </div>
    </>
  );
};

const AntSwitch = withStyles((theme: Theme) =>
  createStyles({
    root: {
      width: 28,
      height: 16,
      padding: 0,
      display: "flex",
    },
    switchBase: {
      padding: 2,
      color: theme.palette.grey[500],
      "&$checked": {
        transform: "translateX(12px)",
        color: theme.palette.common.white,
        "& + $track": {
          opacity: 1,
          backgroundColor: theme.palette.primary.main,
          borderColor: theme.palette.primary.main,
        },
      },
    },
    thumb: {
      width: 12,
      height: 12,
      boxShadow: "none",
    },
    track: {
      border: `1px solid ${theme.palette.grey[500]}`,
      borderRadius: 16 / 2,
      opacity: 1,
      backgroundColor: theme.palette.common.white,
    },
    checked: {},
  })
)(Switch);

interface ProfileMainDrawerProps {
  appStore: AppStore;
  authhandler: any;
}
const ProfileMainDrawer: React.FC<ProfileMainDrawerProps> = ({
  appStore,
  authhandler,
}: ProfileMainDrawerProps) => {
  const classes = useStyles();
  const [password1, setPassword1] = React.useState("");
  const [password2, setPassword2] = React.useState("");
  const [oldpassword, setOldPassword] = React.useState("");
  const [lastname, setLastName] = React.useState("");
  const [firstname, setFirstName] = React.useState("");
  const [email, setEmail] = React.useState("");
  const [contactno, setContactNo] = React.useState("");
  const [updated, setUpdated] = React.useState(false);
  const [popmessage, setPopMessage] = React.useState("");

  const [password1label, setPassword1Label] = React.useState("too short");
  const [password1color, setPassword1Color] = React.useState("#FF0000");
  const [strong, setStrong] = React.useState(false);

  const handleOk = () => {
    setIsModalOpen(false);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [totpURI, setTotpURI] = useState("");
  const [enable2FA, setEnable2FA] = useRecoilState(twoFAState);
  const [loadingMFA, setLoadingMFA] = useState(false);

  React.useEffect(() => {
    const fetchData = async () => {
      var token = await appStore?.authService.getAuthorisedToken();
      if (token) {
        var data = await appStore?.functionService.getOtpUri(token);
        console.debug("TOTP URI", data);
        setTotpURI(data);
      }
    };
    if (isModalOpen) {
      fetchData();
    }
  }, [isModalOpen]);

  React.useEffect(() => {
    const fetchData = async () => {
      const token = await appStore?.authService.getAuthorisedToken();
      setLoadingMFA(true);
      if (token) {
        const user = await appStore?.functionService.getOwnUser(
          token,
          appStore.authService.getUserId()
        );
        if (user) {
          // console.debug("User Obj:", user)
          setFirstName(user.first_name);
          setLastName(user.last_name);
          setEmail(user.email);
          setContactNo(user.contact_number);
          setEnable2FA(user?.totp_enabled);
        }
      }
      setLoadingMFA(false);
    };
    fetchData();
  }, []);

  // React.useEffect(() => {
  //   const fetchData = async () => {
  //     const token = await appStore?.authService.getAuthorisedToken();
  //     if (token) {
  //       const user = await appStore?.functionService.getUser(
  //         token,
  //         appStore.authService.getUserId()
  //       );
  //       if (user) {
  //         console.debug("User OBJ:", user);
  //         setEnable2FA(user?.totp_enabled);
  //       }
  //     }
  //   };
  //   fetchData();
  // }, []);

  const [messagemodalopen, setMessageModalOpen] = React.useState(false);

  const openMessageModal = () => {
    setMessageModalOpen(true);
  };

  const closeMessageModal = () => {
    setMessageModalOpen(false);
    if (updated) {
      appStore?.authService.signOut();
      var ev: Event;
      ev = new Event("authupdate");
      authhandler(ev);
    }
  };
  const handlePassword1Change = (event: React.ChangeEvent<any>) => {
    let password = event.target.value;
    setPassword1(password);
    if (password.length > 12) {
      if (password.toLowerCase() != password) {
        var hasNumber = /\d/;
        if (hasNumber.test(password)) {
          var hasSpecial = /[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/;
          if (!hasSpecial.test(password)) {
            setPassword1Label("Password must contain some special characters!");
            setPassword1Color("#FFBF00");
            setStrong(false);
          } else {
            setPassword1Label("");
            setStrong(true);
            setPassword1Color("#0000FF");
          }
        } else {
          setPassword1Label("Password must contain some numbers!");
          setPassword1Color("#FFBF00");
          setStrong(false);
        }
      } else {
        setPassword1Label("Use uppercase and lowercase letters!");
        setPassword1Color("#FFBF00");
        setStrong(false);
      }
    }
  };
  const handlePassword2Change = (event: React.ChangeEvent<any>) => {
    setPassword2(event.target.value);
  };
  const handleOldPasswordChange = (event: React.ChangeEvent<any>) => {
    setOldPassword(event.target.value);
  };
  const handleFirstNameChange = (event: React.ChangeEvent<any>) => {
    setFirstName(event.target.value);
  };
  const handleLastNameChange = (event: React.ChangeEvent<any>) => {
    setLastName(event.target.value);
  };
  const handleEmailChange = (event: React.ChangeEvent<any>) => {
    setEmail(event.target.value);
  };
  const handleContactNoChange = (event: React.ChangeEvent<any>) => {
    setContactNo(event.target.value);
  };
  const same = password1 === password2 ? true : false;
  const updatePassword = async () => {
    if (password1 != "" && !strong) {
      setPopMessage("New password too weak!");
      openMessageModal();
    } else if (password1 != "" && !same) {
      setPopMessage("Password and confirmation does not match!");
      openMessageModal();
    } else {
      var token = await appStore?.authService.getAuthorisedToken();
      if (token) {
        var modified = await appStore?.functionService.modifyPasswordSelf(
          token,
          oldpassword,
          password1
        );
        if (modified.success) {
          setPopMessage("Password updated, please log in again!");
          setUpdated(true);
          openMessageModal();
        } else {
          setPopMessage("Password could not be updated! " + modified.msg);
          openMessageModal();
        }
      }
    }
  };
  const updateUser = async () => {
    var token = await appStore?.authService.getAuthorisedToken();
    if (token) {
      var modified = await appStore?.functionService.modifyUserSelf(
        token,
        firstname,
        lastname,
        email,
        contactno
      );
      if (modified.success) {
        setPopMessage("User updated, please log in again!");
        setUpdated(true);
        openMessageModal();
      } else {
        setPopMessage("User could not be updated!");
        openMessageModal();
      }
    }
  };

  const deactivateMFA = async () => {
    var token = await appStore?.authService.getAuthorisedToken();
    if (token) {
      var modified = await appStore?.functionService.deactivateMFA(
        token,
        false
      );
      if (modified.success) {
        setPopMessage("MFA deactivated");
        setUpdated(true);
        openMessageModal();
      } else {
        setPopMessage("Failed to deactivate MFA!");
        openMessageModal();
      }
    }
  };

  const disableMFA = () => {
    let proceed = false;
    proceed = window.confirm(
      "WARNING!\n\n Are you sure you want to deactivate MFA."
    );
    if (proceed) {
      deactivateMFA();
    }
  };

  return (
    <div className={classes.medium_panel}>
      <Typography className={classes.bold_text}>First Name:</Typography>

      <TextField
        fullWidth
        onChange={handleFirstNameChange}
        value={firstname}
        variant="outlined"
        margin="normal"
        InputProps={{
          className: classes.bold_text,
        }}
        InputLabelProps={{
          classes: {
            root: classes.bold_text,
          },
        }}
      />

      <Typography className={classes.bold_text}>Last Name:</Typography>
      <TextField
        fullWidth
        onChange={handleLastNameChange}
        value={lastname}
        variant="outlined"
        margin="normal"
        InputProps={{
          className: classes.bold_text,
        }}
        InputLabelProps={{
          classes: {
            root: classes.bold_text,
          },
        }}
      />
      <Typography className={classes.bold_text}>Email:</Typography>
      <TextField
        fullWidth
        onChange={handleEmailChange}
        value={email}
        variant="outlined"
        margin="normal"
        InputProps={{
          className: classes.bold_text,
        }}
        InputLabelProps={{
          classes: {
            root: classes.bold_text,
          },
        }}
      />
      <Typography className={classes.bold_text}>Contact Number:</Typography>
      <TextField
        fullWidth
        onChange={handleContactNoChange}
        value={contactno}
        variant="outlined"
        margin="normal"
        InputProps={{
          className: classes.bold_text,
        }}
        InputLabelProps={{
          classes: {
            root: classes.bold_text,
          },
        }}
      />
      {/* <br/> */}
      <div style={{ marginTop: 20 }}>
        <Typography className={classes.bold_text}>
          <Tooltip placement="right" title="">
            <Grid component="label" container alignItems="center" spacing={1}>
              <Grid item>
                <Typography className={classes.bold_text}>
                  Enable Multi-Factor Authentication
                </Typography>
              </Grid>
              <Grid item>
                <AntSwitch
                  disabled={loadingMFA}
                  checked={enable2FA}
                  onChange={(e) => {
                    if (e.target.checked) {
                      setIsModalOpen(true);
                    } else {
                      disableMFA();
                      setEnable2FA(e.target.checked);
                    }
                    e.stopPropagation();
                  }}
                  onClick={(e) => {
                    e.stopPropagation();
                  }}
                  name="checked2FA"
                />
              </Grid>
            </Grid>
          </Tooltip>
        </Typography>
      </div>
      <div className={classes.button_container}>
        <Button
          variant="contained"
          className={classes.bold_text}
          onClick={updateUser}
        >
          Update
        </Button>
      </div>

      <Typography className={classes.bold_text}>Current Password :</Typography>
      <TextField
        fullWidth
        onChange={handleOldPasswordChange}
        value={oldpassword}
        variant="outlined"
        margin="normal"
        type="password"
        InputProps={{
          className: classes.bold_text,
        }}
        InputLabelProps={{
          classes: {
            root: classes.bold_text,
          },
        }}
      />
      <Typography className={classes.bold_text}>New Password:</Typography>
      <TextField
        fullWidth
        label={password1label}
        onChange={handlePassword1Change}
        value={password1}
        variant="outlined"
        margin="normal"
        type="password"
        InputProps={{
          className: classes.bold_text,
        }}
        InputLabelProps={{
          style: { color: password1color },
          classes: {
            root: classes.bold_text,
          },
        }}
      />
      <br></br>
      <Typography className={same ? classes.bold_text : classes.bold_text_red}>
        Confirm New Password:
      </Typography>
      <TextField
        fullWidth
        onChange={handlePassword2Change}
        value={password2}
        variant="outlined"
        margin="normal"
        type="password"
        InputProps={{
          className: classes.bold_text,
        }}
        InputLabelProps={{
          classes: {
            root: classes.bold_text,
          },
        }}
      />

      <div className={classes.button_container}>
        <Button
          variant="contained"
          className={classes.bold_text}
          onClick={updatePassword}
        >
          Update Password
        </Button>
      </div>
      <MessageModal
        open={messagemodalopen}
        onClose={closeMessageModal}
        message={popmessage}
        elements={undefined}
      />
      <Modal
        width={700}
        centered
        footer={null}
        title="Multi-Factor Authentication"
        open={isModalOpen}
        onOk={handleOk}
        onCancel={handleCancel}
      >
        <Wizard
          closeModal={handleCancel}
          totpURI={totpURI}
          appStore={appStore}
          authhandler={authhandler}
        >
          <div style={{ height: 600, overflowY: "scroll" }}>
            <p></p>
          </div>
        </Wizard>
      </Modal>
    </div>
  );
};
export default ProfileMainDrawer;
