import React, { useState } from "react";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Grid from "@material-ui/core/Grid";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import Container from "@material-ui/core/Container";
import Paper from "@material-ui/core/Paper";
import Card from "@material-ui/core/Card";
import { AppStore } from "../../../stores/AppStore";
import { AuthResult } from "../../../services/FunctionService";
import { useStyles } from "../../styling/global/global";
import { ValueChecking } from "../../HelperFunctions";
import {
  createStyles,
  fade,
  Theme,
  ThemeProvider,
  withStyles,
  makeStyles,
  createMuiTheme,
} from "@material-ui/core/styles";
import RegistrationPage from "../RegistrationPage/RegistrationPage";
interface MockEvent {
  (event: Event): any;
}

interface LoginPageProps {
  appStore?: AppStore;
  authhandler: MockEvent;
}
interface MFACodeProps extends LoginPageProps {
  username: string;
  password: string;
  onClose: () => void;
}

const MFACode: React.FC<MFACodeProps> = ({
  appStore,
  username,
  password,
  authhandler,
  onClose,
}) => {
  const classes = useStyles();
  var [mfaCode, setMFACode] = useState("");
  var [message, setMessage] = useState("");

  async function handleClicked() {
    const result = await appStore?.functionService.checkVersion();
    if (result?.min_management_gui_ver && appStore?.version) {
      var alert_backend = result?.min_management_gui_ver;
      var alert_front = appStore?.version;
      const valueResult = ValueChecking(alert_front, alert_backend);
      if (valueResult === true) {
        if (username == "") {
          setMessage("Username cannot be empty!");
        } else if (password == "") {
          setMessage("Password cannot be empty!");
        } else {
          if (appStore) {
            var login = await appStore.authService.signIn(
              username,
              password,
              mfaCode
            );
            setMessage(appStore.authService.getAuthorisedMessage());
            var entry_orgs = appStore.authService.getOrgs();
            if (entry_orgs && entry_orgs.length <= 1) {
              var ev: Event;
              ev = new Event("authupdate");
              authhandler(ev);
            } else {
              var ev: Event;
              ev = new Event("orgselect");
              authhandler(ev);
            }
          }
        }
      } else {
        alert("Your Management GUI version is out of date, click ok to update");
        appStore?.authService.signOut();
        var ev: Event;
        ev = new Event("authupdate");
        authhandler(ev);

        if ("caches" in window) {
          caches.keys().then((names) => {
            names.forEach((name) => {
              caches.delete(name);
            });
          });
        }
        window.location.reload();
      }
    }
  }

  function handleCodeChange(e: React.ChangeEvent<HTMLInputElement>) {
    setMFACode(e.target.value);
  }
  return (
    <div className={classes.login}>
      <Card className={classes.login_container}>
        <Grid container justify="center">
          <Grid item>
            <img src={"/assets/img/logo_main.png"} alt="Logo" />
            <div className={classes.logo_text_white}>MANAGEMENT</div>
          </Grid>
        </Grid>
        <Typography variant="subtitle1">{message}</Typography>
        <br />
        <h3
          style={{
            fontWeight: 600,
            textAlign: "center",
            color: "white",
            textDecoration: "underline",
          }}
        >
          Multi-Factor Authentication
        </h3>
        <br />
        <Typography variant="subtitle1" style={{ color: "white" }}>
          Enter an MFA Code to complete sign-in
        </Typography>
        <br />
        <form className="form" noValidate>
          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            id="mfaCode"
            label="MFA Code"
            name="mfa_code"
            autoComplete="mfa_code"
            autoFocus
            value={mfaCode}
            onChange={handleCodeChange}
            className={classes.bold_text_white}
            InputProps={{
              className: classes.bold_text_white,
              classes: {
                root: classes.bold_text_white_blue_background,
                notchedOutline: classes.bold_text_white_blue_background,
                focused: classes.bold_text_white_blue_background,
                input: classes.bold_text_white_blue_background,
              },
            }}
            InputLabelProps={{
              classes: {
                root: classes.bold_text_white,
              },
              style: { color: "#FFFFFF" },
            }}
          />
          <Grid
            container
            justify="center"
            direction="column"
            alignItems="center"
          >
            <Grid item>
              <Button
                disabled={!(mfaCode.length > 5)}
                onClick={handleClicked}
                variant="contained"
                className={classes.button_text_white}
              >
                Submit
              </Button>
            </Grid>
            <Grid item>
              <Button
                onClick={onClose}
                // onClick={() => setOpenRegistration(true)}
                variant="contained"
                className={classes.button_text_blue}
              >
                Cancel
              </Button>
            </Grid>
          </Grid>
          <br />
          <Grid container justify="center">
            <Grid item>
              {/* <Copyright /> */}
              <span
                style={{
                  color: "white",
                  textAlign: "center",
                  paddingTop: 20,
                  fontStyle: "bold",
                  paddingLeft: 20,
                }}
              >
                ver. {appStore?.version}
              </span>
            </Grid>
          </Grid>
        </form>
      </Card>
    </div>
  );
};

const LoginPage: React.FC<LoginPageProps> = ({
  appStore,
  authhandler,
}: LoginPageProps) => {
  const classes = useStyles();
  var [message, setMessage] = useState("");
  var [username, setUsername] = useState("");
  var [password, setPassword] = useState("");
  const [openRegistration, setOpenRegistration] = useState(false);
  const [openMFA, setOpenMFA] = useState(false);
  const [mfaCode, setMFACode] = useState("");
  const [totpRequired, setTotpRequired] = useState(false);
  var creds: AuthResult;

  function Copyright() {
    return (
      <a href="https://www.deepalert.ai/" className={classes.bold_text_white}>
        <p
          style={{ height: 30, textAlign: "center" }}
          className={classes.bold_text_white}
        >
          www.deepalert.ai
        </p>
      </a>
    );
  }

  async function handleClicked() {
    const result = await appStore?.functionService.checkVersion();
    if (result?.min_management_gui_ver && appStore?.version) {
      var alert_backend = result?.min_management_gui_ver;
      var alert_front = appStore?.version;
      const valueResult = ValueChecking(alert_front, alert_backend);
      if (valueResult === true) {
        if (username == "") {
          setMessage("Username cannot be empty!");
        } else if (password == "") {
          setMessage("Password cannot be empty!");
        } else {
          if (appStore) {
            var login = await appStore.authService.signIn(
              username,
              password,
              mfaCode
            );
            if (!login) {
              setOpenMFA(true);
            }

            console.debug(
              "Auth message",
              appStore.authService.getAuthorisedMessage()
            );

            if (
              appStore.authService.getAuthorisedMessage() === "TOTP required"
            ) {
              setTotpRequired(true);
              setMessage("MFA Code Required");
            } else {
              setMessage(appStore.authService.getAuthorisedMessage());
            }

            var entry_orgs = appStore.authService.getOrgs();
            if (entry_orgs && entry_orgs.length <= 1) {
              var ev: Event;
              ev = new Event("authupdate");
              authhandler(ev);
            } else {
              var ev: Event;
              ev = new Event("orgselect");
              authhandler(ev);
            }
          }
        }
      } else {
        alert("Your Management GUI version is out of date, click ok to update");
        appStore?.authService.signOut();
        var ev: Event;
        ev = new Event("authupdate");
        authhandler(ev);

        if ("caches" in window) {
          caches.keys().then((names) => {
            names.forEach((name) => {
              caches.delete(name);
            });
          });
        }
        window.location.reload();
      }
    }
  }

  function handleUserChange(e: React.ChangeEvent<HTMLInputElement>) {
    setUsername(e.target.value);
  }
  function handleMFACodeChange(e: React.ChangeEvent<HTMLInputElement>) {
    setMFACode(e.target.value);
  }
  function handlePasswordChange(e: React.ChangeEvent<HTMLInputElement>) {
    setPassword(e.target.value);
  }

  return (
    <>
      {openRegistration === true ? (
        <RegistrationPage
          appStore={appStore}
          onClose={() => setOpenRegistration(false)}
        />
      ) : openMFA ? (
        <MFACode
          username={username}
          password={password}
          authhandler={authhandler}
          appStore={appStore}
          onClose={() => setOpenRegistration(false)}
        />
      ) : (
        <div className={`login-form ${classes.login}`}>
          <Card className={classes.login_container}>
            <Grid container justify="center">
              <Grid item>
                <img src={"/assets/img/logo_main.png"} alt="Logo" />
                <div className={classes.logo_text_white}>MANAGEMENT</div>
              </Grid>
            </Grid>
            <Typography
              variant="subtitle1"
              style={{ color: "rgb(243, 36, 36)", fontWeight: 700 }}
            >
              {message}
            </Typography>

            <form className="form login-form" noValidate>
              <TextField
                variant="outlined"
                margin="normal"
                required
                fullWidth
                id="username"
                label="username"
                name="email"
                autoComplete="email"
                autoFocus
                value={username}
                onChange={handleUserChange}
                className={classes.bold_text_white}
                InputProps={{
                  className: classes.bold_text_white,
                  classes: {
                    root: classes.bold_text_white_blue_background,
                    notchedOutline: classes.bold_text_white_blue_background,
                    focused: classes.bold_text_white_blue_background,
                    input: classes.bold_text_white_blue_background,
                  },
                }}
                InputLabelProps={{
                  classes: {
                    root: classes.bold_text_white,
                  },
                  style: { color: "#FFFFFF" },
                }}
              />
              <TextField
                variant="outlined"
                margin="normal"
                required
                fullWidth
                name="password"
                label="password"
                type="password"
                id="password"
                autoComplete="current-password"
                value={password}
                onChange={handlePasswordChange}
                onKeyPress={(ev) => {
                  if (ev.key === "Enter") {
                    handleClicked();
                    ev.preventDefault();
                  }
                }}
                InputProps={{
                  className: classes.bold_text_white,
                  classes: {
                    root: classes.bold_text_white_blue_background,
                    notchedOutline: classes.bold_text_white_blue_background,
                  },
                }}
                InputLabelProps={{
                  classes: {
                    root: classes.bold_text_white,
                  },
                  style: { color: "#FFFFFF" },
                }}
              />

              {totpRequired && (
                <>
                  <br />
                  <br />
                  <h2 style={{ color: "white", fontSize: 14, fontWeight: 600 }}>
                    Please Enter MFA Code:
                  </h2>
                  <TextField
                    variant="outlined"
                    margin="normal"
                    required
                    fullWidth
                    id="mfa_code"
                    label="MFA Code"
                    name="mfa_code"
                    autoComplete="mfa-code"
                    autoFocus
                    value={mfaCode}
                    onChange={handleMFACodeChange}
                    className={classes.bold_text_white}
                    InputProps={{
                      className: classes.bold_text_white,
                      classes: {
                        root: classes.bold_text_white_blue_background,
                        notchedOutline: classes.bold_text_white_blue_background,
                        focused: classes.bold_text_white_blue_background,
                        input: classes.bold_text_white_blue_background,
                      },
                    }}
                    InputLabelProps={{
                      classes: {
                        root: classes.bold_text_white,
                      },
                      style: { color: "#FFFFFF" },
                    }}
                  />
                  <br />
                </>
              )}

              <Grid
                container
                justify="center"
                direction="column"
                alignItems="center"
              >
                <Grid item>
                  <Button
                    onClick={handleClicked}
                    variant="contained"
                    className={classes.button_text_white}
                  >
                    LOGIN
                  </Button>
                </Grid>
                <Grid item>
                  <Button
                    onClick={() => setOpenRegistration(true)}
                    variant="contained"
                    className={classes.button_text_blue}
                  >
                    REGISTER
                  </Button>
                </Grid>
              </Grid>
              <br />
              <Grid container justify="center">
                <Grid item>
                  <Copyright />
                  <span
                    style={{
                      color: "white",
                      textAlign: "center",
                      paddingTop: 20,
                      fontStyle: "bold",
                      paddingLeft: 20,
                    }}
                  >
                    ver. {appStore?.version}
                  </span>
                </Grid>
              </Grid>
            </form>
          </Card>
        </div>
      )}
    </>
  );
};

export default LoginPage;
