import React from "react";
import { Tag } from "antd";
import classes from "../../UI/ui.module.css";
import DeleteForeverIcon from "@material-ui/icons/DeleteForever";
import CustomModal from "../../UI/CustomModal";

interface ListItemProps {
  zone: string;
  ruleId: any;
  class_label: string;
  onDeleteHandler: (ruleId: number, id: any) => void;
  onEditHandler?: (id: any) => void;
  isEdit?: boolean;
  count?: number;
  countLogic?: number;
  object_motion?: string;
  threshold?: number;
  ruleType: string;
  rule_type?: string;
  cadence_secs?: any;
  site?: any;
  sensorDefinedRule?: any;
  whiteBg?: boolean;
  ruleOption?: string;
  itemCount?: number;
  zoneConfig?: any;
  isRuleslist?: boolean;
}

const ZoneItem: React.FC<ListItemProps> = ({
  zone,
  class_label,
  count,
  countLogic,
  object_motion,
  threshold,
  onDeleteHandler,
  ruleId,
  isEdit,
  ruleType,
  rule_type,
  sensorDefinedRule,
  onEditHandler,
  cadence_secs,
  site,
  whiteBg,
  ruleOption,
  itemCount,
  zoneConfig,
  isRuleslist,
}) => {
  const [openDelete, setOpenDelete] = React.useState(false);
  const [selectedId, setSelectedId] = React.useState("");

  let ruleTypes: string[] = [];

  const ruleOptions = sensorDefinedRule?.fields?.rule_configs
    ? Object.keys(sensorDefinedRule?.fields?.rule_configs)
    : null;

  let ruleObj =
    Array.isArray(ruleOptions) && ruleOptions.length > 0
      ? sensorDefinedRule?.fields?.rule_configs[
          `${ruleOption ? ruleOption : ruleOptions[0]}`
        ]
      : undefined;

  const { zone_configs } = ruleObj || { zone_configs: zoneConfig };
  console.debug(
    "Wizard Zones 1",
    "rule_type: ",
    rule_type,
    "zone_configs: 1",
    ruleObj?.zone_configs,
    "ruleOptions: ",
    ruleOptions,
    "zone_configs: 2",
    zone_configs,
    "rule_type: ",
    isRuleslist && Object.keys(zone_configs)
  );

  if (zone_configs && isRuleslist) {
    ruleTypes = Object.keys(zone_configs);
  } else if (zone_configs) {
    ruleTypes = zone_configs.map((item: any) => item.type) || [];
  }

  const colors: any = {
    AlertZonesAndCounts: "#f50",
    AlertZones: "#108ee9",
    KnownVehicleFilter: "#F75CEC",
    SuspiciousActivitySelfStorage: "#5cdbd3",
    GuardTourZonesAndCounts: "#73d13d",
    GuardManagementNoGuard: "#FAB550",
    UnusualActivity: "#F7C100",
    SceneChangeAlert: "#5CB5F7",
  };

  return (
    <div
      key={zone}
      className={classes.item}
      style={{ width: "100%", background: whiteBg ? "white" : "inherit" }}
    >
      <CustomModal
        closeHandler={() => {
          setOpenDelete(false);
        }}
        open={openDelete}
        title={"confirm"}
        continueAction={true}
        onContinueHandler={() => {
          onDeleteHandler(ruleId, selectedId);
          setOpenDelete(false);
        }}
      >
        <h1
          style={{
            fontSize: 14,
            color: "#15325F",
            fontWeight: "bold",
            textAlign: "center",
          }}
        >
          WARNING!
        </h1>
        <br />
        <p
          style={{
            fontSize: 14,
            color: "#f44336",
            fontWeight: "bold",
            textAlign: "center",
            marginLeft: 30,
            marginRight: 30,
          }}
        >
          Are you sure you want to delete this record ?
        </p>
      </CustomModal>
      <div>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            alignContent: "center",
            width: "930px",
          }}
        >
          {
            <div>
              {rule_type && (
                <p
                  className={classes["item-wrapper"]}
                  style={{
                    fontSize: 12,
                    marginBottom: 0,
                    color: "#15325F",
                    width: "100%",
                    fontWeight: 700,
                  }}
                >
                  {/* {itemCount && <Badge count={itemCount} showZero color="#faad14" />} */}
                  <Tag
                    color={
                      colors[`${rule_type}`]
                        ? colors[`${rule_type}`]
                        : "#2db7f5"
                    }
                    style={{ padding: "5px 10px", borderRadius: 6 }}
                  >
                    {rule_type}
                  </Tag>
                </p>
              )}
            </div>
          }
          <p
            className={classes["item-wrapper"]}
            style={{
              fontSize: 12,
              marginBottom: 0,
              color: "#15325F",
              fontWeight: 700,
            }}
          >
            Zone: <span style={{ color: "#2E4FA5" }}>{zone}</span>
          </p>
          {
            // ["alert_zones_and_counts"].includes(ruleType) ||
            (ruleOptions?.includes("AlertZonesAndCounts") ||
              rule_type === "AlertZonesAndCounts") && (
              // ruleTypes.includes("count_logic") && (
              <>
                {count !== undefined && (
                  <p
                    className={classes["item-wrapper"]}
                    style={{
                      fontSize: 12,
                      marginBottom: 0,
                      color: "#15325F",
                      fontWeight: 700,
                    }}
                  >
                    Count: <span style={{ color: "#2E4FA5" }}>{count}</span>
                  </p>
                )}
                {countLogic && (
                  <p
                    className={classes["item-wrapper"]}
                    style={{
                      fontSize: 12,
                      marginBottom: 0,
                      color: "#15325F",
                      fontWeight: 700,
                    }}
                  >
                    Count Logic:{" "}
                    <span style={{ color: "#2E4FA5" }}>{countLogic}</span>
                  </p>
                )}
              </>
            )
          }
          {
            // [
            //   "alert_zones_and_counts",
            //   "alert_zones",
            //   "PPE Detection - coming soon!",
            //   "Guard Tour",
            // ].includes(ruleType) ||
            <>
              {
                // [
                //   "alert_zones_and_counts",
                //   "alert_zones",
                //   "PPE Detection - coming soon!",
                //   "Guard Tour",
                // ].includes(ruleType) ||
                ruleTypes.includes("class_label") && (
                  <p
                    className={classes["item-wrapper"]}
                    style={{
                      fontSize: 12,
                      marginBottom: 0,
                      color: "#15325F",
                      fontWeight: 700,
                    }}
                  >
                    Class:{" "}
                    <span style={{ color: "#2E4FA5" }}>{class_label}</span>
                  </p>
                )
              }
              {ruleTypes.includes("confidence_threshold") && threshold && (
                <p
                  className={classes["item-wrapper"]}
                  style={{
                    fontSize: 12,
                    marginBottom: 0,
                    color: "#15325F",
                    fontWeight: 700,
                  }}
                >
                  Threshold:{" "}
                  <span style={{ color: "#2E4FA5" }}>
                    {isEdit ? Math.round(threshold) : Math.round(threshold)}
                  </span>
                </p>
              )}
              {ruleTypes.includes("object_motion") && object_motion && (
                <p
                  className={classes["item-wrapper"]}
                  style={{
                    fontSize: 12,
                    marginBottom: 0,
                    color: "#15325F",
                    fontWeight: 700,
                  }}
                >
                  Object Motion:{" "}
                  <span style={{ color: "#2E4FA5" }}>{object_motion}</span>
                </p>
              )}
            </>
          }
          {["Guard Management (no guard)"].includes(ruleType) && (
            <>
              {rule_type && (
                <p
                  className={classes["item-wrapper"]}
                  style={{
                    fontSize: 12,
                    marginBottom: 0,
                    color: "#15325F",
                    fontWeight: 700,
                  }}
                >
                  Rule Type:{" "}
                  <span style={{ color: "#2E4FA5" }}>{rule_type}</span>
                </p>
              )}
            </>
          )}
          <div style={{ display: "flex" }}>
            &nbsp;&nbsp;&nbsp;
            <div>
              {!isEdit && (
                <DeleteForeverIcon
                  style={{ color: "#f44336", fontSize: 23, cursor: "pointer" }}
                  onClick={() => {
                    setSelectedId(zone);
                    setOpenDelete(true);
                  }}
                />
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ZoneItem;
