import React, { useEffect } from "react";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import { getBaseUrl } from "../HelperFunctions";
import { Site } from "../../services/FunctionService";
import { AppStore } from "../../stores/AppStore";

import { Select as AntSelect } from "antd";
import "antd/dist/antd.css";
import "./styles.css";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    formControl: {
      margin: theme.spacing(0),
      minWidth: 360,
      //   height: 300,
      maxWidth: 360,
      marginTop: 0,
      scrollMarginRight: 3,
      color: "#15325F",
    },
    chips: {
      display: "flex",
      flexWrap: "wrap",
    },
    chip: {
      margin: 2,
    },
    noLabel: {
      marginTop: theme.spacing(1),
    },
  })
);

const ITEM_HEIGHT = 58;
const ITEM_PADDING_TOP = 0;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      minHeight: 0,
      maxWidth: 500,
      minWidth: 0,
      color: "#15325F",
    },
  },
};

interface Props {
  appStore?: AppStore;
  setLoading: (b: boolean) => void;
  setSiteIds: (ids: number[], motioncamids: number[]) => void;
  groupid: number;
  top_org_only?: boolean;
  setSiteLists: (sites: Site[]) => void;
  loading: boolean;
  selectAllSites: (b: boolean) => void;
  multiple?: boolean;
  is_alpr?: boolean;
  title?: string;
  enabled?: boolean;
}

interface SelectedSite {
  site_name: string;
  site_id: number;
  data_origin: string;
}

const MultiSiteSelect: React.FC<Props> = ({
  groupid,
  setLoading,
  setSiteIds,
  appStore,
  setSiteLists,
  loading,
  selectAllSites,
  multiple = true,
  is_alpr,
  title,
  enabled,
}) => {
  const classes = useStyles();
  const [siteNames, setSiteNames] = React.useState<string[]>([]);
  const [selectAll, setSelectAll] = React.useState(false);

  const [siteList, setSiteList] = React.useState<Site[]>([]);
  const [siteOpts, setSiteOpts] = React.useState<
    { label: string; value: string }[]
  >([]);
  const [selectedOpts, setSelectedOpts] = React.useState<any>([]);

  const handleOptChange = (value: string[]) => {
    let strList = (multiple ? value : [value]) as string[];

    if (strList.indexOf(`Select All : -1`) > -1) {
      setSelectAll((prev) => !prev);
      setSiteNames([]);
      selectAllSites(!selectAll);
    } else {
      if (selectAll) {
        // {label: selectAll ? "----------UnSelect All----------": "----------Select All----------", value: "Select All : -1"},...
        // let arr = siteOpts.map(item => item.value)
        // console.debug("selected arr", arr)
        // setSelectedOpts(arr)
        return;
      }
      setSiteNames(strList);
    }

    let selected_sites: number[] = [];

    for (let item of strList) {
      let site_id = +item.split(" : ")[1];
      let selected_site = siteList.filter(
        (site: Site) => site.site_id === site_id
      )[0];
      if (selected_site) {
        selected_sites.push(selected_site.site_id);
        // if (selected_site.data_origin.includes("motioncam")) {
        //   motion_cam_ids.push(selected_site.site_id);
        // }
      }
    }
    setSiteIds(selected_sites, []);
    setSelectedOpts(multiple ? value : [value]);
  };

  // Fetch sites
  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      var token = await appStore?.authService.getAuthorisedToken();
      var baseUrl = getBaseUrl();
      if (token && groupid >= 1) {
        try {
          const request = JSON.stringify({
            enabled: enabled,
            top_org_id: groupid,
            paginate: false,
            top_org_only: false,
            verbose: true,
          });

          const path = is_alpr ? "/listlprsites" : "/list_sites";

          const res = fetch(baseUrl + path, {
            method: "post",
            headers: {
              Accept: "application/json",
              Authorization: "Bearer " + token,
              "Content-Type": "application/json",
            },
            body: request,
          });
          const result = await (await res).json();

          if (result && result.success) {
            let sites: Site[] = is_alpr ? result?.msg.sites : result?.sites;
            sites.sort(function (a: Site, b: Site) {
              if (b.site_name.toLowerCase() > a.site_name.toLowerCase()) {
                return -1;
              }
              if (b.site_name.toLowerCase() < a.site_name.toLowerCase()) {
                return 1;
              }
              return 0;
            });

            let site_names: string[] = [];
            let opts: { label: string; value: string }[] = [];
            sites.map((site: Site) => {
              site_names.push(site.site_name);
              opts.push({
                label: site.site_name,
                value: `${site.site_name} : ${site.site_id}`,
              });
            });
            setSiteOpts([...opts]);
            setSiteList(sites);
            setSiteLists(sites);
            setSiteNames([]);
          }
        } catch (err) {
          return err;
        } finally {
          setLoading(false);
        }
      }
    };
    fetchData();
  }, [groupid]);

  return (
    <div className="slectWrapper">
      {/* <FormControl className={classes.formControl}>
        {siteNames.length == 0 && !selectAll && (
          <InputLabel
            style={{
              color: "#15325F",
              fontSize: 12,
              fontWeight: "bold",
              padding: 0,
            }}
            id="demo-mutiple-checkbox-label"
          >
            &nbsp;&nbsp;&nbsp;&nbsp;Please select site(s)
          </InputLabel>
        )}
        {selectAll && (
          <InputLabel
            style={{
              color: "#15325F",
              fontSize: 12,
              fontWeight: "bold",
              padding: 0,
            }}
            id="demo-mutiple-checkbox-label"
          >
            &nbsp;&nbsp;&nbsp;&nbsp;All sites
          </InputLabel>
        )}
        <Select
          labelId="demo-mutiple-checkbox-label"
          id="demo-mutiple-checkbox"
          multiple
          value={siteNames}
          onChange={handleChange}
          input={<OutlinedInput name="sites" id="sites" />}
          renderValue={(selected) => (selected as string[]).join(", ")}
          MenuProps={MenuProps}
        >
          {!loading && (
            <MenuItem value={`Select All : -1`}>
              <Checkbox checked={selectAll ? true : false} />
              <ListItemText primary={`All sites`} />
            </MenuItem>
          )}
          {!loading ? (
            siteList.map((site: Site) => (
              <MenuItem
                key={site.site_id}
                value={`${site.site_name} : ${site.site_id}`}
              >
                <Checkbox
                  checked={
                    selectAll
                      ? true
                      : siteNames.indexOf(
                          `${site.site_name} : ${site.site_id}`
                        ) > -1
                  }
                />
                <ListItemText primary={`${site.site_name}`} />
              </MenuItem>
            ))
          ) : (
            <MenuItem>
              <span style={{ width: 260 }}>
                <ListItemText primary={`Loading...`} />
              </span>
            </MenuItem>
          )}
        </Select>
      </FormControl> */}
      {multiple ? (
        <AntSelect
          mode={"multiple"}
          loading={loading}
          maxTagCount={2}
          value={selectedOpts}
          allowClear={true}
          bordered={true}
          style={{ width: 410, color: "#15325f", borderRadius: 4 }}
          placeholder={title ? title : "Please select sites"}
          onChange={handleOptChange}
          options={siteOpts}
        />
      ) : (
        <AntSelect
          maxTagCount={2}
          loading={loading}
          value={selectedOpts}
          allowClear={false}
          size="large"
          bordered={false}
          showArrow={false}
          showSearch
          style={{
            width: 410,
            height: 55,
            paddingTop: 6,
            color: "#15325f",
            fontSize: 13,
          }}
          placeholder="Please select site"
          onChange={handleOptChange}
          options={siteOpts}
        />
      )}
    </div>
  );
};

export default MultiSiteSelect;
