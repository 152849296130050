import React from "react";
import "./RuleConfigs.css";
import { message, Select } from "antd";
import AddZoneForm from "./AddZoneForm";
import { useStyles } from "../../styling/global/global";
import ZoneItem from "./ZoneItem";
import { MainContext } from "../../containers/HomePage/HomePage";

interface props {
  configData?: any;
  sensorId?: any;
  siteId?: any;
  site?: any;
  sensorDefinedRule?: any;
  ruleType?: string;
  defaultRule?: boolean;
}
const RuleConfigs: React.FC<props> = ({
  sensorId,
  siteId,
  site,
  configData,
  sensorDefinedRule,
  ruleType,
  defaultRule,
}) => {
  let ruleTypes: string[] = [];

  if (sensorDefinedRule?.fields?.zone_configs) {
    ruleTypes =
      sensorDefinedRule?.fields?.zone_configs.map((item: any) => item.type) ||
      [];
  }

  React.useEffect(() => {
    if (ruleType) setRuleTypeFilter(ruleType);
  }, [ruleType]);

  const ruleOptions: any = sensorDefinedRule?.fields?.rule_configs
    ? Object.keys(sensorDefinedRule?.fields?.rule_configs).map((item: any) => ({
        label: item,
        value: item,
      }))
    : [];

  const [ruleTypeFilter, setRuleTypeFilter] = React.useState("all");

  const style = useStyles();
  const ctx = React.useContext(MainContext);

  const onDeleteHandler = (ruleId: number, id: number) => {
    message.success("successfully deleted record");
    let zones = ctx.zone_config.filter((zone: any) => zone.id !== id);
    ctx.setZoneConfig(zones);
  };

  const handleChange = (value: string) => {
    setRuleTypeFilter(value);
  };

  return (
    <div>
      <br></br>
      {(ctx.rule_type !== "Guard Management (no guard)" ||
        ctx.zone_config.length === 0) &&
        sensorDefinedRule && (
          <AddZoneForm
            defaultRule={defaultRule}
            sensorId={sensorId}
            siteId={siteId}
            site={site}
            sensorDefinedRule={sensorDefinedRule}
            ruleOption={ruleType}
          />
        )}

      {/* {(ctx?.rule_type?.includes("Guard Tour") ||
        ctx?.rule_type?.includes("Guard Management")) &&
        site?.product?.includes("EdgePlus") && (
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
              margin: "60px auto",
              width: "700px",
            }}
          >
            <p
              style={{
                color: "rgb(21,50,95)",
                fontWeight: "bold",
              }}
            >
              {"IP Address"}
            </p>
            <p
              style={{
                color: "rgb(21,50,95)",
                fontWeight: "bold",
              }}
            >
              {"User Name"}
            </p>
            <p
              style={{
                color: "rgb(21,50,95)",
                fontWeight: "bold",
              }}
            >
              {"Password"}
            </p>
            <p
              style={{
                color: "rgb(21,50,95)",
                fontWeight: "bold",
              }}
            >
              {"Port"}
            </p>
            <Tooltip
              placement="top"
              title={
                !configData?.camera_http_port ||
                !configData?.camera_rtsp_ip ||
                !configData?.camera_rtsp_login ||
                !configData?.camera_rtsp_password
                  ? "Cannot fetch snapshot"
                  : "Able to fetch snapshot"
              }
            >
              <div
                style={{
                  width: "25px",
                  height: "25px",
                  borderRadius: "50%",
                  backgroundColor:
                    !configData?.camera_http_port ||
                    !configData?.camera_rtsp_ip ||
                    !configData?.camera_rtsp_login ||
                    !configData?.camera_rtsp_password
                      ? "orange"
                      : "rgb(21,50,95)",
                }}
              ></div>
            </Tooltip>
          </div>
        )} */}

      <br></br>
      <br></br>
      <br></br>
      <br></br>
      {ctx.zone_config.length > 0 && (
        <div
          style={{
            width: "100%",
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <h1
            className={style.bold_text}
            style={{ fontSize: 15, textAlign: "left" }}
          >
            Configurations
          </h1>
          <div>
            <Select
              value={ruleTypeFilter}
              style={{ width: 220, marginBottom: 15 }}
              onChange={handleChange}
              options={
                ruleOptions.length > 0
                  ? [{ label: "All", value: "all" }, ...ruleOptions]
                  : [{ label: "All", value: "all" }]
              }
            />
          </div>
        </div>
      )}
      {ctx.zone_config.length > 0 && (
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            width: "100%",
            position: "relative",
            height: 430,
            overflowY: "scroll",
            padding: "20px 15px",
            border: "1px solid lightgrey",
            borderRadius: 8,
            backgroundColor: "#f6f6f6",
          }}
        >
          {ctx.zone_config
            .filter((item: any) => {
              if (ruleTypeFilter === "all") {
                return true;
              } else {
                return item.rule_type === ruleTypeFilter;
              }
            })
            .map((zone: any) => (
              <ZoneItem
                isRuleslist={false}
                sensorDefinedRule={sensorDefinedRule}
                ruleId={0}
                zone={`${zone.zone}`}
                count={zone.count}
                countLogic={zone.count_logic}
                object_motion={zone.object_motion}
                cadence_secs={zone?.cadence_secs}
                rule_type={zone?.rule_type}
                threshold={zone.confidence_threshold}
                class_label={zone.class_label}
                onDeleteHandler={(ruleId: number, id: any) => {
                  onDeleteHandler(ruleId, zone.id);
                }}
                ruleType={ctx.rule_type}
                site={site}
                ruleOption={ruleType}
                whiteBg
              />
            ))}
        </div>
      )}
    </div>
  );
};

export default RuleConfigs;
