import React, { useEffect, useState } from "react";
import OrgtreeAutocomplete from "../../../UI/OrgtreeAutocomplete";
import MultiSiteSelect from "../../../UI/MultiSiteSelect";
import CameraList from "../../../UI/CameraList";
import { AppStore } from "../../../../stores/AppStore";
import { useStyles } from "../../../styling/global/global";
import { Site } from "../../../../services/FunctionService";
import Grid from "@material-ui/core/Grid";
import UserList from "../../UsersList";
import Button from "@material-ui/core/Button";
import { Select as AntSelect, Tag } from "antd";
import "antd/dist/antd.css";
import FormControl from "@material-ui/core/FormControl";

import { JsonToTable } from "react-json-to-table";

import TimeRange from "../../../UI/DashboardUI/TimeRange";

import CustomModal from "../../../UI/CustomModal";

import { Table, Typography, Switch } from "antd";
import type { ColumnsType } from "antd/es/table";
import { isArray } from "util";
const { Text, Link } = Typography;

interface UserAuditTrailsProps {
  appStore?: AppStore;
}

const UserAuditTrails: React.FC<UserAuditTrailsProps> = ({ appStore }) => {
  const classes = useStyles();
  const [userAuditLogs, setUserAuditLogs] = useState<any>("user-audit");
  const [groupid, setGroupId] = useState(-1);
  const [siteid, setSiteId] = useState<number[]>([]);
  const [sensorId, setSensorId] = useState<any>("-1");
  const [loading, setLoading] = React.useState<boolean>(false);
  const [siteList, setSiteList] = React.useState<Site[]>([]);
  const [newEndTime, setNewEndTime] = useState<number | null>(
    new Date().getTime() / 1000
  );
  const [loadingSite, setLoadingSite] = React.useState<boolean>(false);
  const [selectAllSites, setSelectAllSites] = React.useState(false);
  const [openTimeRange, setOpenTimeRange] = React.useState(false);
  const [tableView, setTableView] = React.useState(true);
  const [newStartTime, setNewStartTime] = useState<number>(
    new Date(new Date().getTime() - 1000 * 60 * 60 * 24).getTime() / 1000
  );
  const [dateTitle, setDateTitle] = React.useState<string>("Last 24 hours");
  const [siteNames, setSiteNames] = React.useState<string[]>([]);
  const [selectedUserId, setSelectedUserId] = React.useState<number>();
  const [selectAll, setSelectAll] = React.useState(false);
  const [selectedActions, setSelectedActions] = React.useState([]);

  const [selectedOpts, setSelectedOpts] = React.useState<any>([]);
  const [actionTypesList, setActionTypesList] = React.useState<any>([]);
  const [event_timeout, setEventTimeout] = useState(false);

  const [newStartTimeFilter, setNewStartTimeFilter] = useState<number>(0);

  const [savedTime, setSavedTime] = useState({ startT: 0, endT: 0 });

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [activityDetails, setActivityDetail] = useState<any>({});
  const [activityDetailsJson, setActivityDetailJson] = useState<any>("");

  const [data, setData] = React.useState<any>([]);
  const [actionLookups, setActionLookups] = React.useState<any>({});

  const showModal = () => {
    setIsModalOpen(true);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };

  const columns: any = [
    {
      title: "Username",
      width: 100,
      dataIndex: "by_username",
      key: "by_username",
    },
    {
      title: "Action Type",
      width: 100,
      dataIndex: "action_type",
      key: "action_type",
      render: (_: any, record: any) => {
        if (actionLookups) {
          return <span>{actionLookups[`${record.action_type}`]}</span>;
        } else {
          return <Tag>N/A</Tag>;
        }
      },
    },
    {
      title: "Group",
      width: 100,
      dataIndex: "org_name",
      key: "org_name",
      render: (_: any, record: any) => {
        if (record.org_name) {
          return <span>{record.org_name}</span>;
        } else {
          return <Tag>N/A</Tag>;
        }
      },
    },
    {
      title: "Site",
      width: 100,
      dataIndex: "site_id",
      key: "site_id",
      render: (_: any, record: any) => {
        if (record.site_id && record.site_name) {
          return (
            <span>
              {record.site_name} - (site id = {record.site_id})
            </span>
          );
        } else {
          return <Tag>N/A</Tag>;
        }
      },
    },
    {
      title: "Camera",
      width: 100,
      dataIndex: "sensor_id_str",
      key: "sensor_id_str",
      render: (_: any, record: any) => {
        if (record.sensor_id_str && record.sensor_human_name) {
          return (
            <span>
              {record.sensor_id_str} - ({record.sensor_human_name})
            </span>
          );
        } else {
          return <Tag>N/A</Tag>;
        }
      },
    },
    {
      title: "Created At",
      width: 100,
      dataIndex: "created",
      key: "created",
    },
    {
      title: "View Details",
      key: "action_type",
      dataIndex: "action_type",
      fixed: "right",
      width: 100,
      render: (_: any, record: any) => (
        <a
          onClick={() => {
            setActivityDetail(
              JSON.parse(
                JSON.stringify(record)
                  .replace(/true/g, '"true"')
                  .replace(/false/g, '"false"')
              )
            );
            setActivityDetailJson(JSON.stringify(record));
            showModal();
          }}
        >
          View
        </a>
      ),
    },
  ];

  const applyTime = (startTime: number, endTime: number) => {
    setNewStartTime(startTime);
    setNewEndTime(endTime);
    setOpenTimeRange(false);
  };

  // FETCH ACTIVITY LOGS
  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      const token = await appStore?.authService.getAuthorisedToken();
      if (token && groupid > -1) {
        let data = await appStore?.functionService.getUserLogs(
          token,
          groupid.toString(),
          selectedActions.toString(),
          siteid.toString(),
          newStartTime,
          newEndTime,
          sensorId,
          selectedUserId?.toString()
        );
        setData(data);
      } else {
        console.debug(`User Logs Data:failed `);
      }
      setLoading(false);
    };
    fetchData();
  }, [
    groupid,
    selectedActions,
    siteid,
    newStartTime,
    newEndTime,
    sensorId,
    selectedUserId,
    selectedOpts,
  ]);

  // FETCH ACTION TYPES
  useEffect(
    () => {
      const fetchData = async () => {
        setLoading(true);
        const token = await appStore?.authService.getAuthorisedToken();
        if (token) {
          let data = await appStore?.functionService.getActionTypes(
            token,
            groupid.toString()
          );
          setActionTypesList(removeDuplicateObjects(data.action_types));
          setActionLookups(data.action_lookup);
        } else {
          console.debug(`User Logs Data:failed `);
        }
        setLoading(false);
      };
      fetchData();
    },
    [
      // groupid,
      // selectedActions,
      // siteid,
      // newStartTime,
      // newEndTime,
      // sensorId,
      // selectedUserId,
      // selectedOpts,
    ]
  );

  const handleOptChange = (value: any, option: any) => {
    setSelectedActions(
      option.map((item: any) => {
        return item.value;
      })
    );

    return setSelectedOpts(value);
  };

  function removeDuplicateObjects(array: any[]) {
    const uniqueValues = new Set();
    return array.filter((obj: any) => {
      if (!uniqueValues.has(obj.value)) {
        uniqueValues.add(obj.value);
        return true;
      }
      return false;
    });
  }

  return (
    <div>
      <CustomModal
        closeHandler={() => {
          handleCancel();
          setTableView(true);
        }}
        open={isModalOpen}
      >
        <h2
          style={{ fontWeight: 600, fontSize: 18 }}
          className={classes.bold_text}
        >
          Activity Details:
        </h2>
        <br />
        <Switch
          style={{ marginBottom: 50 }}
          checkedChildren="Json Format"
          unCheckedChildren="Tabular Format"
          checked={tableView}
          onChange={() => setTableView((prev) => !prev)}
        />
        {tableView && (
          <div style={{ minWidth: 300 }}>
            <JsonToTable styles={{ width: "100%" }} json={activityDetails} />
          </div>
        )}
        {!tableView && (
          <div style={{ minWidth: 300 }}>
            <Text copyable code style={{ fontWeight: 600, fontSize: 15 }}>
              {activityDetailsJson}
            </Text>
          </div>
        )}
      </CustomModal>
      <CustomModal
        closeHandler={() => {
          setOpenTimeRange(false);
        }}
        open={openTimeRange}
      >
        <TimeRange
          auditLogs={userAuditLogs}
          applyChangesHandler={applyTime}
          backDateHandler={(e: number) => {
            setEventTimeout(false);
            setNewStartTime(e);

            setNewStartTimeFilter(e);
            setNewEndTime(null);

            setOpenTimeRange(false);
          }}
          savedTime={savedTime}
          title={dateTitle}
          setSavedTime={(e) => {
            setSavedTime(e);
          }}
          dateTitle={(e) => setDateTitle(e)}
          closeModal={() => setOpenTimeRange(false)}
        />
      </CustomModal>
      <Grid item xs={12} sm={12} md={12} spacing={0}>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            minWidth: 900,
            paddingRight: 10,
            marginTop: 10,
          }}
        >
          <OrgtreeAutocomplete
            groupid={groupid}
            enabled={undefined}
            appStore={appStore}
            setGroupId={(id) => {
              setGroupId(id);
              setSiteId([]);
              setSensorId("-1");
            }}
            setLoading={(e: boolean) => setLoading(e)}
          />
          <MultiSiteSelect
            title="Filter by sites"
            selectAllSites={setSelectAllSites}
            groupid={groupid}
            appStore={appStore}
            setSiteIds={(ids, motioncamids) => {
              setSiteId(ids);
              setSensorId("-1");
            }}
            setSiteLists={(sites: Site[]) => setSiteList(sites)}
            setLoading={(e: boolean) => setLoadingSite(e)}
            top_org_only={false}
            loading={loading}
          />
          <CameraList
            ignoreHubs={true}
            selectAllSites={selectAllSites}
            siteids={siteid}
            sensorId={sensorId}
            appStore={appStore}
            setSiteId={(id) => setSiteId([id])}
            setLoading={(e: boolean) => setLoadingSite(e)}
            setSensorId={(e) => {
              setSensorId(e.join());
            }}
            loading={loadingSite}
            multi
          />
        </div>
      </Grid>
      <Grid item xs={12} sm={12} md={12} spacing={0}>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            minWidth: 900,
            paddingRight: 10,
            marginTop: 10,
          }}
        >
          <UserList
            setSelectedUserId={(id) => setSelectedUserId(id)}
            groupid={+groupid}
            appStore={appStore}
          />
          <div className="slectWrapper">
            <AntSelect
              mode={"multiple"}
              // loading={loading}
              maxTagCount={3}
              value={selectedOpts}
              allowClear={true}
              bordered={true}
              style={{ width: 410, color: "#15325f", borderRadius: 4 }}
              placeholder="Filter by action types"
              onChange={handleOptChange}
              options={actionTypesList}
              showSearch={true}
              optionFilterProp="label"
            />
          </div>
          <FormControl
            variant="outlined"
            style={{
              width: 360,
              color: "#6D809D",
              marginTop: 0,
              marginLeft: 3,
              backgroundColor: "white",
            }}
          >
            <Button
              style={{
                fontWeight: 500,
                fontSize: 13,
                borderRadius: 4,
                height: 51,
                marginLeft: 3,
                marginRight: 1,
              }}
              variant="outlined"
              color="primary"
              component="span"
              onClick={() => setOpenTimeRange(true)}
            >
              {dateTitle || ""}
            </Button>
          </FormControl>
        </div>
      </Grid>
      <Grid item xs={12} sm={12} md={12} spacing={0}>
        <Table
          style={{ marginTop: 100 }}
          columns={columns}
          dataSource={data}
          scroll={{ y: 500 }}
          loading={loading}
        />
      </Grid>
    </div>
  );
};

export default UserAuditTrails;
