import React, { useEffect } from "react";
import { makeStyles, Theme, createStyles } from "@material-ui/core/styles";
import { message } from "antd";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import Zone from "./Zone";
import { Tooltip } from "@material-ui/core";
import { Site, Sensor } from "../../../services/FunctionService";
import { AppStore } from "../../../stores/AppStore";
import { MainContext } from "../../containers/HomePage/HomePage";
import { getBaseUrl } from "../../HelperFunctions";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {},
    backButton: {
      marginRight: theme.spacing(1),
    },
    instructions: {
      marginTop: 0,
      marginBottom: theme.spacing(1),
    },
  })
);

function getSteps() {
  return ["Alert Configuration"];
  // return ["Alert Settings", "Alert Configuration"];
}

export default function RulesWizard({
  closeWizard,
  appStore,
  sensor,
  site,
  saveZonesHandler,
  setOpenWizardModal,
  sensorDefinedRule,
  defaultRule,
  sensorDefinedRules,
  orgId,
  default_rules,
  fetchDefaultRules,
  theORG,
  setUpdateRulesMsg,
  setLoadingSpinner,
  ruleDefs,
  siteId,
}: {
  closeWizard: () => void;
  appStore?: AppStore;
  sensor?: Sensor;
  site?: Site;
  saveZonesHandler?: () => void;
  setOpenWizardModal?: (val: boolean) => void;
  sensorDefinedRule?: any;
  defaultRule?: boolean;
  sensorDefinedRules?: any[];
  orgId?: number;
  siteId?: number;
  default_rules?: any;
  fetchDefaultRules?: () => void;
  theORG?: any;
  ruleDefs?: any;
  setUpdateRulesMsg?: (id: any) => void;
  setLoadingSpinner?: (id: boolean) => void;
}) {
  const classes = useStyles();
  const [activeStep, setActiveStep] = React.useState(0);
  const [channelID, setChannelD] = React.useState(0);
  const [loading, setLoading] = React.useState(false);
  const steps = getSteps();
  const [msg, setMsg] = React.useState("Loading...");
  const { setShowGraph } = React.useContext(MainContext);

  const ctx = React.useContext(MainContext);

  const createNewRule = () => {
    const ruleOptions = sensorDefinedRule?.fields?.rule_configs
      ? Object.keys(sensorDefinedRule?.fields?.rule_configs)
      : undefined;

    const rulesWithNoZones: any = [];
    if (ruleOptions) {
      ruleOptions.map((item: string) => {
        if (!sensorDefinedRule?.fields?.rule_configs[item]?.zone_configs) {
          rulesWithNoZones.push(item);
        }
      });
    }
    console.debug(
      "sensorDefinedRule",
      sensorDefinedRule,
      "RRR ruleOptions",
      ruleOptions,
      "rulesWithNoZone: ",
      rulesWithNoZones,
      "Configs: ",
      ctx.zone_config
    );

    var zone_sanitised = ctx.zone_config;
    var cadence_secs: any = undefined;
    var rules_config: any = {};
    console.debug("Zone Sanitised: ", zone_sanitised);
    for (var i in zone_sanitised) {
      if (zone_sanitised[i]["id"]) {
        zone_sanitised[i]["id"] = undefined;
      }
      if (zone_sanitised[i]["meta"]) {
        zone_sanitised[i]["meta"] = undefined;
      }

      const rType = zone_sanitised[i]["rule_type"];
      console.debug("Zone Sanitised Type: ", rType);

      if (zone_sanitised[i]["rule_type"]) {
        zone_sanitised[i]["rule_type"] = undefined;
      }

      if (!rules_config[`${rType}`]) {
        rules_config[`${rType}`] = { zone_configs: [zone_sanitised[i]] }; // Initialize the object and the zone_config array
      } else if (rules_config[`${rType}`].zone_configs) {
        rules_config[`${rType}`].zone_configs.push(zone_sanitised[i]);
      }

      if (
        rules_config[`${rType}`].zone_configs &&
        rulesWithNoZones.includes(`${rType}`)
      ) {
        rules_config[`${rType}`] = {};
      }
    }

    console.debug("RRR config::", rules_config);

    if (sensorDefinedRule?.fields?.cadence_secs) {
      cadence_secs = sensorDefinedRule?.fields?.cadence_secs?.enabled
        ? +ctx.fetchInterval.split(" ")[0] * 60
        : null;
      console.debug("Setting Cadence secs:", cadence_secs);
    }

    const alertConfigType = sensorDefinedRule?.fields?.alert_config_type;
    const rule = {
      name: ctx.rule_name.replaceAll("_", " "),
      cadence_secs: cadence_secs,
      alert_config_type: alertConfigType,
      alert_type: ctx.alertType.toLowerCase(),
      whitelist_id:
        sensorDefinedRule?.fields?.whitelist_id !== undefined
          ? ctx.whitelistName?.id || null
          : undefined,
      rule_configs: rules_config,
      valid_days_and_times: !sensorDefinedRule?.fields?.valid_days_and_times
        ? undefined
        : ctx.formattedTime(ctx.timeEntrySet) === null ||
          ctx.formattedTime(ctx.timeEntrySet) === ""
        ? '[("Mon,Tue,Wed,Thu,Fri,Sat,Sun", "00:00:00-24:00:00")]'
        : ctx.formattedTime(ctx.timeEntrySet),
      exact_times: !sensorDefinedRule?.fields?.exact_times
        ? undefined
        : (ctx.formattedTime(ctx.timeEntrySet) === null ||
            ctx.formattedTime(ctx.timeEntrySet) === "") &&
          ctx.rule_type === "snapshot"
        ? '[("Mon,Tue,Wed,Thu,Fri,Sat,Sun", "00:00:00")]'
        : `${ctx.formattedTime(ctx.timeEntrySet)}`.replace(/-24:00:00/g, ""),
      // environment: ctx.rule_environment,
      id: defaultRule ? 1 : undefined,
    };

    return rule;
  };

  const postRuleHandler = async () => {
    setLoading(true);
    var token = await appStore?.authService.getAuthorisedToken();

    const ruleOptions = sensorDefinedRule?.fields?.rule_configs
      ? Object.keys(sensorDefinedRule?.fields?.rule_configs)
      : undefined;

    const rulesWithNoZones: any = [];
    if (ruleOptions) {
      ruleOptions.map((item: string) => {
        if (!sensorDefinedRule?.fields?.rule_configs[item]?.zone_configs) {
          rulesWithNoZones.push(item);
        }
      });
    }

    console.debug(
      "sensorDefinedRule",
      sensorDefinedRule,
      "RRR ruleOptions",
      ruleOptions,
      "rulesWithNoZone: ",
      rulesWithNoZones,
      "Configs: ",
      ctx.zone_config
    );

    if (token) {
      var zone_sanitised = ctx.zone_config;
      var cadence_secs: any = undefined;
      var rules_config: any = {};
      console.debug("Zone Sanitised: ", zone_sanitised);
      for (var i in zone_sanitised) {
        // if (zone_sanitised[i]["zone"] == "full image") {
        //   zone_sanitised[i]["zone"] = "full_image";
        // }
        // if (zone_sanitised[i]["cadence_secs"]) {
        //   cadence_secs = +zone_sanitised[i]["cadence_secs"].split(" ")[0] * 60;
        //   zone_sanitised[i]["cadence_secs"] = undefined;
        // }
        if (zone_sanitised[i]["id"]) {
          zone_sanitised[i]["id"] = undefined;
        }
        if (zone_sanitised[i]["meta"]) {
          zone_sanitised[i]["meta"] = undefined;
        }

        const rType = zone_sanitised[i]["rule_type"];
        console.debug("Zone Sanitised Type: ", rType);

        if (zone_sanitised[i]["rule_type"]) {
          zone_sanitised[i]["rule_type"] = undefined;
        }

        if (!rules_config[`${rType}`]) {
          rules_config[`${rType}`] = { zone_configs: [zone_sanitised[i]] }; // Initialize the object and the zone_config array
        } else if (rules_config[`${rType}`].zone_configs) {
          // let tempArr = [...rules_config[`${rType}`].zone_config]
          // tempArr.push(zone_sanitised[i])
          // rules_config[`${rType}`].zone_config = [...tempArr]
          rules_config[`${rType}`].zone_configs.push(zone_sanitised[i]);
        }

        if (
          rules_config[`${rType}`].zone_configs &&
          rulesWithNoZones.includes(`${rType}`)
        ) {
          rules_config[`${rType}`] = {};
        }
      }

      console.debug("RRR config::", rules_config);

      if (sensorDefinedRule?.fields?.cadence_secs) {
        cadence_secs = sensorDefinedRule?.fields?.cadence_secs?.enabled
          ? +ctx.fetchInterval.split(" ")[0] * 60
          : null;
        console.debug("Setting Cadence secs:", cadence_secs);
      }

      const alertConfigType = sensorDefinedRule?.fields?.alert_config_type;
      const rule = {
        name: ctx.rule_name.replaceAll("_", " "),
        cadence_secs: cadence_secs,
        alert_config_type: alertConfigType,
        alert_type: ctx.alertType.toLowerCase(),
        whitelist_id:
          sensorDefinedRule?.fields?.whitelist_id !== undefined
            ? ctx.whitelistName?.id || null
            : undefined,
        rule_configs: rules_config,
        valid_days_and_times: !sensorDefinedRule?.fields?.valid_days_and_times
          ? undefined
          : ctx.formattedTime(ctx.timeEntrySet) === null ||
            ctx.formattedTime(ctx.timeEntrySet) === ""
          ? '[("Mon,Tue,Wed,Thu,Fri,Sat,Sun", "00:00:00-24:00:00")]'
          : ctx.formattedTime(ctx.timeEntrySet),
        exact_times: !sensorDefinedRule?.fields?.exact_times
          ? undefined
          : (ctx.formattedTime(ctx.timeEntrySet) === null ||
              ctx.formattedTime(ctx.timeEntrySet) === "") &&
            ctx.rule_type === "snapshot"
          ? '[("Mon,Tue,Wed,Thu,Fri,Sat,Sun", "00:00:00")]'
          : `${ctx.formattedTime(ctx.timeEntrySet)}`.replace(/-24:00:00/g, ""),
        // environment: ctx.rule_environment,
      };
      const ruleRequest = JSON.stringify({
        alert_config: rule,
        sensor_id: sensor?.sensor_id,
        orgId: orgId,
        meta:
          ctx.endpointIds.length > 0
            ? { end_point_ids: ctx.endpointIds }
            : { end_point_ids: null },
        class_label: ctx.rule_type === "Snapshot" ? "snapshot" : undefined,
      });

      try {
        const baseUrl = getBaseUrl();

        let ruleRes: any = await fetch(baseUrl + "/manage_sensor_rules", {
          method: "post",
          headers: {
            Accept: "application/json",
            Authorization: "Bearer " + token,
            "Content-Type": "application/json",
          },
          body: ruleRequest,
        });

        const ruleResult = (await (await ruleRes).json()) as any;
        if (ruleResult.success) {
          message.success("successfully created advanced rule");
          let msg =
            ctx.rule_type === "Known Vehicle Filter"
              ? "Please refer to the ALPR page inorder to whitelist your vehicle(s)."
              : "Request successfully submitted";
          setMsg(msg);
        } else {
          setMsg("failed to create advanced rule, please try again later");
          message.error("failed to create advanced rule");
        }

        ctx.clearRulesState();
        ctx.setRuleName("");
        ctx.setRuleList([]);
        ctx.setEndpointIds([]);
      } catch (err) {
        console.debug("err -> ", err);
        message.error("Something went wrong please try again later!");
        setActiveStep(0);
        setLoading(false);
      }
    }
    setLoading(false);
  };

  const submitGroupDefaultRuleHandler = async () => {
    const newRule = createNewRule();
    if (!newRule) return;

    const updatedDefaultRules = {
      ...default_rules,
      rules: [...default_rules?.rules, newRule],
    };

    const rulesWithoutId = updatedDefaultRules.rules.map(
      ({ id, ...rule }: { id: number; rule: any }) => rule
    );

    const updatedDefaultRulesWithoutId = {
      ...default_rules,
      alert_configs: rulesWithoutId,
    };

    console.debug(
      "Rules Wizard Post data: ",
      updatedDefaultRulesWithoutId,
      "newRule: ",
      newRule
    );

    setLoading(true);
    try {
      const token = await appStore?.authService?.getAuthorisedToken();
      if (!token) {
        setLoading(false);
        return;
      }

      const update = siteId
        ? await appStore?.functionService?.createSiteDefaultRules(
            token,
            siteId,
            updatedDefaultRulesWithoutId
          )
        : await appStore?.functionService?.createGroupDefaultRules(
            token,
            orgId,
            updatedDefaultRulesWithoutId
          );

      if (update) {
        setUpdateRulesMsg!(update.msg);
        fetchDefaultRules!();
        message.success("successfully created advanced rule");
        setMsg(update.msg);

        ctx.clearRulesState();
        ctx.setRuleName("");
        ctx.setRuleList([]);
        ctx.setEndpointIds([]);

        setLoading(false);
      }
    } catch (err) {
      console.error("Error updating rules:", err);
      setMsg("Error creating rules");
    } finally {
      setLoading(false);
      setActiveStep(0);
      setTimeout(() => {
        closeWizard();
      }, 2000);
    }
  };

  useEffect(() => {
    if (!ctx.rule_name && sensorDefinedRule?.fields?.name?.default) {
      ctx.setRuleName(`${sensorDefinedRule?.fields?.name?.default}`.trim());
    }
  }, [sensorDefinedRule, ctx.rule_name]);

  const handleNext = () => {
    // setShowGraph(false);
    if (
      sensorDefinedRule?.fields?.zone_configs === undefined &&
      !ctx.rule_name
    ) {
      ctx.setRuleName(
        `${sensorDefinedRule?.fields?.name?.default} ${ctx.last_rule_id}`.trim()
      );
    }

    if (activeStep === steps.length - 1) {
      if (
        !ctx.rule_name ||
        (ctx.zone_config.length === 0 &&
          // ctx.rule_type !== "Scene Change Alert" &&
          // ctx.rule_type !== "unusual_activity" &&
          // ctx.rule_type !== "Snapshot")
          sensorDefinedRule?.fields?.zone_configs !== undefined)
      ) {
        setMsg("Invalid inputs, please enter missing fields");
      } else {
        defaultRule ? submitGroupDefaultRuleHandler() : postRuleHandler();
      }
    }
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleReset = () => {
    setActiveStep(0);
    closeWizard();
    ctx.clearRulesState();
    ctx.setRuleName("");
    ctx.setRuleList([]);
    ctx.setEndpointIds([]);
    setMsg("");
  };

  React.useEffect(() => {
    const fetchData = async () => {
      if (site && site.site_id) {
        setLoading(true);
        var token = await appStore?.authService.getAuthorisedToken();
        if (token) {
          var alertchannelz: any[] =
            await appStore?.functionService.getAlertChannelsForSite(
              token,
              site.site_id
            );
          if (alertchannelz) {
            let ids_list = alertchannelz.map((channel) => {
              if (channel.site_id === site.site_id) {
                return channel.alert_channel_id;
              }
            });

            if (ids_list[0]) {
              setChannelD(ids_list[0]);
            }
          }
        }
        setLoading(false);
      }
    };
    fetchData();

    return () => setActiveStep(0);
  }, [site]);

  useEffect(() => {
    setShowGraph(false);
    return () => {
      if (!defaultRule) {
        setShowGraph(true);
      }
    };
  }, [sensorDefinedRule, sensorDefinedRules]);

  function getStepContent(stepIndex: number) {
    switch (stepIndex) {
      case 0:
        return (
          <Zone
            appStore={appStore}
            sensor={sensor}
            site={site}
            handleNext={handleNext}
            handlePrev={handleBack}
            showTable={true}
            showWizard={false}
            setOpenWizardModal={setOpenWizardModal}
            saveZonesHandler={saveZonesHandler}
            sensorDefinedRule={sensorDefinedRule}
            sensorDefinedRules={sensorDefinedRules}
            showSummary
            editable
            defaultRule={defaultRule}
            noEndTime={ctx.rule_type === "snapshot"}
            ruleDefs={ruleDefs}
          />
        );
      default:
        return "Unknown stepIndex";
    }
  }

  let disabled = false;

  if (
    (activeStep === 0 &&
      (ctx.rule_name === "" || ctx.zone_config.length === 0)) ||
    activeStep === steps.length
  ) {
    disabled = true;
  }

  console.debug(
    "handleNext: ",
    sensorDefinedRule?.alert_config_type !== "SceneChangeAlert" &&
      sensorDefinedRule?.alert_config_type !== "UnusualActivity" &&
      sensorDefinedRule?.alert_config_type !== "Snapshot",
    "sensorDefinedRule?.alert_config_type ",
    sensorDefinedRule?.fields?.alert_config_type,
    "ctx.rule_type: ",
    ctx.rule_type
  );
  return (
    <div className={classes.root} style={{ marginTop: 0 }}>
      <div>
        {activeStep === steps.length ? (
          <div style={{ margin: 0, padding: 0 }}>
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                width: "100%",
              }}
            >
              <Typography
                className={classes.instructions}
                style={{
                  color: msg.startsWith("Invalid") ? "red" : "#15325F",
                  fontWeight: 600,
                }}
              >
                {msg}
              </Typography>
            </div>
            {!defaultRule && (
              <div
                style={{
                  display: "flex",
                  justifyContent: "flex-end",
                  alignItems: "flex-end",
                }}
              >
                <Button onClick={handleReset}>Finish</Button>
              </div>
            )}
          </div>
        ) : (
          <div
            style={{
              margin: 20,
              paddingLeft: 20,
              paddingRight: 20,
              width: "100%",
            }}
          >
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                width: "100%",
              }}
            >
              <Typography className={classes.instructions}>
                {getStepContent(activeStep)}
              </Typography>
            </div>
            <div
              style={{
                display: "flex",
                justifyContent: "flex-end",
                alignItems: "flex-end",
                width: "100%",
                height: "100%",
                padding: 20,
              }}
            >
              <>
                {!defaultRule && (
                  <Button
                    onClick={() => {
                      closeWizard();
                      ctx.clearRulesState();
                    }}
                    className={classes.backButton}
                  >
                    Cancel
                  </Button>
                )}
                {defaultRule && (
                  <Button
                    onClick={() => {
                      closeWizard();
                    }}
                    className={classes.backButton}
                  >
                    Cancel
                  </Button>
                )}
              </>
              {activeStep !== 0 && (
                <Button
                  disabled={activeStep === 0}
                  onClick={handleBack}
                  className={classes.backButton}
                  variant="contained"
                  color="primary"
                >
                  Prev
                </Button>
              )}
              &nbsp;&nbsp;
              <Tooltip
                placement="bottom"
                title={disabled ? "Please enter all fields" : ""}
              >
                <Button
                  variant="contained"
                  color="primary"
                  onClick={handleNext}
                  disabled={
                    (disabled &&
                      sensorDefinedRule?.fields?.zone_configs !== undefined) ||
                    ctx.zone_config.length === 0
                  }
                >
                  {activeStep === steps.length - 1 ? "Create" : "Next"}
                </Button>
              </Tooltip>
            </div>
          </div>
        )}
      </div>
    </div>
  );
}
