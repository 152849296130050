import React, { useState, useEffect, ReactElement } from "react";
import FormGroup from "@material-ui/core/FormGroup";
import EditIcon from "@material-ui/icons/Edit";
import DeleteIcon from "@material-ui/icons/Delete";
import { makeStyles } from "@material-ui/core/styles";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import FormControl from "@material-ui/core/FormControl";
import Autocomplete from "@material-ui/lab/Autocomplete";
import InputLabel from "@material-ui/core/InputLabel";
import Checkbox from "@material-ui/core/Checkbox";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import {
  ExpansionPanel,
  ExpansionPanelSummary,
  ExpansionPanelDetails,
  Tooltip,
} from "@material-ui/core";
import { PlusOutlined, UsergroupAddOutlined } from "@ant-design/icons";
import { AppStore } from "../../../../stores/AppStore";
import { EntryPoint, User, Org } from "../../../../services/FunctionService";
import { Divider, IconButton, Typography } from "@material-ui/core";
import AddRoleToOrgModal from "../AddRoleToOrgModal/AddRoleToOrgModal";
import AddEntryOrgToUserModal from "../AddEntryOrgToUserModal/AddEntryOrgToUserModal";
import MessageModal from "../../../modals/MessageModal/MessageModal";
import Container from "@material-ui/core/Container";
import ConfirmDialog from "../../ConfirmDialog/ConfirmDialog";
import { useStyles } from "../../../styling/global/global";
import Button from "@material-ui/core/Button";

import {
  withStyles,
  useTheme,
  Theme,
  createStyles,
  fade,
} from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import Switch from "@material-ui/core/Switch";
import TextField from "@material-ui/core/TextField";
import { atom, useRecoilState } from "recoil";

import { GroupIdState } from "../UserManagementMainDrawer/UserManagementMainDrawer";
// import { GroupIdState } from '../UserDetailMainDrawer/UserDetailMainDrawer'

import {
  Button as AntButton,
  Modal,
  message,
  Steps,
  theme as antTheme,
  Typography as AntTypography,
  Space,
  Form,
  Input,
} from "antd";
import { QRCodeSVG } from "qrcode.react";

const steps = [
  {
    title: "Guide",
    content: "First-content",
  },
  {
    title: "Set Up",
    content: "Second-content",
  },
  {
    title: "Verification",
    content: "Last-content",
  },
];

const useStyle = makeStyles({
  paper: {
    border: "2px solid #6D809D",
    color: "#15325F",
  },
});

export const twoFAState = atom({
  key: "twoFAEnabled", // unique ID (with respect to other atoms/selectors)
  default: false, // default value (aka initial value)
});

interface WizardProps {
  children: ReactElement;
  closeModal: () => void;
  totpURI: string;
  appStore?: AppStore;
}

interface AntTheme {
  useToken: () => any; // Adjust the return type accordingly
  // Other properties of the theme object
}

const InstructionsPage: React.FC<{ totpURI: string }> = ({ totpURI }) => {
  const infoContent = (count: string, text: string) => (
    <div
      style={{
        display: "flex",
        alignItems: "center",
        width: "100%",
        justifyContent: "space-between",
        margin: 10,
        marginBottom: 5,
        textAlign: "left",
        lineHeight: 1.5,
        fontSize: 15,
      }}
    >
      <p>
        {count}. {text}
      </p>
    </div>
  );

  return (
    <div style={{ lineHeight: 2, margin: 20 }}>
      <AntTypography.Title level={3} style={{ margin: 0, textAlign: "left" }}>
        Multi-factor Authentication
      </AntTypography.Title>
      <AntTypography.Paragraph
        strong
        style={{ margin: 0, textAlign: "left", fontSize: 14 }}
      >
        Set up an authenticator app
      </AntTypography.Paragraph>
      <br />
      {infoContent(
        "1",
        "Install any authenticator app of your choice on your mobile device."
      )}
      {infoContent(
        "2",
        "Add your account to the authenticator app through the add new account flow."
      )}
      {infoContent(
        "3",
        'Use "DeepAlert" as the account name and scan the QR code below:'
      )}
      <QRCodeSVG value={totpURI} />
    </div>
  );
};

const InformationPage = () => {
  const infoContent = (url: string, text: string) => (
    <div
      style={{
        display: "flex",
        alignItems: "center",
        width: "100%",
        justifyContent: "space-between",
        color: "black",
        margin: 10,
        marginBottom: 5,
        textAlign: "left",
        lineHeight: 1.5,
        fontSize: 15,
      }}
    >
      <div>
        <img style={{ margin: 20 }} src={url}></img>
      </div>
      <p>{text}</p>
    </div>
  );
  return (
    <div style={{ lineHeight: 2, margin: 20 }}>
      <AntTypography.Title level={3} style={{ margin: 0, textAlign: "left" }}>
        Multi-factor Authentication
      </AntTypography.Title>
      <AntTypography.Paragraph
        strong
        style={{ margin: 0, textAlign: "left", fontSize: 14 }}
      >
        Secure your account with two-step verification
      </AntTypography.Paragraph>
      <br />
      {infoContent(
        "https://static.licdn.com/sc/h/egg1ezsv65pc86jriqjtd7pge",
        "Multi-factor authentication gives you additional security by requiring a verification code whenever you sign in on new device."
      )}
      {infoContent(
        "https://static.licdn.com/sc/h/a4otjm5rw3fqp223rzcuq7l6o",
        "Your Authenticator App helps us keep your account secure by adding an additional layer of verification."
      )}
    </div>
  );
};

interface VerificationPageProps {
  appStore?: AppStore;
}

const CodeVerificationPage: React.FC<VerificationPageProps> = ({
  appStore,
}) => {
  const [verified, setVeried] = useState(false);
  const [loading, setLoading] = useState(false);
  const [, setTwoFAEnabled] = useRecoilState(twoFAState);

  const onFinish = async (values: any) => {
    setLoading(true);
    const token = await appStore?.authService.getAuthorisedToken();
    if (token) {
      let data = await appStore?.functionService.enableOTP(token, values.code);
      if (data.success) {
        setTwoFAEnabled(true);
        setVeried(true);
      }
      if (!data.success) {
        message.error(data.msg);
      }
    }
    setLoading(false);
  };

  const onFinishFailed = (errorInfo: any) => {
    setVeried(false);
    setTwoFAEnabled(false);
  };

  type FieldType = {
    username?: string;
    password?: string;
    remember?: string;
  };

  const infoContent = (url: string, text: string) => (
    <div
      style={{
        display: "flex",
        alignItems: "center",
        width: "100%",
        justifyContent: "space-between",
        margin: 10,
        marginBottom: 5,
        textAlign: "left",
        lineHeight: 1.5,
        fontSize: 16,
        color: "limegreen",
      }}
    >
      <div>
        <img style={{ marginRight: 5 }} src={url}></img>
      </div>
      <p style={{ marginRight: 30, fontWeight: 600 }}>{text}</p>
    </div>
  );

  return (
    <div style={{ lineHeight: 2, margin: 20 }}>
      <AntTypography.Title level={3} style={{ margin: 0, textAlign: "left" }}>
        Multi-factor Authentication
      </AntTypography.Title>
      {!verified && (
        <>
          <AntTypography.Paragraph
            strong
            style={{ margin: 0, textAlign: "left", fontSize: 14 }}
          >
            Enter the 6-digit verification code generated by your Authenticator
            app.
          </AntTypography.Paragraph>
        </>
      )}
      <br />
      {!verified && (
        <div
          style={{
            display: "flex",
            alignItems: "center",
            width: "100%",
            justifyContent: "space-between",
            marginBottom: 5,
            textAlign: "left",
            lineHeight: 1.5,
            fontSize: 15,
          }}
        >
          <p>Note: You can also copy the code by tapping on it and paste it.</p>
        </div>
      )}
      {!verified && <br />}
      {!verified && (
        <Form
          name="basic"
          style={{ maxWidth: 600 }}
          initialValues={{ remember: true }}
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
          autoComplete="off"
          layout="inline"
        >
          <Form.Item<FieldType>
            label="Verification Code"
            name="code"
            rules={[
              { required: true, message: "Please enter the 6 digit Code!" },
            ]}
          >
            <Input placeholder="Enter 6 digit Code" />
          </Form.Item>
          <Form.Item wrapperCol={{ offset: 8, span: 16 }}>
            <AntButton type="primary" htmlType="submit" loading={loading}>
              Verify
            </AntButton>
          </Form.Item>
        </Form>
      )}
      {verified &&
        infoContent(
          "https://static.licdn.com/sc/h/a4otjm5rw3fqp223rzcuq7l6o",
          "Verification successful! Your multi-factor authentication is now active."
        )}
    </div>
  );
};

export const Wizard: React.FC<WizardProps> = (props) => {
  const { token } =
    (antTheme !== null ? (antTheme as AntTheme) : undefined)?.useToken() || {};
  const [current, setCurrent] = useState(0);

  const next = () => {
    setCurrent(current + 1);
  };

  const prev = () => {
    setCurrent(current - 1);
  };

  useEffect(() => {
    return () => setCurrent(0);
  }, []);

  const items = steps.map((item) => ({ key: item.title, title: item.title }));

  const contentStyle: React.CSSProperties = {
    lineHeight: "260px",
    textAlign: "center",
    color: "rgba(0, 0, 0, 0.45)",
    backgroundColor: `rgba(0, 0, 0, 0.02)`,
    borderRadius: 8,
    border: `1px dashed #d9d9d9`,
    marginTop: 16,
  };

  return (
    <>
      <Steps current={current} items={items} />
      <div style={contentStyle}>
        {/* {props.children} */}
        {current === 0 && <InformationPage />}
        {current === 1 && <InstructionsPage totpURI={props.totpURI} />}
        {current === 2 && <CodeVerificationPage appStore={props.appStore} />}
      </div>
      <div style={{ marginTop: 24 }}>
        {current < steps.length - 1 && (
          <AntButton type="primary" onClick={() => next()}>
            Next
          </AntButton>
        )}
        {current === steps.length - 1 && (
          <AntButton type="primary" onClick={() => props.closeModal()}>
            Done
          </AntButton>
        )}
        {current > 0 && (
          <AntButton style={{ margin: "0 8px" }} onClick={() => prev()}>
            Previous
          </AntButton>
        )}
        <AntButton
          style={{ margin: "0 8px" }}
          onClick={() => props.closeModal()}
        >
          Close
        </AntButton>
      </div>
    </>
  );
};

const AntSwitch = withStyles((theme: Theme) =>
  createStyles({
    root: {
      width: 28,
      height: 16,
      padding: 0,
      display: "flex",
    },
    switchBase: {
      padding: 2,
      color: theme.palette.grey[500],
      "&$checked": {
        transform: "translateX(12px)",
        color: theme.palette.common.white,
        "& + $track": {
          opacity: 1,
          backgroundColor: theme.palette.primary.main,
          borderColor: theme.palette.primary.main,
        },
      },
    },
    thumb: {
      width: 12,
      height: 12,
      boxShadow: "none",
    },
    track: {
      border: `1px solid ${theme.palette.grey[500]}`,
      borderRadius: 16 / 2,
      opacity: 1,
      backgroundColor: theme.palette.common.white,
    },
    checked: {},
  })
)(Switch);

interface UserDetailMainDrawerProps {
  appStore?: AppStore;
  user: User;
  setUser: (x: any) => void;
  authhandler: any;
}

const alert_web_roles = [
  {
    label: "View Alerts",
    value: "web_alerts",
  },
  {
    label: "View History",
    value: "web_alert_history",
  },

  {
    label: "View Escalations",
    value: "web_alert_escalations",
  },
  {
    label: "Camera filter",
    value: "web_alert_camera_filter",
  },
  {
    label: "Bulk Actions",
    value: "web_bulk_actions",
  },
  {
    label: "Mute Control",
    value: "web_mute_controls",
  },
  {
    label: "Mute one hour",
    value: "web_mute_one_hour",
  },
];
const user_roles = [
  "org_admin",
  "site_admin",
  "user_admin",
  "billing_admin",
  "move_site",
  // "advanced_rules_admin",
  "classifier_jwt",
];

const UserDetailMainDrawer: React.FC<UserDetailMainDrawerProps> = ({
  appStore,
  user,
  setUser,
  authhandler,
}: UserDetailMainDrawerProps) => {
  const classes = useStyles();
  const [entrypoints, setEntryPoints] = useState<EntryPoint[] | undefined>(
    undefined
  );
  const [addroleopen, setAddRoleOpen] = useState(false);
  const [addentryopen, setAddEntryOpen] = useState(false);
  const [currentEntryOrg, setCurrentEntryOrg] = useState(0);
  const [currentEntryOrgName, setCurrentEntryOrgName] = useState("");
  const [popmessage, setPopMessage] = useState("");
  const [messagemodalopen, setMessageModalOpen] = React.useState(false);
  const [refresh, setRefresh] = React.useState(0);
  const [confirmOpen, setConfirmOpen] = React.useState(false);
  const [confirmmessage, setConfirmMessage] = React.useState("");
  const [entry_org_id, setEntryOrgId] = React.useState(0);
  const [lastname, setLastName] = React.useState("");
  const [firstname, setFirstName] = React.useState("");
  const [username, setUserName] = React.useState("");
  const [email, setEmail] = React.useState("");
  const [contactno, setContactNo] = React.useState("");
  const [reload, setReload] = React.useState(0);
  const [password1, setPassword1] = React.useState("");
  const [password2, setPassword2] = React.useState("");
  const [enabled, setEnabled] = React.useState<boolean>(false);
  const w_roles: any = {};
  alert_web_roles.map((item: any) => {
    if (user.alert_web_roles.includes(item.value)) {
      w_roles[item.value] = true;
    } else {
      w_roles[item.value] = false;
    }
  });
  const [webRoleSelectorStatus, setWebRoleSelectorStatus] =
    React.useState<any>(w_roles);
  const [roleSelectorStatus, setRoleSelectorStatus] = React.useState<any>({});
  // const [enable2FA, setEnable2FA] = React.useState<boolean>(false);
  const [oldpassword, setOldPassword] = React.useState("");
  const [password1label, setPassword1Label] = React.useState("too short");
  const [password1color, setPassword1Color] = React.useState("#FF0000");
  const [strong, setStrong] = React.useState(false);
  const [logout, setLogout] = React.useState(false);

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [totpURI, setTotpURI] = useState("");
  const [enable2FA, setEnable2FA] = useRecoilState(twoFAState);
  const [loadingOrgs, setLoading] = React.useState(true);
  const [groupId] = useRecoilState(GroupIdState);
  const [groupid, setGroupId] = React.useState(-1); // edit this
  const [selecteEntryGroupId, setSelectedEntryGroupId] =
    React.useState<number>(-1);
  console.debug("Selected GroupID: ", groupId); // edit this
  const [selecteGroupId, setSelectedGroupId] = React.useState(groupId); // edit this
  const [groupname, setGroupName] = React.useState(""); // edit this
  const [selectedGroupName, setSelectedGroupName] = React.useState(""); // edit this
  const [userRole, setUserRole] = React.useState(user.job_description || ""); // edit this
  const [addedEntryorgs, setAddedEntryOrgs] = useState<number[] | []>([]);
  const [deletedEntryorgs, setDeletedEntryOrgs] = useState<number[]>([]);

  const [orgslist, setOrgsList] = React.useState<Org[]>([]);
  const [usersfortree, setUsersForTree] = React.useState([
    "Sysadmin",
    "DeepAlert Sales",
    "DeepAlert Support",
    "DeepAlert Dev",
    "Operator",
    "Supervisor",
    "False Alarm Filtering Manager",
    "False Alarm Filtering Administrator",
    "Technician",
    "End-user access - installer",
    "Other",
  ]);

  const [showOther, setShowOther] = React.useState(false);
  const [showOtherComment, setShowOtherComment] = React.useState<string>("");
  const userName = appStore?.authService.getUsername();

  const styler = useStyle();

  var loading = true;

  const showModal = () => {
    setIsModalOpen(true);
  };

  console.debug("User: ", user, "WebRoles: ", webRoleSelectorStatus);

  const roles = appStore?.authService.getRoles();
  var roleselector: React.ReactNode[] = [];
  var webroleselector: React.ReactNode[] = [];

  const handleChange = (event: React.ChangeEvent<any>) => {
    if (appStore) {
      roleSelectorStatus[event.target.name] = event.target.checked;
      setRoleSelectorStatus(roleSelectorStatus);
    }
    setReload(new Date().getTime());
  };

  // Initialize web role status Pinto
  useEffect(() => {
    if (appStore) {
      setWebRoleSelectorStatus(appStore.helpers.webRoleSelectorStatus);
    }
  }, [appStore]);

  // Handle changes in web role Pinto
  const handleWebRoleChange = (event: React.ChangeEvent<any>) => {
    if (appStore) {
      const { name, checked } = event.target;

      const currentRoles = { ...webRoleSelectorStatus };
      if (name === "web_mute_controls" && checked) {
        currentRoles["web_mute_one_hour"] = false;
      } else if (name === "web_mute_one_hour" && checked) {
        currentRoles["web_mute_controls"] = false;
      }

      currentRoles[name] = checked;
      appStore.helpers.webRoleSelectorStatus = { ...currentRoles };
      setWebRoleSelectorStatus(currentRoles);
    }
    setReload(new Date().getTime());
  };

  useEffect(() => {
    setWebRoleSelectorStatus(w_roles);
  }, [user]);

  const handleChangeAuto = (value: any) => {
    if (!value) {
      return setGroupId(-1);
    }
    const new_group_id = +value.org_id;
    console.debug("Group ID", new_group_id);
    setGroupId(new_group_id);
    let group = orgslist.find((x: any) => x.org_id === value.org_id);
    if (group) {
      setGroupName(group.org_name);
    }
  };

  const handleChangeAuto2 = (value: any) => {
    if (!value) {
      return setGroupId(-1);
    }
    const new_group_id = +value.org_id;
    console.debug("Group ID", new_group_id);
    setSelectedGroupId(new_group_id);
    let group = orgslist.find((x: any) => x.org_id === value.org_id);
    if (group) {
      setSelectedGroupName(group.org_name);
    }
    setRefresh(new Date().getTime());
  };

  useEffect(() => {
    if (user && user.totp_enabled) {
      console.debug("2FA USER OBJ", user);
      setEnable2FA(user!.totp_enabled);
    } else {
      setEnable2FA(false);
    }
  }, [user]);

  const handleOk = () => {
    setIsModalOpen(false);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };

  if (entrypoints) {
    loading = false;
  }

  const getBaseUrl = (): string => {
    let base = "";
    if (window.location.href.includes("local")) {
      base = "https://manage.staging.deepalert.ai";
    }
    base += "/api/v1.3";
    return base;
  };

  //  Fetching Organizations
  useEffect(() => {
    const fetchData = async () => {
      var token = await appStore?.authService.getAuthorisedToken();
      var baseUrl = getBaseUrl();
      if (token) {
        // Fetch Start
        try {
          const request = JSON.stringify({
            enabled: true,
            paginate: false,
            verbose: false,
            incl_tree_names: true,
          });

          const res = fetch(baseUrl + "/list_orgs", {
            method: "post",
            headers: {
              Accept: "application/json",
              Authorization: "Bearer " + token,
              "Content-Type": "application/json",
            },
            body: request,
          });
          const result = await (await res).json();
          const orglist = result;
          if (orglist && orglist.success) {
            let orgz = orglist?.orgs;
            orgz.sort(function (a: any, b: any) {
              return a.org_tree_names - b.org_tree_names;
            });
            orgz.sort(function (a: any, b: any) {
              return a.org_tree_names.length - b.org_tree_names.length;
            });
            setOrgsList(orgz);
            setLoading(false);
          }
        } catch (err) {
          return err;
        }
      }
    };
    fetchData();
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      var token = await appStore?.authService.getAuthorisedToken();
      if (token) {
        var data = await appStore?.functionService.getOtpUri(token);
        console.debug("TOTP URI", data);
        setTotpURI(data);
      }
    };
    if (isModalOpen) {
      fetchData();
    }
  }, [isModalOpen]);

  useEffect(() => {
    const setData = async () => {
      setLastName(user.last_name);
      setFirstName(user.first_name);
      setEmail(user.email);
      setContactNo(user.contact_number);
      setUserName(user.username);
      setEnabled(user.enabled);
    };
    setData();
  }, []);
  useEffect(() => {
    const fetchData = async () => {
      var token = await appStore?.authService.getAuthorisedToken();
      if (token) {
        var entrypoints = await appStore?.functionService.getUserEntryPoints(
          token,
          user.user_id
        );

        var entry_rolez: any = [];
        var entry_id: { [key: string]: number } = {};
        var groupId = -1;
        var selectedId = -1;
        var selectedName = "";
        var groupName = "";

        if (entrypoints) {
          entrypoints?.forEach((entrypoint: any, index: number) => {
            if (entrypoint.org_id === selecteGroupId) {
              groupId = entrypoint.org_id;
              groupName = entrypoint.org_name;
            } else {
              selectedName = entrypoint.org_name;
              selectedId = entrypoint.org_id;
            }

            entry_rolez.push(entrypoint.role);
          });
        }

        // setSelectedGroupId(groupId);
        setSelectedEntryGroupId(groupId);
        setSelectedGroupName(groupName);
        // setGroupId(selectedId);
        // setGroupName(selectedName);
        console.debug("u_role: ", entry_rolez);
        let u_roles: any = {};
        user_roles.map((item: any) => {
          if (entry_rolez.includes(item)) {
            u_roles[item] = true;
          } else {
            u_roles[item] = false;
          }
        });
        // setRoleSelectorStatus(u_roles);
        var entry_data = entrypoints?.filter(
          (item: any) => item.org_id === selecteGroupId
        );
        var rolez = entry_data?.map((item: any) => item.role);
        var roleStats: any = {};
        rolez.map((item: string) => (roleStats[item] = true));

        console.debug("RoleSelector: ", roleStats);
        setRoleSelectorStatus(roleStats);
        setEntryPoints(entrypoints);
      }
    };
    fetchData();
  }, [refresh]);

  const handleFirstNameChange = (event: React.ChangeEvent<any>) => {
    setFirstName(event.target.value);
  };
  const handleLastNameChange = (event: React.ChangeEvent<any>) => {
    setLastName(event.target.value);
  };
  const handleUserNameChange = (event: React.ChangeEvent<any>) => {
    setUserName(event.target.value);
  };
  const handleEmailChange = (event: React.ChangeEvent<any>) => {
    setEmail(event.target.value);
  };
  const handleContactNoChange = (event: React.ChangeEvent<any>) => {
    setContactNo(event.target.value);
  };
  const openMessageModal = () => {
    setMessageModalOpen(true);
  };
  const closeMessageModal = () => {
    setMessageModalOpen(false);
    if (logout) {
      appStore?.authService.signOut();
      var ev: Event;
      ev = new Event("authupdate");
      authhandler(ev);
    }
  };
  const openAddRoleModal = (
    e: React.MouseEvent<HTMLButtonElement>,
    entry_org_id: number,
    entry_org_name: string
  ) => {
    setCurrentEntryOrg(entry_org_id);
    setCurrentEntryOrgName(entry_org_name);
    setAddRoleOpen(true);
  };
  const openConfirm = (
    e: React.MouseEvent<HTMLButtonElement>,
    entry_org_id: number,
    entry_org_name: string
  ) => {
    setEntryOrgId(entry_org_id);
    setConfirmMessage(
      "Are you sure you want to delete " + entry_org_name + "?"
    );
    setConfirmOpen(true);
  };
  const dummy = () => {};
  const deleteEntryPoint = async () => {
    setConfirmOpen(false);
    const token = await appStore?.authService.getAuthorisedToken();
    if (token) {
      let delete_entrypoint =
        await appStore?.functionService.deleteUserEntryPoint(
          token,
          entry_org_id,
          user.user_id
        );
      if (delete_entrypoint) {
        if (delete_entrypoint.success) {
          setDeletedEntryOrgs((prev) => [...prev, entry_org_id]);
          setPopMessage(
            "Deleted Group for  : " + user.first_name + " " + user.last_name
          );
          openMessageModal();
          setEntryPoints(undefined);
          setRefresh(new Date().getTime());
        } else {
          setPopMessage(delete_entrypoint.msg);
          openMessageModal();
        }
      }
    }
  };
  const closeAddRoleModal = () => {
    setCurrentEntryOrg(0);
    setCurrentEntryOrgName("");
    setAddRoleOpen(false);
  };
  const openAddEntryOrg = () => {
    setAddEntryOpen(true);
  };
  const closeAddEntryOrg = () => {
    setAddEntryOpen(false);
  };
  const handlePassword1Change = (event: React.ChangeEvent<any>) => {
    let password = event.target.value;
    setPassword1(password);
    if (password.length > 12) {
      if (password.toLowerCase() != password) {
        var hasNumber = /\d/;
        if (hasNumber.test(password)) {
          var hasSpecial = /[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/;
          if (!hasSpecial.test(password)) {
            setPassword1Label("Password must contain some special characters!");
            setPassword1Color("#FFBF00");
            setStrong(false);
          } else {
            setPassword1Label("");
            setStrong(true);
            setPassword1Color("#0000FF");
          }
        } else {
          setPassword1Label("Password must contain some numbers!");
          setPassword1Color("#FFBF00");
          setStrong(false);
        }
      } else {
        setPassword1Label("Use uppercase and lowercase letters!");
        setPassword1Color("#FFBF00");
        setStrong(false);
      }
    }
  };
  const handlePassword2Change = (event: React.ChangeEvent<any>) => {
    setPassword2(event.target.value);
  };
  const handleOldPasswordChange = (event: React.ChangeEvent<any>) => {
    setOldPassword(event.target.value);
  };
  const saveEntryOrgsForUser = async (
    user_id: number,
    selectedorgs: { [key: number]: any }
  ) => {
    var roles: string = "";
    var neworgs: any = [];
    for (let key in selectedorgs) {
      const org: number = selectedorgs[key];
      neworgs.push(+key);
      if (org) {
        const token = await appStore?.authService.getAuthorisedToken();
        if (token) {
          let update_entrypoint =
            await appStore?.functionService.modifyUserEntryPoint(
              token,
              Number(key),
              user_id,
              "default"
            );
          if (update_entrypoint) {
            if (update_entrypoint.success) {
              closeAddEntryOrg();
              setPopMessage(
                "Updated entrypoints for " +
                  user.first_name +
                  " " +
                  user.last_name
              );
              if (user.user_id === appStore?.authService.getUserId()) {
                setPopMessage(
                  "Updated entrypoints for " +
                    user.first_name +
                    " " +
                    user.last_name +
                    ". Please log in again!"
                );
                setLogout(true);
              } else {
                setPopMessage(
                  "Updated entrypoints for " +
                    user.first_name +
                    " " +
                    user.last_name
                );
                setRefresh(new Date().getTime());
              }
              openMessageModal();
            } else {
              setPopMessage(update_entrypoint.msg);
              closeAddEntryOrg();
              openMessageModal();
            }
          }
        }
      }
    }
    console.debug("neworgs", neworgs);
    setAddedEntryOrgs((prev) => [...prev, ...neworgs]);
  };
  const same = password1 === password2 ? true : false;
  const updatePassword = async () => {
    if (password1 != "" && !strong) {
      setPopMessage("New password too weak!");
      openMessageModal();
    } else if (password1 != "" && !same) {
      setPopMessage("Password and confirmation does not match!");
      openMessageModal();
    } else {
      var token = await appStore?.authService.getAuthorisedToken();
      if (token) {
        var modified = await appStore?.functionService.modifyPasswordAdmin(
          token,
          user.user_id,
          oldpassword,
          password1
        );
        if (modified && modified.success) {
          setPassword1("");
          setPassword2("");
          setOldPassword("");
          if (user.user_id === appStore?.authService.getUserId()) {
            setPopMessage("Password updated! Please log in again!");
            setLogout(true);
          } else {
            setPopMessage("Password updated!");
          }
          openMessageModal();
        } else {
          setPopMessage("Password could not be updated! " + modified?.msg);
          openMessageModal();
        }
      }
    }
  };
  const updateUser = async (moveGroup?: boolean) => {
    var token = await appStore?.authService.getAuthorisedToken();
    if (token) {
      var selected_roles = "default";
      var selected_webroles = "";

      for (let role in roleSelectorStatus) {
        if (roleSelectorStatus[role]) {
          selected_roles = selected_roles ? selected_roles + "," + role : role;
        }
      }

      for (let webrole in webRoleSelectorStatus) {
        if (webRoleSelectorStatus[webrole]) {
          selected_webroles = selected_webroles
            ? selected_webroles + "," + webrole
            : webrole;
        }
      }
      var modified = !moveGroup
        ? await appStore?.functionService.modifyUserAdmin(
            token,
            user.user_id,
            firstname,
            lastname,
            email,
            contactno,
            username,
            enabled,
            selected_roles,
            selected_webroles,
            userRole === "Other" ? showOtherComment : userRole || "",
            selecteEntryGroupId
          )
        : await appStore?.functionService.modifyUserGroup(
            token,
            user.user_id,
            selecteEntryGroupId,
            groupid,
            selected_roles,
            userRole === "Other" ? showOtherComment : userRole || ""
          );
      if (modified && modified.success) {
        if (user.user_id === appStore?.authService.getUserId()) {
          setPopMessage("User updated! Please log in again!");
          setLogout(true);
        } else {
          setPopMessage("User updated!");
        }
        openMessageModal();
      } else {
        setPopMessage("User could not be updated! " + modified?.msg);
        openMessageModal();
      }
    }
    setRefresh(new Date().getTime());
  };

  const handleChangeUser = (value: any) => {
    if (value === "Other") {
      setShowOther(true);
    } else {
      setShowOther(false);
    }
    setUserRole(value);
  };

  const saveRolesForEntryOrg = async (
    user_id: number,
    entry_org_id: number,
    selectedroles: { [key: string]: any }
  ) => {
    var roles: string = "default";
    for (let role in selectedroles) {
      const thisrole = selectedroles[role];
      if (thisrole) {
        roles = roles + "," + role;
      }
    }
    const token = await appStore?.authService.getAuthorisedToken();
    if (token) {
      let update_entrypoint =
        await appStore?.functionService.modifyUserEntryPoint(
          token,
          entry_org_id,
          user_id,
          roles
        );
      if (update_entrypoint) {
        if (update_entrypoint.success) {
          closeAddRoleModal();
          setPopMessage(
            "Updated roles for " +
              user.first_name +
              " " +
              user.last_name +
              " for group " +
              currentEntryOrgName
          );
          if (user.user_id === appStore?.authService.getUserId()) {
            setPopMessage(
              "Updated roles for " +
                user.first_name +
                " " +
                user.last_name +
                " for group " +
                currentEntryOrgName +
                ". Please log in Again!"
            );
            setLogout(true);
          } else {
            setPopMessage(
              "Updated roles for " +
                user.first_name +
                " " +
                user.last_name +
                " for group " +
                currentEntryOrgName
            );
            setRefresh(new Date().getTime());
          }
          openMessageModal();
        } else {
          setPopMessage(update_entrypoint.msg);
          closeAddRoleModal();
          openMessageModal();
        }
      }
    }
  };

  // Render web roles in the UI Pinto
  if (roles) {
    let description = "";
    roleselector.push(
      <Typography className={classes.bold_text}>
        Management Permissions:
      </Typography>
    );
    webroleselector.push(
      <Typography className={classes.bold_text}>
        Alerting Interface Permissions::
      </Typography>
    );

    alert_web_roles.map((item: any) => {
      const checked = !!webRoleSelectorStatus[`${item.value}`];
      webroleselector.push(
        <Tooltip title={description!} placement="bottom">
          <FormControlLabel
            className={classes.bold_text}
            control={
              <Checkbox
                className={classes.form_component_checkbox_left_margin}
                color="primary"
                checked={checked}
                onChange={handleWebRoleChange}
                name={item.value}
              />
            }
            label={
              <span className={classes.bold_text}>
                {item.label.replace(/\b\w/g, (char: string) =>
                  char.toUpperCase()
                )}
              </span>
            }
          />
        </Tooltip>
      );
    });

    var entry_data = entrypoints?.filter(
      (item: any) => item.org_id === selecteGroupId
    );
    var rolez = entry_data?.map((item: any) => item.role);
    console.debug("rolez: ", rolez);

    console.debug("roleSelectorStatusxx: ", roleSelectorStatus);
    if (roles.indexOf("org_admin") > -1) {
      console.debug("rolez2: ", true);
      description = appStore?.functionService.translate(
        "en_ZA",
        "user_roles_descriptions",
        "org_admin"
      )!;
      roleselector.push(
        <Tooltip title={description!} placement="bottom">
          <FormControlLabel
            className={classes.bold_text}
            control={
              <Checkbox
                className={classes.form_component_checkbox_left_margin}
                color="primary"
                // checked={rolez && rolez.indexOf("org_admin") > -1}
                checked={!!roleSelectorStatus["org_admin"]}
                onChange={handleChange}
                name={"org_admin"}
              />
            }
            label={<span className={classes.bold_text}>Group Admin</span>}
          />
        </Tooltip>
      );
    }

    if (roles.indexOf("site_admin") > -1) {
      description = appStore?.functionService.translate(
        "en_ZA",
        "user_roles_descriptions",
        "site_admin"
      )!;
      roleselector.push(
        <Tooltip title={description!} placement="bottom">
          <FormControlLabel
            className={classes.bold_text}
            control={
              <Checkbox
                className={classes.form_component_checkbox_left_margin}
                color="primary"
                checked={!!roleSelectorStatus["site_admin"]}
                // checked={rolez && rolez.indexOf("site_admin") > -1}
                onChange={handleChange}
                name={"site_admin"}
              />
            }
            label={<span className={classes.bold_text}>Site Admin</span>}
          />
        </Tooltip>
      );
    }
    if (roles.indexOf("user_admin") > -1) {
      description = appStore?.functionService.translate(
        "en_ZA",
        "user_roles_descriptions",
        "user_admin"
      )!;
      roleselector.push(
        <Tooltip title={description!} placement="bottom">
          <FormControlLabel
            className={classes.bold_text}
            control={
              <Checkbox
                className={classes.form_component_checkbox_left_margin}
                color="primary"
                checked={!!roleSelectorStatus["user_admin"]}
                // checked={rolez && rolez.indexOf("user_admin") > -1}
                onChange={handleChange}
                name={"user_admin"}
              />
            }
            label={<span className={classes.bold_text}>User Admin</span>}
          />
        </Tooltip>
      );
    }
    if (roles.indexOf("billing_admin") > -1) {
      description = appStore?.functionService.translate(
        "en_ZA",
        "user_roles_descriptions",
        "billing_admin"
      )!;
      roleselector.push(
        <Tooltip title={description!} placement="bottom">
          <FormControlLabel
            className={classes.bold_text}
            control={
              <Checkbox
                className={classes.form_component_checkbox_left_margin}
                color="primary"
                checked={!!roleSelectorStatus["billing_admin"]}
                // checked={rolez && rolez.indexOf("billing_admin") > -1}
                onChange={handleChange}
                name={"billing_admin"}
              />
            }
            label={<span className={classes.bold_text}>Billing Admin</span>}
          />
        </Tooltip>
      );
    }
    if (roles.indexOf("uat") > -1) {
      description = appStore?.functionService.translate(
        "en_ZA",
        "user_roles_descriptions",
        "uat"
      )!;
      roleselector.push(
        <Tooltip title={description!} placement="bottom">
          <FormControlLabel
            className={classes.bold_text}
            control={
              <Checkbox
                className={classes.form_component_checkbox_left_margin}
                color="primary"
                checked={!!roleSelectorStatus["uat"]}
                // checked={rolez && rolez.indexOf("uat") > -1}
                onChange={handleChange}
                name={"uat"}
              />
            }
            label={<span className={classes.bold_text}>UAT</span>}
          />
        </Tooltip>
      );
    }
    // new role
    if (roles.indexOf("move_site") > -1 && userName?.endsWith("deepalert.ai")) {
      description = appStore?.functionService.translate(
        "en_ZA",
        "user_roles_descriptions",
        "move_site"
      )!;
      roleselector.push(
        <Tooltip title={description!} placement="bottom">
          <FormControlLabel
            className={classes.bold_text}
            control={
              <Checkbox
                className={classes.form_component_checkbox_left_margin}
                color="primary"
                checked={!!roleSelectorStatus["move_site"]}
                onChange={handleChange}
                name={"move_site"}
              />
            }
            label={<span className={classes.bold_text}>Move Site</span>}
          />
        </Tooltip>
      );
    }
    if (roles.indexOf("support") > -1) {
      description = appStore?.functionService.translate(
        "en_ZA",
        "user_roles_descriptions",
        "support"
      )!;
      roleselector.push(
        <Tooltip title={description!} placement="bottom">
          <FormControlLabel
            className={classes.bold_text}
            control={
              <Checkbox
                className={classes.form_component_checkbox_left_margin}
                color="primary"
                checked={!!roleSelectorStatus["support"]}
                // checked={rolez && rolez.indexOf("support") > -1}
                onChange={handleChange}
                name={"support"}
              />
            }
            label={<span className={classes.bold_text}>Support</span>}
          />
        </Tooltip>
      );
    }

    if (roles.indexOf("classifier_jwt") > -1) {
      description = appStore?.functionService.translate(
        "en_ZA",
        "user_roles_descriptions",
        "classifier_jwt"
      )!;
      roleselector.push(<div className={classes.space_smallest}></div>);
      roleselector.push(
        <Typography className={classes.bold_text}>Other Roles:</Typography>
      );
      roleselector.push(
        <Tooltip title={description!} placement="bottom">
          <FormControlLabel
            className={classes.bold_text}
            control={
              <Checkbox
                className={classes.form_component_checkbox_left_margin}
                color="primary"
                // checked={rolez && rolez.indexOf("classifier_jwt") > -1}
                checked={!!roleSelectorStatus["classifier_jwt"]}
                onChange={handleChange}
                name={"classifier_jwt"}
              />
            }
            label={<span className={classes.bold_text}>Inference Tokens</span>}
          />
        </Tooltip>
      );
    }
  }

  var entry_dict: { [key: string]: [string] } = {};
  var entry_id: { [key: string]: number } = {};
  if (entrypoints) {
    console.debug(
      "Entry Points Data: ",
      entrypoints,
      "selecteGroupId: ",
      selecteGroupId
    );
    entrypoints.forEach((entrypoint: any) => {
      if (!entry_dict[entrypoint.org_name]) {
        entry_dict[entrypoint.org_name] = [entrypoint.role];
      } else {
        entry_dict[entrypoint.org_name].push(entrypoint.role);
      }
      if (!entry_id[entrypoint.org_name]) {
        entry_id[entrypoint.org_name] = entrypoint.org_id;
      }
    });
  }
  console.debug("Entrypoints", entrypoints);

  var entrypoints_drawer: React.ReactNode[] = [];
  for (let entryorg in entry_dict) {
    let roles = entry_dict[entryorg];
    let entry_org_id = entry_id[entryorg];
    var selectedroles: { [key: string]: boolean } = {};

    var rolelist: React.ReactNode[] = [];

    let tooltip = "";
    rolelist.push(
      <Typography className={classes.bold_text} style={{ margin: "10px 0px" }}>
        Management Permissions:
      </Typography>
    );

    if (roles.indexOf("org_admin") >= 0) {
      tooltip = appStore?.functionService.translate(
        "en_ZA",
        "user_roles_descriptions",
        "org_admin"
      )!;
      rolelist.push(
        <Tooltip title={tooltip!} placement="bottom">
          <ExpansionPanelDetails>
            <div className={classes.expansion_shelf}>
              <div className={classes.shelf_name}>
                <div className={classes.shelf_icon}>
                  <UsergroupAddOutlined style={{ fontSize: 20 }} />
                </div>
                <div className={classes.bold_text}>Group Admin</div>
              </div>
              <div className={classes.shelf_container}>
                <div className={classes.shelf_item}>
                  <div className="circle"></div>
                </div>
              </div>
              <div className={classes.shelf_menu}></div>
            </div>
          </ExpansionPanelDetails>
        </Tooltip>
      );
    }

    if (roles.indexOf("move_site") >= 0 && userName?.endsWith("deepalert.ai")) {
      tooltip = appStore?.functionService.translate(
        "en_ZA",
        "user_roles_descriptions",
        "move_site"
      )!;
      rolelist.push(
        <Tooltip title={tooltip!} placement="bottom">
          <ExpansionPanelDetails>
            <div className={classes.expansion_shelf}>
              <div className={classes.shelf_name}>
                <div className={classes.shelf_icon}>
                  <UsergroupAddOutlined style={{ fontSize: 20 }} />
                </div>
                <div className={classes.bold_text}>Move Site</div>
              </div>
              <div className={classes.shelf_container}>
                <div className={classes.shelf_item}>
                  <div className="circle"></div>
                </div>
              </div>
              <div className={classes.shelf_menu}></div>
            </div>
          </ExpansionPanelDetails>
        </Tooltip>
      );
    }
    if (rolez && roles.indexOf("site_admin") >= 0) {
      tooltip = appStore?.functionService.translate(
        "en_ZA",
        "user_roles_descriptions",
        "site_admin"
      )!;
      rolelist.push(
        <Tooltip title={tooltip!} placement="bottom">
          <ExpansionPanelDetails>
            <div className={classes.expansion_shelf}>
              <div className={classes.shelf_name}>
                <div className={classes.shelf_icon}>
                  <UsergroupAddOutlined style={{ fontSize: 20 }} />
                </div>
                <div className={classes.bold_text}>Site Admin</div>
              </div>
              <div className={classes.shelf_container}>
                <div className={classes.shelf_item}>
                  <div className="circle"></div>
                </div>
              </div>
              <div className={classes.shelf_menu}></div>
            </div>
          </ExpansionPanelDetails>
        </Tooltip>
      );
    }
    if (rolez && roles.indexOf("user_admin") >= 0) {
      tooltip = appStore?.functionService.translate(
        "en_ZA",
        "user_roles_descriptions",
        "user_admin"
      )!;
      rolelist.push(
        <Tooltip title={tooltip!} placement="bottom">
          <ExpansionPanelDetails>
            <div className={classes.expansion_shelf}>
              <div className={classes.shelf_name}>
                <div className={classes.shelf_icon}>
                  <UsergroupAddOutlined style={{ fontSize: 20 }} />
                </div>
                <div className={classes.bold_text}>User Admin</div>
              </div>
              <div className={classes.shelf_container}>
                <div className={classes.shelf_item}>
                  <div className="circle"></div>
                </div>
              </div>
              <div className={classes.shelf_menu}></div>
            </div>
          </ExpansionPanelDetails>
        </Tooltip>
      );
    }
    if (rolez && roles.indexOf("billing_admin") >= 0) {
      tooltip = appStore?.functionService.translate(
        "en_ZA",
        "user_roles_descriptions",
        "billing_admin"
      )!;
      rolelist.push(
        <Tooltip title={tooltip!} placement="bottom">
          <ExpansionPanelDetails>
            <div className={classes.expansion_shelf}>
              <div className={classes.shelf_name}>
                <div className={classes.shelf_icon}>
                  {/* <svg
                    className="MuiSvgIcon-root jss80"
                    focusable="false"
                    viewBox="0 0 24 24"
                    aria-hidden="true"
                  >
                    <path d="M8 10H5V7H3v3H0v2h3v3h2v-3h3v-2zm10 1c1.66 0 2.99-1.34 2.99-3S19.66 5 18 5c-.32 0-.63.05-.91.14.57.81.9 1.79.9 2.86s-.34 2.04-.9 2.86c.28.09.59.14.91.14zm-5 0c1.66 0 2.99-1.34 2.99-3S14.66 5 13 5c-1.66 0-3 1.34-3 3s1.34 3 3 3zm6.62 2.16c.83.73 1.38 1.66 1.38 2.84v2h3v-2c0-1.54-2.37-2.49-4.38-2.84zM13 13c-2 0-6 1-6 3v2h12v-2c0-2-4-3-6-3z"></path>
                  </svg> */}
                  <UsergroupAddOutlined style={{ fontSize: 20 }} />
                </div>
                <div className={classes.bold_text}>Billing Admin</div>
              </div>
              <div className={classes.shelf_container}>
                <div className={classes.shelf_item}>
                  <div className="circle"></div>
                </div>
              </div>
              <div className={classes.shelf_menu}></div>
            </div>
          </ExpansionPanelDetails>
        </Tooltip>
      );
    }
    if (rolez && rolelist.length == 1) {
      rolelist.push(
        <Typography className={classes.bold_text}>None</Typography>
      );
    }
    if (rolez && roles.indexOf("classifier_jwt") >= 0) {
      tooltip = appStore?.functionService.translate(
        "en_ZA",
        "user_roles_descriptions",
        "classifier_jwt"
      )!;
      rolelist.push(<div className={classes.space_smallest}></div>);
      rolelist.push(
        <Typography className={classes.bold_text}>Other Roles:</Typography>
      );
      rolelist.push(
        <Tooltip title={tooltip!} placement="bottom">
          <ExpansionPanelDetails>
            <div className={classes.expansion_shelf}>
              <div className={classes.shelf_name}>
                <div className={classes.shelf_icon}>
                  {/* <svg
                    className="MuiSvgIcon-root jss80"
                    focusable="false"
                    viewBox="0 0 24 24"
                    aria-hidden="true"
                  >
                    <path d="M8 10H5V7H3v3H0v2h3v3h2v-3h3v-2zm10 1c1.66 0 2.99-1.34 2.99-3S19.66 5 18 5c-.32 0-.63.05-.91.14.57.81.9 1.79.9 2.86s-.34 2.04-.9 2.86c.28.09.59.14.91.14zm-5 0c1.66 0 2.99-1.34 2.99-3S14.66 5 13 5c-1.66 0-3 1.34-3 3s1.34 3 3 3zm6.62 2.16c.83.73 1.38 1.66 1.38 2.84v2h3v-2c0-1.54-2.37-2.49-4.38-2.84zM13 13c-2 0-6 1-6 3v2h12v-2c0-2-4-3-6-3z"></path>
                  </svg> */}
                  <UsergroupAddOutlined style={{ fontSize: 20 }} />
                </div>
                <div className={classes.bold_text}>Inference Tokens</div>
              </div>
              <div className={classes.shelf_container}>
                <div className={classes.shelf_item}>
                  <div className="circle"></div>
                </div>
              </div>
              <div className={classes.shelf_menu}></div>
            </div>
          </ExpansionPanelDetails>
        </Tooltip>
      );
    }

    entrypoints_drawer.push(
      <ExpansionPanel>
        <ExpansionPanelSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <Typography>
            <div className={classes.header}>
              <div className={classes.header_icon}>
                <svg
                  width="20"
                  height="16"
                  viewBox="0 0 20 16"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M7.17 2L9.17 4H18V14H2V2H7.17ZM8 0H2C0.9 0 0.00999999 0.9 0.00999999 2L0 14C0 15.1 0.9 16 2 16H18C19.1 16 20 15.1 20 14V4C20 2.9 19.1 2 18 2H10L8 0Z"
                    fill="#15325F"
                    fill-opacity="0.87"
                  />
                </svg>
              </div>
              <div className={classes.header_text_icons}>
                {entryorg}
                <Tooltip placement="bottom" title="Edit entry point">
                  <IconButton
                    edge="end"
                    aria-label="add"
                    onClick={(e: React.MouseEvent<HTMLButtonElement>) =>
                      openAddRoleModal(e, entry_org_id, entryorg)
                    }
                  >
                    <EditIcon color={"primary"} />
                  </IconButton>
                </Tooltip>
                <Tooltip placement="bottom" title="Delete entry">
                  <IconButton
                    edge="end"
                    aria-label="add"
                    onClick={(e: React.MouseEvent<HTMLButtonElement>) =>
                      openConfirm(e, entry_org_id, entryorg)
                    }
                  >
                    <DeleteIcon color={"primary"} />
                  </IconButton>
                </Tooltip>
              </div>
            </div>
          </Typography>
        </ExpansionPanelSummary>
        {rolelist}
      </ExpansionPanel>
    );
  }

  var editable = appStore!.authService!.getRoles()!.indexOf("user_admin") >= 0;
  var updatepassword: React.ReactNode[] = [];
  var entry_orgs = [
    ...user!.entry_orgs.map((item: any) => item.org_id),
    ...addedEntryorgs,
  ];
  if (deletedEntryorgs.length > 0) {
    console.debug("Entry_orgs3: ", entry_orgs);
    entry_orgs = entry_orgs.filter(
      (i: number) => deletedEntryorgs.indexOf(i) == -1
    );
  }
  console.debug("Entry_orgs2", entry_orgs);
  if (editable) {
    updatepassword.push(
      <>
        <div
          style={{
            borderRadius: 20,
            boxShadow: "0 2px 8px rgba(0,0,0,0.3)",
            backgroundColor: "white",
            padding: 30,
            marginTop: 20,
          }}
        >
          <h3
            className={classes.bold_text}
            style={{ marginBottom: 20, textAlign: "center", fontSize: 13 }}
          >
            UPDATE PASSWORD
          </h3>
          <Typography className={classes.bold_text}>
            Admin Password (Your password) :
          </Typography>
          <TextField
            fullWidth
            onChange={handleOldPasswordChange}
            value={oldpassword}
            variant="outlined"
            margin="normal"
            type="password"
            InputProps={{
              className: classes.bold_text,
            }}
            InputLabelProps={{
              classes: {
                root: classes.bold_text,
              },
            }}
          />
          <Typography className={classes.bold_text}>New Password:</Typography>
          <TextField
            fullWidth
            label={password1label}
            onChange={handlePassword1Change}
            value={password1}
            variant="outlined"
            margin="normal"
            type="password"
            InputProps={{
              className: classes.bold_text,
            }}
            InputLabelProps={{
              style: { color: password1color },
              classes: {
                root: classes.bold_text,
              },
            }}
          />
          <br></br>
          <Typography
            className={same ? classes.bold_text : classes.bold_text_red}
          >
            Confirm New Password:
          </Typography>
          <TextField
            fullWidth
            onChange={handlePassword2Change}
            value={password2}
            variant="outlined"
            margin="normal"
            type="password"
            InputProps={{
              className: classes.bold_text,
            }}
            InputLabelProps={{
              classes: {
                root: classes.bold_text,
              },
            }}
          />
        </div>
        <div className={classes.button_container}>
          <Button
            variant="contained"
            className={classes.bold_text}
            onClick={updatePassword}
          >
            Update Password
          </Button>
        </div>{" "}
      </>
    );
  }
  var updatebutton: React.ReactNode[] = [];
  if (editable) {
    updatebutton.push(
      <div className={classes.button_container}>
        <Button
          variant="contained"
          className={classes.bold_text}
          onClick={() => updateUser()}
        >
          Update
        </Button>
      </div>
    );
  }
  if (loading) {
    return (
      <Container maxWidth="md">
        <div className="wait-container">
          <svg width="48px" height="48px" viewBox="0 0 128 128">
            <g>
              <linearGradient id="linear-gradient">
                <stop offset="0%" stop-color="#ffffff" />
                <stop offset="100%" stop-color="#22305f" />
              </linearGradient>
              <path
                d="M63.85 0A63.85 63.85 0 1 1 0 63.85 63.85 63.85 0 0 1 63.85 0zm.65 19.5a44 44 0 1 1-44 44 44 44 0 0 1 44-44z"
                fill="url(#linear-gradient)"
                fill-rule="evenodd"
              />
              <animateTransform
                attributeName="transform"
                type="rotate"
                from="0 64 64"
                to="360 64 64"
                dur="1080ms"
                repeatCount="indefinite"
              ></animateTransform>
            </g>
          </svg>
        </div>
      </Container>
    );
  } else {
    return (
      <div
        className="main-drawer-container"
        style={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          marginLeft: 10,
        }}
      >
        <div
          className={classes.shelf_container}
          style={{
            // marginLeft: 50,
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <div
            className={classes.grab_icon}
            onClick={() => {
              setUser(undefined);
            }}
          >
            <svg
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M21 11H6.83l3.58-3.59L9 6l-6 6 6 6 1.41-1.41L6.83 13H21z"
                fill="#15325F"
                fill-opacity="0.87"
              ></path>
            </svg>
          </div>
          <div>
            <svg
              className="MuiSvgIcon-root jss80 MuiSvgIcon-fontSizeLarge"
              focusable="false"
              viewBox="0 0 24 24"
              aria-hidden="true"
            >
              <path d="M12 12c2.21 0 4-1.79 4-4s-1.79-4-4-4-4 1.79-4 4 1.79 4 4 4zm0 2c-2.67 0-8 1.34-8 4v2h16v-2c0-2.66-5.33-4-8-4z"></path>
            </svg>
          </div>
          <div
            className={classes.header_text}
            style={{
              width: "fit-content",
              marginBottom: 10,
              marginTop: 20,
              alignSelf: "center",
              textAlign: "center",
            }}
          >
            {user.first_name + " " + user.last_name}
          </div>
        </div>
        <div style={{ width: "90%" }}>
          {/* <Divider variant="middle"/> */}
          <hr
            style={{
              borderTop: "1px solid rgba(0, 0, 0, 0.12)",
              borderBottom: "none",
              borderLeft: "none",
              borderRight: "none",
            }}
          ></hr>
        </div>
        <div
          style={{
            borderRadius: 20,
            boxShadow: "0 2px 8px rgba(0,0,0,0.3)",
            backgroundColor: "white",
            padding: 30,
            marginTop: 20,
          }}
          className={classes.medium_panel}
        >
          <h3
            className={classes.bold_text}
            style={{
              marginBottom: 20,
              textAlign: "center",
              fontSize: 13,
              width: "100%",
            }}
          >
            Group selection and moving
          </h3>
          <FormControl
            className={classes.form_component_medium}
            variant="outlined"
            fullWidth={false}
            style={{ width: "100%" }}
          >
            <InputLabel className={classes.bold_text}>
              {" "}
              Selected user entry group:{" "}
            </InputLabel>
            <br></br>
            <Autocomplete
              id="combo-box-demo"
              className={classes.form_component_medium}
              options={orgslist.filter(
                (item: any) => entry_orgs.indexOf(item.org_id) > -1
                // &&
                // item.org_id !== entry_orgs[0]
              )}
              onChange={(event: React.ChangeEvent<any>, value: any) => {
                handleChangeAuto2(value);
              }}
              getOptionLabel={(option: any) => {
                let tree = option.org_tree_names!.map(
                  (item: any) => `   /   ${item} `
                );
                let treeString = tree.toString();
                const rpl = treeString.replace(/,/g, "");
                return `${rpl}`;
              }}
              style={{
                width: "100%",
                color: "#6D809D",
                margin: "15px 0px 10px 0px",
                padding: 0,
              }}
              classes={{ paper: styler.paper }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label={
                    <span
                      className={classes.bold_text}
                      style={{ color: "#15325F", width: "100%" }}
                    >
                      {selectedGroupName
                        ? selectedGroupName
                        : "Please select group"}
                    </span>
                  }
                  variant="outlined"
                  value={selecteGroupId}
                  className={classes.bold_text}
                />
              )}
            />
          </FormControl>
          <FormControl
            className={classes.form_component_medium}
            variant="outlined"
            fullWidth={false}
            style={{ width: "100%" }}
          >
            <InputLabel className={classes.bold_text}>
              {" "}
              {/* Move user to entry group:{' '} */}
              Move user to entry group
            </InputLabel>
            <br></br>
            <Autocomplete
              id="combo-box-demo"
              className={classes.form_component_medium}
              options={orgslist.filter(
                (item: any) => item.org_id !== selecteEntryGroupId
              )}
              onChange={(event: React.ChangeEvent<any>, value: any) => {
                handleChangeAuto(value);
              }}
              getOptionLabel={(option: any) => {
                let tree = option.org_tree_names!.map(
                  (item: any) => `   /   ${item} `
                );
                let treeString = tree.toString();
                const rpl = treeString.replace(/,/g, "");
                return `${rpl}`;
              }}
              style={{
                width: "100%",
                color: "#6D809D",
                margin: "15px 0px 10px 0px",
                padding: 0,
              }}
              classes={{ paper: styler.paper }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label={
                    <span
                      className={classes.bold_text}
                      style={{ color: "#15325F", width: "100%" }}
                    >
                      {groupname ? groupname : "Please select group"}
                    </span>
                  }
                  variant="outlined"
                  value={groupid}
                  className={classes.bold_text}
                />
              )}
            />
          </FormControl>
          <FormControl
            className={classes.form_component_medium}
            variant="outlined"
            fullWidth={false}
            style={{ width: "100%" }}
          >
            <InputLabel className={classes.bold_text}> User Role: </InputLabel>
            <br></br>
            <Autocomplete
              id="combo-box-demo"
              options={usersfortree!}
              onChange={(event: React.ChangeEvent<any>, value: any) => {
                handleChangeUser(value);
              }}
              getOptionLabel={(option) => {
                return option;
              }}
              renderOption={(option: any) => {
                return <span>{option}</span>;
              }}
              style={{
                width: "100%",
                color: "#6D809D",
                margin: "15px 0px 10px 0px",
                padding: 0,
              }}
              classes={{ paper: styler.paper }}
              renderInput={(params: any) => {
                console.debug("PARAMS: ************", params);
                return (
                  <TextField
                    {...params}
                    label={
                      <span className={classes.bold_text}>
                        {userRole ? userRole : "Please Select Role"}
                      </span>
                    }
                    variant="outlined"
                    value={userRole}
                    className={classes.bold_text}
                  />
                );
              }}
            />
            {showOther && (
              <div>
                <TextField
                  onChange={(e) => {
                    setShowOtherComment(e.target.value);
                  }}
                  style={{ color: "#15325F", marginTop: 20 }}
                  label="Enter Your Role"
                  value={showOtherComment}
                  margin="normal"
                  fullWidth
                  InputProps={{
                    className: classes.bold_text,
                  }}
                />
              </div>
            )}
          </FormControl>
          <div className={classes.header}>
            <Tooltip
              placement="bottom"
              title="Each user on the system must belong to an entry group with associated roles for that group. Should it be required that a user accesses the management interface with different profiles, a user can belong to more than one entry group in the system with associated roles for each entry group."
            >
              <div className={classes.header_text}></div>
            </Tooltip>
            <AntButton
              type="primary"
              style={{
                width: 150,
                borderRadius: 8,
                backgroundColor: "rgb(21, 50, 95)",
              }}
              onClick={() => updateUser(true)}
            >
              <span className="button-text">Move</span>
            </AntButton>
          </div>
        </div>

        <div
          style={{
            borderRadius: 20,
            boxShadow: "0 2px 8px rgba(0,0,0,0.3)",
            backgroundColor: "white",
            padding: 30,
            marginTop: 20,
          }}
          className={classes.medium_panel}
        >
          <h3
            className={classes.bold_text}
            style={{
              marginBottom: 20,
              textAlign: "center",
              fontSize: 13,
              width: "100%",
            }}
          >
            {/* Management and Alert Web Roles */}
            Management Permissions
          </h3>
          <FormControl
            component="fieldset"
            variant="outlined"
            className={classes.form_component}
            style={{ display: "flex" }}
          >
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "start",
              }}
            >
              <FormGroup>{roleselector}</FormGroup>
              {/* <FormGroup>{webroleselector}</FormGroup> */}
            </div>
          </FormControl>
          <div className={classes.header}>
            <Tooltip
              placement="bottom"
              title="Each user on the system must belong to an entry group with associated roles for that group. Should it be required that a user accesses the management interface with different profiles, a user can belong to more than one entry group in the system with associated roles for each entry group."
            >
              <div className={classes.header_text}></div>
            </Tooltip>
            <AntButton
              type="primary"
              style={{
                width: 150,
                borderRadius: 8,
                backgroundColor: "rgb(21, 50, 95)",
              }}
              onClick={() => updateUser()}
            >
              <span className="button-text">Update</span>
            </AntButton>
          </div>
        </div>
        <div
          style={{
            borderRadius: 20,
            boxShadow: "0 2px 8px rgba(0,0,0,0.3)",
            backgroundColor: "white",
            padding: 30,
            marginTop: 20,
          }}
          className={classes.medium_panel}
        >
          <h3
            className={classes.bold_text}
            style={{
              marginBottom: 20,
              textAlign: "center",
              fontSize: 13,
              width: "100%",
            }}
          >
            {/* Management and Alert Web Roles */}
            Alerting Interface Permissions
          </h3>
          <FormControl
            component="fieldset"
            variant="outlined"
            className={classes.form_component}
            style={{ display: "flex" }}
          >
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "start",
              }}
            >
              {/* <FormGroup>{roleselector}</FormGroup> */}
              <FormGroup>{webroleselector}</FormGroup>
            </div>
          </FormControl>
          <div className={classes.header}>
            <Tooltip
              placement="bottom"
              title="Each user on the system must belong to an entry group with associated roles for that group. Should it be required that a user accesses the management interface with different profiles, a user can belong to more than one entry group in the system with associated roles for each entry group."
            >
              <div className={classes.header_text}></div>
            </Tooltip>
            <AntButton
              type="primary"
              style={{
                width: 150,
                borderRadius: 8,
                backgroundColor: "rgb(21, 50, 95)",
              }}
              loading={loading}
              onClick={() => updateUser()}
            >
              <span className="button-text">Update</span>
            </AntButton>
          </div>
        </div>
        <div className={classes.medium_panel}>
          {appStore &&
            appStore!.authService!.getRoles() &&
            appStore!.authService!.getRoles()!.indexOf("sys_admin") >= 0 && (
              <div
                style={{
                  borderRadius: 20,
                  boxShadow: "0 2px 8px rgba(0,0,0,0.3)",
                  backgroundColor: "white",
                  width: "100%",
                  padding: 30,
                  marginTop: 20,
                }}
              >
                <div className={classes.header}>
                  <Tooltip
                    placement="bottom"
                    title="Each user on the system must belong to an entry group with associated roles for that group. Should it be required that a user accesses the management interface with different profiles, a user can belong to more than one entry group in the system with associated roles for each entry group."
                  >
                    <div className={classes.header_text}>Groups with Roles</div>
                  </Tooltip>
                  <AntButton
                    type="primary"
                    icon={<PlusOutlined />}
                    style={{
                      width: 150,
                      borderRadius: 8,
                      backgroundColor: "rgb(21, 50, 95)",
                    }}
                    onClick={openAddEntryOrg}
                  >
                    <span className="button-text">Add Entry Point</span>
                  </AntButton>
                </div>
                {entrypoints_drawer}
              </div>
            )}
          <div
            style={{
              borderRadius: 20,
              boxShadow: "0 2px 8px rgba(0,0,0,0.3)",
              backgroundColor: "white",
              padding: 30,
              marginTop: 20,
            }}
          >
            <h3
              className={classes.bold_text}
              style={{
                marginBottom: 20,
                textAlign: "center",
                fontSize: 13,
              }}
            >
              UPDATE PERSONAL INFORMATION
            </h3>
            <TextField
              label="Username"
              fullWidth
              onChange={handleUserNameChange}
              value={username}
              variant="outlined"
              margin="normal"
              disabled={!editable}
              InputProps={{
                className: classes.bold_text,
              }}
              InputLabelProps={{
                classes: {
                  root: classes.bold_text,
                },
              }}
            />
            <TextField
              label="First Name"
              fullWidth
              onChange={handleFirstNameChange}
              value={firstname}
              variant="outlined"
              margin="normal"
              disabled={!editable}
              InputProps={{
                className: classes.bold_text,
              }}
              InputLabelProps={{
                classes: {
                  root: classes.bold_text,
                },
              }}
            />
            <TextField
              label="Last Name"
              fullWidth
              onChange={handleLastNameChange}
              value={lastname}
              variant="outlined"
              margin="normal"
              disabled={!editable}
              InputProps={{
                className: classes.bold_text,
              }}
              InputLabelProps={{
                classes: {
                  root: classes.bold_text,
                },
              }}
            />
            <TextField
              label="Email"
              fullWidth
              onChange={handleEmailChange}
              value={email}
              variant="outlined"
              margin="normal"
              disabled={!editable}
              InputProps={{
                className: classes.bold_text,
              }}
              InputLabelProps={{
                classes: {
                  root: classes.bold_text,
                },
              }}
            />
            <TextField
              label="Contact Number"
              fullWidth
              onChange={handleContactNoChange}
              value={contactno}
              variant="outlined"
              margin="normal"
              disabled={!editable}
              InputProps={{
                className: classes.bold_text,
              }}
              InputLabelProps={{
                classes: {
                  root: classes.bold_text,
                },
              }}
            />
            {/* ENABLE OR DISABLE USER */}
            <div style={{ marginTop: 20 }}>
              <Typography className={classes.bold_text}>
                <Tooltip placement="right" title="">
                  <Grid
                    component="label"
                    container
                    alignItems="center"
                    spacing={1}
                  >
                    <Grid item>
                      <h4>Disable / Enable User</h4>
                    </Grid>
                    <Grid item>
                      <AntSwitch
                        checked={enabled}
                        onChange={(e) => {
                          e.stopPropagation();
                          setEnabled(e.target.checked);
                        }}
                        onClick={(e) => {
                          e.stopPropagation();
                        }}
                        name="checkedC"
                      />
                    </Grid>
                  </Grid>
                </Tooltip>
              </Typography>
            </div>
            {/* {appStore?.authService.getUserId() === user.user_id && (
              <div style={{ marginTop: 20 }}>
                <Typography className={classes.bold_text}>
                  <Tooltip placement="right" title="">
                    <Grid
                      component="label"
                      container
                      alignItems="center"
                      spacing={1}
                    >
                      <Grid item>
                        <h4>Enable Two Factor Verification</h4>
                      </Grid>
                      <Grid item>
                        <AntSwitch
                          checked={enable2FA}
                          onChange={(e) => {
                            if (e.target.checked) {
                              setIsModalOpen(true);
                            } else {
                              setEnable2FA(e.target.checked);
                            }
                            e.stopPropagation();
                          }}
                          onClick={(e) => {
                            e.stopPropagation();
                          }}
                          name="checked2FA"
                        />
                      </Grid>
                    </Grid>
                  </Tooltip>
                </Typography>
              </div>
            )} */}
          </div>
          {/* END */}
          {updatebutton}
          {updatepassword}
        </div>

        <div style={{ width: "90%" }}>
          {/* <Divider variant="middle"/> */}
          <hr
            style={{
              borderTop: "1px solid rgba(0, 0, 0, 0.12)",
              borderBottom: "none",
              borderLeft: "none",
              borderRight: "none",
            }}
          ></hr>
        </div>
        <AddEntryOrgToUserModal
          initialentrypoints={entrypoints!}
          saveEntryOrgsForUser={(
            user_id: number,
            selectedorgs: { [key: number]: any }
          ) => saveEntryOrgsForUser(user_id, selectedorgs)}
          open={addentryopen}
          onClose={closeAddEntryOrg}
          user_id={user.user_id}
          user_name={user.first_name + " " + user.last_name}
          appStore={appStore}
        />
        <AddRoleToOrgModal
          entrypoints={entrypoints}
          saveRolesForEntryOrg={(
            user_id: number,
            entry_org_id: number,
            selectedroles: { [key: string]: any }
          ) => saveRolesForEntryOrg(user_id, entry_org_id, selectedroles)}
          open={addroleopen}
          onClose={closeAddRoleModal}
          user_id={user.user_id}
          entry_org_id={currentEntryOrg!}
          entry_org_name={currentEntryOrgName!}
          appStore={appStore}
        />
        <MessageModal
          open={messagemodalopen}
          onClose={closeMessageModal}
          message={popmessage}
          elements={undefined}
        />
        <ConfirmDialog
          title=""
          open={confirmOpen}
          setOpen={setConfirmOpen}
          onConfirm={deleteEntryPoint}
        >
          {confirmmessage}
        </ConfirmDialog>

        <Modal
          width={700}
          centered
          footer={null}
          title="Multi-factor Authentication"
          open={isModalOpen}
          onOk={handleOk}
          onCancel={handleCancel}
        >
          <Wizard
            closeModal={handleCancel}
            totpURI={totpURI}
            appStore={appStore}
          >
            <div style={{ height: 600, overflowY: "scroll" }}>
              <p>Some contents...</p>
              <p>Some contents...</p>
              <p>Some contents...</p>
            </div>
          </Wizard>
        </Modal>
      </div>
    );
  }
};
export default UserDetailMainDrawer;
