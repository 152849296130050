import { FunctionService, OrgSelect, Org, Token } from "./FunctionService";

export class AuthService {
  public handler: ((ev: Event) => void) | null;
  private message: string;
  private isAuthorised: boolean;
  private functionService: FunctionService | null;
  private orgs: OrgSelect[];
  private entry_org: OrgSelect | undefined;
  private roles: string[];
  private breadcrumbs: Org[] | undefined;
  private firstname: string;
  private lastname: string;
  private username: string;
  private user_id: number;
  private signed_in_org_id: number;
  async signIn(
    email: string,
    password: string,
    mfaCode?: string
  ): Promise<boolean> {
    try {
      var authtokens = this.functionService?.authenticateUser(
        email,
        password,
        mfaCode
      );
      if (authtokens) {
        if ((await authtokens).success) {
          this.message = "Successful Login!";
          localStorage.setItem("access_token", (await authtokens).access_token);
          this.decodeAndStore(localStorage.getItem("access_token")!);
          let refresh_token = (await authtokens).refresh_token;
          if (refresh_token) {
            this.isAuthorised = true;
            localStorage.setItem(
              "refresh_token",
              (await authtokens).refresh_token
            );
          }
        } else {
          if ((await authtokens).code === 401) {
            this.isAuthorised = false;
            if ((await authtokens).msg.startsWith("Invalid OTP")) {
              this.message = "MFA Code invalid";
            } else {
              this.message = "Invalid username or password!";
            }
            localStorage.setItem("access_token", "");
            localStorage.setItem("refresh_token", "");
          } else if ((await authtokens).code === 404) {
            this.isAuthorised = false;
            this.message =
              "Login credentials are valid but not configured for management functions - please contact your DeepAlert system administrator!";
            localStorage.setItem("access_token", "");
            localStorage.setItem("refresh_token", "");
          } else if (
            (await authtokens).code === 403 &&
            (await authtokens).msg === "TOTP required"
          ) {
            this.isAuthorised = false;
            this.message = "TOTP required";
            localStorage.setItem("access_token", "");
            localStorage.setItem("refresh_token", "");
          } else {
            this.isAuthorised = false;
            this.message = "Something went wrong, please try again later!";
            localStorage.setItem("access_token", "");
            localStorage.setItem("refresh_token", "");
            //removed res code to avoid undefined bug
            // let code = (await authtokens).code;
            // this.message = this.message + "(" + code + ")";
          }
        }
      }
      var userId = email;
      console.debug("userGuiding UserId: ", userId);
      // (window as any).userGuiding.identify(userId); // Using 'as any' for type assertion
      return true;
    } catch (err) {
      return false;
    }
  }

  async signInOrg(org_id: number): Promise<boolean> {
    try {
      this.signed_in_org_id = org_id;
      var authtokens = this.functionService?.authenticateUserOrg(
        org_id,
        await this.getAuthorisedToken()
      );
      if (authtokens) {
        if ((await authtokens).success) {
          this.isAuthorised = true;
          this.message = "Successful Login!";
          localStorage.setItem("access_token", (await authtokens).access_token);
          localStorage.setItem(
            "refresh_token",
            (await authtokens).refresh_token
          );
          this.decodeAndStore(localStorage.getItem("access_token")!);
          //await this.setBreadcrumbOrgs(org_id);
        } else {
          this.isAuthorised = false;
          this.message = "Invalid Group!";
          localStorage.setItem("access_token", "");
          localStorage.setItem("refresh_token", "");
        }
      }
      return this.isAuthorised;
    } catch (err) {
      return false;
    }
  }

  async freshSignIn(email: string, password: string): Promise<boolean> {
    try {
      var authtokens = this.functionService?.freshAuthenticateUser(
        email,
        password
      );
      if (authtokens) {
        if ((await authtokens).success) {
          this.isAuthorised = true;
          this.message = "Successful Login!";
          localStorage.setItem("access_token", (await authtokens).access_token);
        } else {
          this.isAuthorised = false;
          this.message = "Invalid username or password!";
        }
      }
      return true;
    } catch (err) {
      return false;
    }
  }

  signOut() {
    const access_token = localStorage.getItem("access_token");
    const refresh_token = localStorage.getItem("refresh_token");
    this.isAuthorised = false;
    if (refresh_token) {
      this.functionService?.removeRefreshToken(refresh_token);
    }
    if (access_token) {
      this.functionService?.removeAccessToken(access_token);
    }
    localStorage.setItem("access_token", "");
    localStorage.setItem("refresh_token", "");
    this.orgs = [];
    if (this.handler) {
      var ev: Event;
      ev = new Event("authupdate");
      this.handler!(ev);
    }
  }

  setHandler = (handler: (ev: Event) => void) => {
    this.handler = handler;
  };

  isCurrentlyAuthorised() {
    return this.isAuthorised;
  }

  getAuthorisedMessage() {
    return this.message;
  }
  getBreadCrumbs() {
    return this.breadcrumbs;
  }
  getOrgs() {
    return this.orgs;
  }
  getRoles() {
    return this.roles;
  }
  getEntryOrg() {
    return this.entry_org;
  }
  getTranslations(token: string, locale: string) {
    this.functionService?.getTranslations(token, locale);
  }

  async setBreadcrumbOrgs(org: Org) {
    var org_ids: number[] = [];
    let breadcrumb_orgs: Org[] = [];
    let token = this.getToken();
    //First strip out all the null values from the rest server
    if (org?.org_tree) {
      org.org_tree.forEach((org_id) => {
        if (org_id !== null) {
          org_ids.push(org_id);
        }
      });

      //now check if entry org lower in the tree
      var process_org_ids: number[] = [];
      if (org_ids.includes(this.getEntryOrg()?.id!)) {
        process_org_ids.unshift(this.entry_org?.id!);
        var show = false;
        org_ids.forEach((org_id) => {
          if (show) {
            process_org_ids.push(org_id);
          }
          if (org_id === this.getEntryOrg()?.id!) {
            show = true;
          }
        });
      } else {
        org_ids.unshift(this.entry_org?.id!);
        process_org_ids = org_ids;
      }
      if (process_org_ids.length > 1) {
        breadcrumb_orgs = await Promise.all(
          process_org_ids.map(async (org_id) => {
            let result = undefined;
            if (org_id === org.org_id) {
              result = org;
            } else {
              result = await this.functionService?.getOrgBreadcrumb(
                token,
                org_id
              )!;
            }
            return result;
          })
        )!;
      } else {
        breadcrumb_orgs.push(org);
      }
    }
    if (breadcrumb_orgs && breadcrumb_orgs.length > 0) {
      this.breadcrumbs = breadcrumb_orgs;
    }
    return this.breadcrumbs;
  }

  async setBreadcrumbsDirectly(orgs: Org[]) {
    let token = this.getToken();
    // return this.breadcrumbs?.push(orgs);
    this.breadcrumbs = orgs;
  }

  getUsername() {
    return this.username;
  }

  getFirstLastname() {
    return this.firstname + " " + this.lastname;
  }
  getFirstName() {
    return this.firstname;
  }
  getLastName() {
    return this.lastname;
  }
  getUserId() {
    return this.user_id;
  }
  getAvatar() {
    var ret = this.firstname.substr(0, 1) + this.lastname.substr(0, 1);

    return ret.toUpperCase();
  }

  getLoggedInOrg() {
    return this.signed_in_org_id;
  }
  getToken() {
    const access_token = localStorage.getItem("access_token")!;
    return access_token;
  }
  async getAuthorisedToken() {
    try {
      const access_token = localStorage.getItem("access_token")!;
      if (access_token) {
        if (
          JSON.parse(atob(access_token.split(".")[1])).exp <=
          Math.floor(Date.now() / 1000 + 120)
        ) {
          var refresh_token = localStorage.getItem("refresh_token");
          if (refresh_token) {
            var authtokens = await this.functionService?.refreshAccessToken(
              refresh_token
            );
            if (authtokens) {
              if (authtokens.success) {
                localStorage.setItem("access_token", authtokens.access_token);
                this.isAuthorised = true;
                this.decodeAndStore(localStorage.getItem("access_token")!);
                return this.isAuthorised ? authtokens.access_token : "";
              } else {
                localStorage.setItem("access_token", "");
                this.isAuthorised = false;
                this.signOut();
                return "";
              }
            } else {
              localStorage.setItem("access_token", "");
              this.isAuthorised = false;
              this.signOut();
              return "";
            }
          } else {
            this.signOut();
            return "";
          }
        } else {
          this.isAuthorised = true;
          this.decodeAndStore(localStorage.getItem("access_token")!);
          return this.isAuthorised ? access_token : "";
        }
      } else {
        return "";
      }
    } catch (e) {
      return "";
    }
  }

  decodeAndStore(token: string): void {
    try {
      var decrypt: Token = this.parseJwt(token) as Token;
      this.roles = decrypt?.user_claims?.roles!; // add roles
      this.orgs = decrypt?.user_claims?.entry_orgs!;
      this.firstname = JSON.parse(
        atob(token.split(".")[1])
      ).identity.first_name;
      this.lastname = JSON.parse(atob(token.split(".")[1])).identity.last_name;
      this.user_id = JSON.parse(atob(token.split(".")[1])).identity.user_id;
      this.username = JSON.parse(atob(token.split(".")[1])).identity.username;
      this.signed_in_org_id = decrypt?.identity.entry_org_id;
      if (decrypt?.identity.entry_org_id !== 0) {
        this.entry_org = this.orgs.find(
          (x) => x.id === decrypt?.identity.entry_org_id
        );
      } else {
        for (let firstkey in decrypt.user_claims.entry_orgs) {
          this.entry_org = decrypt.user_claims.entry_orgs[firstkey];
          break;
        }
      }
    } catch (e) {}
  }
  parseJwt(token: string) {
    try {
      return JSON.parse(atob(token.split(".")[1]));
    } catch (e) {
      return null;
    }
  }
  constructor() {
    this.isAuthorised = false;
    this.functionService = null;
    this.message = "Sign In";
    this.signed_in_org_id = 0;
    this.orgs = [];
    this.roles = [];
    this.breadcrumbs = [];
    this.entry_org = undefined;
    this.firstname = "";
    this.lastname = "";
    this.username = "";
    this.user_id = 0;
    this.getAuthorisedToken();
    this.handler = null;
  }

  setFunctionService(functionService: FunctionService) {
    this.functionService = functionService;
  }
}
