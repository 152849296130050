import React, { useState } from "react";
import classes from "./ui.module.css";

interface ListItemProps {
  human_name: string;
  sensor_id_str: string;
  enabled: string;
  site_id: number;
  site_name: string;
  onClick: (sensor_id: number) => void;
  style?: React.CSSProperties;
  className?: string;
  sensor_id: number;
}

const CameraListItem: React.FC<ListItemProps> = ({
  human_name,
  sensor_id_str,
  enabled,
  site_id,
  site_name,
  onClick,
  style,
  className,
  sensor_id,
}) => {
  const handleClick = () => {
    console.debug(`Clicked on sensor with ID: ${sensor_id}`);
    onClick(sensor_id);
  };

  return (
    <li
      key={sensor_id_str}
      className={`${classes.item} ${className}`}
      onClick={handleClick}
      style={{
        cursor: "pointer",
        transition: "background-color 0.3s ease",
        ...style,
      }}
    >
      <div style={{ display: "flex", flexDirection: "column" }}>
        <p
          className={classes["item-wrapper"]}
          style={{ fontSize: 13, color: "#15325F" }}
        >
          human name: <span style={{ color: "grey" }}>{human_name}</span>
        </p>
        <p
          className={classes["item-wrapper"]}
          style={{ fontSize: 13, color: "#15325F" }}
        >
          site id: <span style={{ color: "grey" }}>{site_id}</span> | site name:{" "}
          <span style={{ color: "grey" }}>{site_name}</span> | sensor_id_str:{" "}
          <span style={{ color: "grey" }}>{sensor_id_str}</span> | enabled:{" "}
          <span style={{ color: "grey" }}>{enabled ? "true" : "false"}</span>
        </p>
      </div>
    </li>
  );
};

export default CameraListItem;
