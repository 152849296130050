import React, { useState, useEffect, useRef } from "react";
import { useStyles } from "../../styling/global/global";
import Button from "@material-ui/core/Button";
import { AppStore } from "../../../stores/AppStore";
import { getBaseUrl } from "../../HelperFunctions";
import FormControl from "@material-ui/core/FormControl";
import LinearProgress from "@material-ui/core/LinearProgress";
import CustomModal from "../../UI/CustomModal";
import CameraListItem from "../../UI/CameraListItem";
import SiteListItem from "../../UI/SiteListItem";
import { Site } from "../../../services/FunctionService";
import classic from "../../UI/ui.module.css";

import Grid from "@material-ui/core/Grid";
import SiteActivityStats from "../../UI/DashboardUI/SiteActivityStats";

import Overview from "../../UI/DashboardUI/Overview";
import Pill from "../../UI/DashboardUI/Pill";
import SplitButton from "../../UI/DashboardUI/SplitButton";
import TimeRange from "../../UI/DashboardUI/TimeRange";

import FalseAlarms from "../../UI/DashboardUI/FalseAlarms";
import EqualizerRoundedIcon from "@material-ui/icons/EqualizerRounded";
import TrendingUpRoundedIcon from "@material-ui/icons/TrendingUpRounded";
import GraphicEqRoundedIcon from "@material-ui/icons/GraphicEqRounded";
import TrendingDownRoundedIcon from "@material-ui/icons/TrendingDownRounded";

import TextField from "@material-ui/core/TextField";

import Autocomplete from "@material-ui/lab/Autocomplete";

import RightDrawer from "../../containers/RightDrawer/RightDrawer";

import {
  MainMenu,
  SiteHealth,
  Activity,
  SensorHealth,
  Org,
  HealthTimelineCount,
  SensorHealthTimelineCount,
} from "../../../services/FunctionService";

import { makeStyles } from "@material-ui/core/styles";

const useStyle = makeStyles({
  paper: {
    border: "2px solid #6D809D",
    color: "#15325F",
  },
});

let url = "../../../assets/deepalert_logo.png";

interface DashboardMainDrawerProps {
  setSelectedItem: (item: any, editing: boolean) => void;
  appStore: AppStore;
  currentOrg: Org;
  children: React.ReactNode;
  showFullModalHandler: (params: (number | string)[]) => void;
  siteHealthModal: (params: (number | string)[], site_id?: number) => void;
  setIntervalvalue: (x: number) => void;
  mainMenu: MainMenu;
}

interface GroupStats {
  success: boolean;
  total_enabled_orgs: number;
  total_enabled_sites: number;
  total_hubs?: number;
  total_enabled_sensors: number;
  total_reports?: number;
  total_alertconfigs?: number;
  enabled_org_ids: number[];
  total_new_sites: number;
  total_sites_disabled: number;
  total_new_sensors: number;
  total_sensors_disabled: number;
  site_stats: {
    inference_record_count: number;
    site_id: number;
    org_id?: number;
    site_name: string;
    alert_record_count: number;
  }[];
}

interface site_stats {
  inference_record_count: number;
  site_id: number;
  site_name: string;
  alert_record_count: number;
  group_id: number;
  enabled: boolean;
}

interface site_metric {
  name: string;
  value: string | number;
  item?: any;
}

const DashboardMainDrawer: React.FC<DashboardMainDrawerProps> = ({
  setSelectedItem,
  appStore,
  currentOrg,
  showFullModalHandler,
  siteHealthModal,
  setIntervalvalue,
  mainMenu,
  children,
}: DashboardMainDrawerProps) => {
  const classes = useStyles();

  const [orgslist, setOrgsList] = React.useState<any>([]);
  const [low_sites_list, setLowSitesList] = React.useState<site_metric[] | []>(
    []
  );
  const [high_sites_list, setHighSitesList] = React.useState<
    site_metric[] | []
  >([]);
  const [high_group_list, setHighGroupList] = React.useState<
    site_metric[] | []
  >([]);
  const [sites_health, setSiteHealth] = useState<
    { name: string; value: number | string }[]
  >([]);

  const [groupid, setGroupId] = React.useState(-1);
  const [loading, setLoading] = React.useState(true);
  const [newSensors, setNewSensors] = React.useState<unknown[]>([]);
  const [groupname, setGroupName] = React.useState(
    appStore?.authService.getEntryOrg()?.name
  );
  const [siteList, setSiteList] = React.useState<Site[]>([]);
  const [groupTreeName, setGroupTreeName] = React.useState("");
  const [message, setMessage] = React.useState("");
  const [groupStats, setGroupStats] = React.useState<GroupStats | undefined>(
    undefined
  );

  const handleCameraClick = async (sensor_id: number) => {
    try {
      console.debug(`Clicked on sensor with ID: ${sensor_id}`);

      let type = "sensor";
      if (type === "sensor") {
        const sensor: any = newSensors.find(
          (item: any) => item.id === sensor_id
        );
        console.debug("sensorListRef", newSensors);

        if (sensor) {
          console.debug(`Found sensor: ${JSON.stringify(sensor)}`);
          setLoading(true);
          const token = await appStore?.authService.getAuthorisedToken();
          if (token) {
            const rest_org = await appStore?.functionService.getOrgOneLevel(
              token!,
              sensor.org_id
            );
            if (rest_org) {
              if (rest_org.orgs === undefined) {
                rest_org.orgs = [];
              }
              await appStore?.authService.setBreadcrumbOrgs(rest_org);
              appStore?.helpers.setCurrentOrgId(sensor.org_id);
              let temp_sensor: any = sensor;
              temp_sensor.type = "sensor";
              temp_sensor.name = sensor.human_name;
              temp_sensor.sensor_id = sensor.id;
              console.debug("Temp sensor", temp_sensor);
              setSelectedItem(temp_sensor, false);
            }
          }
          setLoading(false);
        } else {
          console.error(`Sensor with ID ${sensor_id} not found`);
        }
      }
    } catch (error) {
      console.error("Error handling camera click:", error);
    } finally {
      setLoading(false);
    }
  };

  const [alarms, setAlarms] = React.useState<number>(0);
  const [triggers, setTriggers] = React.useState<number>(0);
  const [eventCount, setEventCount] = React.useState<number>(0);
  const [false_alarm, setFalseAlarm] = React.useState<number>(0);
  const [hours_saved, setHoursSaved] = React.useState<number | string>(0);

  // graphs
  const [sensorhealth, setSensorHealth] = useState<SensorHealth | undefined>(
    undefined
  );
  const [healthsites, setHealthSites] = useState<SiteHealth[] | undefined>(
    undefined
  );
  const [activity, setActivity] = useState<Activity | undefined | any>({
    alert_counts: [],
    inference_counts: [],
  });
  const [healthtimeline, setHealthTimeLine] = useState<
    HealthTimelineCount[] | undefined
  >(undefined);
  const [sensorhealthtimeline, setSensorHealthTimeline] = useState<
    SensorHealthTimelineCount[] | undefined
  >(undefined);
  const [interval, setInter] = useState<number>(200);
  const [newStartTime, setNewStartTime] = useState<number | null>(
    new Date(new Date().getTime() - 1000 * 60 * 60 * 24).getTime() / 1000
  );
  const [newEndTime, setNewEndTime] = useState<number | null>(
    new Date().getTime() / 1000
  );
  const [autoRefresh, setAutoRefresh] = useState(false);

  const [dateTitle, setDateTitle] = React.useState<string>("Last 24 hrs");
  const [statsType, setStatsType] = React.useState<string>("sensor");
  const [newSites, setNewSites] = React.useState<unknown[]>([]);
  const [disabledSites, setDisabledSites] = React.useState<unknown[]>([]);
  const [disabledSensors, setDisabledSensors] = React.useState<unknown[]>([]);
  const controllerRef = useRef<AbortController | null>();
  const siteListRef = useRef<Site[]>([]);
  const sensorListRef = useRef<any[]>([]);

  const [savedTime, setSavedTime] = useState({ startT: 0, endT: 0 });

  // end
  const [openTimeRange, setOpenTimeRange] = React.useState(false);

  const styler = useStyle();

  function convertIntervalToHours(interval: number) {
    let hours = 2;
    if (interval === 17) {
      hours = 4;
    }
    if (interval === 29) {
      hours = 8;
    }
    if (interval === 44) {
      hours = 12;
    }
    if (interval === 62) {
      hours = 24;
    }
    if (interval === 84) {
      hours = 48;
    }
    if (interval === 110) {
      hours = 168;
    }
    if (interval === 148) {
      hours = 336;
    }
    // new
    if (interval === 200) {
      hours = 672;
    }

    if (interval === 405) {
      hours = 1344;
    }

    if (interval === 520) {
      hours = 2016;
    }
    return hours;
  }

  const setIntervalChange = (hours: number) => {
    if (hours >= 4 && hours < 8) {
      setInter(17);
    }
    if (hours >= 8 && hours < 12) {
      setInter(29);
    }

    if (hours >= 12 && hours < 24) {
      setInter(44);
    }
    if (hours >= 24 && hours < 48) {
      setInter(62);
    }
    if (hours >= 48 && hours < 168) {
      setInter(84);
    }
    if (hours >= 168 && hours < 336) {
      setInter(110);
    }
    if (hours >= 336 && hours < 672) {
      setInter(148);
    }
    if (hours >= 672 && hours < 1344) {
      setInter(200);
    }
    if (hours >= 1344 && hours < 2016) {
      setInter(494);
    }
    if (hours >= 2016) {
      setInter(520);
    }
  };

  const fetchActivityStatsData = async () => {
    const token = await appStore?.authService.getAuthorisedToken();
    if (token) {
      let now = new Date();
      convertIntervalToHours(interval);
      let hours = 721;
      let endHours: undefined | number = undefined;

      if (newStartTime) {
        hours = Math.round((now.getTime() / 1000 - newStartTime) / 3600);
      }

      if (newEndTime) {
        endHours = Math.round((now.getTime() / 1000 - newEndTime) / 3600);
      }

      setIntervalChange(endHours ? endHours - hours : hours);

      const activity = await appStore?.functionService.fetchAlertActivityStats(
        token,
        groupid !== -1 ? groupid : appStore?.authService.getEntryOrg()?.id!,
        now.getTime() / 1000 - 3600 * hours,
        hours * 60,
        endHours ? now.getTime() / 1000 - 3600 * endHours! : undefined,
        // "[150]",
        null,
        null
      );
      if (activity.success) {
        setActivity((prev: Activity | any) => {
          return {
            ...prev,
            alert_counts: activity.msg.alert_counts,
          };
        });
      }

      const health = await appStore?.functionService.getHealthTimeLineForOrg(
        token,
        groupid !== -1 ? groupid : appStore?.authService.getEntryOrg()?.id!,
        now.getTime() / 1000 - 3600 * hours,
        hours * 60
      );
      if (health.success) {
        setHealthTimeLine(health.msg);
      }
    }
  };

  const fetchActivityStatsDataInf = async () => {
    const token = await appStore?.authService.getAuthorisedToken();
    if (token) {
      let now = new Date();
      convertIntervalToHours(interval);
      let hours = 721;
      let endHours: undefined | number = undefined;

      if (newStartTime) {
        hours = Math.round((now.getTime() / 1000 - newStartTime) / 3600);
      }

      if (newEndTime) {
        endHours = Math.round((now.getTime() / 1000 - newEndTime) / 3600);
      }

      setIntervalChange(endHours ? endHours - hours : hours);

      const activity =
        await appStore?.functionService.fetchAInferenceActivityStats(
          token,
          groupid !== -1 ? groupid : appStore?.authService.getEntryOrg()?.id!,
          now.getTime() / 1000 - 3600 * hours,
          hours * 60,
          endHours ? now.getTime() / 1000 - 3600 * endHours! : undefined,
          // "[150]",
          null,
          null
        );
      if (activity.success) {
        setActivity((prev: Activity | any) => {
          return {
            ...prev,
            inference_counts: activity.msg.inference_counts,
          };
        });
      }

      const health = await appStore?.functionService.getHealthTimeLineForOrg(
        token,
        groupid !== -1 ? groupid : appStore?.authService.getEntryOrg()?.id!,
        now.getTime() / 1000 - 3600 * hours,
        hours * 60
      );
      if (health.success) {
        setHealthTimeLine(health.msg);
      }
    }
  };

  //Interval change on graph on right drawer
  const handleIntervalChange = (newinterval: number) => {
    setInter(newinterval);
  };

  useEffect(() => {
    setActivity({ alert_counts: [], inference_counts: [] });
    fetchActivityStatsData();
    fetchActivityStatsDataInf();
    const refresh = setInterval(() => {
      if (autoRefresh) {
        fetchActivityStatsData();
        fetchActivityStatsDataInf();
      }
    }, 1000 * 60 * 1);
    return () => clearInterval(refresh);
  }, [groupid, interval, newStartTime, newEndTime, autoRefresh]);

  const fetchOrgsData = async () => {
    setLoading(true);
    var token = await appStore?.authService.getAuthorisedToken();
    var baseUrl = getBaseUrl();
    if (token) {
      try {
        const request = JSON.stringify({
          enabled: true,
          paginate: false,
          verbose: false,
          incl_tree_names: true,
        });

        const res = fetch(baseUrl + "/list_orgs", {
          method: "post",
          headers: {
            Accept: "application/json",
            Authorization: "Bearer " + token,
            "Content-Type": "application/json",
          },
          body: request,
        });
        const result = await (await res).json();
        const orglist = result;
        if (orglist && orglist.success) {
          let orgz = orglist?.orgs;
          orgz.sort(function (a: any, b: any) {
            return a.org_tree_names - b.org_tree_names;
          });
          orgz.sort(function (a: any, b: any) {
            return a.org_tree_names.length - b.org_tree_names.length;
          });
          setOrgsList(orgz);
          setLoading(false);
        }
      } catch (err) {
        return err;
      }
    }
  };

  // Fetch Organizations
  useEffect(() => {
    fetchOrgsData();
  }, [groupid]);

  // Fetch sites
  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      var token = await appStore?.authService.getAuthorisedToken();
      var baseUrl = getBaseUrl();
      if (token) {
        try {
          const request = JSON.stringify({
            enabled: true,
            top_org_id: appStore.authService.getEntryOrg()?.id!,
            paginate: false,
            top_org_only: false,
            verbose: true,
          });

          const res = fetch(baseUrl + "/list_sites", {
            method: "post",
            headers: {
              Accept: "application/json",
              Authorization: "Bearer " + token,
              "Content-Type": "application/json",
            },
            body: request,
          });
          const result = await (await res).json();
          if (result && result.success) {
            let sites: Site[] = result?.sites;
            sites.sort(function (a: Site, b: Site) {
              if (b.site_name.toLowerCase() > a.site_name.toLowerCase()) {
                return -1;
              }
              if (b.site_name.toLowerCase() < a.site_name.toLowerCase()) {
                return 1;
              }
              return 0;
            });

            setSiteList(sites);
            siteListRef.current = sites;
          }
          setLoading(false);
        } catch (err) {
          return err;
        } finally {
          setLoading(false);
        }
      }
    };
    fetchData();
  }, []);

  const applyTime = (startTime: number, endTime: number) => {
    setNewStartTime(startTime);
    setNewEndTime(endTime);
    setOpenTimeRange(false);
  };

  const handleItemSelection = async (value: any) => {
    let type = "site";
    if (typeof value === "number") {
      type = "org";
    }

    if (type === "site") {
      value = siteListRef.current.find(
        (item) => item.site_id === value.site_id
      );
    }
    if (value) {
      setLoading(true);
      const token = await appStore?.authService.getAuthorisedToken();
      if (token) {
        var rest_org = await appStore?.functionService.getOrgOneLevel(
          token!,
          value.org_id
        );
        if (rest_org) {
          if (rest_org.orgs == undefined) {
            rest_org.orgs = [];
          }
          await appStore?.authService.setBreadcrumbOrgs(rest_org);
          appStore?.helpers.setCurrentOrgId(value.org_id);
          let temp_site: Site = value;
          temp_site.type = "site";
          temp_site.name = value.site_name;
          setSelectedItem(temp_site, false);
        }
      }
      setLoading(false);
      // closeModal();
    }
  };

  const fetchStatsData = async () => {
    if (controllerRef.current) {
      controllerRef.current.abort();
    }
    const controller = new AbortController();
    controllerRef.current = controller;

    var token = await appStore?.authService.getAuthorisedToken();
    var baseUrl = getBaseUrl();
    if (token) {
      try {
        const request = JSON.stringify({
          enabled: true,
          verbose: true,
          top_org_id:
            groupid !== -1 ? groupid : appStore?.authService.getEntryOrg()?.id!,
          start_day: 1,
          start_time_filter: newStartTime,
          end_time_filter: newEndTime,
        });

        const res = fetch(baseUrl + "/dashboard_stats", {
          method: "post",
          headers: {
            Accept: "application/json",
            Authorization: "Bearer " + token,
            "Content-Type": "application/json",
          },
          body: request,
          signal: controllerRef.current?.signal,
        });
        const result = await (await res).json();
        const statsData = result;
        if (statsData && statsData.success) {
          let stats = statsData;

          setNewSites(statsData.new_sites);
          setDisabledSites(statsData.disabled_sites);
          setNewSensors(statsData.new_sensors);
          console.debug("State data  ==> ", statsData);
          setDisabledSensors(statsData.disabled_sensors);

          let total_triggers = statsData.total_inferences;
          let total_alarms = statsData.total_alerts;
          let total_events = statsData.total_events;

          // let total_hours_saved =
          //   ((total_triggers - total_alarms) * 10) / (60 * 60);
          let x = total_triggers / total_events + 5;
          let total_hours_saved =
            (x * (total_events - total_alarms)) / (60 * 60);

          // let calc_false_alarm = (1 - total_alarms / total_triggers) * 100;
          let calc_false_alarm =
            ((total_events - total_alarms) / total_events) * 100;

          if (total_triggers === 0) {
            total_alarms = 0;
            total_triggers = 0;
            total_hours_saved = 0;
            calc_false_alarm = 0;
          }
          setEventCount(total_events);
          setAlarms(total_alarms);
          setTriggers(total_triggers);
          setHoursSaved(parseInt(total_hours_saved.toFixed(2)));
          setFalseAlarm(parseInt(calc_false_alarm.toFixed(2)));
          setGroupStats(stats);

          let lowAlertingSites = Object.keys(statsData.alert_activity_asc)
            .map((item: string) => {
              return {
                name: statsData.alert_site_dict[item].name,
                value: statsData.alert_activity_asc[item],
                item: statsData.alert_site_dict[item],
                type: "site",
              };
            })
            .sort(function (a: any, b: any) {
              return a.value - b.value;
            });

          const sensorData = statsData.alert_sensor_list;

          let highAlertingSites = Object.keys(statsData.alert_activity_desc)
            .map((item: string) => {
              const sensors = sensorData.filter(
                (sensor: any) => sensor.site_id == item
              );

              const highAlertingSitesDetails = (
                <table>
                  <tr>
                    <th>Camera Name</th>
                    <th>Alert Count</th>
                  </tr>
                  {sensors.map((i: any) => (
                    <tr>
                      <td>{i?.sensor_id_str}</td>
                      <td>{i?.count}</td>
                    </tr>
                  ))}
                </table>
              );
              return {
                name: statsData.alert_site_dict[item].name,
                value: statsData.alert_activity_desc[item],
                item: statsData.alert_site_dict[item],
                type: "site",
                details: highAlertingSitesDetails,
              };
            })
            .sort(function (a: any, b: any) {
              return b.value - a.value;
            });

          let highAlertingOrg = Object.keys(statsData.org_alert_activity_desc)
            .map((item: string) => {
              return {
                name: statsData.org_alert_site_dict[item].name,
                value: statsData.org_alert_activity_desc[item],
                item: statsData.org_alert_site_dict[item],
                type: "org",
              };
            })
            .sort(function (a: any, b: any) {
              return b.value - a.value;
            });

          let top_ten_groups = highAlertingOrg.slice(0, 10);

          setHighGroupList(top_ten_groups);
          setHighSitesList(highAlertingSites);
          setLowSitesList(lowAlertingSites);
        } else {
          console.debug("Stats data failed: ", statsData);
        }
        controllerRef.current = null;
        setLoading(false);
      } catch (err) {
        return err;
      }
    }
  };

  const fetchSites = async () => {
    try {
      setLoading(true);
      var token = await appStore?.authService.getAuthorisedToken();
      if (token) {
        const request = JSON.stringify({
          top_org_id:
            groupid !== -1 ? groupid : appStore?.authService.getEntryOrg()?.id!,
        });
        const response = await fetch(getBaseUrl() + "/site_health_history", {
          method: "post",
          headers: {
            Accept: "application/json",
            Authorization: "Bearer " + token,
            "Content-Type": "application/json",
          },
          body: request,
        });

        if (!response.ok) {
          setMessage("Error fetching data, please try again");
        } else {
          const result = (await (await response).json()) as any;
          let transformedData = result.msg.map((site: any) => {
            let org_tree_ids = result.site_list.find(
              (item: any) => item.site_id === site.site_id
            ).org_tree;
            let data_origin = result.site_list.find(
              (item: any) => item.site_id === site.site_id
            ).data_origin;
            if (site.overall_indicator === "ok") {
              return {
                site_id: site.site_id,
                site_name: site.site_name,
                hub_id_str: site.hub_id_str,
                overall_indicator: "Healthy",
                org_tree: org_tree_ids,
                data_origin: data_origin,
                type: "site",
              };
            }
            if (site.overall_indicator === "warning") {
              return {
                site_id: site.site_id,
                site_name: site.site_name,
                hub_id_str: site.hub_id_str,
                overall_indicator: "Degraded",
                org_tree: org_tree_ids,
                data_origin: data_origin,
                type: "site",
              };
            }
            if (site.overall_indicator === "error") {
              return {
                site_id: site.site_id,
                site_name: site.site_name,
                hub_id_str: site.hub_id_str,
                overall_indicator: "unHealthy",
                org_tree: org_tree_ids,
                data_origin: data_origin,
                type: "site",
              };
            }
            if (site.overall_indicator === "unknown") {
              return {
                site_id: site.site_id,
                site_name: site.site_name,
                hub_id_str: site.hub_id_str,
                overall_indicator: "unKnown",
                org_tree: org_tree_ids,
                data_origin: data_origin,
                type: "site",
              };
            }
          });

          let unKnown_count = 0;
          let unHealthy_count = 0;
          let degraded_count = 0;
          let healthy_count = 0;

          let unKnown_item = [];
          let unHealthy_item = [];
          let degraded_item = [];
          let healthy_item = [];

          for (let x of transformedData) {
            if (x.overall_indicator === "unKnown") {
              unKnown_count++;
              unKnown_item.push(x);
            }
            if (x.overall_indicator === "Degraded") {
              degraded_count++;
              degraded_item.push(x);
            }
            if (x.overall_indicator === "unHealthy") {
              unHealthy_count++;
              unHealthy_item.push(x);
            }
            if (x.overall_indicator === "Healthy") {
              healthy_count++;
              healthy_item.push(x);
            }
          }

          let sites_health_list = [
            {
              name: "Unhealthy",
              value: unHealthy_count,
              type: "site_health",
              item: unHealthy_item,
              details:
                unHealthy_item.length === 0 ? null : (
                  <table>
                    <th>Site Name</th>
                    <th>ID</th>
                    {unHealthy_item?.map((item) => (
                      <tr
                        style={{ cursor: "pointer" }}
                        onClick={() => handleItemSelection(item)}
                      >
                        <td>{item.site_name}</td>
                        <td>{item.site_id}</td>
                      </tr>
                    ))}
                  </table>
                ),
            },
            {
              name: "Degraded",
              value: degraded_count,
              type: "site_health",
              item: degraded_item,
              details:
                degraded_item.length === 0 ? null : (
                  <table>
                    <th>Site Name</th>
                    <th>ID</th>
                    {degraded_item?.map((item) => (
                      <tr
                        style={{ cursor: "pointer" }}
                        onClick={() => handleItemSelection(item)}
                      >
                        <td>{item.site_name}</td>
                        <td>{item.site_id}</td>
                      </tr>
                    ))}
                  </table>
                ),
            },
            {
              name: "Healthy",
              value: healthy_count,
              type: "site_health",
              item: healthy_item,
              details:
                healthy_item.length === 0 ? null : (
                  <table>
                    <th>Site Name</th>
                    <th>ID</th>
                    {healthy_item?.map((item) => (
                      <tr
                        style={{ cursor: "pointer" }}
                        onClick={() => handleItemSelection(item)}
                      >
                        <td>{item.site_name}</td>
                        <td>{item.site_id}</td>
                      </tr>
                    ))}
                  </table>
                ),
            },
            {
              name: "Unknown",
              value: unKnown_count,
              type: "site_health",
              item: unKnown_item,
              details:
                unKnown_item.length === 0 ? null : (
                  <table>
                    <th>Site Name</th>
                    <th>ID</th>
                    {unKnown_item?.map((item) => (
                      <tr
                        style={{ cursor: "pointer" }}
                        onClick={() => handleItemSelection(item)}
                      >
                        <td>{item.site_name}</td>
                        <td>{item.site_id}</td>
                      </tr>
                    ))}
                  </table>
                ),
            },
          ];

          setSiteHealth(sites_health_list);
        }

        setLoading(false);
      }

      // setLoading(false);
    } catch (err) {
    } finally {
      setLoading(false);
    }
  };

  const isActive = true;

  // Fetch Organizations
  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      await fetchStatsData();
      setLoading(false);
    };
    fetchData();
  }, [groupid, newStartTime, newEndTime]);

  // Fetch Organizations for Auto refresh
  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      await fetchStatsData();
      setLoading(false);
    };

    const refresh = setInterval(() => {
      if (autoRefresh) {
        fetchData();
      }
    }, 1000 * 60 * 2);
    return () => clearInterval(refresh);
  }, [autoRefresh]);

  // Fetch Sites
  useEffect(() => {
    fetchSites();
  }, [groupid]);

  const refreshHandler = () => {
    setLoading(true);
    setGroupStats(undefined);
    setHoursSaved(0);
    setAlarms(0);
    setTriggers(0);
    setFalseAlarm(0);
    setHighSitesList([]);
    setLowSitesList([]);
    setHighGroupList([]);
    fetchStatsData();
    fetchActivityStatsData();
    fetchActivityStatsDataInf();
    setOpenTimeRange(false);
  };

  const handleChangeAuto = (value: any) => {
    if (!value || +value.org_id === groupid) {
      return;
    }
    setGroupStats(undefined);
    setHoursSaved(0);
    setAlarms(0);
    setTriggers(0);
    setFalseAlarm(0);
    setHighSitesList([]);
    setLowSitesList([]);
    setHighGroupList([]);
    const new_group_id = +value.org_id;
    setGroupId(new_group_id);
    let group = orgslist.find((x: any) => x.org_id === value.org_id);
    if (group) {
      let tree = group.org_tree_names!.map((item: any) => `   /   ${item} `);
      let treeString = tree.toString();
      const rpl = treeString.replace(/,/g, "");
      setGroupTreeName(rpl);

      setGroupName(group.org_name);
    }
  };

  return (
    <div className={classes.root_dashboard} style={{ width: 1380 }}>
      <CustomModal
        closeHandler={() => {
          setOpenTimeRange(false);
        }}
        open={openTimeRange}
      >
        <TimeRange
          applyChangesHandler={applyTime}
          backDateHandler={(e: number) => {
            setNewStartTime(e);
            setNewEndTime(null);
            setOpenTimeRange(false);
          }}
          savedTime={savedTime}
          setSavedTime={(e) => {
            setSavedTime(e);
          }}
          title={dateTitle}
          dateTitle={(e) => setDateTitle(e)}
          closeModal={() => setOpenTimeRange(false)}
        />
      </CustomModal>
      <CustomModal
        closeHandler={() => {
          setStatsType("");
        }}
        open={
          statsType == "" && !!newSites.length
            ? false
            : statsType == "New sites" && !!newSites.length
            ? true
            : statsType == "Disabled sites" && !!disabledSites.length
            ? true
            : statsType == "Disabled sensors" && !!disabledSensors.length
            ? true
            : statsType == "New sensors" && !!newSensors.length
            ? true
            : false
        }
      >
        {statsType == "New sites" && !!newSites.length && (
          <ul
            className={classic["items-wrapper"]}
            style={{ marginLeft: 0, paddingLeft: 0 }}
          >
            {newSites.map((stats: any) => {
              return (
                <SiteListItem
                  site_name={stats.site_name}
                  org_id={stats.org_id}
                  enabled={stats.enabled}
                  site_id={stats.site_id}
                  sensor_count={stats.sensor_count}
                />
              );
            })}
          </ul>
        )}
        {statsType == "Disabled sites" && !!disabledSites.length && (
          <ul
            className={classic["items-wrapper"]}
            style={{ marginLeft: 0, paddingLeft: 0 }}
          >
            {disabledSites.map((stats: any) => {
              return (
                <SiteListItem
                  site_name={stats.site_name}
                  org_id={stats.org_id}
                  enabled={stats.enabled}
                  site_id={stats.site_id}
                  sensor_count={stats.sensor_count}
                />
              );
            })}
          </ul>
        )}
        {statsType == "Disabled sensors" && !!disabledSensors.length && (
          <ul
            className={classic["items-wrapper"]}
            style={{ marginLeft: 0, paddingLeft: 0 }}
          >
            {disabledSensors.map((stats: any) => {
              return (
                <CameraListItem
                  human_name={stats.human_name}
                  sensor_id_str={stats.sensor_id_str}
                  enabled={stats.enabled}
                  site_id={stats.site_id}
                  site_name={stats.name}
                  sensor_id={stats.id}
                  onClick={() => handleItemSelection(stats)}
                />
              );
            })}
          </ul>
        )}
        {statsType == "New sensors" && !!newSensors.length && (
          <ul
            className={classic["items-wrapper"]}
            style={{ marginLeft: 0, paddingLeft: 0 }}
          >
            {newSensors.map((stats: any) => {
              return (
                <CameraListItem
                  human_name={stats.human_name}
                  sensor_id_str={stats.sensor_id_str}
                  enabled={stats.enabled}
                  site_id={stats.sensor}
                  site_name={stats.name}
                  sensor_id={stats.id}
                  onClick={() => handleItemSelection(stats)}
                  style={{
                    cursor: "pointer",
                    backgroundColor: isActive ? "" : "transparent",
                    transition: "background-color 0.3s ease",
                  }}
                />
              );
            })}
          </ul>
        )}
      </CustomModal>
      <Grid container spacing={1}>
        <Grid item xs={12}>
          <div
            style={{ paddingLeft: 20, display: "flex", alignItems: "center" }}
          >
            <div style={{ marginRight: 20 }}>
              <Button
                variant="outlined"
                color="primary"
                component="span"
                onClick={() => setOpenTimeRange(true)}
              >
                {dateTitle || ""}
              </Button>
            </div>
            <div>
              <SplitButton
                onClick={() => {
                  refreshHandler();
                }}
                autoRefresh={(e) => {
                  setAutoRefresh(e);
                }}
              />
            </div>
          </div>
        </Grid>
        <Grid item xs={12}>
          <div
            style={{
              borderRadius: 20,
              display: "flex",
              justifyContent: " space-between",
              alignItems: "center",
            }}
          >
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                boxShadow: "0 2px 10px rgba(0, 0, 0, 0.4)",
                padding: 14,
                margin: 18,

                height: "140px",
                background: "white",
                borderRadius: 15,
                color: "white",
              }}
            >
              <div>
                <FormControl
                  className={classes.form_component_medium2}
                  variant="outlined"
                  fullWidth={true}
                >
                  {!loading ? (
                    <Autocomplete
                      id="combo-box-demo"
                      className={classes.form_component_medium}
                      options={orgslist}
                      onChange={(event: React.ChangeEvent<any>, value: any) => {
                        handleChangeAuto(value);
                        window.scrollTo({ top: 0, behavior: "smooth" });
                      }}
                      getOptionLabel={(option) => {
                        let tree = option.org_tree_names!.map(
                          (item: any) => `   /   ${item} `
                        );
                        let treeString = tree.toString();
                        const rpl = treeString.replace(/,/g, "");
                        return `${rpl}`;
                      }}
                      style={{ width: 400, color: "#6D809D" }}
                      classes={{ paper: styler.paper }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label={
                            <span
                              className={classes.bold_text}
                              style={{ color: "#15325F" }}
                            >
                              {groupname ? groupname : "Please Select Group"}
                            </span>
                          }
                          variant="outlined"
                          value={groupid}
                          className={classes.bold_text}
                        />
                      )}
                    />
                  ) : (
                    <div style={{ width: 400, paddingTop: 20 }}>
                      <br />
                      <LinearProgress />
                    </div>
                  )}
                </FormControl>
              </div>
            </div>
            <FalseAlarms
              title={"FILTER PERCENTAGE"}
              subtitle={"95%"}
              width={610}
              primaryBgColor={"#F32424"}
              secondaryBgColor={
                "linear-gradient(90deg, rgb(71, 115, 184) 0%, rgba(57, 72, 158, 0.98) 100%)"
              }
              hours_saved={hours_saved ? hours_saved.toString() : "--"}
              alarms={alarms ? alarms.toString() : "--"}
              triggers={triggers ? triggers.toString() : "--"}
              false_alarm={false_alarm}
              eventCount={eventCount}
            />
            <Overview
              title={"CURRENT OVERVIEW"}
              cameras={
                groupStats?.total_enabled_sensors
                  ? groupStats?.total_enabled_sensors
                  : "--"
              }
              sites={
                groupStats?.total_enabled_sites
                  ? groupStats?.total_enabled_sites
                  : "--"
              }
              groups={
                groupStats?.total_enabled_orgs
                  ? groupStats?.total_enabled_orgs - 1
                  : "--"
              }
              subtitle={url}
              width={580}
              primaryBgColor={
                "linear-gradient(90deg, rgb(71, 115, 184) 0%, rgba(57, 72, 158, 0.98) 100%)"
              }
              secondaryBgColor={"#E8F9FD"}
            />
          </div>
        </Grid>
        <Grid item xs={6}>
          <div style={{ paddingLeft: 20 }}>
            <div
              style={{
                boxShadow: "0 2px 10px rgba(0, 0, 0, 0.4)",
                background: "white",
                borderRadius: 8,
                color: "white",
                width: "100%",
              }}
            >
              <div
                style={{
                  width: 650,
                  alignContent: "center",
                  paddingTop: 40,
                  paddingBottom: 50,
                  marginLeft: 50,
                  color: "grey",
                }}
              >
                {!loading ? (
                  <RightDrawer
                    isDashboard={true}
                    interval={interval}
                    mid={
                      groupid !== -1
                        ? groupid
                        : appStore?.authService.getEntryOrg()?.id!
                    }
                    type={"top_org_id"}
                    activity={activity}
                    sensorhealthtimeline={sensorhealthtimeline}
                    healthtimeline={healthtimeline}
                    health={healthsites}
                    sensorhealth={sensorhealth}
                    mainMenu={mainMenu}
                    showFullModalHandler={showFullModalHandler}
                    siteHealthModal={siteHealthModal}
                    setIntervalvalue={handleIntervalChange}
                  ></RightDrawer>
                ) : (
                  <div
                    style={{
                      width: 600,
                      height: 215,
                      padding: 20,
                      paddingLeft: 15,
                      paddingTop: 100,
                    }}
                  >
                    {/* <br />
                    <br />
                    <br />
                    <br /> */}
                    <LinearProgress />
                  </div>
                )}
              </div>
            </div>

            <div
              style={{
                boxShadow: "0 2px 10px rgba(0, 0, 0, 0.4)",
                background: "white",
                borderRadius: 8,
                height: 300,
                marginTop: 20,
                padding: 20,
              }}
            >
              <br></br>
              <br></br>
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "space-around",
                    padding: 20,
                  }}
                >
                  <span
                    onClick={() => {
                      if (!groupStats?.total_new_sensors) {
                        return;
                      }
                      setStatsType("New sensors");
                    }}
                  >
                    <Pill
                      name={"New cameras"}
                      value={
                        groupStats?.total_new_sensors
                          ? groupStats?.total_new_sensors
                          : "--"
                      }
                    />
                  </span>
                  <br></br>
                  <br></br>
                  <span
                    onClick={() => {
                      if (!groupStats?.total_sensors_disabled) {
                        return;
                      }
                      setStatsType("Disabled sensors");
                    }}
                  >
                    <Pill
                      name={"Disabled cameras"}
                      value={
                        groupStats?.total_sensors_disabled
                          ? groupStats?.total_sensors_disabled
                          : "--"
                      }
                    />
                  </span>
                </div>
                <div
                  style={{
                    borderLeft: "0.9px solid #ccce",
                    height: 190,
                  }}
                ></div>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    padding: 20,
                    justifyContent: "space-around",
                  }}
                >
                  <span
                    onClick={() => {
                      if (!groupStats?.total_new_sites) {
                        return;
                      }
                      setStatsType("New sites");
                    }}
                  >
                    <Pill
                      name={"New sites"}
                      value={
                        groupStats?.total_new_sites
                          ? groupStats?.total_new_sites
                          : "--"
                      }
                    />
                  </span>
                  <br></br>
                  <br></br>
                  <span
                    onClick={() => {
                      if (!groupStats?.total_sites_disabled) {
                        return;
                      }
                      setStatsType("Disabled sites");
                    }}
                  >
                    <Pill
                      name={"Disabled sites"}
                      value={
                        groupStats?.total_sites_disabled
                          ? groupStats?.total_sites_disabled
                          : "--"
                      }
                    />
                  </span>
                </div>
              </div>
            </div>
          </div>
        </Grid>
        <Grid item xs={5}>
          <div
            style={{
              marginLeft: 20,
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              width: 600,
            }}
          >
            <SiteActivityStats
              title={"High Alerting Groups"}
              stats={high_group_list}
              isNotLoading={loading}
              handleItemSelection={handleItemSelection}
            >
              <EqualizerRoundedIcon fontSize="medium" />
            </SiteActivityStats>
            <SiteActivityStats
              title={"High Alerting Sites"}
              stats={high_sites_list}
              isNotLoading={loading}
              handleItemSelection={handleItemSelection}
            >
              <TrendingUpRoundedIcon fontSize="medium" />
            </SiteActivityStats>
          </div>
          <div
            style={{
              marginLeft: 20,
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              width: 600,
            }}
          >
            <SiteActivityStats
              title={"Current Site Health"}
              stats={sites_health}
              isNotLoading={loading}
              handleItemSelection={handleItemSelection}
            >
              <GraphicEqRoundedIcon fontSize="medium" />
            </SiteActivityStats>
            <SiteActivityStats
              title={"Low Alerting Sites"}
              stats={low_sites_list}
              isNotLoading={loading}
              handleItemSelection={handleItemSelection}
            >
              <TrendingDownRoundedIcon fontSize="medium" />
            </SiteActivityStats>
          </div>
          <br />
        </Grid>
      </Grid>
    </div>
  );
};
export default DashboardMainDrawer;
