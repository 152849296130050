import React, { useState, useEffect } from "react";
import DialogTitle from "@material-ui/core/DialogTitle";
import Dialog from "@material-ui/core/Dialog";
import Button from "@material-ui/core/Button";
import CheckIcon from "@material-ui/icons/Check";
import CloseIcon from "@material-ui/icons/Close";
import InfoIcon from "@material-ui/icons/Info";
import { AppStore } from "../../../stores/AppStore";
import {
  Hub,
  StatusResult,
  HubTokenDetail,
  HubActivationToken,
  GetMessageToken,
} from "../../../services/FunctionService";
import { Typography, Divider } from "@material-ui/core";
import { useStyles } from "../../styling/global/global";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import PriorityHighIcon from "@material-ui/icons/PriorityHigh";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import { withStyles, Theme, createStyles } from "@material-ui/core/styles";
interface StatusHubModalProps {
  appStore?: AppStore;
  onClose: () => void;
  hub: Hub;
  open: boolean;
  updateHub(): void;
}

const StatusHubModal: React.FC<StatusHubModalProps> = ({
  onClose,
  open,
  updateHub,
  hub,
  appStore,
}: StatusHubModalProps) => {
  const classes = useStyles();
  const [message, setMessage] = React.useState<string | undefined>(undefined);
  const [status, setStatus] = useState<StatusResult | undefined>(undefined);
  const [reissue, setReIssue] = useState<boolean>(false);
  const [reissuemessage, setReIssueMessage] = useState<boolean>(false);
  const [messagetokendetail, setMessageTokenDetail] = useState<
    GetMessageToken | undefined
  >(undefined);
  const [refresh, setRefresh] = useState<boolean>(false);
  const [restart, setRestart] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(true);
  const [tokendetail, setTokenDetail] = useState<
    HubTokenDetail | undefined | any
  >(undefined);
  const [newactivationtoken, setNewActivationToken] = useState<any>(undefined);
  const [onscreenloading, setOnScreenLoading] = useState<boolean>(false);
  useEffect(() => {
    const fetchData = async () => {
      var token = await appStore?.authService.getAuthorisedToken();
      if (token && hub && open) {
        var status = await appStore?.functionService.getHubStatus(
          token,
          hub.hub_id
        );
        setStatus(status);
      }
    };
    fetchData();
  }, [open]);
  useEffect(() => {
    const fetchData = async () => {
      var token = await appStore?.authService.getAuthorisedToken();
      if (token && hub && open) {
        var detail = await appStore?.functionService.getHubsTokenDetail(
          token,
          hub.hub_id_str
        );
        setTokenDetail(detail);
      }
    };
    fetchData();
  }, [open]);
  useEffect(() => {
    const fetchData = async () => {
      var token = await appStore?.authService.getAuthorisedToken();
      if (token && hub && reissue) {
        var newtoken = await appStore?.functionService.getNewHubActivationToken(
          token,
          hub.hub_id_str,
          true
        );
        setNewActivationToken(newtoken);
        setOnScreenLoading(false);
        setReIssue(false);
      }
    };
    fetchData();
  }, [reissue]);
  useEffect(() => {
    const fetchData = async () => {
      var token = await appStore?.authService.getAuthorisedToken();
      if (token && hub && reissuemessage) {
        var newtoken = await appStore?.functionService.getHubMessageToken(
          token,
          hub.hub_id_str
        );
        setMessageTokenDetail(newtoken);
        setOnScreenLoading(false);
        setReIssueMessage(false);
      }
    };
    fetchData();
  }, [reissuemessage]);
  useEffect(() => {
    const fetchData = async () => {
      const token = await appStore?.authService.getAuthorisedToken();
      if (token && hub && refresh) {
        const hub_force = await appStore?.functionService.forceHubUpdate(
          token,
          hub.hub_id
        );
        setOnScreenLoading(false);
        if (hub_force?.success) {
          setMessage("Hub refresh instruction sent successfully");
        } else {
          setMessage("Hub refresh instruction failed: " + hub_force?.msg);
        }
        const detail = await appStore?.functionService.getHubsTokenDetail(
          token,
          hub.hub_id_str
        );
        if (detail?.success) {
          setTokenDetail(detail);
        }
        setRefresh(false);
      }
    };
    fetchData();
  }, [refresh]);
  useEffect(() => {
    const fetchData = async () => {
      const token = await appStore?.authService.getAuthorisedToken();
      if (token && hub && restart) {
        const hub_restart = await appStore?.functionService.forceHubRestart(
          token,
          hub.hub_id
        );
        setOnScreenLoading(false);
        if (hub_restart?.success) {
          setMessage("Hub restart instruction sent successfully");
        } else {
          if (hub_restart?.msg && hub_restart?.msg.indexOf("timed out") >= 0) {
            setMessage("Hub restart instruction sent");
          } else {
            setMessage("Hub restart instruction failed: " + hub_restart?.msg);
          }
        }
        setRestart(false);
      }
    };
    fetchData();
  }, [restart]);
  const StyledTableRow = withStyles((theme: Theme) =>
    createStyles({
      root: {
        "&:nth-of-type(odd)": {
          backgroundColor: theme.palette.action.hover,
        },
      },
    })
  )(TableRow);
  const StyledTableCell = withStyles((theme: Theme) =>
    createStyles({
      head: {
        backgroundColor: theme.palette.common.black,
        color: theme.palette.common.white,
      },
      body: {
        fontFamily: "Open Sans",
        fontStyle: "normal",
        fontWeight: "bold",
        fontSize: 16,
        color: "#15325F",
      },
    })
  )(TableCell);
  const handleClose = () => {
    setStatus(undefined);
    setNewActivationToken(undefined);
    setTokenDetail(undefined);
    setMessageTokenDetail(undefined);
    setReIssue(false);
    setReIssueMessage(false);
    setMessage("");
    setLoading(true);
    onClose();
  };
  const handleReIssue = () => {
    setOnScreenLoading(true);
    setNewActivationToken(undefined);
    setTokenDetail(undefined);
    setReIssue(true);
  };
  const handleReIssueMessage = () => {
    setOnScreenLoading(true);
    setNewActivationToken(undefined);
    setTokenDetail(undefined);
    setReIssueMessage(true);
  };
  const handleRefresh = () => {
    setMessage("");
    setOnScreenLoading(true);
    setRefresh(true);
  };
  const handleRestart = () => {
    setMessage("");
    setOnScreenLoading(true);
    setRestart(true);
  };
  if (status && tokendetail && loading) {
    setLoading(false);
  }
  var reissuebutton: React.ReactNode[] = [];
  var rolebuttons: React.ReactNode[] = [];
  if (
    appStore &&
    appStore.authService &&
    appStore.authService.getRoles() &&
    (appStore.authService.getRoles().indexOf("sys_admin") >= 0 ||
      appStore.authService.getRoles().indexOf("support") >= 0)
  ) {
    rolebuttons.push(
      <>
        <Button
          disabled={onscreenloading}
          className={classes.save_button}
          variant="contained"
          onClick={handleReIssue}
        >
          <span className={classes.bold_text}>Reissue Activation Token</span>
        </Button>
        <Button
          disabled={onscreenloading}
          className={classes.save_button}
          variant="contained"
          onClick={handleReIssueMessage}
        >
          <span className={classes.bold_text}>Reissue Message Token</span>
        </Button>
      </>
    );
  }
  if (
    appStore &&
    appStore.authService &&
    appStore.authService.getRoles() &&
    appStore.authService.getRoles().indexOf("site_admin") >= 0
  ) {
    rolebuttons.push(
      <Button
        disabled={onscreenloading}
        className={classes.save_button}
        variant="contained"
        onClick={handleRefresh}
      >
        <span className={classes.bold_text}>Sync Config</span>
      </Button>
    );
  }
  if (
    appStore &&
    appStore.authService &&
    appStore.authService.getRoles() &&
    appStore.authService.getRoles().indexOf("sys_admin") >= 0
  ) {
    rolebuttons.push(
      <Button
        disabled={onscreenloading}
        className={classes.save_button}
        variant="contained"
        onClick={handleRestart}
      >
        <span className={classes.bold_text}>Restart Hub</span>
      </Button>
    );
  }
  reissuebutton.push(
    <div className={classes.button_container}>{rolebuttons}</div>
  );

  var tokeninfo: React.ReactNode[] = [];
  var isCameraMate = hub?.type_str === "cameramate";
  if (
    tokendetail != undefined &&
    tokendetail.success &&
    newactivationtoken == undefined &&
    messagetokendetail == undefined
  ) {
    if (tokendetail?.hub_activated == "no") {
      tokeninfo.push(
        <>
          <StyledTableRow>
            <StyledTableCell className={classes.bold_text}>
              Activation code
            </StyledTableCell>
            <StyledTableCell className={classes.bold_text}>
              {tokendetail?.activation_code}
            </StyledTableCell>
            <StyledTableCell></StyledTableCell>
          </StyledTableRow>
          <StyledTableRow>
            <StyledTableCell className={classes.bold_text}>
              Code Expiry
            </StyledTableCell>
            <StyledTableCell className={classes.bold_text}>
              {tokendetail?.code_expiry}
            </StyledTableCell>
            <StyledTableCell></StyledTableCell>
          </StyledTableRow>
          {!isCameraMate && (
            <StyledTableRow>
              <StyledTableCell className={classes.bold_text}>
                Hub Activated
              </StyledTableCell>
              <StyledTableCell className={classes.bold_text}>
                {tokendetail?.hub_activated}
              </StyledTableCell>
              <StyledTableCell></StyledTableCell>
            </StyledTableRow>
          )}
        </>
      );
    } else {
      if (!isCameraMate)
        tokeninfo.push(
          <StyledTableRow>
            <StyledTableCell className={classes.bold_text}>
              Hub Activated
            </StyledTableCell>
            <StyledTableCell className={classes.bold_text}>
              {tokendetail?.hub_activated}
            </StyledTableCell>
            <StyledTableCell></StyledTableCell>
          </StyledTableRow>
        );
    }

    if (
      appStore!.authService!.getRoles()!.indexOf("sys_admin") >= 0 &&
      !isCameraMate
    ) {
      tokeninfo.push(
        <>
          <StyledTableRow>
            <StyledTableCell className={classes.bold_text}>
              Message Server
            </StyledTableCell>
            <StyledTableCell className={classes.bold_text}>
              {tokendetail?.message_server}
            </StyledTableCell>
            <StyledTableCell></StyledTableCell>
          </StyledTableRow>
          <StyledTableRow>
            <StyledTableCell className={classes.bold_text}>
              VPN Key Loaded
            </StyledTableCell>
            <StyledTableCell className={classes.bold_text}>
              {tokendetail?.vpn_key_loaded.toString()}
            </StyledTableCell>
            <StyledTableCell></StyledTableCell>
          </StyledTableRow>
        </>
      );
    }
  } else if (newactivationtoken) {
    if (newactivationtoken.success) {
      tokeninfo.push(
        <>
          <StyledTableRow>
            <StyledTableCell className={classes.bold_text}>
              Activation code
            </StyledTableCell>
            <StyledTableCell className={classes.bold_text}>
              {newactivationtoken?.activation_code}
            </StyledTableCell>
            <StyledTableCell></StyledTableCell>
          </StyledTableRow>
          <StyledTableRow>
            <StyledTableCell className={classes.bold_text}>
              Code Expiry
            </StyledTableCell>
            <StyledTableCell className={classes.bold_text}>
              {newactivationtoken?.code_expiry}
            </StyledTableCell>
            <StyledTableCell></StyledTableCell>
          </StyledTableRow>
          {!isCameraMate && (
            <StyledTableRow>
              <StyledTableCell className={classes.bold_text}>
                Hub Activated
              </StyledTableCell>
              <StyledTableCell className={classes.bold_text}>
                {newactivationtoken?.hub_activated}
              </StyledTableCell>
              <StyledTableCell></StyledTableCell>
            </StyledTableRow>
          )}
        </>
      );
      if (
        appStore!.authService!.getRoles()!.indexOf("sys_admin") >= 0 &&
        !isCameraMate
      ) {
        tokeninfo.push(
          <>
            <StyledTableRow>
              <StyledTableCell className={classes.bold_text}>
                Message Server
              </StyledTableCell>
              <StyledTableCell className={classes.bold_text}>
                {newactivationtoken?.message_server}
              </StyledTableCell>
              <StyledTableCell></StyledTableCell>
            </StyledTableRow>
            <StyledTableRow>
              <StyledTableCell className={classes.bold_text}>
                VPN Key Loaded
              </StyledTableCell>
              <StyledTableCell className={classes.bold_text}>
                {newactivationtoken?.vpn_key_loaded.toString()}
              </StyledTableCell>
              <StyledTableCell></StyledTableCell>
            </StyledTableRow>
          </>
        );
      }
    } else {
      tokeninfo.push(
        <StyledTableRow>
          <StyledTableCell className={classes.bold_text}>
            Re-Issue failed!
          </StyledTableCell>
          <StyledTableCell className={classes.bold_text}>
            {newactivationtoken?.msg}
          </StyledTableCell>
          <StyledTableCell></StyledTableCell>
        </StyledTableRow>
      );
    }
  } else if (messagetokendetail) {
    if (messagetokendetail?.success && !isCameraMate) {
      tokeninfo.push(
        <>
          <StyledTableRow>
            <StyledTableCell className={classes.bold_text}>
              Message Token
            </StyledTableCell>
            <StyledTableCell className={classes.bold_text}>
              {messagetokendetail?.token}
            </StyledTableCell>
            <StyledTableCell></StyledTableCell>
          </StyledTableRow>
          <StyledTableRow>
            <StyledTableCell className={classes.bold_text}>
              Message Token Result
            </StyledTableCell>
            <StyledTableCell className={classes.bold_text}>
              {messagetokendetail?.msg}
            </StyledTableCell>
            <StyledTableCell></StyledTableCell>
          </StyledTableRow>
        </>
      );
    } else {
      tokeninfo.push(
        <>
          <StyledTableRow>
            <StyledTableCell className={classes.bold_text}>
              Getting token Failed!
            </StyledTableCell>
            <StyledTableCell className={classes.bold_text}></StyledTableCell>
            <StyledTableCell></StyledTableCell>
          </StyledTableRow>
          <StyledTableRow>
            <StyledTableCell className={classes.bold_text}>
              Result
            </StyledTableCell>
            <StyledTableCell className={classes.bold_text}>
              {messagetokendetail?.msg}
            </StyledTableCell>
            <StyledTableCell></StyledTableCell>
          </StyledTableRow>
        </>
      );
    }
  }
  var admin = "";
  var update = "";
  var rest = "";
  var health = "";
  var epoch = new Date(0);
  var updated = "";
  var time = "";
  var updatestatus: React.ReactNode[] = [];
  if (status?.success) {
    admin = status.status.sw_ver_hub_admin;
    update = status.status.sw_ver_update_conf;
    rest = status.status.sw_ver_hub_rest_server;
    health = status.status.sw_ver_hub_health;
    if (status.status.last_conf_update_epoch_secs == 0) {
      updated = "Never";
    } else {
      epoch.setUTCSeconds(status.status.last_conf_update_epoch_secs);
      updated = epoch.toString();
    }

    var now = new Date();
    var diff = (now.getTime() - epoch.getTime()) / 60 / 1000;
    var minutes = Math.floor(diff);
    if (minutes < 5) {
      updatestatus.push(
        <StyledTableCell>
          <CheckIcon fontSize="large" style={{ fill: "green" }} />
        </StyledTableCell>
      );
    }
    if (minutes >= 5 && minutes < 10) {
      updatestatus.push(
        <StyledTableCell>
          <PriorityHighIcon fontSize="large" style={{ fill: "orange" }} />
        </StyledTableCell>
      );
    }
    if (minutes >= 10) {
      updatestatus.push(
        <StyledTableCell>
          <CloseIcon fontSize="large" style={{ fill: "red" }} />
        </StyledTableCell>
      );
    }
    var seconds = Math.floor((diff - Math.floor(diff)) * 60);
    time = minutes + ":" + seconds;
    if (status?.status.last_conf_update_epoch_secs == 0) {
      time = "Never";
    }
  }

  var type_str: React.ReactNode[] = [];
  if (hub && !isCameraMate) {
    type_str.push(
      <StyledTableRow>
        <StyledTableCell className={classes.bold_text}>Type</StyledTableCell>
        <StyledTableCell className={classes.bold_text}>
          {hub.type_str}
        </StyledTableCell>
        <StyledTableCell></StyledTableCell>
      </StyledTableRow>
    );
  }
  var online: React.ReactNode[] = [];

  const hubStatus: any = { "0": "Offline", "1": "online", "2": "unknown" };
  const hubIcon: any = {
    "0": <CloseIcon fontSize="large" style={{ fill: "red" }} />,
    "1": <CheckIcon fontSize="large" style={{ fill: "green" }} />,
    "2": <InfoIcon fontSize="large" style={{ fill: "grey" }} />,
  };

  if (status?.success) {
    online.push(
      <StyledTableRow>
        <StyledTableCell className={classes.bold_text}>
          Connectivity
        </StyledTableCell>
        <StyledTableCell className={classes.bold_text}>
          Hub is{" "}
          {isCameraMate && Number.isInteger(status?.status)
            ? hubStatus[`${status.status}`]
            : "online"}
        </StyledTableCell>
        <StyledTableCell>
          {isCameraMate && Number.isInteger(status?.status) ? (
            hubIcon[`${status.status}`]
          ) : (
            <CheckIcon fontSize="large" style={{ fill: "green" }} />
          )}
        </StyledTableCell>
      </StyledTableRow>
    );
  } else {
    online.push(
      <StyledTableRow>
        <StyledTableCell className={classes.bold_text}>
          Connectivity
        </StyledTableCell>
        <StyledTableCell className={classes.bold_text}>
          Hub is{" "}
          {isCameraMate && Number.isInteger(status?.status)
            ? hubStatus[`${status!.status}`]
            : "offline"}{" "}
        </StyledTableCell>
        <StyledTableCell>
          {isCameraMate && Number.isInteger(status?.status) ? (
            hubIcon[`${status!.status}`]
          ) : (
            <CloseIcon fontSize="large" style={{ fill: "red" }} />
          )}
        </StyledTableCell>
      </StyledTableRow>
    );
  }

  var info: React.ReactNode[] = [];
  if (status?.success && newactivationtoken == undefined && !isCameraMate) {
    info.push(
      <>
        <StyledTableRow>
          <StyledTableCell className={classes.bold_text}>
            Last config sync check
          </StyledTableCell>
          <StyledTableCell className={classes.bold_text}>
            {updated}
          </StyledTableCell>
          {updatestatus}
        </StyledTableRow>
        <StyledTableRow>
          <StyledTableCell className={classes.bold_text}>
            Admin sw version
          </StyledTableCell>
          <StyledTableCell className={classes.bold_text}>
            {admin}
          </StyledTableCell>
          <StyledTableCell></StyledTableCell>
        </StyledTableRow>
        <StyledTableRow>
          <StyledTableCell className={classes.bold_text}>
            Update sw version
          </StyledTableCell>
          <StyledTableCell className={classes.bold_text}>
            {update}
          </StyledTableCell>
          <StyledTableCell></StyledTableCell>
        </StyledTableRow>
        <StyledTableRow>
          <StyledTableCell className={classes.bold_text}>
            Rest sw version
          </StyledTableCell>
          <StyledTableCell className={classes.bold_text}>
            {rest}
          </StyledTableCell>
          <StyledTableCell></StyledTableCell>
        </StyledTableRow>
        <StyledTableRow>
          <StyledTableCell className={classes.bold_text}>
            Health sw version
          </StyledTableCell>
          <StyledTableCell className={classes.bold_text}>
            {health}
          </StyledTableCell>
          <StyledTableCell></StyledTableCell>
        </StyledTableRow>
      </>
    );
  }
  var inlineloading: React.ReactNode[] = [];
  if (onscreenloading) {
    inlineloading.push(
      <div className="wait-container">
        <svg width="48px" height="48px" viewBox="0 0 128 128">
          <g>
            <linearGradient id="linear-gradient">
              <stop offset="0%" stop-color="#ffffff" />
              <stop offset="100%" stop-color="#22305f" />
            </linearGradient>
            <path
              d="M63.85 0A63.85 63.85 0 1 1 0 63.85 63.85 63.85 0 0 1 63.85 0zm.65 19.5a44 44 0 1 1-44 44 44 44 0 0 1 44-44z"
              fill="url(#linear-gradient)"
              fill-rule="evenodd"
            />
            <animateTransform
              attributeName="transform"
              type="rotate"
              from="0 64 64"
              to="360 64 64"
              dur="1080ms"
              repeatCount="indefinite"
            ></animateTransform>
          </g>
        </svg>
      </div>
    );
  }
  if (loading) {
    return (
      <Dialog
        onClose={handleClose}
        open={open}
        className={classes.modal_dialog}
      >
        <DialogTitle>
          <span className={classes.bold_text}>
            Getting Status for {hub?.hub_id_str}
          </span>
          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
          <CloseIcon className={classes.close_icon} onClick={handleClose} />
        </DialogTitle>
        <div className="wait-container">
          <svg width="48px" height="48px" viewBox="0 0 128 128">
            <g>
              <linearGradient id="linear-gradient">
                <stop offset="0%" stop-color="#ffffff" />
                <stop offset="100%" stop-color="#22305f" />
              </linearGradient>
              <path
                d="M63.85 0A63.85 63.85 0 1 1 0 63.85 63.85 63.85 0 0 1 63.85 0zm.65 19.5a44 44 0 1 1-44 44 44 44 0 0 1 44-44z"
                fill="url(#linear-gradient)"
                fill-rule="evenodd"
              />
              <animateTransform
                attributeName="transform"
                type="rotate"
                from="0 64 64"
                to="360 64 64"
                dur="1080ms"
                repeatCount="indefinite"
              ></animateTransform>
            </g>
          </svg>
        </div>
      </Dialog>
    );
  } else {
    return (
      <Dialog
        onClose={handleClose}
        open={open}
        className={classes.modal_dialog}
      >
        <DialogTitle>
          <span className={classes.bold_text}>
            Status for {hub?.hub_id_str}{" "}
          </span>
          <CloseIcon className={classes.close_icon} onClick={handleClose} />
        </DialogTitle>
        <TableContainer component={Paper}>
          <Table>
            <TableBody>
              {tokeninfo}
              {online}
              {info}
              {type_str}
            </TableBody>
          </Table>
        </TableContainer>
        <Divider></Divider>
        <br></br>
        <br></br>
        {inlineloading}
        <Typography className={classes.bold_text}>{message}</Typography>

        {!isCameraMate && reissuebutton}
      </Dialog>
    );
  }
};
export default StatusHubModal;
