import React, { useState, useEffect } from "react";
import { AppStore } from "../../../stores/AppStore";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import TextField from "@material-ui/core/TextField";
import { RadioGroup } from "@material-ui/core";
import Radio from "@material-ui/core/Radio";
import { useStyles } from "../../styling/global/global";
import MenuItem from "@material-ui/core/MenuItem";
import InputLabel from "@material-ui/core/InputLabel";
import { AddCircle } from "@material-ui/icons";
import { IconButton } from "@material-ui/core";
import TimeEntryPanelSingle from "../../components/TimeEntryUI/TimeEntryPanelSingle";
import { TimeEntrySetSingle } from "../../../core/models/TimeEntrySingle";
import Typography from "@material-ui/core/Typography";
import Select from "@material-ui/core/Select";
import Alert from "@material-ui/lab/Alert";
import Slider from "@material-ui/core/Slider";
import {
  Tooltip,
  ExpansionPanel,
  ExpansionPanelSummary,
  ExpansionPanelDetails,
  FormControlLabel,
  FormControl,
  Checkbox,
} from "@material-ui/core";

interface SecurityAdvancedProps {
  appStore?: AppStore;
  securityconfig: { [key: string]: any };
  setSecurityConfig: (x: { [key: string]: any }) => void;
  editable: boolean;
  isValid: (isValid: boolean) => boolean;
}

function valuetext(value: number) {
  return `${value} seconds`;
}
const SecurityAdvanced: React.FC<SecurityAdvancedProps> = ({
  editable,
  appStore,
  securityconfig,
  setSecurityConfig,
  isValid,
}: SecurityAdvancedProps) => {
  const classes = useStyles();
  const [refresh, setRefresh] = React.useState(0);
  const [message, setMessage] = React.useState<string>("");
  const [maxMessage, setMaxMessage] = React.useState<string>("");
  const [triggerSecsMessage, setTriggerSecsMessage] =
    React.useState<string>("");

  const [minTopValue, setMinTopValue] = useState(0);
  const [minMiddleValue, setMinMiddleValue] = useState(0);
  const [minBottomValue, setMinBottomValue] = useState(0);

  const [maxTopValue, setMaxTopValue] = useState(0);
  const [maxMiddleValue, setMaxMiddleValue] = useState(0);
  const [maxBottomValue, setMaxBottomValue] = useState(0);

  const handleIgnorePanZoomChange = (event: React.ChangeEvent<any>) => {
    securityconfig.detect_pan_and_zoom = !securityconfig.detect_pan_and_zoom;
    setSecurityConfig(securityconfig);
    setRefresh(new Date().getTime());
  };
  const handleIgnoreShadowChange = (event: React.ChangeEvent<any>) => {
    securityconfig.ignore_shadows = !securityconfig.ignore_shadows;
    setSecurityConfig(securityconfig);
    setRefresh(new Date().getTime());
  };
  const handleTrackTriggerSecondsChange = (
    event: React.ChangeEvent<any>,
    value: any
  ) => {
    // if (isNaN(event.target.value)) {
    //   console.debug(`This is the triggered value: ${value}`);
    //   setMessage('Tracking trigger seconds must be a valid number!');
    // } else {
    securityconfig.tracking_trigger_secs = Number(value);
    setSecurityConfig(securityconfig);
    setRefresh(new Date().getTime());
    // }
  };
  const handleContrastSensitivityChange = (event: React.ChangeEvent<any>) => {
    securityconfig.pixel_diff_thresh = Number(event.target.value);
    setSecurityConfig(securityconfig);
    setRefresh(new Date().getTime());
  };
  const handleMotionSensitivityChange = (event: React.ChangeEvent<any>) => {
    securityconfig.background_refresh_rate = Number(event.target.value);
    setSecurityConfig(securityconfig);
    setRefresh(new Date().getTime());
  };
  const handleTopMinChange = (event: React.ChangeEvent<any>) => {
    setMinTopValue(+event.target.value);
    if (isNaN(event.target.value)) {
      setMessage("Value too large, number must be between 0.0002 & 1");
    } else {
      securityconfig.min_contour_area_fraction = setPortion(
        event.target.value,
        2,
        securityconfig.min_contour_area_fraction
      );
      setSecurityConfig(securityconfig);
      setRefresh(new Date().getTime());
    }
  };
  const handleMiddleMinChange = (event: React.ChangeEvent<any>) => {
    setMinMiddleValue(+event.target.value);
    if (isNaN(event.target.value)) {
      setMessage("Minimum motion box sizes must be a valid number!");
    } else {
      securityconfig.min_contour_area_fraction = setPortion(
        event.target.value,
        1,
        securityconfig.min_contour_area_fraction
      );
      setSecurityConfig(securityconfig);
      setRefresh(new Date().getTime());
    }
  };
  const handleBottomMinChange = (event: React.ChangeEvent<any>) => {
    setMinBottomValue(+event.target.value);
    if (isNaN(event.target.value)) {
      setMessage("Minimum motion box sizes must be a valid number!");
    } else {
      securityconfig.min_contour_area_fraction = setPortion(
        event.target.value,
        0,
        securityconfig.min_contour_area_fraction
      );
      setSecurityConfig(securityconfig);
      setRefresh(new Date().getTime());
    }
  };
  const handleTopMaxChange = (event: React.ChangeEvent<any>) => {
    setMaxTopValue(+event.target.value);
    if (isNaN(event.target.value)) {
      setMaxMessage("Maximum motion box sizes must be a valid number!");
    } else {
      securityconfig.max_contour_area_fraction = setPortion(
        event.target.value,
        2,
        securityconfig.max_contour_area_fraction
      );
      setSecurityConfig(securityconfig);
      setRefresh(new Date().getTime());
    }
  };
  const handleMiddleMaxChange = (event: React.ChangeEvent<any>) => {
    setMaxMiddleValue(+event.target.value);
    if (isNaN(event.target.value)) {
      setMaxMessage("Maximum motion box sizes must be a valid number!");
    } else {
      securityconfig.max_contour_area_fraction = setPortion(
        event.target.value,
        1,
        securityconfig.max_contour_area_fraction
      );
      setSecurityConfig(securityconfig);
      setRefresh(new Date().getTime());
    }
  };
  const handleBottomMaxChange = (event: React.ChangeEvent<any>) => {
    setMaxBottomValue(+event.target.value);
    if (isNaN(event.target.value)) {
      setMaxMessage("Maximum motion box sizes must be a valid number!");
    } else {
      securityconfig.max_contour_area_fraction = setPortion(
        event.target.value,
        0,
        securityconfig.max_contour_area_fraction
      );
      setSecurityConfig(securityconfig);
      setRefresh(new Date().getTime());
    }
  };

  const setPortion = (value: string, portion: number, current: string) => {
    current = current.replace("[", "");
    current = current.replace("]", "");
    var currents = current.split(",");
    currents[portion] = value;
    return "[" + currents[0] + "," + currents[1] + "," + currents[2] + "]";
  };

  const getPortion = (portion: number, current: string) => {
    if (current) {
      current = current.replace("[", "");
      current = current.replace("]", "");
      const currents = current.split(",");
      return currents[portion];
    } else {
      return "0.0";
    }
  };
  const handleRSIntervalChange = (event: React.ChangeEvent<any>) => {
    if (isNaN(event.target.value)) {
      setMessage("Interval seconds must be a valid number!");
    } else {
      securityconfig.object_detection_rest_timeout_secs = Number(
        event.target.value
      );
      setSecurityConfig(securityconfig);
      setRefresh(new Date().getTime());
    }
  };

  // MINIMUM VALIDATIONS

  const blurHandlerMin = (event: React.ChangeEvent<any>, portion: number) => {
    if (portion === 2 && +event.target.value > 0.0002) {
      if (
        +event.target.value >
        +getPortion(2, securityconfig.max_contour_area_fraction)
      ) {
        isValid(false);
        return setMessage(
          "Minimum Top Value Cannot be greater than Maximum Top Value"
        );
      }
    }

    if (portion === 1 && +event.target.value > 0.0002) {
      if (
        +event.target.value >
        +getPortion(1, securityconfig.max_contour_area_fraction)
      ) {
        isValid(false);
        return setMessage(
          "Minimum Middle Value Cannot be greater than Maximum Middle Value"
        );
      }
    }

    if (portion === 0 && +event.target.value > 0.0002) {
      if (
        +event.target.value >
        +getPortion(0, securityconfig.max_contour_area_fraction)
      ) {
        isValid(false);
        return setMessage(
          "Minimum Bottom Value Cannot be greater than Maximum Bottom Value"
        );
      }
    }

    if (+event.target.value < 0.0002) {
      isValid(false);
      return setMessage(
        "Your input value is too small, value cannot be less than 0.0002"
      );
    }
    if (+event.target.value > 1) {
      isValid(false);
      return setMessage(
        "Your input value is too large, value cannot be greater than 1"
      );
    }
    setMessage("");
    isValid(true);
  };

  //   MAX VALIDATIONS

  const blurHandlerMax = (event: React.ChangeEvent<any>, portion: number) => {
    if (portion === 2 && +event.target.value > 0.0002) {
      if (
        +event.target.value <
        +getPortion(2, securityconfig.min_contour_area_fraction)
      ) {
        isValid(false);
        setMessage("");
        return setMaxMessage(
          "Maximum Top Value Cannot be less than Minimum Top Value"
        );
      }
    }

    if (portion === 1 && +event.target.value > 0.0002) {
      if (
        +event.target.value <
        +getPortion(1, securityconfig.min_contour_area_fraction)
      ) {
        isValid(false);
        setMessage("");
        return setMaxMessage(
          "Maximum Middle Value Cannot be less than Minimum Middle Value"
        );
      }
    }

    if (portion === 0 && +event.target.value > 0.0002) {
      if (
        +event.target.value <
        +getPortion(0, securityconfig.min_contour_area_fraction)
      ) {
        isValid(false);
        setMessage("");
        return setMaxMessage(
          "Maximum Bottom Value Cannot be less than Minimum Bottom Value"
        );
      }
    }

    if (+event.target.value < 0.0002) {
      isValid(false);
      return setMaxMessage(
        "Your input value is too small, value cannot be less than 0.0002"
      );
    }
    if (+event.target.value > 1) {
      isValid(false);
      return setMaxMessage(
        "Your input value is too large, value cannot be greater than 1"
      );
    }
    setMaxMessage("");
    isValid(true);
  };

  // TRIGGER SECONDS VALIDATIONS

  const triggerSecondsValidator = (event: React.ChangeEvent<any>) => {
    if (+event.target.value < 0.5) {
      isValid(false);
      return setTriggerSecsMessage("Value cannot be less than 0.5 seconds");
    }
    if (+event.target.value > 2) {
      isValid(false);
      return setTriggerSecsMessage("Value cannot be greater than 2 seconds");
    }
    isValid(true);
    setTriggerSecsMessage("");
  };

  return (
    <div className={classes.medium_panel}>
      <div className={classes.header}>
        <div style={{ marginTop: 20 }} className={classes.header_text}>
          Advanced Settings
        </div>
      </div>
      <div className={classes.modal_dialog}>
        <Typography className={classes.bold_error_text}>
          {message && <Alert severity="error">{message}</Alert>}
        </Typography>
        <div className={classes.form_component}>
          {/* <div className={classes.bold_text}>
            Regular Snapshot Interval Seconds
          </div>
          <TextField
            label='Seconds'
            onChange={handleRSIntervalChange}
            value={securityconfig?.regular_snapshot_interval_secs}
            variant='outlined'
            margin='normal'
            fullWidth
            InputProps={{
              className: classes.bold_text,
            }}
            InputLabelProps={{
              classes: {
                root: classes.bold_text,
              },
            }}
          /> */}
        </div>
        <Tooltip
          placement="top"
          title="Sets the smallest fraction of the image total pixels that can trigger a motion event. Make this smaller to detect smaller objects, but potentially increase nuisance triggers and alerts. Image is divided into 3 zones. Default is 0.003 - bottom, 0.002 - middle, 0.002 - top & values in range 0.0002 to 1.0. "
        >
          <div className={classes.form_component}>
            <div className={classes.bold_text}>Minimum Motion Box Size</div>
            <TextField
              disabled={!editable}
              label="Top"
              onChange={handleTopMinChange}
              value={getPortion(2, securityconfig.min_contour_area_fraction)}
              variant="outlined"
              margin="normal"
              //   type='number'
              fullWidth
              inputProps={{ max: "0.5", step: "0.00" }}
              onBlur={(e) => {
                blurHandlerMin(e, 2);
              }}
              InputProps={{
                className: classes.bold_text,
              }}
              InputLabelProps={{
                classes: {
                  root: classes.bold_text,
                },
              }}
            />

            <TextField
              disabled={!editable}
              label="Middle"
              onChange={handleMiddleMinChange}
              value={getPortion(1, securityconfig.min_contour_area_fraction)}
              variant="outlined"
              margin="normal"
              //   type='number'
              fullWidth
              InputProps={{
                className: classes.bold_text,
              }}
              inputProps={{ max: "0.5", step: "0.00" }}
              onBlur={(e) => {
                blurHandlerMin(e, 1);
              }}
              InputLabelProps={{
                classes: {
                  root: classes.bold_text,
                },
              }}
            />
            <TextField
              disabled={!editable}
              label="Bottom"
              onChange={handleBottomMinChange}
              value={getPortion(0, securityconfig.min_contour_area_fraction)}
              variant="outlined"
              margin="normal"
              //   type='number'
              fullWidth
              InputProps={{
                className: classes.bold_text,
              }}
              inputProps={{ max: "0.5", step: "0.00" }}
              onBlur={(e) => {
                blurHandlerMin(e, 0);
              }}
              InputLabelProps={{
                classes: {
                  root: classes.bold_text,
                },
              }}
            />
          </div>
        </Tooltip>
        <Tooltip
          placement="top"
          title="Sets the largest fraction of the image total pixels that can trigger a motion event. Image is divided into 3 zones. Default is 0.5 - bottom, 0.5 - middle, 0.5 - top & values in range > min motion box size to 1.0."
        >
          <div className={classes.form_component}>
            <div style={{ margin: 20 }}>
              <Typography className={classes.bold_error_text}>
                {maxMessage && <Alert severity="error">{maxMessage}</Alert>}
              </Typography>
            </div>
            <div className={classes.bold_text}>Maximum Motion Box Size</div>
            <TextField
              disabled={!editable}
              label="Top"
              onChange={handleTopMaxChange}
              value={getPortion(2, securityconfig.max_contour_area_fraction)}
              variant="outlined"
              margin="normal"
              //   type='number'
              fullWidth
              InputProps={{
                className: classes.bold_text,
              }}
              inputProps={{ max: "1", step: "0.00" }}
              onBlur={(e) => {
                blurHandlerMax(e, 2);
              }}
              InputLabelProps={{
                classes: {
                  root: classes.bold_text,
                },
              }}
            />
            <TextField
              disabled={!editable}
              label="Middle"
              onChange={handleMiddleMaxChange}
              value={getPortion(1, securityconfig.max_contour_area_fraction)}
              variant="outlined"
              margin="normal"
              //   type='number'
              fullWidth
              InputProps={{
                className: classes.bold_text,
              }}
              inputProps={{ max: "1", step: "0.00" }}
              onBlur={(e) => {
                blurHandlerMax(e, 1);
              }}
              InputLabelProps={{
                classes: {
                  root: classes.bold_text,
                },
              }}
            />
            <TextField
              disabled={!editable}
              label="Bottom"
              onChange={handleBottomMaxChange}
              value={getPortion(0, securityconfig.max_contour_area_fraction)}
              variant="outlined"
              margin="normal"
              fullWidth
              //   type='number'
              InputProps={{
                className: classes.bold_text,
              }}
              inputProps={{ max: "1", step: "0.00" }}
              onBlur={(e) => {
                blurHandlerMax(e, 0);
              }}
              InputLabelProps={{
                classes: {
                  root: classes.bold_text,
                },
              }}
            />

            {/************************** Form Fields for Button *******************/}
            {/* <div style={{ marginBottom: 20 }} className={classes.bold_text}>
              Minimum Values
            </div>
            <div style={{ display: 'flex' }}>
              <FormControl variant='outlined' className={classes.formControl}>
                <InputLabel id='demo-simple-select-outlined-label'>
                  Top
                </InputLabel>
                <Select
                  labelId='demo-simple-select-outlined-label'
                  id='demo-simple-select-outlined'
                  label='Age'
                  onChange={handleTopMinChange}
                  value={getPortion(
                    0,
                    securityconfig.min_contour_area_fraction
                  )}
                >
                  <MenuItem value={'0.001'}>Extra Small</MenuItem>
                  <MenuItem value={'0.002'}>Small</MenuItem>
                  <MenuItem value={'0.003'}>Medium</MenuItem>
                  <MenuItem value={'0.01'}>Large</MenuItem>
                  <MenuItem value={'0.1'}>Extra Large</MenuItem>
                </Select>
              </FormControl>

              <FormControl variant='outlined' className={classes.formControl}>
                <InputLabel id='demo-simple-select-outlined-label'>
                  Middle
                </InputLabel>
                <Select
                  labelId='demo-simple-select-outlined-label'
                  id='demo-simple-select-outlined'
                  label='Age'
                  onChange={handleMiddleMinChange}
                  value={getPortion(
                    1,
                    securityconfig.min_contour_area_fraction
                  )}
                >
                  <MenuItem value={'0.001'}>Extra Small</MenuItem>
                  <MenuItem value={'0.002'}>Small</MenuItem>
                  <MenuItem value={'0.003'}>Medium</MenuItem>
                  <MenuItem value={'0.01'}>Large</MenuItem>
                  <MenuItem value={'0.1'}>Extra Large</MenuItem>
                </Select>
              </FormControl>

              <FormControl variant='outlined' className={classes.formControl}>
                <InputLabel id='demo-simple-select-outlined-label'>
                  Bottom
                </InputLabel>
                <Select
                  labelId='demo-simple-select-outlined-label'
                  id='demo-simple-select-outlined'
                  onChange={handleBottomMinChange}
                  value={getPortion(
                    2,
                    securityconfig.min_contour_area_fraction
                  )}
                  label='Age'
                >
                  <MenuItem value={'0.001'}>Extra Small</MenuItem>
                  <MenuItem value={'0.002'}>Small</MenuItem>
                  <MenuItem value={'0.003'}>Medium</MenuItem>
                  <MenuItem value={'0.01'}>Large</MenuItem>
                  <MenuItem value={'0.1'}>Extra Large</MenuItem>
                </Select>
              </FormControl>
            </div> */}

            {/* <br />
            <div style={{ marginBottom: 20 }} className={classes.bold_text}>
              Maximum Values
            </div>
            <div style={{ display: 'flex' }}>
              <FormControl variant='outlined' className={classes.formControl}>
                <InputLabel id='demo-simple-select-outlined-label'>
                  Top
                </InputLabel>
                <Select
                  labelId='demo-simple-select-outlined-label'
                  id='demo-simple-select-outlined'
                  onChange={handleTopMaxChange}
                  value={getPortion(
                    0,
                    securityconfig.max_contour_area_fraction
                  )}
                  label='Age'
                >
                  <MenuItem value={'0.1'}>Small</MenuItem>
                  <MenuItem value={'0.3'}>Middle</MenuItem>
                  <MenuItem value={'0.5'}>Large</MenuItem>
                  <MenuItem value={'0.9'}>Extra Large</MenuItem>
                </Select>
              </FormControl>

              <FormControl variant='outlined' className={classes.formControl}>
                <InputLabel id='demo-simple-select-outlined-label'>
                  Middle
                </InputLabel>
                <Select
                  labelId='demo-simple-select-outlined-label'
                  id='demo-simple-select-outlined'
                  onChange={handleMiddleMaxChange}
                  value={getPortion(
                    1,
                    securityconfig.max_contour_area_fraction
                  )}
                  label='Age'
                >
                  <MenuItem value={'0.1'}>Small</MenuItem>
                  <MenuItem value={'0.3'}>Middle</MenuItem>
                  <MenuItem value={'0.5'}>Large</MenuItem>
                  <MenuItem value={'0.9'}>Extra Large</MenuItem>
                </Select>
              </FormControl>

              <FormControl variant='outlined' className={classes.formControl}>
                <InputLabel id='demo-simple-select-outlined-label'>
                  Bottom
                </InputLabel>
                <Select
                  labelId='demo-simple-select-outlined-label'
                  id='demo-simple-select-outlined'
                  onChange={handleBottomMaxChange}
                  value={getPortion(
                    2,
                    securityconfig.max_contour_area_fraction
                  )}
                  label='Age'
                >
                  <MenuItem value={'0.1'}>Small</MenuItem>
                  <MenuItem value={'0.3'}>Middle</MenuItem>
                  <MenuItem value={'0.5'}>Large</MenuItem>
                  <MenuItem value={'0.9'}>Extra Large</MenuItem>
                </Select>
              </FormControl>
            </div> */}
          </div>
        </Tooltip>
        <Tooltip
          placement="top"
          title="Low means less sensitive to new motion (e.g. for scene with moving foliage). High means any new motion is more likely to trigger (more triggers and nuisance alerts)."
        >
          <div className={classes.form_component}>
            <div className={classes.bold_text}>
              Motion Trigger New Motion Sensitivity
            </div>
            <FormControl
              component="fieldset"
              className={classes.form_component}
            >
              <RadioGroup
                aria-label="motionsensitivity"
                name="motionsensitivity"
                value={securityconfig.background_refresh_rate}
                onChange={handleMotionSensitivityChange}
              >
                <FormControlLabel
                  value={50}
                  control={<Radio disabled={!editable} />}
                  label="Low"
                />
                <FormControlLabel
                  value={70}
                  control={<Radio disabled={!editable} />}
                  label="Default"
                />
                <FormControlLabel
                  value={85}
                  control={<Radio disabled={!editable} />}
                  label="High"
                />
              </RadioGroup>
            </FormControl>
          </div>
        </Tooltip>
        <Tooltip
          placement="top"
          title="Low means stronger contrast of moving object against background needed for trigger. High means more sensitivity to low contrast objects (more triggers and nuisance alerts)."
        >
          <div className={classes.form_component}>
            <div className={classes.bold_text}>
              Motion Trigger Contrast Sensitivity
            </div>
            <FormControl
              component="fieldset"
              className={classes.form_component}
            >
              <RadioGroup
                value={securityconfig.pixel_diff_thresh}
                onChange={handleContrastSensitivityChange}
              >
                <FormControlLabel
                  className={classes.bold_text}
                  value={70}
                  control={<Radio disabled={!editable} />}
                  label="Low"
                />
                <FormControlLabel
                  className={classes.bold_text}
                  value={50}
                  control={<Radio disabled={!editable} />}
                  label="Default"
                />
                <FormControlLabel
                  className={classes.bold_text}
                  value={35}
                  control={<Radio disabled={!editable} />}
                  label="High"
                />
              </RadioGroup>
            </FormControl>
          </div>
        </Tooltip>
        <div style={{ margin: 30 }}>
          <Typography className={classes.bold_error_text}>
            {triggerSecsMessage && (
              <Alert severity="error">{triggerSecsMessage}</Alert>
            )}
          </Typography>
        </div>
        {/* <Tooltip
          placement='top'
          title='How long an object will be tracked before triggering. Lower values mean higher sensitivity to fast moving objects but will also increase motion triggers. For example moving foliage. Default is 1.0 sec & range is 0.5s - 2s.'
        >
           <TextField
            disabled={!editable}
            label='Tracking Trigger Seconds'
            onChange={handleTrackTriggerSecondsChange}
            onBlur={triggerSecondsValidator}
            value={
              securityconfig.tracking_trigger_secs
                ? securityconfig.tracking_trigger_secs
                : '0'
            }
            variant='outlined'
            margin='normal'
            // type='number'
            fullWidth
            // inputProps={{ min: '0.5', max: '2'}}
            InputProps={{
              className: classes.bold_text,
            }}
            InputLabelProps={{
              classes: {
                root: classes.bold_text,
              },
            }}
          />
        </Tooltip> */}
        <div className={classes.root}>
          <div className={classes.bold_text}>Tracking Trigger Seconds</div>
          {/* <Typography id='discrete-slider-small-steps' gutterBottom>
          seconds
          </Typography> */}
          <Tooltip
            placement="top"
            title="How long an object will be tracked before triggering. Lower values mean higher sensitivity to fast moving objects but will also increase motion triggers. For example moving foliage. Default is 1.0 sec & range is 0.5s - 2s."
          >
            <Slider
              defaultValue={securityconfig.tracking_trigger_secs}
              onChange={handleTrackTriggerSecondsChange}
              onBlur={triggerSecondsValidator}
              getAriaValueText={valuetext}
              aria-labelledby="discrete-slider-small-steps"
              step={0.1}
              marks
              min={0.5}
              max={2}
              valueLabelDisplay="on"
            />
          </Tooltip>
        </div>
        <Tooltip
          placement="bottom"
          title="Check to ignore shadow-like objects in triggers. Default is to not to ignore (unchecked)."
        >
          <div className={classes.form_component}>
            <FormControlLabel
              className={classes.form_component_checkbox}
              control={
                <Checkbox
                  color="primary"
                  disabled={!editable}
                  checked={securityconfig.ignore_shadows}
                  onChange={handleIgnoreShadowChange}
                />
              }
              label="Ignore Shadow-like Objects"
            />
          </div>
        </Tooltip>
        <Tooltip
          placement="bottom"
          title="Check to detect and ignore pan, tilt and zoom motion of camera. This option adds processing load and default is unchecked"
        >
          <div className={classes.form_component}>
            <FormControlLabel
              className={classes.form_component_checkbox}
              control={
                <Checkbox
                  color="primary"
                  disabled={!editable}
                  checked={securityconfig.detect_pan_and_zoom}
                  onChange={handleIgnorePanZoomChange}
                />
              }
              label="Detect and ignore pan and zoom (adds processing load)"
            />
          </div>
        </Tooltip>
      </div>
    </div>
  );
};

export default SecurityAdvanced;
