import React, { useState, useEffect, useRef } from "react";
import { Modal } from "antd";
import { Site, Sensor, CanvasImage } from "../../../services/FunctionService";
import LinearProgress from "@material-ui/core/LinearProgress";
import CustomButton from "../../UI/CustomButton";
import { AppStore } from "../../../stores/AppStore";
import DrawCanvas from "../Snapshot/DrawCanvas";
import Grid from "@material-ui/core/Grid";
import { useStyles } from "../../styling/global/global";
import RuleConfigs from "./RuleConfigs";
import { getBaseUrl } from "../../HelperFunctions";
import { Card, InputLabel } from "@material-ui/core";
import RulesList from "./RulesList";
import TextField from "@material-ui/core/TextField";
import FormControl from "@material-ui/core/FormControl";
import { MainContext } from "../../containers/HomePage/HomePage";
import Skeleton from "./Skeleton";
import Autocomplete from "@material-ui/lab/Autocomplete";

import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";
import Input from "@material-ui/core/Input";

import Chip from "@material-ui/core/Chip";
import MultipleSelect, { CardSelect } from "./MultipleSelect";

import { useTheme, Theme } from "@material-ui/core/styles";

import { atom, useRecoilState } from "recoil";
import TimeSelector from "./TimeSelector";

export const deliveryEndpointsState = atom({
  key: "deliveryEndpointsState",
  default: [],
});

export const hasSnapshotRuleState = atom({
  key: "hasSnapshotRule",
  default: false,
});

function getStyles(name: string, personName: string[], theme: Theme) {
  return {
    fontWeight:
      personName.indexOf(name) === -1
        ? theme.typography.fontWeightRegular
        : theme.typography.fontWeightMedium,
    width: 600,
  };
}

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 300,
    },
  },
};

interface ZoneProps {
  appStore?: AppStore;
  sensor?: Sensor;
  site?: Site;
  handleNext?: () => void;
  handlePrev?: () => void;
  saveZonesHandler?: () => void;
  setOpenWizardModal?: (val: boolean) => void;
  showTable?: boolean;
  defaultRule?: boolean;
  showWizard?: boolean;
  sensorDefinedRule?: any;
  sensorDefinedRules?: any[];
  editable?: boolean;
  noEndTime?: boolean;
  showSummary?: boolean;
  ruleDefs?: any;
}

const EditButton = () => (
  <svg
    width="40"
    height="40"
    viewBox="0 0 40 40"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect width="40" height="40" rx="5" fill="#2E4FA5" />
    <path
      d="M27.6484 16.7034L24.2974 13.3516C24.186 13.2401 24.0537 13.1517 23.908 13.0914C23.7624 13.031 23.6064 13 23.4488 13C23.2911 13 23.1351 13.031 22.9895 13.0914C22.8439 13.1517 22.7116 13.2401 22.6001 13.3516L13.3518 22.6007C13.2398 22.7117 13.1511 22.8439 13.0907 22.9895C13.0303 23.1351 12.9995 23.2913 13 23.4489V26.8007C13 27.119 13.1264 27.4242 13.3515 27.6493C13.5765 27.8743 13.8818 28.0007 14.2 28.0007H17.5518C17.7095 28.0013 17.8656 27.9704 18.0113 27.91C18.1569 27.8496 18.289 27.7609 18.4001 27.649L27.6484 18.4006C27.7599 18.2892 27.8483 18.1569 27.9086 18.0113C27.969 17.8657 28 17.7096 28 17.552C28 17.3944 27.969 17.2383 27.9086 17.0927C27.8483 16.9471 27.7599 16.8148 27.6484 16.7034ZM17.5518 26.8007H14.2V23.4489L20.8001 16.8489L24.1519 20.2007L17.5518 26.8007ZM25.0001 19.3516L21.6484 16.0006L23.4484 14.2006L26.8002 17.5516L25.0001 19.3516Z"
      fill="white"
    />
  </svg>
);

const Zone: React.FC<ZoneProps> = ({
  appStore,
  sensor,
  site,
  saveZonesHandler,
  setOpenWizardModal,
  showTable = false,
  showWizard = false,
  sensorDefinedRule,
  sensorDefinedRules,
  editable,
  noEndTime,
  showSummary,
  defaultRule,
  ruleDefs,
}) => {
  let ruleTypes = [];

  const ctx = React.useContext(MainContext);

  const ruleOptions = sensorDefinedRule?.fields?.rule_configs
    ? Object.keys(sensorDefinedRule?.fields?.rule_configs)
    : undefined;

  console.debug(
    "\nctx zoneconfigs $",
    ctx.zone_config,
    "\nruleOptions: ",
    ruleOptions,
    "\nrrruleDefs: ",
    ruleDefs,
    "\nsensorDefinedRule: ",
    sensorDefinedRule
  );

  const [ruleOption, setRuleOption] = useState<string | undefined>(
    ruleOptions && ruleOptions.length > 0 ? ruleOptions[0] : undefined
  );
  let ruleObj =
    Array.isArray(ruleOptions) && ruleOptions.length > 0
      ? sensorDefinedRule?.fields?.rule_configs[`${ruleOption}`]
      : undefined;

  const { zone_configs, label } = ruleObj || {
    zone_configs: undefined,
    label: undefined,
  };
  console.debug(
    "Wizard Zones 1",
    zone_configs,
    "ruleOptions: ",
    ruleOptions,
    " ruleOption: ",
    ruleOption,
    "compare: ",
    ruleOptions && ruleOptions.length > 0 ? ruleOptions[0] : undefined,
    "sensorDefinedRule:",
    sensorDefinedRule,
    "Rule Name",
    ctx.rule_name
  );
  if (zone_configs) {
    ruleTypes = zone_configs.map((item: any) => item.type) || [];
  }

  useEffect(() => {
    setRuleOption(
      ruleOptions && ruleOptions.length > 0 ? ruleOptions[0] : undefined
    );
  }, [sensorDefinedRule?.fields?.rule_configs]);

  const [canvasimage, setCanvasImage] = React.useState<CanvasImage | undefined>(
    undefined
  );
  const [canvasImageScaleFactor, setCanvasImageScaleFactor] =
    React.useState<number>(1);
  const [config, setConfig] = React.useState<{ [key: string]: any }>({});

  const [saved_motion_exclusion_zones, setSavedMotionExclusionZones] =
    React.useState("");
  const [, setMessageModalOpen] = React.useState(false);
  const [loading, setLoading] = React.useState(defaultRule ? false : true);
  const [exclusionzones, setExclusionZones] = React.useState<{
    [key: string]: any;
  }>({});
  const [, setSavedZones] = React.useState<{
    [key: string]: any;
  }>({});
  const [zonesInUse, setZonesInUse] = React.useState<{
    [key: string]: any;
  }>({});
  const [zoneKeysInUse, setZoneKeysInUse] = React.useState<[] | string[]>();
  const [overrideexclusionzones1, setOverrideExclusionZones1] = React.useState<{
    [key: string]: any;
  }>({});
  const [overrideexclusionzones2, setOverrideExclusionZones2] = React.useState<{
    [key: string]: any;
  }>({});

  const [currentzone, setCurrentZone] = useState("PRIMARY");
  const [coordsInUse, setCoordsInUse] = useState<any>("");

  const [, setPopMessage] = useState("");
  const [zoneOptions, setZoneOptions] = useState<any>([]);
  const [, setRefresh] = useState(0);
  const [whitelistName, setWhitelistName] = React.useState<any>(undefined);

  const [alpr_whitelist, setAlprWhitelist] = useState<any>([]);
  let showWhitelistField = false;

  const canvasref = useRef<DrawCanvas>(null);
  const clearZonesCheck = React.useRef(false);
  const [personName, setPersonName] = React.useState<string[]>([]);
  const [endpointtype, setEndPointType] = React.useState(-1);
  const [deliveryEndpoints, setDeliveryEndpoints] = useState<any>("");

  var endpointtypes: React.ReactNode[] = [];

  const [cadenceSecs, setCadenceSecs] = React.useState(
    sensorDefinedRule?.fields?.cadence_secs?.default
      ? `${sensorDefinedRule?.fields?.cadence_secs?.default / 60} min`
      : ctx.fetchInterval
  );
  const { setShowGraph } = React.useContext(MainContext);

  const classes = useStyles();
  const theme = useTheme();

  const [endpoints, setEndpoints] = useRecoilState(deliveryEndpointsState);
  const [, setHasSnapshotRule] = useRecoilState(hasSnapshotRuleState);

  const [endpointNames, setEndpointNames] = React.useState<string[] | any>(
    ctx.endpointIds || []
  );
  const [selectAllEndpoints, setSelectAllEndpoints] = React.useState(true);

  useEffect(() => {
    setShowGraph(false);
    // return () => {
    //   !defaultRule && setShowGraph(true);
    // };
  }, []);

  //Multiple Selection method
  const handleChange = (event: React.ChangeEvent<{ value: unknown }>) => {
    const data = event.target.value as any;
    if (data.indexOf(-1) > -1) {
      setEndpointNames([
        -1,
        ...deliveryEndpoints.map(
          (item: any) => item.alert_channel_end_point_id
        ),
      ]);
      setSelectAllEndpoints(true);
      ctx.setEndpointIds([]);
    } else {
      // setIsEditable(true);
      setEndpointNames(event.target.value as string[]);
      setSelectAllEndpoints(false);
      ctx.setEndpointIds(event.target.value as string[]);
    }
  };

  if (deliveryEndpoints && deliveryEndpoints.length > 0) {
    deliveryEndpoints.map((item: any) => {
      endpointtypes.push(
        <MenuItem value={item.alert_channel_end_point_id}>
          <span className={classes.bold_text}>{item.name}</span>
        </MenuItem>
      );
    });
  }

  var baseUrl = getBaseUrl();

  async function fetchAlertEndpointsData(alertChannelIds: string) {
    if (!deliveryEndpoints && alertChannelIds && !defaultRule) {
      setLoading(true);
      var token = await appStore?.authService.getAuthorisedToken();
      if (token) {
        var endpoints = await appStore?.functionService.getAlertEndPoints(
          token,
          alertChannelIds
        );
        endpoints = endpoints?.ret?.map((item: any) => {
          let i = { ...item };
          if (i.end_point_type === "web") {
            i.name = "Alert Web Interface";
          } else if (i.end_point_type === "telegram") {
            i.name = "Telegram - " + i.meta?.recipient_name;
          }
          return i;
        });
        setDeliveryEndpoints(endpoints);
        setEndpoints(endpoints);
      }
      setLoading(false);
    }
  }

  React.useEffect(() => {
    const fetchData = async () => {
      if (site && site.site_id && appStore && !defaultRule) {
        setLoading(true);
        var token = await appStore?.authService.getAuthorisedToken();
        if (token) {
          var alertchannelz: any[] =
            await appStore?.functionService.getAlertChannelsForSite(
              token,
              site.site_id
            );
          if (alertchannelz && !!alertchannelz.length) {
            let ids_list = alertchannelz.map((channel) => {
              if (channel.site_id === site.site_id) {
                return channel.alert_channel_id;
              }
            });

            // if (ids_list[0]) {
            //   setChannelD(ids_list[0]);
            // }

            fetchAlertEndpointsData(ids_list.toString());
          }
        }
        setLoading(false);
      }
    };
    fetchData();
  }, [site]);

  const handleEndPointTypeChange = (event: React.ChangeEvent<any>) => {
    setEndPointType(event.target.value);
  };

  const handleChangeAuto = (value: any) => {
    if (!value) {
      return;
    }

    setWhitelistName(value);
    ctx.setWhitelistName(value);
  };

  // useEffect(() => {
  //   if (ctx.rule_type === "Known Vehicle Filter" && alpr_whitelist.length > 0) {
  //     ctx.setWhitelistName(alpr_whitelist[0]);
  //   }
  // }, [ctx.rule_type]);

  if (ctx.rule_type === "Known Vehicle Filter") {
    showWhitelistField = true;
  } else {
    showWhitelistField = false;
  }

  //get polygons from confic format zones
  const getPolygons = (zones: string) => {
    zones = zones.replace("[[", "");
    zones = zones.replace("]]", "");
    var polygonsdict: { [key: string]: [number[]] } = {};
    var polygons = zones.split("],[");
    var count = 0;
    polygons.forEach((polygon) => {
      var points = polygon.split("),(");
      var pointsarray: any = [];
      points.forEach((point) => {
        point = point.replace("(", "");
        point = point.replace(")", "");
        var coords = point.split(",");
        var coordsarray = [];
        coordsarray.push(Number(coords[0]));
        coordsarray.push(Number(coords[1]));
        pointsarray.push(coordsarray);
      });
      polygonsdict["Polygon_" + count] = pointsarray;
      count++;
    });
    return polygonsdict;
  };

  const getSnap = async (snap_type: string) => {
    setLoading(true);
    const token = await appStore?.authService.getAuthorisedToken();
    if (token && sensor) {
      var newconfig = config;
      let me_zones = undefined;
      if (currentzone === "PRIMARY") {
        me_zones = config.motion_exclusion_zones;
      } else if (currentzone === "OVERRIDE1") {
        me_zones = config.override_exclusion_zones_1;
      } else if (currentzone === "OVERRIDE2") {
        me_zones = config.override_exclusion_zones_2;
      }

      var cimage =
        snap_type === "real_time"
          ? await appStore?.functionService.getSnapshot(
              token,
              sensor?.sensor_id
            )
          : await appStore?.functionService.getSnapshotFromServer(
              token,
              sensor.sensor_id
            );
      var scale = 1;

      ctx.setSnapshot(cimage.standard_image);
      setCanvasImage(cimage.standard_image);

      if (snap_type === "real_time") {
        if (
          cimage &&
          cimage.success &&
          config &&
          cimage.standard_image.stringb64
        ) {
          scale = cimage.standard_image.width / 500;
          processSnapshot(scale, cimage.standard_image, me_zones, newconfig);
        } else {
          setPopMessage("Could not get snapshot!");
          setLoading(false);
          openMessageModal();
        }
      } else {
        if (cimage && cimage.success && config && cimage.standard_image) {
          scale = cimage.standard_image.width / 500;
          processSnapshot(scale, cimage.standard_image, me_zones, newconfig);
        } else {
          setPopMessage("Could not get snapshot!");
          openMessageModal();
        }
      }
    }
    setLoading(false);
  };

  const processSnapshot = (
    scale: number,
    imageValue: CanvasImage,
    me_zones: any,
    newconfig: { [key: string]: any }
  ): void => {
    if (
      (config.image_width == undefined || config.image_width == null) &&
      me_zones
    ) {
      me_zones = me_zones.replace("[[", "").replace("]]", "").split("],[");
      let str_new_me_zones = "";
      if (me_zones && me_zones.length > 0) {
        let str_new_polygon = "";
        me_zones.forEach((me_zone: any) => {
          let pairs = me_zone.split("),(");
          if (pairs && pairs.length > 0) {
            pairs.forEach((pair: string) => {
              let points = pair.split(",");
              let newstringpair = "";
              if (points.length == 2) {
                let x = Number(points[0].replace("(", ""));
                let y = Number(points[1].replace(")", ""));
                x = Math.round(x * scale);
                y = Math.round(y * scale);
                newstringpair = "(" + x + "," + y + ")";
                str_new_polygon = str_new_polygon + newstringpair + ",";
              }
            });
          }
          str_new_polygon =
            "[" +
            str_new_polygon.substring(0, str_new_polygon.length - 1) +
            "]";
          str_new_me_zones = str_new_me_zones + str_new_polygon + ",";
        });
        str_new_me_zones =
          "[" +
          str_new_me_zones.substring(0, str_new_me_zones.length - 1) +
          "]";
        newconfig.motion_exclusion_zones = str_new_me_zones;
        setConfig(newconfig);
        let zones = getPolygons(str_new_me_zones);
        if (currentzone === "PRIMARY") {
          setExclusionZones(zones);
        } else if (currentzone === "OVERRIDE1") {
          setOverrideExclusionZones1(zones);
        } else if (currentzone === "OVERRIDE2") {
          setOverrideExclusionZones2(zones);
        }
      }
    }

    setCanvasImage(imageValue);
    setLoading(false);
  };

  const openMessageModal = () => {
    setMessageModalOpen(true);
  };

  const closeMessageModal = () => {
    setMessageModalOpen(false);
  };

  const reset = () => {
    fetchZonesData();
  };

  const getZoneLabel = (zoneNames: string[] | []) => {
    //Calculates the next suffix from the list of the zoneNames supplied.
    let sensor_id_suffixes: any = [];
    let next_suffix: any;

    if (zoneNames.length === 0) return "a";

    // Extract suffixes of zoneNames and put them in one array
    zoneNames.forEach((item: string) => {
      sensor_id_suffixes.push(item.toLowerCase());
    });

    // check which suffix(s) has the highest string length
    // sort array according to string length
    sensor_id_suffixes.sort((a: any, b: any) => b.length - a.length);

    // Get the highest string length and filter all items with that string length
    let highest_str_length = sensor_id_suffixes[0].length;
    let suffixes_with_highest_length = sensor_id_suffixes.filter(
      (item: any) => item.length === highest_str_length
    );

    //Sort the filtered suffixes in descending order
    suffixes_with_highest_length.sort().reverse();

    // Remove the 1st item
    let last_used_suffix = suffixes_with_highest_length[0];

    // Check if that last used suffix contains z

    if (last_used_suffix.includes("z")) {
      // If highest_str_length  is greater than 1 simply append "a" to last_used_suffix
      // If not simply initialize next_suffix  to "aa"

      if (highest_str_length > 1) {
        //check how many times z appears
        let count = suffixes_with_highest_length[0].match(/z/g) || [];

        // Check if z appears more than once
        if (count.length > 1) {
          // check if everything is z in string
          if (highest_str_length === count.length) {
            next_suffix = "a";
            count.forEach((z: string) => {
              next_suffix += "a";
            });
            return next_suffix;
          } else {
            // split suffixes_with_highest_length[0] by z
            let cntWithoutZ = suffixes_with_highest_length[0].split("z")[0];

            // get the last char and increment & store nextChar
            let lastChar = cntWithoutZ[cntWithoutZ.length - 1];
            let nextChar = String.fromCharCode(
              lastChar.charCodeAt(lastChar.length - 1) + 1
            );

            // remove the last char
            let conentsWithoutLastchar = cntWithoutZ.slice(0, -1);

            // join returned by next char + a based on for each
            next_suffix = conentsWithoutLastchar + nextChar;
            count.forEach((z: any) => {
              next_suffix += "a";
            });
            return next_suffix;
          }
        } else {
          // copy suffixes_with_highest_length[0] into new arr
          let contents = suffixes_with_highest_length[0];

          // splice z out and store in var
          let cntWithoutZ = contents.slice(0, -1);

          // get the last character and increament store in var
          let lastChar = cntWithoutZ[cntWithoutZ.length - 1];
          let nextChar = String.fromCharCode(
            lastChar.charCodeAt(lastChar.length - 1) + 1
          );

          // remove the last char after z and store returned in var
          let cntWithoutlastChar = cntWithoutZ.slice(0, -1);

          //join the return + increamented char + "a"
          next_suffix = cntWithoutlastChar + nextChar + "a";

          // next_suffix = last_used_suffix + "a";
          return next_suffix;
        }
      } else {
        next_suffix = "aa";
        return next_suffix;
      }
    } else {
      //When the last used suffix doesn't contain z
      // Check if highest_str_length  is greater than 1
      if (highest_str_length > 1) {
        // If greater than 1, Check the last_character, store it a variable
        // remove the last_character from string
        // append the next character to the new string returned from operation above
        let last_character = last_used_suffix[highest_str_length - 1];
        let first_characters = last_used_suffix.slice(0, -1);
        let next_character = String.fromCharCode(
          last_character.charCodeAt(last_character.length - 1) + 1
        );
        next_suffix = first_characters + next_character;
        return next_suffix;
      } else {
        // If highest_str_length is NOT greater than 1, simply use the next character and assign value for next Character
        next_suffix = String.fromCharCode(
          last_used_suffix.charCodeAt(last_used_suffix.length - 1) + 1
        );
        return next_suffix;
      }
    }
  };

  //Create exclusion zones in cofig format. .. IMPORTANT! THIS DOES NOT SAVE TO THE DB
  const saveExclusionZones = () => {
    var motion_exclusion_zones = [];
    var motion_exclusion_zone_with_key = [];
    for (let key in exclusionzones) {
      if (key.startsWith("Polygon")) {
        let motion_exclusion_zone: string = "";
        var points: [] = exclusionzones[key];
        //Polygon has to have at least 3 points!
        if (points.length > 2) {
          points.forEach((point, index) => {
            if (point[1] != undefined) {
              var polypoint = "(" + point[0] + "," + point[1] + ")";
              motion_exclusion_zone = motion_exclusion_zone + polypoint + ",";
            }
          });
          motion_exclusion_zone = motion_exclusion_zone.substring(
            0,
            motion_exclusion_zone.length - 1
          );
          motion_exclusion_zones.push(motion_exclusion_zone);
          motion_exclusion_zone_with_key.push({
            key: key,
            zone: motion_exclusion_zone,
          });
        }
      }
    }
    var zones = "[";
    var zonesList: any[] = [];
    var zones_list: any[] = [];
    var temp_zone_list: any[] = [];

    motion_exclusion_zone_with_key.forEach((me_zone, index) => {
      let newZone: any = {};
      newZone[`${index + 1}`] = "[" + me_zone.zone + "]";

      zones = zones + "[" + me_zone.zone + "],";
      zonesList.push(newZone);

      const indx = +me_zone.key.split("_")[1];
      const foundLetter = zoneOptions[indx];

      let suffix;
      if (foundLetter) {
        suffix = foundLetter.toUpperCase();
      } else {
        suffix = getZoneLabel(temp_zone_list).toUpperCase();
      }

      zones_list.push(suffix);
      temp_zone_list.push(suffix);
    });

    if (zones.length > 1) {
      zones = zones.substring(0, zones.length - 1) + "]";
    } else {
      zones = zones + "]";
    }

    if (config && (zones != "[]" || clearZonesCheck.current === true)) {
      config.motion_exclusion_zones = zones;
      ctx.setZoneOptions(zones_list);
      ctx.setSavedZones(zones);
    }
    setConfig(config);

    setRefresh(new Date().getTime());
  };

  const saveExclusionZonesOverride1 = () => {
    var motion_exclusion_zones = [];
    for (let key in overrideexclusionzones1) {
      if (key.startsWith("Polygon")) {
        let motion_exclusion_zone: string = "";
        var points: [] = overrideexclusionzones1[key];
        //Polygon has to have at least 3 points!
        if (points.length > 2) {
          points.forEach((point) => {
            if (point[1] != undefined) {
              var polypoint = "(" + point[0] + "," + point[1] + ")";
              motion_exclusion_zone = motion_exclusion_zone + polypoint + ",";
            }
          });
          motion_exclusion_zone = motion_exclusion_zone.substring(
            0,
            motion_exclusion_zone.length - 1
          );
          motion_exclusion_zones.push(motion_exclusion_zone);
        }
      }
    }
    var zones = "[";
    motion_exclusion_zones.forEach((me_zone) => {
      zones = zones + "[" + me_zone + "],";
    });
    if (zones.length > 1) {
      zones = zones.substring(0, zones.length - 1) + "]";
    } else {
      zones = zones + "]";
    }
    if (config) {
      config.override_exclusion_zones_1 = zones;
    }
    setConfig(config);
    setRefresh(new Date().getTime());
  };

  const saveExclusionZonesOverride2 = () => {
    var motion_exclusion_zones = [];
    for (let key in overrideexclusionzones2) {
      if (key.startsWith("Polygon")) {
        let motion_exclusion_zone: string = "";
        var points: [] = overrideexclusionzones2[key];
        //Polygon has to have at least 3 points!
        if (points.length > 2) {
          points.forEach((point) => {
            if (point[1] != undefined) {
              var polypoint = "(" + point[0] + "," + point[1] + ")";
              motion_exclusion_zone = motion_exclusion_zone + polypoint + ",";
            }
          });
          motion_exclusion_zone = motion_exclusion_zone.substring(
            0,
            motion_exclusion_zone.length - 1
          );
          motion_exclusion_zones.push(motion_exclusion_zone);
        }
      }
    }
    var zones = "[";
    motion_exclusion_zones.forEach((me_zone) => {
      zones = zones + "[" + me_zone + "],";
    });
    if (zones.length > 1) {
      zones = zones.substring(0, zones.length - 1) + "]";
    } else {
      zones = zones + "]";
    }
    if (config) {
      config.override_exclusion_zones_2 = zones;
    }
    setConfig(config);
    setRefresh(new Date().getTime());
  };

  //Receive data from completed polygon
  const onDataUpdate = (zones: any) => {
    delete zones["Line"];

    if (zones["Polygon_0"] && zones["Polygon_0"].length < 3)
      delete zones["Polygon_0"];

    if (JSON.stringify(zones) === "{}") {
      clearZonesCheck.current = true;
    }

    if (currentzone === "PRIMARY") {
      setExclusionZones(zones);
      saveExclusionZones();
    } else if (currentzone === "OVERRIDE1") {
      setOverrideExclusionZones1(zones);
      saveExclusionZonesOverride1();
    } else if (currentzone === "OVERRIDE2") {
      setOverrideExclusionZones2(zones);
      saveExclusionZonesOverride2();
    }
  };

  const onFinishDraw = () => {
    //nothing todo has data comes through on onDataUpdate
  };

  // sensor config from the DB using json server
  const fetchZonesData = async () => {
    setLoading(true);
    var token = await appStore?.authService.getAuthorisedToken();
    if (token) {
      const request = JSON.stringify({
        sensor_id: sensor?.sensor_id,
      });

      try {
        const baseUrl = getBaseUrl();
        let res: any = await fetch(baseUrl + "/fetch_advanced_alert_zones", {
          method: "post",
          headers: {
            Accept: "application/json",
            Authorization: "Bearer " + token,
            "Content-Type": "application/json",
          },
          body: request,
        });
        let result = await (await res).json();

        let zone_names_list = [];
        let full_image_zone = "";
        let coordinates = "[";
        let coordinates_in_use = "[";

        let temp_object = result?.advanced_alert_zones;

        let keys_list = temp_object
          ? Object.keys(temp_object)
              .sort(function (a, b) {
                if (b < a) {
                  return -1;
                }
                if (b > a) {
                  return 1;
                }
                return 0;
              })
              .sort(function (a, b) {
                return b.length - a.length;
              })
              .reverse()
          : [];

        let arr_keys = [];

        for (let zone_name of result.zones_in_use || []) {
          let indx = keys_list.indexOf(zone_name);
          if (indx >= 0) {
            arr_keys.push(`Polygon_${indx}`);
          }
        }

        setZoneKeysInUse(arr_keys);

        for (let x in result.advanced_alert_zones) {
          if (x == "full_image") {
            full_image_zone = "full image";
          } else {
            zone_names_list.push(x);
            coordinates = coordinates + result.advanced_alert_zones[x] + ",";
            if (result.zones_in_use.indexOf(x) >= 0) {
              coordinates_in_use =
                coordinates_in_use + result.advanced_alert_zones[x] + ",";
            }
          }
        }

        coordinates = coordinates + "]";
        coordinates_in_use = coordinates_in_use + "]";

        coordinates = coordinates.replace("],]", "]]").replace(" ", "");
        coordinates = coordinates.replace(/ /g, "");

        coordinates_in_use = coordinates_in_use
          .replace("],]", "]]")
          .replace(" ", "");
        coordinates_in_use = coordinates_in_use.replace(/ /g, "");

        if (
          coordinates &&
          result?.standard_image &&
          result?.standard_image?.stringb64 !== ""
        ) {
          let zones = getPolygons(coordinates);
          let zones_in_use = getPolygons(coordinates_in_use);

          setCoordsInUse(coordinates);
          setSavedMotionExclusionZones(coordinates);

          setZonesInUse(zones_in_use);
          ctx.setSavedZones(coordinates);
          ctx.setRuleSnapshotId(result.id || 0);
          setZoneOptions(
            zone_names_list.filter((option) => option !== "full image")
          );
          zone_names_list.push(full_image_zone);
          ctx.setZoneOptions(zone_names_list);
          ctx.setSnapshot(result.standard_image);
          setCanvasImage(result.standard_image);
          ctx.setScaleFactor(result.scale_factor);
          setCanvasImageScaleFactor(result.scale_factor);
          setExclusionZones(zones);
          setSavedZones(zones);
        } else {
          getSnap("real_time2");
          setSavedMotionExclusionZones("[]");
          ctx.setSavedZones("[]");
          ctx.setRuleSnapshotId(result[0]?.id || 0);
          ctx.setZoneOptions(["full image"]);
          setExclusionZones({});
        }
      } catch (err) {
        console.debug("err -> ", err);
        setLoading(false);
      }
    }
    setLoading(false);
  };
  useEffect(() => {
    if (!defaultRule && sensor) {
      fetchZonesData();
    }
  }, [site, sensor]);

  const fetchWhitelistData = async () => {
    setLoading(true);
    var token = await appStore?.authService.getAuthorisedToken();
    if (token && site) {
      try {
        const res = await fetch(
          baseUrl + `/listwhitelist`,
          // `http://localhost:3004/list_whitelist?site_id=${siteid[0]}`,
          {
            method: "POST",
            headers: {
              Accept: "application/json",
              Authorization: "Bearer " + token,
              "Content-Type": "application/json",
            },
            body: JSON.stringify({ site_id: site.site_id }),
          }
        );
        const result = await (await res).json();
        if (result.success) {
          if (result.msg.known_vehicle_list.length > 0) {
            const whitelistData = [
              ...result.msg.known_vehicle_list.map((item: any) => {
                return {
                  name: item.name,
                  site_id: site.site_id,
                  id: item.known_vehicle_list_id,
                };
              }),
            ];
            setAlprWhitelist(whitelistData);
            ctx.setWhitelistName(whitelistData[0]);
          }
        } else {
          setAlprWhitelist([
            { id: -1, name: "Default Whitelist", site_id: site.site_id },
          ]);
        }
      } catch (err) {
        return err;
      } finally {
        setLoading(false);
      }
    } else {
      setAlprWhitelist([]);
    }
  };

  useEffect(() => {
    if (sensorDefinedRule?.fields?.whitelist_id !== undefined) {
      fetchWhitelistData();
    }
  }, [site]);

  const fetchRules = async () => {
    ctx.setRuleList([]);
    setLoading(true);
    try {
      ctx.setRuleEnvironment("None");
      var token = await appStore?.authService.getAuthorisedToken();
      if (token) {
        const baseUrl = getBaseUrl();

        const request = JSON.stringify({
          sensor_id: sensor?.sensor_id,
        });

        const res = await fetch(baseUrl + `/list_sensor_rules`, {
          method: "post",
          headers: {
            Accept: "application/json",
            Authorization: "Bearer " + token,
            "Content-Type": "application/json",
          },
          body: request,
        });
        const result = (await (await res).json()) as any;

        if (result?.alert_configs?.length > 0) {
          var isSnapshotRule = false;
          const filteredResults = result.alert_configs.map((item: any) => {
            if (item?.alert_config_type === "Snapshot") {
              isSnapshotRule = true;
            }
            const ruleKeys = Object.keys(item?.rule_configs) || [];

            let zoneConfigs: any = [];
            ruleKeys.map((key: string) => {
              if (item?.rule_configs[key]?.zone_configs) {
                item?.rule_configs[key]?.zone_configs.map((zone: any) =>
                  zoneConfigs.push({
                    ...zone,
                    rule_type: key,
                  })
                );
              } else {
                zoneConfigs.push({
                  zone: "full_image",
                  rule_type: key,
                  type: "zone",
                });
              }
            });

            return {
              cadence_secs: item?.cadence_secs,
              sensor_id: item?.sensor_id,
              rulesName: item?.alert_config_name || "no data",
              rulesType: item?.alert_config_type || "no data",
              alertType: item?.alert_type || "no data",
              rulesSubType: item?.alert_config_type || "no data",
              zoneConfigs: zoneConfigs,
              environment: item?.environment || "no data",
              valid_days_and_times: item?.valid_days_and_times,
              exact_times: item?.exact_times,
              id: item?.alert_config_id,
              deliveryEndpoints: !item?.meta?.end_point_ids
                ? ["ALL"]
                : deliveryEndpoints.length > 0
                ? deliveryEndpoints
                    .filter(
                      (endpoint: any) =>
                        item?.meta?.end_point_ids
                          // .split(",")
                          .indexOf(endpoint.alert_channel_end_point_id) > -1
                    )
                    .map((i: any) => `${i.name}, `) || ["none"]
                : ["none"],
            };
          });
          setHasSnapshotRule(isSnapshotRule);
          ctx.setRuleList(filteredResults);
          if (filteredResults[0]["environment"]) {
            ctx.setRuleEnvironment(filteredResults[0]["environment"]);
          }
          if (filteredResults.length > 0)
            ctx.setLastRuleId(filteredResults[0].id + 1);
        } else if (result?.alert_configs?.length === 0) {
          ctx.setRuleList([]);
        }
      }
    } catch (e) {
      console.debug(e);
    }

    setLoading(false);
  };

  const fetchTimeZone = async () => {
    setLoading(true);
    const token = await appStore?.authService.getAuthorisedToken();
    if (token && sensor) {
      const request = JSON.stringify({
        site_id: sensor?.site_id,
      });
      try {
        const baseUrl = getBaseUrl();
        const res = fetch(baseUrl + "/fetch_time_zone", {
          method: "post",
          headers: {
            Accept: "application/json",
            Authorization: "Bearer " + token,
            "Content-Type": "application/json",
          },
          body: request,
        });
        const result = await (await res).json();
        if (result?.success) {
          ctx.setTimeZone(result.timezone_str);
        } else {
          ctx.setTimeZone("UTC");
        }
      } catch (err) {
        console.debug("err -> ", err);
        setLoading(false);
        ctx.setTimeZone("UTC");
      } finally {
        setLoading(false);
      }
    }
  };

  useEffect(() => {
    if (!showTable && deliveryEndpoints && !defaultRule) {
      setLoading(true);
      fetchRules();
      fetchTimeZone();
    }
  }, [site, sensor, deliveryEndpoints]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const isSMTPOrClientHub =
    site?.data_origin?.includes("smtp_client") ||
    site?.data_origin?.includes("cameramate") ||
    site?.data_origin?.includes("client_hub");

  let temp_rule_types: any = [];

  if (ctx.zone_config) {
    ctx.zone_config.map((item: any) => {
      if (!temp_rule_types.includes(item.rule_type)) {
        temp_rule_types.push(item.rule_type);
      }
    });
  }

  const [isModalOpen, setIsModalOpen] = useState(false);

  const showModal = () => {
    setIsModalOpen(true);
  };

  const handleOk = () => {
    setIsModalOpen(false);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };

  const knownVehicleFilterForm = showTable &&
    sensorDefinedRule?.fields?.alert_config_type === "KnownVehicleFilter" && (
      <Grid item xs={12}>
        <br />
        <br />
        <h1
          className={classes.bold_text}
          style={{ marginLeft: 20, fontSize: 15, textAlign: "center" }}
        >
          Select Whitelist:
        </h1>
        <br />
        <div
          style={{
            display: "flex",
            alignItems: "center",
            textAlign: "center",
            width: "100%",
          }}
        >
          <Autocomplete
            id="combo-box-demo"
            onChange={(event: React.ChangeEvent<any>, value: any) => {
              handleChangeAuto(value);
            }}
            disabled={alpr_whitelist.length === 0}
            defaultValue={
              alpr_whitelist.length === 0
                ? { id: -1, name: "Default Whitelist" }
                : alpr_whitelist[0]
            }
            options={alpr_whitelist}
            getOptionLabel={(option: any) => option?.name}
            style={{ width: 600, color: "#15325F" }}
            renderInput={(params: any) => (
              <TextField
                {...params}
                label={
                  <span
                    className={classes.bold_text}
                    style={{ color: "#15325F" }}
                  >
                    {whitelistName ? whitelistName.name : ""}
                  </span>
                }
                // variant="outlined"
                className={classes.bold_text}
              />
            )}
          />
        </div>
        <br />
      </Grid>
    );

  const basicRuleConfigsForm = ((showTable && !showSummary) || editable) && (
    <Grid item xs={12}>
      <br />
      <h1
        className={classes.bold_text}
        style={{ marginLeft: 20, fontSize: 15, textAlign: "center" }}
      >
        Enter Alert Name:
      </h1>
      <br />
      <div
        style={{
          display: "flex",
          alignItems: "center",
          textAlign: "center",
          width: "100%",
        }}
      >
        <TextField
          id="outlined-basic"
          multiline
          value={ctx.rule_name.replaceAll("_", " ")}
          placeholder={
            ctx.rule_name.replaceAll("_", " ") ||
            `${sensorDefinedRule?.fields?.name.default} ${ctx.last_rule_id}`.trim()
            // ctx.rule_name.replaceAll("_", " ") ||
            // `${ctx.rule_type
            //   .toLocaleUpperCase()
            //   .replaceAll("_", " ")} ${ctx.last_rule_id}`.trim()
          }
          onChange={(e: any) => ctx.setRuleName(e.target.value)}
          style={{ width: 600, margin: 10, color: "#15325F" }}
        />
      </div>
      <br />
      {!defaultRule && (
        <>
          <br />
          <h1
            className={classes.bold_text}
            style={{ marginLeft: 20, fontSize: 15, textAlign: "center" }}
          >
            Alert Delivery Endpoints:
          </h1>
          <br />
          <div>
            <FormControl className={classes.form_component_medium1}>
              {endpointNames.length === 0 && (
                <InputLabel id="demo-mutiple-chip-label">
                  &nbsp;&nbsp;&nbsp;&nbsp;All Endpoints
                </InputLabel>
              )}
              <Select
                labelId="demo-mutiple-chip-label"
                id="demo-mutiple-chip"
                multiple
                // disabled={!!deliveryEndpoints.length}
                value={endpointNames}
                onChange={handleChange}
                input={
                  <Input style={{ width: "100%" }} id="select-multiple-chip" />
                }
                renderValue={(selected) => (
                  <div className={classes.chips}>
                    {(selected as string[]).map((value) => (
                      <Chip
                        key={value}
                        label={
                          [
                            {
                              alert_channel_end_point_id: -1,
                              name: "Select all delivery endpoints",
                            },
                            ...deliveryEndpoints,
                          ].find(
                            (item: any) =>
                              item.alert_channel_end_point_id === value
                          )?.name || ""
                        }
                        className={classes.chip}
                      />
                    ))}
                  </div>
                )}
                MenuProps={MenuProps}
              >
                {[...deliveryEndpoints].map((item: any) => (
                  <MenuItem
                    key={item.name}
                    value={item.alert_channel_end_point_id}
                    style={getStyles(item.name, personName, theme)}
                  >
                    {item.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </div>
        </>
      )}

      {ruleOptions && !editable && (
        <>
          <br />
          <br />
          <div style={{ width: "600px", margin: "0 auto" }}>
            <MultipleSelect
              handleValueChange={(value: any) => {
                setRuleOption(value);
              }}
              defaultValue={ruleOption}
              multiple={false}
              title={"Select Rule Type"}
              names={ruleOptions}
              value={ruleOption}
              fullWidth={true}
            />
          </div>
        </>
      )}

      {knownVehicleFilterForm}
      {/* Fetch Interval*/}
      {sensorDefinedRule?.fields?.cadence_secs?.enabled ? (
        <>
          <br />
          <h1
            className={classes.bold_text}
            style={{
              marginLeft: 20,
              fontSize: 15,
              textAlign: "center",
            }}
          >
            {sensorDefinedRule?.fields?.cadence_secs?.label}:{" "}
            {sensorDefinedRule?.fields?.cadence_secs?.default / 60} min
          </h1>
          <br />
          <div style={{ width: "100%", margin: "0 auto" }}>
            <MultipleSelect
              fullWidth
              handleValueChange={(value: any) => {
                setCadenceSecs(value);
                ctx.setFetchInterval(value);
              }}
              defaultValue={
                sensorDefinedRule?.fields?.cadence_secs?.default
                  ? `${
                      sensorDefinedRule?.fields?.cadence_secs?.default / 60
                    } min`
                  : cadenceSecs
              }
              multiple={false}
              title=""
              names={sensorDefinedRule?.fields?.cadence_secs?.options.map(
                (item: any) => `${item / 60} min`
              )}
              value={cadenceSecs}
            />
          </div>
        </>
      ) : null}
      {editable && (
        <>
          <br />
          <br />
          <h1
            className={classes.bold_text}
            style={{
              marginLeft: 20,
              fontSize: 15,
              marginBottom: 10,
              textAlign: "center",
            }}
          >
            Active Alert Times:
          </h1>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
              width: "100%",
            }}
          >
            <TimeSelector
              editable
              isWizard
              noEndTime={
                sensorDefinedRule?.fields?.name.default === "Snapshot Rule"
              }
            />
          </div>
        </>
      )}
    </Grid>
  );

  const AlertSettingsForm = (
    <Modal
      closeIcon={null}
      title=""
      open={isModalOpen}
      onOk={handleOk}
      onCancel={handleCancel}
    >
      <div
        style={{
          backgroundColor: "#f6f6f6",
          borderRadius: 12,
          padding: 20,
          border: "1px solid #ccce",
          marginTop: 7,
        }}
      >
        {basicRuleConfigsForm}
      </div>
    </Modal>
  );

  var card: React.ReactNode[] = [];
  if (
    (!loading &&
      canvasimage &&
      canvasimage &&
      canvasimage.stringb64 &&
      canvasimage.stringb64 != "") ||
    defaultRule
  ) {
    var drawcanvas: React.ReactNode[] = [];

    if (currentzone === "PRIMARY" && !defaultRule) {
      drawcanvas.push(
        <DrawCanvas
          hideDelete={editable}
          key={currentzone}
          allowDrawing={!showTable ? true : false}
          ref={canvasref}
          showlabels
          imgSrc={"data:image/png;base64," + canvasimage?.stringb64}
          tool={"Polygon"}
          width={canvasimage?.width}
          height={canvasimage?.height}
          brushSize={2}
          color={"black"}
          onFinishDraw={onFinishDraw}
          onDataUpdate={onDataUpdate}
          initialData={getPolygons(saved_motion_exclusion_zones)}
          savedData={getPolygons(saved_motion_exclusion_zones)}
          zonesInUse={zonesInUse}
          zonesKeysInUse={zoneKeysInUse}
          zone={true}
          zoneOptions={zoneOptions}
        />
      );
    }

    card.push(
      <>
        <Grid
          container
          spacing={1}
          justifyContent="center"
          direction="column"
          alignItems="center"
        >
          {/* {!showTable && (
            <Grid item xs={10}>
              <Card
                className={classes.icon_container}
                style={{
                  cursor: "pointer",
                  borderRadius: 8,
                  border: "1px solid rgb(0, 60, 128)",
                  backgroundColor: "rgb(0, 60, 128)",
                }}
                onClick={reset}
              >
                <Tooltip placement="bottom" title="Reset exclusion zones">
                  <IconButton edge="end" aria-label="add">
                    <RotateLeftIcon style={{ color: "white" }} />{" "}
                    <span
                      style={{ fontWeight: 600, fontSize: 14, color: "white" }}
                    >
                      Undo Changes
                    </span>
                  </IconButton>
                </Tooltip>
              </Card>
            </Grid>
          )} */}
          <Grid xs={12}>
            <Card
              className={
                editable ? classes.card_container : classes.card_container1
              }
              style={{
                // width: 740,
                // height: 580,
                // overflowX: "scroll",
                // overflowY: "scroll",
                display: "flex",
                flexDirection: "column",
                paddingBottom: "10px",
                width: defaultRule ? 1100 : "100%",
              }}
            >
              {!showTable && (
                <p
                  style={{
                    width: "80%",
                    alignSelf: "center",
                    fontSize: 14,
                    margin: "auto",
                    marginBottom: "10px",
                    textAlign: "center",
                  }}
                >
                  Please draw inclusion zones on the canvas below - note that a
                  full image zone is automatically created
                </p>
              )}
              <div
                style={{
                  display: "flex",
                  justifyContent: editable ? "space-between" : "center",
                  width: "100%",
                }}
              >
                {!defaultRule && drawcanvas}
                {showSummary && (
                  <div
                    style={{
                      width: 400,
                      paddingTop: 15,
                      textAlign: "left",
                      marginLeft: 30,
                    }}
                  >
                    <h4 style={{ color: "#2E4FA5", fontWeight: 700 }}>
                      Alert Name:
                    </h4>
                    <p style={{ color: "#2E4FA5" }}>
                      {ctx.rule_name.replaceAll("_", " ") ||
                        `${sensorDefinedRule?.fields?.name.default}`.trim() ||
                        "Enter Rule Name"}
                    </p>
                    {sensorDefinedRule?.fields?.cadence_secs?.enabled && (
                      <>
                        <br />
                        <h4 style={{ color: "#2E4FA5", fontWeight: 700 }}>
                          Fetch Interval:{" "}
                          <span style={{ color: "#2E4FA5", fontWeight: 200 }}>
                            {cadenceSecs}
                          </span>
                        </h4>
                      </>
                    )}
                    {showTable &&
                      sensorDefinedRule?.fields?.alert_config_type ===
                        "KnownVehicleFilter" && (
                        <>
                          <br />
                          <h4 style={{ color: "#2E4FA5", fontWeight: 700 }}>
                            Whitelist:{" "}
                            <span style={{ color: "#2E4FA5", fontWeight: 200 }}>
                              {whitelistName
                                ? whitelistName
                                : "Default Whitelist"}
                            </span>
                          </h4>
                        </>
                      )}

                    {!defaultRule && (
                      <>
                        {" "}
                        <br />
                        <h4 style={{ color: "#2E4FA5", fontWeight: 700 }}>
                          Alert Delivery Endpoints:
                        </h4>
                        <p style={{ color: "#2E4FA5" }}>
                          {endpointNames.length !== 0 ? (
                            <Select
                              labelId="demo-mutiple-chip-label"
                              id="demo-mutiple-chip"
                              multiple
                              // disabled={!!deliveryEndpoints.length}
                              value={endpointNames}
                              onChange={handleChange}
                              style={{ border: "none", width: "100%" }}
                              input={
                                <Input
                                  style={{
                                    width: "fit-content",
                                    border: "none",
                                  }}
                                  id="select-multiple-chip"
                                />
                              }
                              renderValue={(selected) => (
                                <div className={classes.chips}>
                                  {(selected as string[]).map((value) => (
                                    <Chip
                                      key={value}
                                      label={
                                        [
                                          {
                                            alert_channel_end_point_id: -1,
                                            name: "Select all delivery endpoints",
                                          },
                                          ...deliveryEndpoints,
                                        ].find(
                                          (item: any) =>
                                            item.alert_channel_end_point_id ===
                                            value
                                        )?.name || ""
                                      }
                                      className={classes.chip}
                                    />
                                  ))}
                                </div>
                              )}
                              MenuProps={MenuProps}
                              disabled
                            >
                              {[...deliveryEndpoints].map((item: any) => (
                                <MenuItem
                                  key={item.name}
                                  value={item.alert_channel_end_point_id}
                                  style={getStyles(
                                    item.name,
                                    personName,
                                    theme
                                  )}
                                >
                                  {item.name}
                                </MenuItem>
                              ))}
                            </Select>
                          ) : (
                            <span>All Endpoints</span>
                          )}
                        </p>
                      </>
                    )}
                    <br />
                    <h4 style={{ color: "#2E4FA5", fontWeight: 700 }}>
                      Active Alert Times:{" "}
                      <span style={{ color: "#2E4FA5", fontWeight: 200 }}>
                        {ctx.scheduled ? "Scheduled" : "Always"}
                      </span>
                    </h4>
                    <br />
                    <div style={{ cursor: "pointer" }} onClick={showModal}>
                      <EditButton />
                    </div>
                  </div>
                )}
              </div>
              {/* {sensor?.snapshot_width && sensor?.snapshot_height && (
                <p
                  style={{
                    width: "100%",
                    fontSize: 14,
                    textAlign: "center",
                    margin: "auto",
                    marginBottom: "10px",
                    marginTop: "10px",
                  }}
                >
                  Your camera resolution is {sensor.snapshot_width} x{" "}
                  {sensor.snapshot_height}
                </p>
              )} */}
            </Card>
          </Grid>

          {!showTable && (
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                width: "100%",
                marginTop: 20,
              }}
            >
              <CustomButton
                disabled={false}
                title={"Undo Changes"}
                onClickHandler={reset}
                id="undo"
                styles={{ backgroundColor: "#6287E8" }}
              />
              <CustomButton
                disabled={false}
                title={!showWizard ? "Save Zones" : "Close"}
                onClickHandler={() => {
                  if (saveZonesHandler) {
                    saveZonesHandler();
                  }
                }}
                id="ad"
                styles={{ backgroundColor: "#2E4FA5" }}
              />
              <CustomButton
                disabled={false}
                title={"Configure Alert"}
                onClickHandler={() => {
                  if (setOpenWizardModal) {
                    setOpenWizardModal(true);
                  }
                }}
                styles={{ backgroundColor: "#88DE54", color: "black" }}
                id="ad"
              />
              {/* <CustomButton
                disabled={false}
                title={"Fetch Snapshot"}
                onClickHandler={() => {
                  if (
                    site?.data_origin === "alert_smtp_client" ||
                    site?.data_origin === "sentinel_http_edge_human1" ||
                    site?.data_origin === "immix_ailink_human1"
                  ) {
                    getSnap("server");
                  } else {
                    getSnap("real_time");
                  }
                }}
                id="ad"
              /> */}
            </div>
          )}

          <br />
          {showTable && (
            // ctx.rule_type !== "Scene Change Alert" &&
            // ctx.rule_type !== "unusual_activity" &&
            // ctx.rule_type !== "Snapshot" && (
            <>
              <>
                <div
                  style={{
                    width: "100%",
                    padding: 15,
                    backgroundColor: "#f6f6f6",
                    borderRadius: 8,
                    marginTop: 30,
                    border: "1px solid lightgrey",
                  }}
                >
                  <div
                    style={{
                      width: "100%",
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                      marginBottom: 30,
                      marginTop: 0,
                      fontWeight: 700,
                      color: "#2E4FA5",
                    }}
                  >
                    <div>
                      {ctx.alertType} :{" "}
                      {sensorDefinedRule?.fields?.alert_config_type}
                    </div>
                    {ruleOptions && (
                      <div>
                        Actively Using {temp_rule_types.length}/
                        {ruleOptions.length}
                      </div>
                    )}
                  </div>
                  <div style={{ minHeight: 100, overflowY: "scroll" }}>
                    <CardSelect
                      zoneConfigs={ctx.zone_config}
                      handleValueChange={(value: any) => {
                        setRuleOption(value);
                      }}
                      // title={"Select Rule Type"}
                      names={ruleOptions}
                      value={ruleOption}
                      fullWidth
                    />
                  </div>
                </div>
              </>
              <Grid item xs={12} style={{ width: "100%" }}>
                <RuleConfigs
                  configData={sensor?.config}
                  sensorId={sensor?.sensor_id}
                  siteId={sensor?.site_id}
                  site={site}
                  sensorDefinedRule={sensorDefinedRule}
                  ruleType={ruleOption}
                  defaultRule={defaultRule}
                />
              </Grid>
            </>
          )}
          <br />
          {!showTable && ctx?.rulesList?.length > 0 && (
            <Grid item xs={12}>
              <h1
                style={{
                  fontSize: 15,
                  marginBottom: 10,
                  fontWeight: 600,
                  textAlign: "left",
                  alignContent: "center",
                  color: "#15325F",
                }}
              >
                Configured Rules: ({ctx.rulesList ? ctx.rulesList.length : 0})
              </h1>
              <RulesList
                appStore={appStore}
                reloadRules={fetchRules}
                reloadZones={fetchZonesData}
                sensorId={sensor?.sensor_id}
                timeZone={fetchTimeZone}
                site={site}
                sensorDefinedRule={sensorDefinedRule}
                sensorDefinedRules={sensorDefinedRules}
              />
            </Grid>
          )}
          {!showTable && loading && <p>Loading...</p>}
          <br />
        </Grid>
      </>
    );
  } else if (loading) {
    card.push(
      <Grid container spacing={1}>
        <Grid item xs={12}>
          <div
            style={{
              marginTop: 20,
              width: "100%",
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <div style={{ marginTop: 20, width: "100%", margin: "0 auto" }}>
              <LinearProgress />
            </div>
            <p style={{ marginTop: 10, width: "100%" }}>Loading...</p>
            <br />
            <Skeleton />
          </div>
        </Grid>
      </Grid>
    );
  } else {
    let display = (
      <Grid
        container
        spacing={1}
        alignItems={"center"}
        justifyContent={"center"}
        style={{ width: "98%" }}
      >
        <Grid item xs={12}>
          <Card
            className={classes.card_container}
            style={{ width: defaultRule ? 1100 : "80%" }}
          >
            <div
              style={{
                display: "flex",
                justifyContent: editable ? "space-between" : "center",
                width: "100%",
              }}
            >
              <img
                className={classes.placeholder}
                src="/assets/img/snapshot.png"
                alt=""
              />
              {showSummary && (
                <div
                  style={{
                    width: 400,
                    paddingTop: 15,
                    textAlign: "left",
                    marginLeft: 30,
                  }}
                >
                  <h4 style={{ color: "#2E4FA5", fontWeight: 700 }}>
                    Alert Name:
                  </h4>
                  <p style={{ color: "#2E4FA5" }}>
                    {ctx.rule_name.replaceAll("_", " ") ||
                      `${sensorDefinedRule?.fields?.name.default}`.trim() ||
                      "Enter Rule Name"}
                  </p>
                  {sensorDefinedRule?.fields?.cadence_secs?.enabled && (
                    <>
                      <br />
                      <h4 style={{ color: "#2E4FA5", fontWeight: 700 }}>
                        Fetch Interval:{" "}
                        <span style={{ color: "#2E4FA5", fontWeight: 200 }}>
                          {cadenceSecs}
                        </span>
                      </h4>
                    </>
                  )}
                  {showTable &&
                    sensorDefinedRule?.fields?.alert_config_type ===
                      "KnownVehicleFilter" && (
                      <>
                        <br />
                        <h4 style={{ color: "#2E4FA5", fontWeight: 700 }}>
                          Whitelist:{" "}
                          <span style={{ color: "#2E4FA5", fontWeight: 200 }}>
                            {whitelistName
                              ? whitelistName
                              : "Default Whitelist"}
                          </span>
                        </h4>
                      </>
                    )}

                  {!defaultRule && (
                    <>
                      {" "}
                      <br />
                      <h4 style={{ color: "#2E4FA5", fontWeight: 700 }}>
                        Alert Delivery Endpoints:
                      </h4>
                      <p style={{ color: "#2E4FA5" }}>
                        {endpointNames.length !== 0 ? (
                          <Select
                            labelId="demo-mutiple-chip-label"
                            id="demo-mutiple-chip"
                            multiple
                            // disabled={!!deliveryEndpoints.length}
                            value={endpointNames}
                            onChange={handleChange}
                            style={{ border: "none", width: "100%" }}
                            input={
                              <Input
                                style={{
                                  width: "fit-content",
                                  border: "none",
                                }}
                                id="select-multiple-chip"
                              />
                            }
                            renderValue={(selected) => (
                              <div className={classes.chips}>
                                {(selected as string[]).map((value) => (
                                  <Chip
                                    key={value}
                                    label={
                                      [
                                        {
                                          alert_channel_end_point_id: -1,
                                          name: "Select all delivery endpoints",
                                        },
                                        ...deliveryEndpoints,
                                      ].find(
                                        (item: any) =>
                                          item.alert_channel_end_point_id ===
                                          value
                                      )?.name || ""
                                    }
                                    className={classes.chip}
                                  />
                                ))}
                              </div>
                            )}
                            MenuProps={MenuProps}
                            disabled
                          >
                            {[...deliveryEndpoints].map((item: any) => (
                              <MenuItem
                                key={item.name}
                                value={item.alert_channel_end_point_id}
                                style={getStyles(item.name, personName, theme)}
                              >
                                {item.name}
                              </MenuItem>
                            ))}
                          </Select>
                        ) : (
                          <span>All Endpoints</span>
                        )}
                      </p>
                    </>
                  )}
                  <br />
                  <h4 style={{ color: "#2E4FA5", fontWeight: 700 }}>
                    Active Alert Times:{" "}
                    <span style={{ color: "#2E4FA5", fontWeight: 200 }}>
                      {ctx.scheduled ? "Scheduled" : "Always"}
                    </span>
                  </h4>
                  <br />
                  <div style={{ cursor: "pointer" }} onClick={showModal}>
                    <EditButton />
                  </div>
                </div>
              )}
            </div>
          </Card>
          {AlertSettingsForm}
        </Grid>

        {!showTable && (
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              width: "100%",
              marginTop: 20,
            }}
          >
            <CustomButton
              disabled={false}
              title={"Undo Changes"}
              onClickHandler={reset}
              id="undo"
              styles={{ backgroundColor: "#6287E8" }}
            />
            <CustomButton
              disabled={true}
              title={!showWizard ? "Save Zones" : "Close"}
              onClickHandler={() => {
                if (saveZonesHandler) {
                  saveZonesHandler();
                }
              }}
              id="ad"
              styles={{ backgroundColor: "#2E4FA5" }}
            />
            <CustomButton
              disabled={false}
              title={"Configure Alert"}
              onClickHandler={() => {
                if (setOpenWizardModal) {
                  setOpenWizardModal(true);
                }
              }}
              id="ad"
              styles={{ backgroundColor: "#88DE54", color: "black" }}
            />
            {/* <CustomButton
              disabled={false}
              title={"Take Snapshot"}
              onClickHandler={() => {
                if (
                  site?.data_origin === "alert_smtp_client" ||
                  site?.data_origin === "sentinel_http_edge_human1" ||
                  site?.data_origin === "immix_ailink_human1"
                ) {
                  getSnap("server");
                } else {
                  getSnap("real_time");
                }
              }}
              id="ad"
            /> */}
          </div>
        )}

        {showTable && (
          <>
            <Grid item xs={12}>
              <br />
              <br />
              {!showTable && ctx.rulesList.length > 0 && (
                <Grid item xs={12}>
                  <h1
                    style={{
                      fontSize: 15,
                      marginBottom: 10,
                      fontWeight: 600,
                      textAlign: "center",
                      alignContent: "center",
                      color: "#15325F",
                    }}
                  >
                    Rules:
                  </h1>
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      textAlign: "center",
                      justifyContent: "center",
                      width: "100%",
                    }}
                  >
                    <RulesList
                      appStore={appStore}
                      reloadRules={fetchRules}
                      sensorId={sensor?.sensor_id}
                      reloadZones={fetchZonesData}
                      timeZone={fetchTimeZone}
                      site={site}
                      sensorDefinedRule={sensorDefinedRule}
                      sensorDefinedRules={sensorDefinedRules}
                    />
                  </div>
                </Grid>
              )}
              {/* (
                <>
                  <h1
                    className={classes.bold_text}
                    style={{
                      marginLeft: 20,
                      fontSize: 15,
                      textAlign: "center",
                    }}
                  >
                    Enter Rule's Name:
                  </h1>
                  <br />
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      textAlign: "center",
                      justifyContent: "center",
                      width: "100%",
                    }}
                  >
                    <TextField
                      id="outlined-basic"
                      variant="outlined"
                      multiline
                      value={ctx.rule_name.replaceAll("_", " ")}
                      placeholder={
                        ctx.rule_name.replaceAll("_", " ") ||
                        `${ctx.rule_name
                          .toLocaleUpperCase()
                          .replace("_", " ")} ${ctx.last_rule_id}`.trim()
                      }
                      onChange={(e: any) => ctx.setRuleName(e.target.value)}
                      style={{ width: 600, marginLeft: 20 }}
                      className={classes.bold_text}
                    />
                  </div>
                </>
              ) */}
            </Grid>
            {showTable && showWhitelistField && (
              <Grid item xs={12}>
                <br />
                <br />
                <h1
                  className={classes.bold_text}
                  style={{ marginLeft: 20, fontSize: 15, textAlign: "center" }}
                >
                  Select Whitelist:
                </h1>
                <br />
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    textAlign: "center",
                    justifyContent: "center",
                    width: "100%",
                  }}
                >
                  <Autocomplete
                    id="combo-box-demo"
                    onChange={(event: React.ChangeEvent<any>, value: any) => {
                      handleChangeAuto(value);
                    }}
                    disabled={alpr_whitelist.length === 0}
                    defaultValue={
                      alpr_whitelist.length === 0
                        ? { id: -1, name: "Default Whitelist" }
                        : alpr_whitelist[0]
                    }
                    options={alpr_whitelist}
                    getOptionLabel={(option: any) => option.name}
                    style={{ width: 600, color: "#15325F", marginLeft: 20 }}
                    renderInput={(params: any) => (
                      <TextField
                        {...params}
                        label={
                          <span
                            className={classes.bold_text}
                            style={{ color: "#15325F" }}
                          >
                            {whitelistName ? whitelistName.name : ""}
                          </span>
                        }
                        className={classes.bold_text}
                      />
                    )}
                  />
                </div>
              </Grid>
            )}
          </>
        )}
        <br />
        {showTable && (
          <Grid item xs={12}>
            <RuleConfigs
              configData={sensor?.config}
              sensorId={sensor?.sensor_id}
              siteId={sensor?.site_id}
              site={site}
              sensorDefinedRule={sensorDefinedRule}
              ruleType={ruleOption}
              defaultRule={defaultRule}
            />
          </Grid>
        )}
        <br />
        {!showTable && ctx.rulesList.length > 0 && (
          <Grid item xs={12}>
            <h1
              style={{
                fontSize: 15,
                marginBottom: 10,
                fontWeight: 600,
                textAlign: "center",
                alignContent: "center",
                color: "#15325F",
              }}
            >
              Rules:
            </h1>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                textAlign: "center",
                justifyContent: "center",
                width: "100%",
              }}
            >
              <RulesList
                appStore={appStore}
                reloadRules={fetchRules}
                sensorId={sensor?.sensor_id}
                reloadZones={fetchZonesData}
                timeZone={fetchTimeZone}
                site={site}
                sensorDefinedRule={sensorDefinedRule}
                sensorDefinedRules={sensorDefinedRules}
              />
            </div>
          </Grid>
        )}
        <br />
      </Grid>
    );
    return display;
  }

  return (
    <div style={{ padding: 2 }}>
      {AlertSettingsForm}
      {card}
    </div>
  );
};

export function SelectEnvironment() {
  const classes = useStyles();
  const ctx = React.useContext(MainContext);
  const [currentEnv, setCurrentEnv] = useState("None");
  const sanityCheck = () => {
    if (ctx.rule_environment.includes("Other")) {
      setCurrentEnv("Other");
    } else if (ctx.rule_environment) {
      setCurrentEnv(ctx.rule_environment);
    }
  };

  useEffect(() => {
    sanityCheck();
  }, [ctx.rule_environment]);

  const handleChange = (event: React.ChangeEvent<{ value: unknown }>) => {
    var new_env = event.target.value as string;
    if (new_env === "Other") {
      var own_environment = prompt("Please enter a custom environment");
      new_env += " -> " + own_environment;
    }
    ctx.setRuleEnvironment(new_env);

    sanityCheck();
  };

  return (
    <div>
      <FormControl variant="outlined" className={classes.formControl}>
        <TextField
          id="demo-simple-select-outlined"
          variant={"outlined"}
          value={currentEnv}
          disabled={true}
          label="Environment"
          style={{ width: 300 }}
        ></TextField>
      </FormControl>
    </div>
  );
}

export default Zone;
