import React from "react";
import classes from "./ui.module.css";
// import ListAlertItem from "./ListAlertItem";
import ListAlprItem from "./ListAlprItem";
import Spinner from "../../spinner/Spinner";
import { MainContext } from "../containers/HomePage/HomePage";
import FlipMove from "react-flip-move";

interface review_logs {
  created: number;
  log_username: string;
  review_status: string;
  content: string;
}

interface EventType {
  doc_id: string;
  track_id: string;
  elastic_index: string;
  event_type: string;
  data_origin: string;
  site_id: number;
  site_name: string;
  sensor_id_str: string;
  sensor_human_name: string;
  media_url: string;
  image_height: number;
  image_width: number;
  created: number;
  classifier: string;
  probabilities: number[] | string[];
  class_labels: string[];
  message: string;
  improve_accuracy_flag: boolean;
  improve_accuracy_username: string | null;
  improve_accuracy_timestamp: string | null;
  suppress_flag: boolean;
  record_type: string;
  review_logs?: review_logs[] | [];
  alpr?: any;
}

interface ListProps {
  activatePrevButton: React.Dispatch<any>;
  PrevORNext: React.Dispatch<any>;
  getActiveItem: React.Dispatch<any>;
  type: string;
  lastImage: (e: any) => void;
  selectedId: string;
  alertList: EventType[];
  openModal: (id: number | string) => void;
  loading: boolean;
}

const ListAlprEvents: React.FC<ListProps> = ({
  activatePrevButton,
  PrevORNext,
  getActiveItem,
  type,
  openModal,
  alertList,
  selectedId,
  lastImage,
  loading,
}) => {
  const { showGraph } = React.useContext(MainContext);
  if (type === "filtered" || type === "alerted" || type === "filtered") {
    console.debug("setting last image");
  }
  console.debug("setting selectedId in ListAlpr", selectedId);
  console.debug(
    "setting selectedId in ListAlpr2",
    alertList.map((item: EventType, index: number) => item.doc_id)
  );
  return (
    <div className={classes["items-wrapper"]} style={{ height: "100%" }}>
      <FlipMove>
        {alertList.map((item: EventType, index: number) => {
          if (!item.alpr) {
            return;
          }
          return (
            <>
              <p
                key={item.doc_id}
                className={
                  item.doc_id === selectedId ? classes.item3 : classes.item2
                }
                style={!showGraph ? { maxWidth: 450 } : {}}
                onClick={() => {
                  openModal(item.doc_id);
                  getActiveItem(item.doc_id);
                  activatePrevButton("able");
                  PrevORNext((prev: string) => {
                    return "";
                  });
                  console.debug("clicked event ", item);
                }}
              >
                {alertList.length > 5 ? (
                  <ListAlprItem
                    key={item.doc_id}
                    renderRef={alertList.length - 5 === index + 1}
                    lastImage={
                      type === "filtered" ||
                      type === "alerted" ||
                      type === "all" ||
                      type === "flagged"
                        ? lastImage
                        : (e = 0) => {}
                    }
                    alert={item}
                    type={type}
                  />
                ) : (
                  <ListAlprItem
                    key={item.doc_id}
                    renderRef={alertList.length === index + 1}
                    lastImage={
                      type === "all" ||
                      type === "alerted" ||
                      type === "filtered"
                        ? lastImage
                        : (e = 0) => {}
                    }
                    alert={item}
                    type={type}
                  />
                )}
              </p>
            </>
          );
        })}
      </FlipMove>
      {loading && (
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Spinner />
        </div>
      )}
    </div>
  );
};

export default ListAlprEvents;
