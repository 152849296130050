import React, { useState } from "react";
import classes from "./ui.module.css";
import ListAlertItem from "./ListAlertItem";
import Spinner from "../../spinner/Spinner";
import { MainContext } from "../containers/HomePage/HomePage";
import FlipMove from "react-flip-move";

interface review_logs {
  created: number;
  log_username: string;
  review_status: string;
  content: string;
}

interface EventType {
  doc_id: string;
  track_id: string;
  elastic_index: string;
  event_type: string;
  data_origin: string;
  site_id: number;
  site_name: string;
  sensor_id_str: string;
  sensor_human_name: string;
  media_url: string;
  image_height: number;
  image_width: number;
  created: number;
  classifier: string;
  probabilities: number[] | string[];
  class_labels: string[];
  message: string;
  improve_accuracy_flag: boolean;
  improve_accuracy_username: string | null;
  improve_accuracy_timestamp: string | null;
  suppress_flag: boolean;
  record_type: string;
  review_logs?: review_logs[] | [];
}

interface AlertType {
  alert_id: string;
  site_id: number;
  site_name: string;
  sensor_human_name: string;
  sensor_id_str: string;
  probability: number;
  created: number;
  media_url: string;
  message: string;
  alert_type: string;
  type?: string;
  class_label: string;
  improve_accuracy_flag: boolean;
}

interface ListProps {
  activatePrevButton: React.Dispatch<any>;
  selectedDataAlert: any;
  activeItem: any;
  filterString: string;
  selectedAlert: any;
  PrevORNext: React.Dispatch<any>;
  auto_play_Func: React.Dispatch<any>;
  getActiveItem: React.Dispatch<any>;
  type: string;
  lastImage: (e: any) => void;
  selectedId: string;
  alertList: EventType[];
  openModal: (id: number | string) => void;
  loading: boolean;
}

const ListAlert: React.FC<ListProps> = ({
  selectedDataAlert,
  activatePrevButton,
  selectedAlert,
  activeItem,
  filterString,
  PrevORNext,
  getActiveItem,
  auto_play_Func,
  type,
  openModal,
  alertList,
  selectedId,
  lastImage,
  loading,
}) => {
  const { showGraph } = React.useContext(MainContext);
  if (type === "filtered" || type === "alerted" || type === "filtered") {
    console.debug("setting last image");
  }

  const [item_ID, setItemID] = useState(alertList[0]?.doc_id);

  // const [item_ID, setItemID] = useState(selectedId);
  const [recordType, setRecordType] = useState(alertList[0]?.record_type);

  const activeAlertOrInference =
    recordType === "alert" && selectedAlert?.doc_id
      ? classes.alerts
      : classes.item3;

  return (
    <div className={classes["items-wrapper"]} style={{ height: "100%" }}>
      <FlipMove>
        {alertList?.map((item: EventType, index: number) => {
          return (
            <>
              <p
                key={item.doc_id}
                style={!showGraph ? { maxWidth: 450 } : {}}
                onClick={() => {
                  openModal(item.doc_id);
                  setItemID(item.doc_id);
                  setRecordType(item.record_type);
                  getActiveItem(item.doc_id);
                  activatePrevButton("able");

                  PrevORNext((prev: string) => {
                    return "";
                  });
                  if (
                    filterString === "silenced" ||
                    filterString === "unsilenced" ||
                    filterString === "flagged"
                  ) {
                    auto_play_Func(false);
                  } else if (selectedDataAlert.inference_data.length > 1) {
                    auto_play_Func(true);
                  } else if (selectedDataAlert.inference_data.length <= 1) {
                    auto_play_Func(false);
                  }
                }}
                className={
                  item.doc_id === selectedAlert.doc_id &&
                  selectedAlert?.record_type === "alert"
                    ? classes.alerts
                    : item.doc_id === selectedAlert.doc_id &&
                      selectedAlert?.record_type !== "alert"
                    ? classes.item3
                    : classes.item2
                }
              >
                {alertList.length > 5 ? (
                  <ListAlertItem
                    autoPlayFunc={auto_play_Func}
                    key={item.doc_id}
                    renderRef={alertList.length - 5 === index + 1}
                    lastImage={
                      type === "filtered" ||
                      type === "alerted" ||
                      type === "all" ||
                      type === "flagged"
                        ? lastImage
                        : (e = 0) => {}
                    }
                    alert={item}
                    type={type}
                  />
                ) : (
                  <ListAlertItem
                    autoPlayFunc={auto_play_Func}
                    key={item.doc_id}
                    renderRef={alertList.length === index + 1}
                    lastImage={
                      type === "all" ||
                      type === "alerted" ||
                      type === "filtered"
                        ? lastImage
                        : (e = 0) => {}
                    }
                    alert={item}
                    type={type}
                  />
                )}
              </p>
            </>
          );
        })}
      </FlipMove>
      {loading && (
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Spinner />
        </div>
      )}
    </div>
  );
};

export default ListAlert;
