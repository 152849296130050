import React from "react";
import clsx from "clsx";
import { makeStyles } from "@material-ui/core/styles";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormControl from "@material-ui/core/FormControl";
import Radio, { RadioProps } from "@material-ui/core/Radio";

const useStyles = makeStyles({
  root: {
    "&:hover": {
      backgroundColor: "transparent",
    },
  },
  icon: {
    borderRadius: "50%",
    width: 22,
    height: 22,
    boxShadow:
      "inset 0 0 0 1px rgba(16,22,26,.2), inset 0 -1px 0 rgba(16,22,26,.1)",
    backgroundColor: "#f5f8fa",
    backgroundImage:
      "linear-gradient(180deg,hsla(0,0%,100%,.8),hsla(0,0%,100%,0))",
    "$root.Mui-focusVisible &": {
      outline: "2px auto rgba(19,124,189,.6)",
      outlineOffset: 2,
    },
    "input:disabled ~ &": {
      boxShadow: "none",
      background: "rgba(206,217,224,.5)",
    },
  },
  checkedIcon: {
    backgroundColor: "#F32424",
    backgroundImage:
      "linear-gradient(180deg,hsla(0,0%,100%,.1),hsla(0,0%,100%,0))",
    "&:before": {
      display: "block",
      width: 22,
      height: 22,
      backgroundImage: "radial-gradient(#fff,#fff 28%,transparent 32%)",
      content: '""',
    },
  },
});

function StyledRadio(props: RadioProps) {
  const classes = useStyles();

  return (
    <Radio
      className={classes.root}
      disableRipple
      color="default"
      checkedIcon={<span className={clsx(classes.icon, classes.checkedIcon)} />}
      icon={<span className={classes.icon} />}
      {...props}
    />
  );
}

const CustomizedRadios: React.FC<{
  disablePrevBtn: React.Dispatch<any>;
  getFilterString: React.Dispatch<any>;
  autoPlay: React.Dispatch<any>;
  loading: boolean;
  flaggedButton: any;
  groupid?: number;
  showFilteredEvents: boolean;
  optionHander: (opt: string) => void;
}> = ({
  optionHander,
  getFilterString,
  autoPlay,
  loading,
  flaggedButton,
  groupid,
  showFilteredEvents,
  disablePrevBtn,
}) => {
  const onChangeHandler = (event: string) => {
    optionHander(event);
  };

  return (
    <FormControl component="fieldset">
      <RadioGroup
        defaultValue="all"
        aria-label="gender"
        name="customized-radios"
        row
        onChange={(event, value) => {
          if (loading) {
            return;
          }
          getFilterString(value);
          onChangeHandler(value);
          if (
            value === "silenced" ||
            value === "unsilenced" ||
            value === "flagged"
          ) {
            autoPlay(false);
          } else {
            autoPlay(true);
          }
          flaggedButton(value);
          disablePrevBtn("disabled");
        }}
      >
        <div style={{ marginLeft: 17 }}>
          <FormControlLabel
            value="all"
            control={<StyledRadio />}
            label="All events"
            disabled={loading || groupid === -1}
          />
          {/* <FormControlLabel
            value="alerted"
            control={<StyledRadio />}
            label="Alerts"
            disabled={loading || groupid === -1}
          /> */}
          {showFilteredEvents && (
            <FormControlLabel
              value="filtered"
              control={<StyledRadio />}
              label="Filtered out events"
              disabled={loading || groupid === -1}
            />
          )}
          <FormControlLabel
            value="alerted"
            control={<StyledRadio />}
            label="Alerts"
            disabled={loading || groupid === -1}
          />
          {/* <FormControlLabel
            value="all"
            control={<StyledRadio />}
            label="All events"
            disabled={loading || groupid === -1}
          /> */}
          <FormControlLabel
            value="flagged"
            control={<StyledRadio />}
            label="Flagged for training"
            disabled={loading || groupid === -1}
            // disabled
          />
          <FormControlLabel
            value="silenced"
            control={<StyledRadio />}
            label="Silenced alerts"
            disabled={loading || groupid === -1}
          />
          <FormControlLabel
            value="unsilenced"
            control={<StyledRadio />}
            label="Un-Silenced alerts"
            disabled={loading || groupid === -1}
          />
        </div>
      </RadioGroup>
    </FormControl>
  );
};

interface AlprProps {
  disablePrevBtn: React.Dispatch<any>;
  loading: boolean;
  groupid?: number;
  showFilteredEvents: boolean;
  optionHander: (opt: string) => void;
}

export const AlprOptions = ({
  optionHander,
  loading,
  groupid,
  showFilteredEvents,
  disablePrevBtn,
}: AlprProps) => {
  const onChangeHandler = (event: string) => {
    console.debug("Radio values", event);
    optionHander(event);
  };
  return (
    <FormControl component="fieldset">
      <RadioGroup
        defaultValue="alerted"
        aria-label="gender"
        name="customized-radios"
        row
        onChange={(event, value) => {
          if (loading) {
            return;
          }
          onChangeHandler(value);
          disablePrevBtn("disabled");
        }}
      >
        <div style={{ marginLeft: 17 }}>
          <FormControlLabel
            value="alerted"
            control={<StyledRadio />}
            label="Add Vehicle to Whitelist"
            disabled={loading || groupid === -1}
          />
          <FormControlLabel
            value="whitelisted"
            control={<StyledRadio />}
            label="Whitelisted Vehicles"
            disabled={loading || groupid === -1}
          />
        </div>
      </RadioGroup>
    </FormControl>
  );
};

export default CustomizedRadios;
